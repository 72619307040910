import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from "reactstrap";
import {
  deleteCity,
  destroyDeleteErrorCity,
  filterCities,
  getCities,
} from "../../../redux/actions/package";
import {
  Edit,
  Delete,
  Publish,
  Add,
  MoreVert,
  ExpandMore,
  CheckCircle,
  Cancel,
} from "@material-ui/icons";
import AddCity from "./AddCity";
import EditCity from "./EditCity";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AvatarGroup, Pagination } from "@material-ui/lab";
import axios from "axios";
import {
  baseUrl,
  baseUrlImage,
  getCityEndpoint,
  imagePath,
} from "../../../redux/url";
import ImportCity from "./ImportCity";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function City() {
  const classes = useStyles();
  const [modal3, setModal3] = useState(false);
  const [showAdd, setShowAdd] = useState({});
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [open, setopen] = useState(false);
  const [open3, setopen3] = useState(false);
  const [open1, setopen1] = useState(false);
  const [open2, setopen2] = useState(false);
  const [deleted, setDeleted] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [totalLength, setTotalLength] = useState(1);
  const [keyWord, setKeyWord] = useState("");
  const { success } = useSelector((state) => state.city);
  const { edited } = useSelector((state) => state.editCity);
  const [sightData, setSightData] = useState([]);
  const { isLoading, cities, isError } = useSelector((state) => state.getCity);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { isDeleted, isError: deleteError } = useSelector(
    (state) => state.deleteC
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose4 = () => {
    var x = document.getElementsByTagName("BODY")[0];
    x.style.paddingRight = "0";
    setAnchorEl(null);
  };
  const handleClose3 = () => {
    setopen3(false);
  };
  const handleClose = () => {
    setopen(false);
  };
  const handleClose1 = () => {
    setopen1(false);
  };
  const handleClose2 = () => {
    setopen2(false);
  };
  const editShowFunction = (data) => {
    setModal1(true);
    setShowAdd(data);
  };
  const deleteCityFunction = (id) => {
    dispatch(deleteCity({ cityId: id }));
  };
  const searchChangeHandler = (e) => {
    setKeyWord(e.target.value);
    if (e.target.value === "") {
      dispatch(
        getCities({
          skip: page * count - count,
          limit: count,
        })
      );
    } else {
      setPage(1);
      dispatch(
        filterCities({
          key: e.target.value,
          skip: 0,
          limit: count,
        })
      );
    }
  };
  const paginationHandler = (e, v) => {
    setPage(v);
    if (keyWord === "") {
      dispatch(
        getCities({
          skip: v * count - count,
          limit: count,
        })
      );
    } else {
      dispatch(
        filterCities({
          key: keyWord,
          skip: v * count - count,
          limit: count,
        })
      );
    }
  };
  const viewSight = (info) => {
    setSightData(info);
    setModal3(true);
  };
  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== ""
    ) {
    } else {
      history.push("/login");
    }
    if (isDeleted && isDeleted !== "") {
      setDeleted(isDeleted);
      setopen2(true);
    }
    if (deleteError && deleteError !== "") {
      setDeleted(deleteError);
      setopen2(true);
    }
    dispatch(
      getCities({
        skip: page * count - count,
        limit: count,
      })
    );
    axios.get(baseUrl + getCityEndpoint).then((result) => {
      setTotalLength(result.data.length);
    });
    return () => {
      return dispatch(destroyDeleteErrorCity());
    };
  }, [success, edited, isDeleted, modal2]);
  return (
    <div>
      <Container className="top-100">
        <Row>
          <Col className="order-xl-1" lg="12" xl="12">
            <Card className="bg-secondary shadow">
              <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col sm="12" lg="4">
                          <h3 className="mb-0">Cities</h3>
                        </Col>
                        <Col sm="12" lg="4">
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                              <TextField
                                id="input-with-icon-grid"
                                label="Search City"
                                className="1234567890"
                                onChange={searchChangeHandler}
                              />
                            </Grid>
                          </Grid>
                        </Col>
                        <Col className="text-right" sm="12" lg="4">
                          <Button
                            color="primary"
                            onClick={() => {
                              return handleClose4(), setModal(true);
                            }}
                            size="sm"
                          >
                            Add City
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => {
                              return (
                                handleClose4(), setModal2(true), setPage(1)
                              );
                            }}
                            size="sm"
                          >
                            Import City
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    {isError ? (
                      <>{isError}</>
                    ) : isLoading ? (
                      <>
                        <tbody>
                          <LinearProgress />
                        </tbody>
                      </>
                    ) : cities.success === false ? (
                      <>
                        <Alert severity="error">{cities.message}</Alert>
                      </>
                    ) : (
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">City Name</th>
                            <th scope="col">Banner Image</th>
                            <th scope="col">is Favourite</th>
                            <th cope="col">Sightseing</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        {cities.length > 0 &&
                          cities.map((row, i) => {
                            return (
                              <tbody>
                                <tr>
                                  <th scope="row">{row.cityName}</th>
                                  <th scope="row">
                                    {row.cityImages?.length > 0 &&
                                      row.cityImages.map((e, i) => {
                                        if (e.imageType === "bannerImage") {
                                          return (
                                            <Avatar
                                              src={baseUrlImage + imagePath + e.imageName}
                                            />
                                          );
                                        }
                                      })}
                                  </th>
                                  <td>
                                    {row.isFavourite ? (
                                      <CheckCircle />
                                    ) : (
                                      <Cancel />
                                    )}
                                  </td>
                                  <td>
                                    <Button
                                      variant="contained"
                                      onClick={() => viewSight(row)}
                                    >
                                      View
                                    </Button>
                                  </td>
                                  <td>
                                    {" "}
                                    <Edit
                                      style={{ cursor: "pointer" }}
                                      onClick={() => editShowFunction(row)}
                                    />{" "}
                                  </td>
                                  <td>
                                    <Delete
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        deleteCityFunction(row._id)
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </Table>
                    )}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="float-right m-3">
              <Pagination
                count={Math.ceil(totalLength / count)}
                page={page}
                onChange={paginationHandler}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <AddCity modal={modal} setModal={setModal} setopen={setopen} />
      {modal1 && (
        <EditCity
          modal={modal1}
          setModal={setModal1}
          data={showAdd}
          setOpen={setopen1}
        />
      )}{" "}
      <ImportCity modal={modal2} setModal={setModal2} setOpen={setopen3} />
      <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
        <Alert onClose={handleClose3} severity="success">
          "Uploaded Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          "Added Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="success">
          "Updated Successfully"
        </Alert>
      </Snackbar>
      {
        <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
          <Alert onClose={handleClose2} severity="error">
            {deleted}
          </Alert>
        </Snackbar>
      }
      {modal3 && (
        <Modal isOpen={modal3} toggle={() => setModal3(false)}>
          <ModalBody>
            <Container>
              <Row>
                <Col className="order-xl-1" lg="12" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">{"Sights"}</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {sightData.sightSeeing.length > 0 ? (
                        sightData.sightSeeing.map((e, i) => {
                          return (
                            <>
                              <Accordion
                                expanded={expanded === `panel${i}`}
                                onChange={handleChange(`panel${i}`)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                >
                                  <Typography className={classes.heading}>
                                    {e.header}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography className={classes.heading}>
                                    {sightData.cityImages?.length > 0 &&
                                      sightData.cityImages.map((elm, ind) => {
                                        if (
                                          elm.imageType === `sightImage${i}`
                                        ) {
                                          return (
                                            <Avatar
                                              src={baseUrlImage + imagePath + elm.imageName}
                                            />
                                          );
                                        }
                                      })}
                                  </Typography>
                                  <br />
                                  <Typography>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: e.description,
                                      }}
                                    ></div>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          );
                        })
                      ) : (
                        <>No Sights Found</>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal3(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export default City;
