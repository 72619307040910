import { CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { baseUrl, importCityEndpoint } from "../../../redux/url";

function ImportCity({ setOpen, setModal, modal }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [error, setError] = useState("");
  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => {
    setOpen1(false);
  };
  const formSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("cityFile", file);
    axios({
      method: "post",
      url: `${baseUrl}${importCityEndpoint}`,
      data: formData,
    })
      .then((result) => {
        if (result.data.success == false) {
          setLoading(false);
          setError(result.data.message);
          setOpen1(true);
        } else {
          setLoading(false);
          setOpen(true);
          setFile({});
          setError("");
          setModal(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
        setOpen1(true);
      });
  };
  useEffect(() => {}, [error, loading]);
  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalBody>
        <Container>
          <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
            <Alert onClose={handleClose1} severity="error">
              {error}
            </Alert>
          </Snackbar>
          <Row>
            <Col lg="12">
              <Form onSubmit={formSubmit}>
                <FormGroup>
                  <label className="form-control-label">
                    Select File For Category module
                  </label>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Input
                      className="form-control-alternative"
                      id="fileSelect"
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  )}{" "}
                </FormGroup>
                <Button color="primary" type="submit" disabled={loading}>
                  {loading ? "submiting.." : "Submit"}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
}

export default ImportCity;
