import { Bookmark, FiberManualRecord, Star } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  getGallery,
  getParticularGallery,
} from "../../../redux/actions/gallery";
import { getCities } from "../../../redux/actions/package";
import { useHistory } from "react-router";
import { LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { baseUrlImage, imagePath } from "../../../redux/url";
import Lightbox from "react-image-lightbox";

function GalleryDetailDisplay(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, isError, parGallery, gallery } = useSelector(
    (state) => state.getGallery
  );
  const { cities } = useSelector((state) => state.getCity);
  var count = 0;
  const [autoPlay, setAutoPlay] = useState({
    a0: false,
  });
  const [favourite, setFavourite] = useState(false);
  const autoPlayFunction = (name, value) => {
    setAutoPlay({
      ...autoPlay,
      [name]: value,
    });
  };
  const imagesArray = [
    "https://wallpaperaccess.com/full/799794.jpg",
    "https://wallpapercave.com/wp/wp2555696.jpg",
    "https://wallpapercave.com/wp/wp2555696.jpg",
    "https://wallpapercave.com/wp/wp2555696.jpg",
    "https://wallpaperaccess.com/full/799794.jpg",
    "https://wallpapercave.com/wp/wp2555696.jpg",
    "https://wallpapercave.com/wp/wp2555696.jpg",
  ];
  const [photoIndex, setPhotoIndex] = useState(0);
  const [state, setState] = useState({
    isOpen: false,
  });
  const [images, setImages] = useState([]);
  const imageClicked = (image, i) => {
    setPhotoIndex(i);
    parGallery[0]?.galleryImages.map((e, ind) => {
      return setImages((old) => [
        ...old,
        baseUrlImage + imagePath + e.imageName,
      ]);
    });
    setState({ isOpen: true })
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getParticularGallery({
        key: props.match.params.id,
      })
    );
    dispatch(getGallery());
    dispatch(getCities());
  }, [window.location.pathname]);
  return (
    <>
      <Container
        style={{ marginTop: "100px", maxWidth: "1300px", marginBottom: "50px" }}
        className="gallery gallery-detail"
      >
        {state.isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setState({ isOpen: false })}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
        <Row>
          {isLoading ? (
            <LinearProgress />
          ) : isError ? (
            <Alert severity="error">{isError}</Alert>
          ) : parGallery.success === false ? (
            <Alert severity="error">{parGallery.message}</Alert>
          ) : (
            parGallery.length > 0 && (
              <>
                <Col lg="12">
                  <div className="heading">
                    <div className="city-name">
                      {" "}
                      <FiberManualRecord
                        style={{ color: "#D03000", marginBottom: "10px" }}
                      />
                      {cities.length > 0 &&
                        cities.map((e, i) => {
                          if (e.cityId === parGallery[0]?.cityName) {
                            return e.cityName;
                          }
                        })}
                    </div>
                    <h3>{parGallery[0]?.tittle}</h3>
                    <p>{parGallery[0]?.cityDescription}</p>
                  </div>
                </Col>
                <Col lg="12">
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                  >
                    <Masonry gutter={"10px"}>
                      {parGallery[0]?.galleryImages?.length > 0 &&
                        parGallery[0]?.galleryImages.map((image, i) => (
                          <img
                            onClick={() => imageClicked(image, i)}
                            key={i}
                            src={baseUrlImage + imagePath + image.imageName}
                            alt="masonary"
                            style={{
                              width: "100%",
                              display: "block",
                              borderRadius: "20px",
                            }}
                          />
                        ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </Col>
                {parGallery[0].cityPackages?.length > 0 &&
                  parGallery[0].cityPackages.map((e, i) => {
                    return (
                      <Col
                        lg="12"
                        className="package"
                        onClick={() => history.push(`/package/${e.packageId}`)}
                      >
                        {e.packageImages?.length > 0 && (
                          <img
                            src={
                              baseUrlImage +
                              imagePath +
                              e.packageImages[0]?.imageName
                            }
                            alt="package"
                          />
                        )}
                        <div className="package-detail">
                          <div className="top">
                            <FiberManualRecord style={{ color: "#D03000" }} />
                            {cities.length > 0 &&
                              cities.map((elm, ind) => {
                                if (elm.cityId === e.cityName) {
                                  return elm.cityName;
                                }
                              })}
                          </div>
                          <div className="center">
                            <div className="rating">
                              <Star style={{ color: "#D03000" }} />
                              <Star style={{ color: "#D03000" }} />
                              <Star style={{ color: "#D03000" }} />
                              <Star style={{ color: "#D03000" }} />
                              <Star style={{ color: "grey" }} />
                            </div>
                            <h3>{e.packageName}</h3>
                            <p>{e.packageDescription}</p>
                          </div>
                          <div className="bottom">
                            {parseInt(e.numberOfNights) + 1} days |{" "}
                            <b>from &#8377;{e.packagePrice}</b>
                          </div>
                          <div
                            className="add-bookmark"
                            style={{
                              backgroundColor: favourite ? "#D03000" : "white",
                            }}
                            onClick={() => setFavourite(!favourite)}
                          >
                            {favourite ? (
                              <Bookmark
                                className="icon"
                                style={{ color: "white" }}
                              />
                            ) : (
                              <Bookmark
                                className="icon"
                                style={{ color: "#D03000" }}
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </>
            )
          )}
          <Col lg="12" className="see-also">
            <h3>See Also Gallery</h3>
          </Col>
          {gallery.map((e, i) => {
            if (e.galleryId !== props.match.params.id && count < 2) {
              count++;
              return (
                <Col
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="image-section"
                  onMouseOver={() => autoPlayFunction(`a${i}`, true)}
                  onMouseOut={() => autoPlayFunction(`a${i}`, false)}
                  onClick={() => history.push(`/gallery/${e.galleryId}`)}
                >
                  <div className="top">
                    <div className="city-name">
                      {" "}
                      <FiberManualRecord
                        style={{ color: "#D03000", marginBottom: "10px" }}
                      />
                      {cities.length > 0 &&
                        cities.map((elm, ind) => {
                          if (elm.cityId === e.cityName) {
                            return elm.cityName;
                          }
                        })}
                    </div>
                    <h3>{e.tittle}</h3>
                  </div>
                  <OwlCarousel
                    className="owl-theme"
                    margin={10}
                    loop={false}
                    autoplay={autoPlay[`a${i}`]}
                    autoplayTimeout={800}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      450: {
                        items: 1,
                      },
                      600: {
                        items: 2,
                      },
                      1000: {
                        items: 3,
                      },
                    }}
                  >
                    {e.galleryImages.map((elm, ind) => {
                      return (
                        <div className="item">
                          <img
                            src={baseUrlImage + imagePath + elm.imageName}
                            alt={"item" + i}
                          />
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </Col>
              );
            }
          })}
        </Row>
      </Container>
    </>
  );
}

export default GalleryDetailDisplay;
