import axios from "axios";
import {
  ADD_EXCLUSIONS_ERROR,
  ADD_EXCLUSIONS_LOADING,
  ADD_EXCLUSIONS_SUCCESS,
  DELETE_EXCLUSIONS_ERROR,
  DELETE_EXCLUSIONS_LOADING,
  DELETE_EXCLUSIONS_SUCCESS,
  DESTROY_ERROR_ADD_EXCLUSIONS,
  DESTROY_ERROR_EDIT_EXCLUSIONS,
  DESTROY_EXCLUSIONS_ADD,
  DESTROY_EXCLUSIONS_DELETE,
  DESTROY_EXCLUSIONS_EDIT,
  EDIT_EXCLUSIONS_ERROR,
  EDIT_EXCLUSIONS_LOADING,
  EDIT_EXCLUSIONS_SUCCESS,
  GET_EXCLUSIONS_ERROR,
  GET_EXCLUSIONS_LOADING,
  GET_EXCLUSIONS_SUCCESS,
} from "../constants";
import {
  baseUrl,
  filterExclusionEndpoint,
  addExclusionsEndpoint,
  deleteExclusionsEndpoint,
  editExclusionsEndpoint,
  getExclusionsEndpoint,
} from "../url";

export const getExclusions = (data) => async (dispatch) => {
  dispatch({ type: GET_EXCLUSIONS_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getExclusionsEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_EXCLUSIONS_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_EXCLUSIONS_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getExclusionsEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_EXCLUSIONS_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_EXCLUSIONS_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_EXCLUSIONS_ERROR, payload: error.message });
  }
};

export const addExclusions = (data) => async (dispatch) => {
  dispatch({ type: ADD_EXCLUSIONS_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addExclusionsEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_EXCLUSIONS_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: ADD_EXCLUSIONS_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_EXCLUSIONS_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_EXCLUSIONS_ERROR, payload: error.message });
  }
};

export const editExclusions = (data) => async (dispatch) => {
  dispatch({ type: EDIT_EXCLUSIONS_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editExclusionsEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_EXCLUSIONS_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: EDIT_EXCLUSIONS_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_EXCLUSIONS_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_EXCLUSIONS_ERROR, payload: error.message });
  }
};

export const deleteExclusions = (data) => async (dispatch) => {
  dispatch({ type: DELETE_EXCLUSIONS_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteExclusionsEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_EXCLUSIONS_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_EXCLUSIONS_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_EXCLUSIONS_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_EXCLUSIONS_ERROR, payload: error.message });
  }
};

export const destroyAddExclusion = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_EXCLUSIONS_ADD, payload: "" });
};
export const destroyEditExclusion = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_EXCLUSIONS_EDIT, payload: "" });
};
export const destroyDeleteExclusion = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_EXCLUSIONS_DELETE, payload: "" });
};
export const destroyAddExclusionError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_ADD_EXCLUSIONS, payload: "" });
};
export const destroyEditExclusionError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_EDIT_EXCLUSIONS, payload: "" });
};
export const destroyDeleteErrorExclusions = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_EXCLUSIONS", payload: "" });
};

export const filterExclusions = (data) => async (dispatch) => {
  dispatch({ type: GET_EXCLUSIONS_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterExclusionEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_EXCLUSIONS_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_EXCLUSIONS_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_EXCLUSIONS_ERROR, payload: error.message });
  }
};
