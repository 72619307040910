import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import {
  addHotel,
  destroyError,
  destroyHotelAdd,
  getCities,
} from "../../../redux/actions/package";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Select from "react-select"
const initialState = {
  name: "",
  contact: "",
  address: "",
  rating: "",
  cityName:""
};
function AddHotel({ modal, setModal, setopen }) {
  const [files, setFiles] = useState({});
  const [data, setData] = useState(initialState);
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((state) => state.addHotel);
  const {isLoading, isError, cities} = useSelector(state=>state.getCity)
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState("");
  const onChangeHandler = (e) => {
    if(e.target){
      const { name, value } = e.target;
      setData({
        ...data,
        [name]: value,
      });
    } else{
      setData({
        ...data,
        ["cityName"]: e.value,
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const toggle = () => setModal(!modal);

  const formSubmitted = (e) => {
    // e.preventDefault()
    const formData = new FormData();
    formData.append("hotelName", data.name);
    formData.append("contactNumber", data.contact);
    formData.append("contactAddress", data.address);
    formData.append("hotelRating", data.rating);
    formData.append("hotelCity", data.cityName);
    formData.append("userId", localStorage.getItem("userId"))
    for (var i = 0; i < files.length; i++) {
      formData.append("hotelImage", files[i]);
    }
    dispatch(addHotel(formData));
  };
  const closeModal = () => {
    setModal(false);
    setopen(true);
    setData(initialState)
    setFiles([])
  };
  useEffect(() => {
    dispatch(getCities())
    if (success !== undefined && success !== "") {
      closeModal();
    }
    if (error !== undefined && error !== "") {
      setEdit(error);
      setOpen(true);
    }
    return () => {
      return dispatch(destroyHotelAdd()), dispatch(destroyError("hotel"));
    };
  }, [success, error]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            {edit && (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {edit}
                </Alert>
              </Snackbar>
            )}
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{"Add Hotel"}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Fill the form
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Hotel Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Hotel Name"
                              type="text"
                              name="name"
                              value={data.name}
                              onChange={onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          for="exampleSelect"
                        >
                          City Name
                        </label>
                        <Select
                        name="cityName"
                          value={{value:data.cityName,label:data.cityName}}
                          onChange={onChangeHandler}
                          options={
                            cities.length > 0 &&
                            cities.map((e, i) => {
                              return { value: e.cityName, label: e.cityName };
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Contact Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Contact Number"
                              type="text"
                              name="contact"
                              value={data.contact}
                              onChange={onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label>Contact Address</label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Contact Address"
                              rows="4"
                              type="textarea"
                              name="address"
                              value={data.address}
                              onChange={onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              for="exampleSelect"
                            >
                              Rating
                            </label>
                            <Input
                              type="select"
                              className="form-control-alternative"
                              name="select"
                              id="exampleSelect"
                              name="rating"
                              value={data.rating}
                              onChange={onChangeHandler}
                            >
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label>Choose Image</label>
                            <Input
                              className="form-control-alternative"
                              type="file"
                              name="address"
                              onChange={(e) => setFiles(e.target.files)}
                              multiple
                              accept="image/*"
                            />
                          </FormGroup>
                        </Col>
                      </>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={formSubmitted}>
            Add Hotel
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddHotel;
