import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import {
  addCity,
  destroyCityAdd,
  destroyError,
} from "../../../redux/actions/package";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, makeStyles, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";
import Select from "react-select";

function AddCity({ modal, setModal, setopen }) {
  const [modal3, setModal3] = useState(false);
  const [name, setName] = useState("");
  const [edit, setEdit] = useState("");
  const [sightShow, setSightShow] = useState(false);
  const [done, setDone] = useState(false);
  const { isLoading, success, isError } = useSelector((state) => state.city);
  const [files, setFiles] = useState([]);
  const [sightFile, setSightFile] = useState([]);
  const [open, setOpen] = useState(true);
  const [favourite, setFavourite] = useState(false);
  const [sight, setSight] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [sightName, setSightName] = useState("");
  const [sightDescription, setSightDescription] = useState(EditorState.createEmpty());
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const formSubmitted = (e) => {
    const formData = new FormData();
    formData.append("cityName", name);
    formData.append("userId", localStorage.getItem("userId"));
    formData.append(
      "cityDescription",
      draftToHtml(convertToRaw(description.getCurrentContent())).toString()
    );
    formData.append("isFavourite", favourite);
    for (var i = 0; i < files.length; i++) {
      formData.append("cityImage", files[i]);
    }
    for (var i = 0; i < bannerImage.length; i++) {
      formData.append("bannerImage", bannerImage[i]);
    }
    if (sight.length !== 0) {
      for (var i = 0; i < sight.length; i++) {
        formData.append(`sightSeeing[${i}][header]`, sight[i]["header"]);
        formData.append(
          `sightSeeing[${i}][description]`,
          sight[i]["description"]
        );
        formData.append(`sightImage${i}`, sight[i]["image"]);
      }
    }
    e.preventDefault();
    dispatch(addCity(formData)).then((result) => {});
    // closeModal()
    setDone(success);
  };
  const closeModal = () => {
    setModal(false);
    setopen(true);
    setSight([]);
    setSightShow(false);
    setFiles([]);
    setSightFile([]);
    setName("");
    setFavourite(false);
    setDescription("");
    setSightDescription(EditorState.createEmpty())
  };
  const fileChangeHandler = (e) => {
    setFiles(e.target.files);
  };
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  useEffect(() => {
    if (success !== undefined && success !== "") {
      closeModal();
    }
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setOpen(true);
    }
    return () => {
      return dispatch(destroyCityAdd()), dispatch(destroyError("city"));
    };
  }, [success, isError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody>
          <Container>
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Add City"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {edit && (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="error">
                          {edit}
                        </Alert>
                      </Snackbar>
                    )}
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                City
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="City name"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <Button
                                color="light"
                                onClick={() => setModal3(true)}
                              >
                                Add Sights
                              </Button>
                            </FormGroup>
                          </Col>
                          {sightShow && (
                            <Col lg="12">
                              {sight.length > 0 &&
                                sight.map((e, i) => {
                                  return (
                                    <div style={{ color: "red" }}>
                                      {e.header}:
                                      <i
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          setSight(
                                            sight.filter((elm) => elm !== e)
                                          )
                                        }
                                      >
                                        X
                                      </i>
                                    </div>
                                  );
                                })}
                            </Col>
                          )}
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Add Images
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="file"
                                name="images"
                                onChange={fileChangeHandler}
                                multiple
                                accept="image/*"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Add Banner Image
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="file"
                                name="images"
                                onChange={(e) => setBannerImage(e.target.files)}
                                accept="image/*"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label">
                                is favourite
                              </label>
                              <Select
                                value={{
                                  label: JSON.stringify(favourite),
                                  value: favourite,
                                }}
                                onChange={(elm) => setFavourite(elm.value)}
                                options={[
                                  { value: true, label: "true" },
                                  { value: false, label: "false" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Add Description
                              </label>
                              <Editor
                                editorState={description}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                  inline: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: { inDropdown: true },
                                  image: {
                                    uploadCallback: uploadImageCallBack,
                                    alt: { present: true, mandatory: true },
                                    previewImage: true,
                                  },
                                }}
                              />{" "}
                            </FormGroup>{" "}
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Add City
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
        {modal3 && (
          <Modal isOpen={modal3} toggle={() => setModal3(false)}>
            <ModalBody>
              <Container>
                <Row>
                  <Col className="order-xl-1" lg="12" xl="12">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">{"Sights"}</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Add Sights
                            </label>
                            <InputGroup>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                name="sightName"
                                placeholder="name"
                                value={sightName}
                                onChange={(e) => setSightName(e.target.value)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Add Description
                              </label>
                              <Editor
                                editorState={sightDescription}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e)=>setSightDescription(e)}
                                toolbar={{
                                  inline: { inDropdown: true },
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: { inDropdown: true },
                                  image: {
                                    uploadCallback: uploadImageCallBack,
                                    alt: { present: true, mandatory: true },
                                    previewImage: true,
                                  },
                                }}
                              />{" "}
                            </FormGroup>{" "}
                          </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Add Image
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="file"
                              name="sightImage"
                              onChange={(e) => setSightFile(e.target.files)}
                              accept="image/*"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Button
                              color="primary"
                              disabled={
                                sightName === "" ||
                                sightDescription === "" ||
                                sightFile.length === 0
                              }
                              onClick={(e) => {
                                return (
                                  e.preventDefault(),
                                  setSight((old) => [
                                    ...old,
                                    {
                                      header: sightName,
                                      description:  draftToHtml(convertToRaw(sightDescription.getCurrentContent())).toString(),
                                      image: sightFile[0],
                                    },
                                  ]),
                                  setSightName(""),
                                  setSightDescription("")
                                );
                              }}
                            >
                              Add
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          {sight.length > 0 &&
                            sight.map((e, i) => {
                              return (
                                <div style={{ color: "red" }}>
                                  <b>{e.image.name}</b>,{e.header}
                                  <i
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() =>
                                      setSight(sight.filter((elm) => elm !== e))
                                    }
                                  >
                                    X
                                  </i>
                                </div>
                              );
                            })}
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={sight.length === 0}
                onClick={() => {
                  return setModal3(false), setSightShow(true);
                }}
              >
                Save
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  return setModal3(false), setSight([]);
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </Modal>
    </div>
  );
}

export default AddCity;
