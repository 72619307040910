import {
  ADD_INCLUSIONS_ERROR,
  ADD_INCLUSIONS_LOADING,
  ADD_INCLUSIONS_SUCCESS,
  DELETE_INCLUSIONS_ERROR,
  DELETE_INCLUSIONS_LOADING,
  DELETE_INCLUSIONS_SUCCESS,
  DESTROY_ERROR_ADD_INCLUSIONS,
  DESTROY_ERROR_EDIT_INCLUSIONS,
  DESTROY_INCLUSIONS_ADD,
  DESTROY_INCLUSIONS_EDIT,
  EDIT_INCLUSIONS_ERROR,
  EDIT_INCLUSIONS_LOADING,
  EDIT_INCLUSIONS_SUCCESS,
  GET_INCLUSIONS_ERROR,
  GET_INCLUSIONS_LOADING,
  GET_INCLUSIONS_SUCCESS,
} from "../constants";

const initialStateHome = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  inclusions: [],
};

export function addInclusion(state = initialStateHome, action) {
  switch (action.type) {
    case ADD_INCLUSIONS_LOADING:
      return { ...state, isLoading: true };
    case ADD_INCLUSIONS_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_INCLUSIONS_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_INCLUSIONS_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_ERROR_ADD_INCLUSIONS:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getInclusion(state = initialStateHome, action) {
  switch (action.type) {
    case GET_INCLUSIONS_LOADING:
      return {...state, isLoading: true, inclusions: [] };
    case GET_INCLUSIONS_SUCCESS:
      return { ...state,isLoading: false, inclusions: action.payload };
    case GET_INCLUSIONS_ERROR:
      return { ...state,isLoading: false, inclusions: [], isError: action.payload };
    default:
      return state;
  }
}

export function editInclusion(state = initialStateHome, action) {
  switch (action.type) {
    case EDIT_INCLUSIONS_LOADING:
      return { ...state, isLoading: true };
    case EDIT_INCLUSIONS_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_INCLUSIONS_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_INCLUSIONS_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_ERROR_EDIT_INCLUSIONS:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function deleteInclusion(state = initialStateHome, action) {
  switch (action.type) {
    case DELETE_INCLUSIONS_LOADING:
      return { ...state, isLoading: true };
    case DELETE_INCLUSIONS_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_INCLUSIONS_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_INCLUSIONS":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}
