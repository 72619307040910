import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  destroyEditAd,
  destroyEditAdError,
  destroyEditLocation,
  destroyEditLocationError,
  editAd,
  editLocation,
} from "../../../redux/actions/settings";
import Select from "react-select";
import {
  deleteCityImage,
  destroyDeleteErrorImageCity,
} from "../../../redux/actions/package";
import { baseUrlImage, imagePath } from "../../../redux/url";

function EditAdvertisment({ modal, setModal, data, setOpen }) {
  const [name, setName] = useState("");
  const [open, setopen] = useState(true);
  const [edit, setEdit] = useState("");
  const [title, setTitle] = useState("");
  const [imageFile, setImageFile] = useState({});
  const [code, setCode] = useState("");
  const [adLocation, setAdLocation] = useState("");
  const dispatch = useDispatch();
  const { isLoading, isError, isEdited } = useSelector((state) => state.editAd);
  const {
    isLoading: deleteImageLoading,
    isError: deleteImageError,
    imageDeleted,
  } = useSelector((state) => state.deleteCityImage);
  const [open1, setOpen1] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [imgId, setImgId] = useState("");
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose = () => {
    setopen(false);
  };
  const toggle = () => setModal(!modal);

  const formSubmitted = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("adType", name);
    formData.append("userId", localStorage.getItem("userId"));
    if (name === "image") {
      formData.append("adImage", imageFile);
      formData.append("adLink", title);
    }
    if (name === "code") {
      formData.append("adCode", code);
    }
    formData.append("adLocation", adLocation);
    formData.append("adId", data._id);
    dispatch(editAd(formData));
  };
  const closeModal = () => {
    setModal(false);
    setOpen(true);
  };
  const deleteImageFunction = (id, imgIdf, data) => {
    dispatch(
      deleteCityImage({
        imageId: id,
      })
    );
    setImgId(imgIdf);
    document.getElementById(imgIdf).style.display = "none";
    // setSight(sight.filter(elm=>elm!==data))
  };
  useEffect(() => {
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setopen(true);
    }
    if (isEdited !== undefined && isEdited !== "") {
      closeModal();
    }
    setName(data.adType);
    if (data.adType === "image") {
      setTitle(data.adLink);
    }
    if (data.adType === "code") {
      setCode(data.adCode);
    }
    if (deleteImageError !== undefined && deleteImageError !== "") {
      setDeleteText(deleteImageError);
      setOpen1(true);
      document.getElementById(imgId).style.display = "flex";
    }
    setAdLocation(data.adLocation);
    return () => {
      return (
        dispatch(destroyEditAd()),
        dispatch(destroyEditAdError()),
        dispatch(destroyDeleteErrorImageCity())
      );
    };
  }, [isEdited, isError, deleteImageError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            {edit && (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {edit}
                </Alert>
              </Snackbar>
            )}
            <Snackbar
              open={open1}
              autoHideDuration={6000}
              onClose={handleClose1}
            >
              <Alert onClose={handleClose1} severity="error">
                {deleteText}
              </Alert>
            </Snackbar>
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Edit Advertisment"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Advertisment Type
                              </label>
                              <Select
                                value={{
                                  label: name.toUpperCase(),
                                  value: name,
                                }}
                                onChange={(element) => setName(element.value)}
                                options={[
                                  {
                                    value: "image",
                                    label: "IMAGE",
                                  },
                                  {
                                    value: "code",
                                    label: "CODE",
                                  },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          {name === "image" && (
                            <>
                              <Col lg="12">
                                <FormGroup>
                                  <label>Image</label>
                                  <Input
                                    className="form-control-alternative"
                                    type="file"
                                    name="description"
                                    onChange={(e) =>
                                      setImageFile(e.target.files[0])
                                    }
                                    accept="image/*"
                                  />
                                </FormGroup>
                              </Col>
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {data.adImages?.length > 0 &&
                                  data.adImages.map((elm, ind) => {
                                    return (
                                      <div
                                        key={elm.imageId}
                                        id={elm.imageId}
                                        style={{
                                          display: "flex",
                                          margin: "0 5px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Avatar
                                          src={
                                            baseUrlImage +
                                            imagePath +
                                            elm.imageName
                                          }
                                        />
                                        <Button
                                          style={{
                                            color: "white",
                                            cursor: "pointer",
                                            backgroundColor: "black",
                                            boxShadow: "none",
                                            width: "20px",
                                            height: "20px",
                                            padding: "0",
                                          }}
                                          onClick={() =>
                                            deleteImageFunction(
                                              elm._id,
                                              elm.imageId
                                            )
                                          }
                                          disabled={deleteImageLoading}
                                        >
                                          X
                                        </Button>
                                      </div>
                                    );
                                  })}
                              </div>
                              <Col lg="12">
                                <FormGroup>
                                  <label>Advertisment Image Link</label>
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="Image link"
                                    type="text"
                                    name="description"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          )}
                          {name === "code" && (
                            <>
                              <Col lg="12">
                                <FormGroup>
                                  <label>Advertisment Code</label>
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="enter code"
                                    type="text"
                                    name="code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          )}
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Advertisment Location
                              </label>
                              <Select
                                value={{
                                  label: adLocation,
                                  value: adLocation,
                                }}
                                onChange={(element) =>
                                  setAdLocation(element.value)
                                }
                                options={[
                                  {
                                    value: "Holiday Bottom",
                                    label: "Holiday Bottom",
                                  },
                                  {
                                    value: "Holiday Top",
                                    label: "Holiday Top",
                                  },
                                  {
                                    value: "Package Left",
                                    label: "Package Left",
                                  },
                                  {
                                    value: "Package Right",
                                    label: "Package Right",
                                  },
                                  {
                                    value: "Package Top",
                                    label: "Package Top",
                                  },
                                  {
                                    value: "Package Bottom",
                                    label: "Package Bottom",
                                  },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Edit Advertisment
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditAdvertisment;
