import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import HolidayHeader from "../../common/headers/HolidayHeader";
import { getCities } from "../../redux/actions/package";
import { baseUrlImage, imagePath } from "../../redux/url";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));
function CityTours() {
    const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading: cityLoading, cities, isError: cityError } = useSelector(
    (state) => state.getCity
  );
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [modal,setModal] = useState(false)
  const [sightData, setSightData] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCities());
  }, []);

  return (
    <>
      <HolidayHeader />
      <div className="most-popular w-100">
        <div className="container">
          <div className="row">
            <h4 className="w-100 float-left heading">
              Hire an Expert to Craft your Trip
            </h4>
            <h2 className="w-100 float-left heading-second">
              <Link to="/tours">Activities in Different Cities </Link>
            </h2>
            {cityError ? (
              <>{cityError}</>
            ) : cityLoading ? (
              <>Loading cities tours.....</>
            ) : (
              cities.map((e, i) => {
                return (
                  <div
                    className="col-xs-12 col-sm-6 col-md-6 col-lg-3"
                    style={{ cursor: "pointer" }}
                    onClick={()=>{
                        return(
                            setSightData(e.sightSeeing),
                            setModal(true)
                        )
                    }}
                  >
                    <div className="destini">
                      <img
                        alt="image"
                        src={baseUrlImage+imagePath+e.images[0]}
                        className="w-100"
                      />
                      <h5>{e.sightSeeing.length} Activities</h5>
                      <h3>{e.cityName}</h3>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      {
          modal && 
          <Modal isOpen={modal} toggle={() => setModal(false)}>
          <ModalBody>
            <Container>
              <Row>
                <Col className="order-xl-1" lg="12" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">{"Sights"}</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {sightData.length > 0 ? (
                        sightData.map((e, i) => {
                          return (
                            <>
                              <Accordion
                                expanded={expanded === `panel${i}`}
                                onChange={handleChange(`panel${i}`)}
                                style={{margin:"10px 0"}}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                >
                                  <Typography className={classes.heading}>
                                   <b>{e.header}</b>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>{e.description}</Typography>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          );
                        })
                      ) : (
                        <>No Sights Found</>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }
    </>
  );
}

export default CityTours;
