import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, NavLink, useHistory } from "react-router-dom";
import { NavItem } from "reactstrap";
import clsx from "clsx";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import logo from "../../assets/img/new/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/actions/user";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white !important",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

export default function UserNavbar() {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.getUser);
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const history = useHistory();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const logoutClicked = () => {
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    history.push("/login");
  };
  useEffect(() => {
    dispatch(getUsers());
  }, [localStorage.getItem("userId")]);
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key={"Logo"}>
          <NavLink exact to="/" style={{ color: "black" }}>
            <img src={logo} alt="...logo" style={{ height: "80px" }} />
          </NavLink>
        </ListItem>
      </List>
      <Divider />
      <List className="side-list-button">
        <ListItem button key={"Home"}>
          <NavLink exact to="/" style={{ color: "black" }}>
            <ListItemText primary={"Home"} />
          </NavLink>
        </ListItem>
        {/* <ListItem button key={"activity"}>
          <NavLink exact to="/activity" style={{ color: "black" }}>
            <ListItemText primary={"Activity & tours"} />
          </NavLink>
        </ListItem> */}
        <ListItem button key={"hotel"}>
          <NavLink exact to="/hotel" style={{ color: "black" }}>
            <ListItemText primary={"Hotel"} />
          </NavLink>
        </ListItem>
        <ListItem button key={"blog"}>
          <NavLink exact to="/blog" style={{ color: "black" }}>
            <ListItemText primary={"Blog"} />
          </NavLink>
        </ListItem>
        <ListItem button key={"gallery"}>
          <NavLink exact to="/gallery" style={{ color: "black" }}>
            <ListItemText primary={"Gallery"} />
          </NavLink>
        </ListItem>
      </List>
    </div>
  );
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className="app-bar">
        <Toolbar className="app-bar-tool">
          <IconButton
            onClick={toggleDrawer("left", true)}
            edge="start"
            className={classes.menuButton + " toggle-button"}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <div className="app-bar-logo">
            <Link to="/">
              <img src={logo} alt="...logo" style={{ height: "80px" }} />
            </Link>
          </div>
          <div className="app-bar-contents">
            <div className="navbar-links">
              <NavItem>
                <NavLink exact to="/">
                  {"Home"}
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink exact to="/activity">
                  {"Activities & tours"}
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink exact to="/hotel">
                  {"Hotel"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink exact to="/blog">
                  {"Blog"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink exact to="/gallery">
                  {"Gallery"}
                </NavLink>
              </NavItem>
            </div>
          </div>
          <div className="app-bar-button">
            {localStorage.getItem("accessToken") ? (
              <>
                {users.length > 0 &&
                  users.map((e, i) => {
                    if (e._id === localStorage.getItem("userId")) {
                      if (e.userRole === 1) {
                        return (
                          <Link to="/admin" className="nav-link">
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ backgroundColor: "#0003DC" }}
                            >
                              Admin
                            </Button>
                          </Link>
                        );
                      }
                      if (e.userRole === 2) {
                        return (
                          <Link to="/admin" className="nav-link">
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ backgroundColor: "#0003DC" }}
                            >
                              Admin
                            </Button>
                          </Link>
                        );
                      }
                      if (e.userRole === 3) {
                        return (
                          <>
                            <Link to="/userProfile" className="nav-link">
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "#0003DC" }}
                              >
                                Agency
                              </Button>
                            </Link>
                            <Button color="inherit" onClick={logoutClicked}>
                              Logout
                            </Button>
                          </>
                        );
                      }
                      if (e.userRole === 4) {
                        return (
                          <>
                            <Link to="/userProfile" className="nav-link">
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "#0003DC" }}
                              >
                                User
                              </Button>
                            </Link>
                            <Button color="inherit" onClick={logoutClicked}>
                              Logout
                            </Button>
                          </>
                        );
                      }
                    }
                  })}
              </>
            ) : (
              <>
                <Link to="/login" className="nav-link">
                  <Button color="inherit">Signin</Button>
                </Link>
                <Link to="/register" className="nav-link">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: "#0003DC" }}
                  >
                    Signup
                  </Button>
                </Link>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {list("left")}
      </SwipeableDrawer>
    </div>
  );
}
