import React, { Component, useEffect } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import NewUserFooter from "../common/footer/NewUserFooter";
import UserFooter from "../common/footer/UserFooter";
import UserNavbar from "../common/navbar/UserNavbar";

const User = ({ children }) => (
  <div className="user new">
  <UserNavbar />
    {children}
    {/* <!---footer-sec--> */}
    <NewUserFooter/>
  </div>
);

const UserLayout = ({ component: Component, ...rest }) => {
  const history = useHistory();
  useEffect(() => {}, []);
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <User>
          <Component {...matchProps} />
        </User>
      )}
    />
  );
};

export default UserLayout;
