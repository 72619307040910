// import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";
// import HolidayHeader from "../../../common/headers/HolidayHeader";
// import { Carousel } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { getInclusions } from "../../../redux/actions/inclusions";
// import { getExclusions } from "../../../redux/actions/exclusion";
// import {
//   baseUrl,
//   baseUrlImage,
//   filterCityEndpoint,
//   imagePath,
// } from "../../../redux/url";
// import {
//   getCities,
//   getCitiesDataHead,
//   getHotel,
//   getHotelIdData,
//   getPackageCategories,
//   getPackageDetail,
// } from "../../../redux/actions/package";
// import { CardContent, CardHeader, LinearProgress } from "@material-ui/core";
// import { Col, Container, Row } from "reactstrap";
// import SightSeeingModal from "./SightSeeingModal";
// import HotelModal from "./HotelModal";
// import UserNavbar from "../../../common/navbar/UserNavbar";
// import { getTransport, getVehicleType } from "../../../redux/actions/transport";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     width: "100%",
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

// export default function PackagesDetail(props) {
//   const dispatch = useDispatch();
//   const { isLoading, isError, packages } = useSelector(
//     (state) => state.getPackage
//   );
//   const {
//     loading: hotelLoading,
//     error: hotelError,
//     idHotel,
//   } = useSelector((state) => state.getHotelId);
//   const {
//     isLoading: loading,
//     isError: errorFetching,
//     headCities,
//   } = useSelector((state) => state.getCityHead);
//   const { exclusions } = useSelector((state) => state.getExclusion);
//   const { inclusions } = useSelector((state) => state.getInclusion);
//   const { hotels } = useSelector((state) => state.getHotel);
//   const { transport } = useSelector((state) => state.getTransport);
//   const { vehicleType } = useSelector((state) => state.getVehicleType);
//   const { packageCategory } = useSelector((state) => state.getPackageCategory);
//   const {cities} = useSelector(state=>state.getCity)
//   const [hotelCount, setHotelCount] = useState(0);
//   const [activityCoutnt, setActivityCount] = useState(0);
//   const [transferCount, setTransferCount] = useState(0);
//   var count = 0;
//   const classes = useStyles();
//   const [value, setValue] = React.useState(0);
//   const [modal, setModal] = useState(false);
//   const [modal2, setModal2] = useState(false);
//   const [info, setInfo] = useState([]);
//   const [itineraryModal, setItineraryModal] = useState({});
//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };
//   const [index, setIndex] = useState(0);

//   const handleSelect = (selectedIndex, e) => {
//     setIndex(selectedIndex);
//   };
//   const getCityNameData = (name, data) => {
//     setModal(true);
//     setItineraryModal(data);
//     dispatch(
//       getCitiesDataHead({
//         key: name,
//       })
//     );
//   };
//   const getHotelNameData = (name) => {
//     setModal2(true);
//     dispatch(
//       getHotelIdData({
//         id: name,
//       })
//     );
//   };
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     dispatch(
//       getPackageDetail({
//         id: props.match.params.id,
//       })
//     );
//     dispatch(getInclusions());
//     dispatch(getExclusions());
//     dispatch(getHotel());
//     dispatch(getPackageCategories());
//     dispatch(getTransport());
//     dispatch(getVehicleType());
//     dispatch(getCities());
//   }, []);

//   return (
//     <>
//       <UserNavbar />
//       <br />
//       <Container>
//         {isError ? (
//           <>{isError}</>
//         ) : isLoading ? (
//           <>
//             <LinearProgress />
//           </>
//         ) : packages.length > 0 ? (
//           <>
//             <Carousel
//               activeIndex={index}
//               onSelect={handleSelect}
//               className="package-crowsel"
//               fade
//             >
//               {packages[0].images.length > 0 &&
//                 packages[0].images.map((e, i) => {
//                   return (
//                     <Carousel.Item interval={1000}>
//                       <img
//                         style={{ height: "300px" }}
//                         className="d-block w-100"
//                         src={baseUrlImage + imagePath + e}
//                         alt={i + " slide"}
//                       />
//                     </Carousel.Item>
//                   );
//                 })}
//             </Carousel>
//             <br />

//             <Container>
//               <Row>
//                 <Col lg="12">
//                   <Typography variant="h4" gutterBottom>
//                     <b>
//                       {packages[0].packageName} from {packages[0].cityName} -{" "}
//                       {packageCategory.length > 0 &&
//                         packageCategory.map((item) => {
//                           if (
//                             item.packageCategoryId ==
//                             packages[0].packageCategory
//                           ) {
//                             return item.packageCategory;
//                           }
//                         })}
//                     </b>
//                   </Typography>
//                 </Col>
//               </Row>
//             </Container>

//             <div className={classes.root}>
//               <AppBar position="static" color="default">
//                 <Tabs
//                   value={value}
//                   onChange={handleChange}
//                   indicatorColor="primary"
//                   textColor="primary"
//                   variant="scrollable"
//                   scrollButtons="auto"
//                   aria-label="scrollable auto tabs example"
//                 >
//                   <Tab label="Day Plan" {...a11yProps(0)} />
//                   <Tab label={"Hotels"} {...a11yProps(1)} />
//                   <Tab label={"Activities"} {...a11yProps(2)} />
//                   <Tab label={"Transfers"} {...a11yProps(3)} />
//                   {/* <Tab label="Summary" {...a11yProps(4)} /> */}
//                   <Tab label="Details" {...a11yProps(4)} />
//                 </Tabs>
//               </AppBar>
//               <TabPanel
//                 value={value}
//                 index={0}
//                 className="itinerary-day-tab-panel"
//               >
//                 <Container>
//                   <Row>
//                     <Col lg="12">
//                       <div className="itinerary-data-user">
//                         {packages[0].itinerary &&
//                         packages[0].itinerary.length > 0 ? (
//                           <>
//                             {packages[0].itinerary.map((e, i) => {
//                               return (
//                                 <>
//                                   <div className="itinerary-data-user-heading">
//                                     <Typography
//                                       variant="subtitle1"
//                                       gutterBottom
//                                     >
//                                       {" "}
//                                       <b>
//                                         Day {e.day} - {e.heading}
//                                       </b>
//                                     </Typography>
//                                   </div>
//                                   <div className="itinerary-data-user-description">
//                                     <Typography
//                                       variant="subtitle1"
//                                       gutterBottom
//                                     >
//                                       {e.description}
//                                     </Typography>
//                                   </div>
//                                   <div className="itinerary-data-user-step">
//                                     {e.type ? (
//                                       <>
//                                         {e.type.length > 0 &&
//                                           e.type.map((elm, ind) => {
//                                             count = count + 1;
//                                             if (elm.name === "Flight") {
//                                               return (
//                                                 <>
//                                                   <div className="itinerary-data-user-step-flight">
//                                                     <img
//                                                       src="/images/airport.png"
//                                                       alt="flight-image"
//                                                       style={{ height: "50px" }}
//                                                     />
//                                                     <div className="ml-3 user-step-flight">
//                                                       <div
//                                                         style={{
//                                                           display: "flex",
//                                                           flexDirection:
//                                                             "column",
//                                                         }}
//                                                         className="ml-2"
//                                                       >
//                                                         <b>
//                                                           {elm.departureTime}
//                                                         </b>
//                                                         <p>{elm.fromCity}</p>
//                                                       </div>
//                                                       <div
//                                                         style={{
//                                                           display: "flex",
//                                                           flexDirection:
//                                                             "column",
//                                                         }}
//                                                         className="ml-2"
//                                                       >
//                                                         ({" "}
//                                                         {parseInt(
//                                                           elm.arrivalTime
//                                                         ) -
//                                                           parseInt(
//                                                             elm.departureTime
//                                                           )}{" "}
//                                                         hr)
//                                                       </div>
//                                                       <div
//                                                         style={{
//                                                           display: "flex",
//                                                           flexDirection:
//                                                             "column",
//                                                         }}
//                                                         className="ml-2"
//                                                       >
//                                                         <b>{elm.arrivalTime}</b>
//                                                         <p>{elm.toCity}</p>
//                                                       </div>
//                                                       <div
//                                                         style={{
//                                                           display: "flex",
//                                                           flexDirection:
//                                                             "column",
//                                                         }}
//                                                         className="ml-2"
//                                                       >
//                                                         <b>Baggage</b>
//                                                         <p>
//                                                           cabin-{" "}
//                                                           {elm.cabinBggage}kg
//                                                           &nbsp; &nbsp; checkin-{" "}
//                                                           {elm.checkBaggage}kg
//                                                         </p>
//                                                       </div>
//                                                     </div>
//                                                   </div>
//                                                   <br />
//                                                 </>
//                                               );
//                                             }
//                                             if (elm.name === "Transport") {
//                                               return (
//                                                 <>
//                                                   <div className="itinerary-data-user-step-transport">
//                                                     <div>
//                                                       <img
//                                                         src="/images/0.png"
//                                                         style={{
//                                                           height: "100px",
//                                                           width: "80%",
//                                                           borderRadius: "5px",
//                                                         }}
//                                                         alt="transport-image"
//                                                       />
//                                                     </div>
//                                                     <div>
//                                                       <h4>
//                                                         <b>Transfer</b>
//                                                       </h4>
//                                                       <div>
//                                                         To -{" "}
//                                                         {cities.length > 0 &&
//                                                           cities.map(
//                                                             (item, place) => {
//                                                               if (
//                                                                 item.cityId ==
//                                                                 elm.toCity
//                                                               ) {
//                                                                 return item.cityName;
//                                                               }
//                                                             }
//                                                           )}
//                                                       </div>
//                                                       <div>
//                                                         {transport.length > 0 &&
//                                                           transport.map(
//                                                             (item, place) => {
//                                                               if (
//                                                                 item.transportId ===
//                                                                 elm.typeTransport
//                                                               ) {
//                                                                 return item.transportType;
//                                                               }
//                                                             }
//                                                           )}
//                                                       </div>
//                                                       <div>
//                                                         {vehicleType.length >
//                                                           0 &&
//                                                           vehicleType.map(
//                                                             (item, place) => {
//                                                               if (
//                                                                 item.vehicleId ===
//                                                                 elm.vehicleType
//                                                               ) {
//                                                                 return item.vehicleType;
//                                                               }
//                                                             }
//                                                           )}
//                                                       </div>
//                                                     </div>
//                                                   </div>
//                                                   <br />
//                                                 </>
//                                               );
//                                             }
//                                             if (elm.name === "Note") {
//                                               return (
//                                                 <>
//                                                   <br />
//                                                   <Typography
//                                                     variant="subtitle2"
//                                                     gutterBottom
//                                                   >
//                                                     <b>
//                                                       {" "}
//                                                       {elm.nodeDescription}
//                                                     </b>
//                                                   </Typography>
//                                                   <br />
//                                                 </>
//                                               );
//                                             }
//                                             if (elm.name === "End of the day") {
//                                               return (
//                                                 <>
//                                                   <div className="itinerary-data-user-step-end">
//                                                     <img
//                                                       src="/images/drink.png"
//                                                       alt="end-image"
//                                                       style={{ height: "50px" }}
//                                                     />
//                                                     <div className="ml-3">
//                                                       <h4>{elm.name}</h4>
//                                                       {elm.endDetail}
//                                                     </div>
//                                                   </div>
//                                                   <br />
//                                                 </>
//                                               );
//                                             }
//                                             if (elm.name === "Sight Seeing") {
//                                               return (
//                                                 <>
//                                                   <div
//                                                     className="itinerary-data-user-step-transport"
//                                                     style={{
//                                                       cursor: "pointer",
//                                                     }}
//                                                     onClick={() =>
//                                                       getCityNameData(
//                                                         e.cityName,
//                                                         elm.sightSeeing
//                                                       )
//                                                     }
//                                                   >
//                                                     <div>
//                                                       <img
//                                                         src="/images/0.png"
//                                                         style={{
//                                                           height: "100px",
//                                                           width: "80%",
//                                                           borderRadius: "5px",
//                                                         }}
//                                                         alt="transport-image"
//                                                       />
//                                                     </div>
//                                                     <div>
//                                                       <div>
//                                                         <h4>
//                                                           <b>
//                                                             Sight Seeing in{" "}
//                                                             {e.cityName}
//                                                           </b>
//                                                         </h4>
//                                                       </div>
//                                                       <div
//                                                         style={{
//                                                           display: "flex",
//                                                           flexWrap: "wrap",
//                                                         }}
//                                                       >
//                                                         {elm.sightSeeing}
//                                                       </div>
//                                                     </div>
//                                                   </div>
//                                                   <br />
//                                                 </>
//                                               );
//                                             }
//                                             if (elm.name === "Hotel") {
//                                               return (
//                                                 hotels.length > 0 &&
//                                                 hotels.map((element, index) => {
//                                                   if (
//                                                     element.hotelId ===
//                                                     elm.hotelName
//                                                   ) {
//                                                     return (
//                                                       <>
//                                                         <div
//                                                           className="itinerary-data-user-step-transport"
//                                                           style={{
//                                                             cursor: "pointer",
//                                                           }}
//                                                           onClick={() =>
//                                                             getHotelNameData(
//                                                               element.hotelId
//                                                             )
//                                                           }
//                                                         >
//                                                           <div>
//                                                             <img
//                                                               src={
//                                                                 element.images
//                                                                   .length > 0
//                                                                   ? baseUrlImage +
//                                                                     imagePath +
//                                                                     element
//                                                                       .images[0]
//                                                                   : "/images/3.png"
//                                                               }
//                                                               style={{
//                                                                 height: "100px",
//                                                                 width: "80%",
//                                                                 borderRadius:
//                                                                   "5px",
//                                                               }}
//                                                               alt="transport-image"
//                                                             />
//                                                           </div>
//                                                           <div>
//                                                             <div>
//                                                               Name-
//                                                               {
//                                                                 element.hotelName
//                                                               }
//                                                             </div>
//                                                             <div>
//                                                               City-
//                                                               {
//                                                                 element.hotelCity
//                                                               }
//                                                             </div>
//                                                             <div>
//                                                               Rating -{" "}
//                                                               {
//                                                                 element.hotelRating
//                                                               }
//                                                             </div>
//                                                           </div>
//                                                         </div>
//                                                         <br />
//                                                       </>
//                                                     );
//                                                   }
//                                                 })
//                                               );
//                                             }
//                                           })}
//                                         <hr />
//                                       </>
//                                     ) : (
//                                       <Typography
//                                         variant="subtitle1"
//                                         gutterBottom
//                                       ></Typography>
//                                     )}
//                                   </div>
//                                 </>
//                               );
//                             })}
//                           </>
//                         ) : (
//                           <Typography variant="h5" gutterBottom>
//                             No Day plan found
//                           </Typography>
//                         )}
//                       </div>
//                     </Col>
//                   </Row>
//                 </Container>
//               </TabPanel>
//               <TabPanel
//                 value={value}
//                 index={1}
//                 className="itinerary-day-tab-panel"
//               >
//                 <Container>
//                   <Row>
//                     <Col lg="12">
//                       <div className="itinerary-data-user">
//                         {packages[0].itinerary &&
//                         packages[0].itinerary.length > 0 ? (
//                           <>
//                             {packages[0].itinerary.map((e, i) => {
//                               return (
//                                 <>
//                                   <div className="itinerary-data-user-step">
//                                     {e.type ? (
//                                       <>
//                                         {e.type.length > 0 &&
//                                           e.type.map((elm, ind) => {
//                                             if (elm.name === "Hotel") {
//                                               return (
//                                                 hotels.length > 0 &&
//                                                 hotels.map((element, index) => {
//                                                   if (
//                                                     element.hotelId ===
//                                                     elm.hotelName
//                                                   ) {
//                                                     return (
//                                                       <>
//                                                         <div
//                                                           className="itinerary-data-user-heading"
//                                                           style={{
//                                                             cursor: "pointer",
//                                                           }}
//                                                           onClick={() =>
//                                                             getHotelNameData(
//                                                               element.hotelId
//                                                             )
//                                                           }
//                                                         >
//                                                           <Typography
//                                                             variant="subtitle1"
//                                                             gutterBottom
//                                                           >
//                                                             {" "}
//                                                             <b>
//                                                               Day {e.day} -{" "}
//                                                               {e.heading}
//                                                             </b>
//                                                           </Typography>
//                                                         </div>
//                                                         <br />
//                                                         <div className="itinerary-data-user-step-transport">
//                                                           <div>
//                                                             <img
//                                                               src={
//                                                                 element.images
//                                                                   .length > 0
//                                                                   ? baseUrlImage +
//                                                                     imagePath +
//                                                                     element
//                                                                       .images[0]
//                                                                   : "/images/3.png"
//                                                               }
//                                                               style={{
//                                                                 height: "100px",
//                                                                 width: "80%",
//                                                                 borderRadius:
//                                                                   "5px",
//                                                               }}
//                                                               alt="transport-image"
//                                                             />
//                                                           </div>
//                                                           <div>
//                                                             <div>
//                                                               Name-
//                                                               {
//                                                                 element.hotelName
//                                                               }
//                                                             </div>
//                                                             <div>
//                                                               City-
//                                                               {
//                                                                 element.hotelCity
//                                                               }
//                                                             </div>
//                                                             <div>
//                                                               Rating -{" "}
//                                                               {
//                                                                 element.hotelRating
//                                                               }
//                                                             </div>
//                                                           </div>
//                                                         </div>
//                                                         <br />
//                                                         <hr />
//                                                       </>
//                                                     );
//                                                   }
//                                                 })
//                                               );
//                                             }
//                                           })}
//                                       </>
//                                     ) : (
//                                       <Typography
//                                         variant="subtitle1"
//                                         gutterBottom
//                                       >
//                                         {/* No steps found  */}
//                                       </Typography>
//                                     )}
//                                   </div>
//                                 </>
//                               );
//                             })}
//                           </>
//                         ) : (
//                           <Typography variant="h5" gutterBottom>
//                             No Day plan found
//                           </Typography>
//                         )}
//                       </div>
//                     </Col>
//                   </Row>
//                 </Container>
//               </TabPanel>
//               <TabPanel
//                 value={value}
//                 index={2}
//                 className="itinerary-day-tab-panel"
//               >
//                 <Container>
//                   <Row>
//                     <Col lg="12">
//                       <div className="itinerary-data-user">
//                         {packages[0].itinerary &&
//                         packages[0].itinerary.length > 0 ? (
//                           <>
//                             {packages[0].itinerary.map((e, i) => {
//                               return (
//                                 <>
//                                   <div className="itinerary-data-user-heading">
//                                     <Typography
//                                       variant="subtitle1"
//                                       gutterBottom
//                                     >
//                                       {" "}
//                                       <b>
//                                         Day {e.day} - {e.cityName}
//                                       </b>
//                                     </Typography>
//                                   </div>
//                                   <div className="itinerary-data-user-step">
//                                     {e.type ? (
//                                       <>
//                                         {e.type.length > 0 &&
//                                           e.type.map((elm, ind) => {
//                                             if (elm.name === "End of the day") {
//                                               return (
//                                                 <>
//                                                   <div className="itinerary-data-user-step-end">
//                                                     <img
//                                                       src="/images/drink.png"
//                                                       alt="end-image"
//                                                       style={{ height: "50px" }}
//                                                     />
//                                                     <div className="ml-3">
//                                                       <h4>{elm.name}</h4>
//                                                       {elm.endDetail}
//                                                     </div>
//                                                   </div>
//                                                   <br />
//                                                 </>
//                                               );
//                                             }
//                                             if (elm.name === "Sight Seeing") {
//                                               return (
//                                                 <>
//                                                   <div
//                                                     className="itinerary-data-user-step-transport"
//                                                     style={{
//                                                       cursor: "pointer",
//                                                     }}
//                                                     onClick={() =>
//                                                       getCityNameData(
//                                                         e.cityName,
//                                                         elm.sightSeeing
//                                                       )
//                                                     }
//                                                   >
//                                                     <div>
//                                                       <img
//                                                         src="/images/0.png"
//                                                         style={{
//                                                           height: "100px",
//                                                           width: "80%",
//                                                           borderRadius: "5px",
//                                                         }}
//                                                         alt="transport-image"
//                                                       />
//                                                     </div>
//                                                     <div>
//                                                       <div>
//                                                         <h4>
//                                                           <b>
//                                                             Sight Seeing in{" "}
//                                                             {e.cityName}
//                                                           </b>
//                                                         </h4>
//                                                       </div>
//                                                       <div
//                                                         style={{
//                                                           display: "flex",
//                                                           flexWrap: "wrap",
//                                                         }}
//                                                       >
//                                                         {elm.sightSeeing}
//                                                       </div>
//                                                     </div>
//                                                   </div>
//                                                   <br />
//                                                 </>
//                                               );
//                                             }
//                                           })}
//                                         <hr />
//                                       </>
//                                     ) : (
//                                       <Typography
//                                         variant="subtitle1"
//                                         gutterBottom
//                                       >
//                                         {/* No steps found */}
//                                       </Typography>
//                                     )}
//                                   </div>
//                                 </>
//                               );
//                             })}
//                           </>
//                         ) : (
//                           <Typography variant="h5" gutterBottom>
//                             No Day plan found
//                           </Typography>
//                         )}
//                       </div>
//                     </Col>
//                   </Row>
//                 </Container>
//               </TabPanel>
//               <TabPanel
//                 value={value}
//                 index={3}
//                 className="itinerary-day-tab-panel"
//               >
//                 <Container>
//                   <Row>
//                     <Col lg="12">
//                       <div className="itinerary-data-user">
//                         {packages[0].itinerary &&
//                         packages[0].itinerary.length > 0 ? (
//                           <>
//                             {packages[0].itinerary.map((e, i) => {
//                               return (
//                                 <>
//                                   <div className="itinerary-data-user-step">
//                                     {e.type ? (
//                                       <>
//                                         {e.type.length > 0 &&
//                                           e.type.map((elm, ind) => {
//                                             if (elm.name === "Transport") {
//                                               return (
//                                                 <>
//                                                   <div className="itinerary-data-user-heading">
//                                                     <Typography
//                                                       variant="subtitle1"
//                                                       gutterBottom
//                                                     >
//                                                       {" "}
//                                                       <b>
//                                                         Day {e.day} -{" "}
//                                                         {e.heading}
//                                                       </b>
//                                                     </Typography>
//                                                   </div>
//                                                   <div className="itinerary-data-user-step-transport">
//                                                     <div>
//                                                       <img
//                                                         src="/images/0.png"
//                                                         style={{
//                                                           height: "100px",
//                                                           width: "80%",
//                                                           borderRadius: "5px",
//                                                         }}
//                                                         alt="transport-image"
//                                                       />
//                                                     </div>
//                                                     <div>
//                                                       <div>
//                                                         To - {elm.toCity}
//                                                       </div>
//                                                       <div>
//                                                         {elm.typeTransport}
//                                                       </div>
//                                                       <div>
//                                                         {elm.vehicleType}
//                                                       </div>
//                                                     </div>
//                                                   </div>
//                                                 </>
//                                               );
//                                             }
//                                           })}
//                                       </>
//                                     ) : (
//                                       <Typography
//                                         variant="subtitle1"
//                                         gutterBottom
//                                       >
//                                         {/* No steps found */}
//                                       </Typography>
//                                     )}
//                                   </div>
//                                 </>
//                               );
//                             })}
//                           </>
//                         ) : (
//                           <Typography variant="h5" gutterBottom>
//                             No Day plan found
//                           </Typography>
//                         )}
//                       </div>
//                     </Col>
//                   </Row>
//                 </Container>
//               </TabPanel>
//               {/* <TabPanel value={value} index={4} className="itinerary-day-tab-panel">
//               Summary
//             </TabPanel> */}
//               <TabPanel
//                 value={value}
//                 index={4}
//                 className="itinerary-day-tab-panel"
//               >
//                 <Container>
//                   <div className="itinerary-data-user">
//                     <CardContent>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Package Name</b>
//                         </Col>
//                         <Col lg="8">{packages[0].packageName}</Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Package Description</b>
//                         </Col>
//                         <Col lg="8">{packages[0].packageDescription}</Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Number Of Nights</b>
//                         </Col>
//                         <Col lg="8">{packages[0].numberOfNights}</Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Departure City</b>
//                         </Col>
//                         <Col lg="8">
//                           {packages[0].departureCity[0]
//                             .split(",")
//                             .map((e, i) => {
//                               return <li>{e}</li>;
//                             })}
//                         </Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Travel Validity</b>
//                         </Col>
//                         <Col lg="8">
//                           {packages[0].travelValidityFrom} to{" "}
//                           {packages[0].travelValidityTo}
//                         </Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>CutOff Date</b>
//                         </Col>
//                         <Col lg="8">{packages[0].cutOffDate}</Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Booking Validity</b>
//                         </Col>
//                         <Col lg="8">
//                           {packages[0].bookingValidityFrom} to{" "}
//                           {packages[0].bookingValidityTo}
//                         </Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Travel Scope</b>
//                         </Col>
//                         <Col lg="8">{packages[0].travelScope}</Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Package Type</b>
//                         </Col>
//                         <Col lg="8">{packages[0].packageType}</Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Online Available</b>
//                         </Col>
//                         <Col lg="8">{packages[0].packageBookableOnline}</Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>City Name</b>
//                         </Col>
//                         <Col lg="8">{packages[0].cityName}</Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Inclusions</b>
//                         </Col>
//                         <Col lg="8">
//                           {packages[0].inclusions[0].split(",").map((e, i) => {
//                             const isFound = inclusions.filter((elm, ind) => {
//                               return elm.inclusionName == e;
//                             });
//                             if (isFound.length > 0) {
//                               return (
//                                 <li>
//                                   <i
//                                     class={`fa fa-${isFound[0].inclusionIcon}`}
//                                   >
//                                     &nbsp;
//                                   </i>
//                                   {isFound[0].inclusionName}
//                                 </li>
//                               );
//                             } else {
//                               return <li>{e}</li>;
//                             }
//                           })}

//                           {packages[0].otherInclusions[0]
//                             .split(",")
//                             .map((e, i) => {
//                               const isFound = inclusions.filter((elm, ind) => {
//                                 return elm.inclusionName == e;
//                               });
//                               if (isFound.length > 0) {
//                                 return (
//                                   <li>
//                                     <i
//                                       class={`fa fa-${isFound[0].inclusionIcon}`}
//                                     >
//                                       &nbsp;
//                                     </i>
//                                     {isFound[0].inclusionName}
//                                   </li>
//                                 );
//                               } else {
//                                 return <li>{e}</li>;
//                               }
//                             })}
//                         </Col>
//                       </Row>
//                       <hr />
//                       <Row>
//                         <Col lg="4">
//                           <b>Exclusions</b>
//                         </Col>
//                         <Col lg="8">
//                           {packages[0].exclusions[0].split(",").map((e, i) => {
//                             const isFound = exclusions.filter((elm, ind) => {
//                               return elm.exclusionName == e;
//                             });
//                             if (isFound.length > 0) {
//                               return (
//                                 <li>
//                                   <i
//                                     class={`fa fa-${isFound[0].exclusionIcon}`}
//                                   >
//                                     &nbsp;
//                                   </i>
//                                   {isFound[0].exclusionName}
//                                 </li>
//                               );
//                             } else {
//                               return <li>{e}</li>;
//                             }
//                           })}
//                           {packages[0].otherExclusions[0]
//                             .split(",")
//                             .map((e, i) => {
//                               const isFound = exclusions.filter((elm, ind) => {
//                                 return elm.exclusionName == e;
//                               });
//                               if (isFound.length > 0) {
//                                 return (
//                                   <li>
//                                     <i
//                                       class={`fa fa-${isFound[0].exclusionIcon}`}
//                                     >
//                                       &nbsp;
//                                     </i>
//                                     {isFound[0].exclusionName}
//                                   </li>
//                                 );
//                               } else {
//                                 return <li>{e}</li>;
//                               }
//                             })}
//                         </Col>
//                       </Row>
//                     </CardContent>
//                   </div>
//                 </Container>
//               </TabPanel>
//             </div>
//             <div style={{ height: "50px" }}></div>
//           </>
//         ) : (
//           <>No Data found</>
//         )}
//         {modal && (
//           <SightSeeingModal
//             data={headCities}
//             modal={modal}
//             setModal={setModal}
//             isLoading={loading}
//             isError={errorFetching}
//             sights={itineraryModal}
//           />
//         )}
//         {modal2 && (
//           <HotelModal
//             data={idHotel}
//             modal={modal2}
//             setModal={setModal2}
//             isLoading={hotelLoading}
//             isError={hotelError}
//           />
//         )}
//       </Container>
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import UserNavbar from "../../../common/navbar/UserNavbar";
import { getExclusions } from "../../../redux/actions/exclusion";
import { getInclusions } from "../../../redux/actions/inclusions";
import {
  Avatar,
  Button,
  Collapse,
  Snackbar,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  getCities,
  getHotel,
  getPackageCategories,
  getPackageDetail,
} from "../../../redux/actions/package";
import { getTransport, getVehicleType } from "../../../redux/actions/transport";
import { Col, Container, Row } from "react-bootstrap";
import {
  Bookmark,
  Remove,
  Add,
  Star,
  ArrowBack,
  ArrowForward,
  FiberManualRecord,
  Close,
} from "@material-ui/icons";
import edit from "../../../assets/img/new/picksEdit.png";
import DatePicker from "react-datepicker";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import "react-datepicker/dist/react-datepicker.css";
import { IconButton } from "@material-ui/core";
import street from "../../../assets/img/new/street.jpg";
import approved from "../../../assets/img/new/approved.png";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link, useHistory, useLocation } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import { baseUrlImage, imagePath } from "../../../redux/url";
import rejected from "../../../assets/img/new/rejected.png";
import $ from "jquery";
import Login from "../../../auth/Login";
import { destroyUserLogin, login } from "../../../redux/actions/user";
import {
  addBooking,
  destroyAddBooking,
  destroyAddBookingError,
} from "../../../redux/actions/booking";
import Lightbox from "react-image-lightbox";
import {
  addFavouritePackage,
  deleteFavouritePackage,
  getFavouritePackage,
} from "../../../redux/actions/favourite";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
function PackagesDetail(props) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading, isError, packages } = useSelector(
    (state) => state.getPackage
  );
  const {
    loading: hotelLoading,
    error: hotelError,
    idHotel,
  } = useSelector((state) => state.getHotelId);
  const {
    isLoading: loading,
    isError: errorFetching,
    headCities,
  } = useSelector((state) => state.getCityHead);
  const { exclusions } = useSelector((state) => state.getExclusion);
  const { inclusions } = useSelector((state) => state.getInclusion);
  const { hotels } = useSelector((state) => state.getHotel);
  const { transport } = useSelector((state) => state.getTransport);
  const { vehicleType } = useSelector((state) => state.getVehicleType);
  const { packageCategory } = useSelector((state) => state.getPackageCategory);
  const {
    isLoading: bkookingLoading,
    isError: bookingError,
    isAdded: bookingAdded,
  } = useSelector((state) => state.addBooking);
  const {
    loading: loginLoading,
    error,
    loggedIn,
  } = useSelector((state) => state.user);
  const {
    isLoading: getFavLoading,
    isError: getFavError,
    favourites: favArray,
  } = useSelector((state) => state.getFavouritePackage);
  const {
    isLoading: addfavLoading,
    isError: addFavError,
    isAdded: addFavAdded,
  } = useSelector((state) => state.addFavouritePackage);
  const {
    isLoading: deleteFavLoading,
    isError: seleteFavLoading,
    isDeleted: addfavDeleted,
  } = useSelector((state) => state.deleteFavouritePackage);
  const { cities } = useSelector((state) => state.getCity);
  const [favourite, setFavourite] = useState(false);
  const [adultCounter, setAdultCounter] = useState(1);
  const [childrenCounter, setChildrenCounter] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [active, setActive] = useState("#head");
  const [fixed, setFixed] = useState({ right: "0" });
  const [showSuccessful, setShowSuccessful] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const query = new URLSearchParams(useLocation().search);
  const [errorText, setErrorText] = useState("");
  const [open, setOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({});
  const [loginCredenials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    isOpen: false,
  });
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);
  const imageClicked = (image, i, items) => {
    setPhotoIndex(i);
    if (items) {
      items.map((e, ind) => {
        return setImages((old) => [
          ...old,
          baseUrlImage + imagePath + e.imageName,
        ]);
      });
    } else {
      packages[0].packageImages.map((e, ind) => {
        return setImages((old) => [
          ...old,
          baseUrlImage + imagePath + e.imageName,
        ]);
      });
    }
    setState({ ...state, isOpen: true });
  };
  const handleClose2 = () => {
    setOpen(false);
  };
  const changeValueFunction = (e) => {
    let { name, value } = e.target;
    setBookingDetails({
      ...bookingDetails,
      [name]: value,
    });
  };
  const bookingSubmit = (e) => {
    e.preventDefault();
    const info = {
      packageName: packages[0]?.packageName,
      packagePrice:
        ((Math.ceil(Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)) +
          1) /
          (parseInt(packages[0]?.numberOfNights) + 1)) *
        parseInt(packages[0]?.packagePrice),
      userFirstName: bookingDetails.firstName,
      userLastName: bookingDetails.lastName,
      userEmail: bookingDetails.email,
      userPhoneNumber: bookingDetails.phone,
      Address: bookingDetails.address,
      adult: String(adultCounter),
      children: String(childrenCounter),
      checkIn: startDate,
      checkOut: endDate,
      note: bookingDetails.note,
      userId: localStorage.getItem("userId"),
    };
    dispatch(addBooking(info));
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowSuccessful(false);
    setState({ ...state, [anchor]: open });
  };
  const setActiveValue = (value) => {
    setActive(value);
  };
  const setFavouriteFunction = () => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== "" &&
      localStorage.getItem("userId") &&
      localStorage.getItem("userId") !== ""
    ) {
      setFavourite(!favourite);
    }
  };
  const loginFunction = (e) => {
    e.preventDefault();
    if (loginCredenials.email !== "" && loginCredenials.password !== "") {
      const info = {
        email: loginCredenials.email,
        password: loginCredenials.password,
      };
      dispatch(login(info));
    } else {
      setErrorText("fill all the fields");
    }
  };
  const list = (anchor) => (
    <div
      className={
        clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        }) + " popup"
      }
      role="presentation"
      style={{ width: "100%" }}
    >
      <Container className="book-popup" fluid>
        {localStorage.getItem("accessToken") &&
        localStorage.getItem("accessToken") !== "" &&
        localStorage.getItem("userId") &&
        localStorage.getItem("userId") !== "" ? (
          <>
            {showSuccessful ? (
              <>
                <Row>
                  <Col lg="12">
                    <div className="success">
                      <h4>Success</h4>
                    </div>
                    <div className="success-text">
                      Your message was successfully sent. We will contact you
                      shortly
                    </div>
                  </Col>
                </Row>
                {/* <div
                  className="close"
                  onClick={toggleDrawer("right", false)}
                ></div> */}
              </>
            ) : (
              <Row>
                <Col lg="12" sm="12" xs="12" md="12" className="details">
                  <div className="heading">Book Now</div>
                  <div className="package-brief">
                    {packages.length > 0 && packages[0].images?.length > 0 && (
                      <img
                        src={baseUrlImage + imagePath + packages[0].images[0]}
                        alt="some"
                      />
                    )}
                    {packages.length > 0 && packages[0].packageName}
                  </div>
                  <hr />
                  <Row className="bottom-info">
                    <Col lg="4" xs="4" md="4" sm="4" className="from">
                      <h4>Check-in</h4>
                      <div className="date">
                        <div className="right">{startDate.getDate()}</div>
                        <div className="left">
                          <p className="month">
                            {renderSwitch(startDate.getMonth() + 1)}
                          </p>
                          <p className="year">{startDate.getFullYear()}</p>
                        </div>
                      </div>
                      <div className="adult">
                        Adult : <span>{adultCounter}</span>
                      </div>
                    </Col>
                    <Col lg="4" xs="4" md="4" sm="4" className="to">
                      <h4>Check-out</h4>
                      <div className="date">
                        <div className="right">{endDate.getDate()}</div>
                        <div className="left">
                          <p className="month">
                            {renderSwitch(endDate.getMonth() + 1)}
                          </p>
                          <p className="year">{endDate.getFullYear()}</p>
                        </div>
                      </div>
                      <div className="children">
                        Children : <span>{childrenCounter}</span>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                </Col>
                <Col lg="12" xs="12" md="12" sm="12">
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose2}
                  >
                    <Alert onClose={handleClose2} severity="error">
                      {errorText}
                    </Alert>
                  </Snackbar>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <TextField
                      required
                      label="First Name"
                      variant="outlined"
                      name="firstName"
                      value={bookingDetails.firstName}
                      onChange={changeValueFunction}
                    />
                    <TextField
                      required
                      label="Last Name"
                      variant="outlined"
                      name="lastName"
                      value={bookingDetails.lastName}
                      onChange={changeValueFunction}
                    />
                    <TextField
                      required
                      label="Email"
                      variant="outlined"
                      name="email"
                      value={bookingDetails.email}
                      onChange={changeValueFunction}
                    />
                    <TextField
                      required
                      label="Phone No."
                      variant="outlined"
                      name="phone"
                      value={bookingDetails.phone}
                      onChange={changeValueFunction}
                    />
                    <br />
                    <TextField
                      required
                      multiline
                      label="Address"
                      variant="outlined"
                      rows={4}
                      name="address"
                      value={bookingDetails.address}
                      onChange={changeValueFunction}
                    />
                    <br />
                    <TextField
                      multiline
                      label="Note"
                      variant="outlined"
                      rows={4}
                      name="note"
                      value={bookingDetails.note}
                      onChange={changeValueFunction}
                    />
                    <Button
                      type="submit"
                      onClick={bookingSubmit}
                      disabled={bkookingLoading}
                    >
                      Book Now |{" "}
                      <b>
                        &#8377;
                        {((Math.ceil(
                          Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)
                        ) +
                          1) /
                          (parseInt(packages[0].numberOfNights) + 1)) *
                          parseInt(packages[0].packagePrice)}
                      </b>
                    </Button>
                  </form>
                </Col>
                {/* <div
                  className="close"
                  onClick={toggleDrawer("right", false)}
                ></div> */}
              </Row>
            )}
          </>
        ) : (
          <>
            <Row>
              <Col lg="12" className="details">
                <div className="heading">
                  Sign In <Link to="/register">Sign Up</Link>
                </div>
                <div
                  className="package-brief"
                  style={{ color: "rgba(74, 74, 74, 0.58)" }}
                >
                  Use the e-mail and password that you specified when
                  registering on the site
                </div>
                <Row>
                  <Col lg="12">
                    {error && (
                      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <Alert severity="error">{error}</Alert>
                      </div>
                    )}
                    <form onSubmit={loginFunction}>
                      <TextField
                        required
                        type="text"
                        label="Email"
                        variant="outlined"
                        autoComplete={"new-email"}
                        value={loginCredenials.email}
                        onChange={(e) =>
                          setLoginCredentials({
                            ...loginCredenials,
                            email: e.target.value,
                          })
                        }
                      />
                      <TextField
                        type="password"
                        required
                        label="Password"
                        variant="outlined"
                        autoComplete="new-password"
                        value={loginCredenials.password}
                        onChange={(e) =>
                          setLoginCredentials({
                            ...loginCredenials,
                            password: e.target.value,
                          })
                        }
                      />
                      <Button
                        type="submit"
                        disabled={loginLoading}
                        onClick={loginFunction}
                      >
                        Sign In
                      </Button>
                    </form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        <div className="close" onClick={toggleDrawer("right", false)}></div>
      </Container>
    </div>
  );
  const renderSwitch = (data) => {
    switch (data) {
      case 1:
        return "January";
      case 2:
        return "Febrauary";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return data;
    }
  };
  const handleScroll = () => {
    const lastScrollY = window.scrollY;
    if (lastScrollY > 890) {
      setFixed({
        position: "fixed",
        top: "17%",
        right: "0",
      });
    } else {
      setFixed({
        right: "0",
      });
    }
  };
  const favouriteClicked = (id) => {
    let userId = localStorage.getItem("userId");
    if (favourite) {
      dispatch(
        deleteFavouritePackage({
          favPackageId: id,
        })
      );
    } else {
      dispatch(
        addFavouritePackage({
          userId: userId,
          packageId: id,
        })
      );
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (loggedIn !== undefined && loggedIn !== "") {
      history.push(window.location.pathname);
    }
    if (bookingAdded !== undefined && bookingAdded !== "") {
      setShowSuccessful(true);
      setBookingDetails({});
    }
    if (bookingError !== undefined && bookingError !== "") {
      setErrorText(bookingError);
      setOpen(true);
    }
    window.addEventListener("scroll", handleScroll, true);
    if (query.get("start") && query.get("start") !== "null") {
      setStartDate(new Date(query.get("start")));
    }
    if (query.get("end") && query.get("end") !== "null") {
      setEndDate(new Date(query.get("end")));
    }
    if (query.get("adult") && query.get("adult") !== "null") {
      setAdultCounter(parseInt(query.get("adult")));
    }
    if (query.get("children") && query.get("children") !== "null") {
      setChildrenCounter(parseInt(query.get("children")));
    }
    dispatch(
      getPackageDetail({
        id: props.match.params.id,
      })
    );
    dispatch(
      getFavouritePackage({
        userId: localStorage.getItem("userId"),
      })
    );
    dispatch(getInclusions());
    dispatch(getExclusions());
    dispatch(getHotel());
    dispatch(getPackageCategories());
    dispatch(getTransport());
    dispatch(getVehicleType());
    dispatch(getCities());
    return () => {
      return (
        dispatch(destroyUserLogin()),
        dispatch(destroyAddBooking()),
        dispatch(destroyAddBookingError())
      );
    };
  }, [loggedIn, bookingAdded, bookingError]);
  return (
    <>
      {isLoading ? (
        <>
          <LinearProgress />{" "}
        </>
      ) : isError ? (
        <>
          <Alert severnity="error">{isError}</Alert>
        </>
      ) : (
        <></>
      )}
      {packages.length > 0 ? (
        <>
          <div data-spy="scroll" data-target=".fiexd-nav" data-offset="50">
            <div id="head" className="scroll-div">
              <Container
                className="package"
                style={{ marginTop: "100px", maxWidth: "1300px" }}
              >
                {state.isOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={
                      images[(photoIndex + images.length - 1) % images.length]
                    }
                    onCloseRequest={() => setState({ ...state, isOpen: false })}
                    onMovePrevRequest={() =>
                      setPhotoIndex(
                        (photoIndex + images.length - 1) % images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % images.length)
                    }
                  />
                )}
                <Row className="package-introduction">
                  <Col lg="9" md="8" sm="12" xs="12" className="head">
                    <FiberManualRecord
                      style={{ color: "#D03000", marginBottom: "10px" }}
                    />{" "}
                    <span>
                      {cities.length > 0 &&
                        cities.map((e, i) => {
                          if (packages[0].cityName === e.cityId) {
                            return e.cityName;
                          }
                        })}
                    </span>
                    <h5>{packages[0].packageName}</h5>
                  </Col>
                  <Col lg="3" md="4" sm="12" xs="12" className="favourite">
                    <Tooltip
                      title={
                        localStorage.getItem("accessToken") &&
                        localStorage.getItem("accessToken") !== "" &&
                        localStorage.getItem("userId") &&
                        localStorage.getItem("userId") !== ""
                          ? "Add to favourite"
                          : "login to add to favourite list"
                      }
                    >
                      <div
                        className="icon"
                        style={{
                          backgroundColor: favourite ? "#D03000" : "white",
                          cursor: "pointer",
                          marginRight: "50px",
                        }}
                        onClick={setFavouriteFunction}
                      >
                        {favourite ? (
                          <Bookmark style={{ color: "white" }} />
                        ) : (
                          <Bookmark style={{ color: "#D03000" }} />
                        )}
                      </div>
                    </Tooltip>
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <OwlCarousel
                      className="owl-theme"
                      margin={10}
                      nav={true}
                      loop={true}
                      autoplay={true}
                      navContainerClass={"icon"}
                      navText={[`&#8592;`, `&#8594;`]}
                      responsiveClass={true}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        450: {
                          items: 1,
                        },
                        600: {
                          items: 2,
                        },
                        750:{
                          items:3
                        },
                        1000: {
                          items: 3,
                        },
                      }}
                    >
                      {packages[0].packageImages?.length > 0 &&
                        packages[0].packageImages.map((e, i) => {
                          return (
                            <div className="item">
                              <img
                                onClick={() => imageClicked(e, i)}
                                src={baseUrlImage + imagePath + e.imageName}
                                alt="package"
                              />
                            </div>
                          );
                        })}
                    </OwlCarousel>
                  </Col>
                  <Col lg="12">
                    <Row className="bottom-info">
                      <Col lg="2" md="2" sm="6" xs="6" className="from">
                        <h4>Check-in</h4>
                        <div className="date-input">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="date-input-tag"
                          />
                          <div className="date">
                            <div className="right">{startDate.getDate()}</div>
                            <div className="left">
                              <p className="month">
                                {renderSwitch(startDate.getMonth() + 1)}
                              </p>
                              <p className="year">{startDate.getFullYear()}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg="2" md="2" sm="6" xs="6" className="to">
                        <h4>Check-out</h4>
                        <div className="date-input">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className="date-input-tag"
                          />
                          <div className="date">
                            <div className="right">{endDate.getDate()}</div>
                            <div className="left">
                              <p className="month">
                                {renderSwitch(endDate.getMonth() + 1)}
                              </p>
                              <p className="year">{endDate.getFullYear()}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg="2" md="2" sm="6" xs="6" className="adult">
                        <h4>Adult</h4>
                        <div className="counter-input">
                          <div className="right">
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              disabled={adultCounter >= 20}
                              onClick={() => setAdultCounter(adultCounter + 1)}
                            >
                              <Add />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              disabled={adultCounter === 0}
                              color="primary"
                              onClick={() => setAdultCounter(adultCounter - 1)}
                            >
                              <Remove />
                            </IconButton>
                          </div>
                          <div className="left">{adultCounter}</div>
                        </div>
                      </Col>
                      <Col lg="2" md="2" sm="6" xs="6" className="children">
                        <h4>Children</h4>
                        <div className="counter-input">
                          <div className="right">
                            <IconButton
                              aria-label="delete"
                              disabled={childrenCounter >= 20}
                              color="primary"
                              onClick={() =>
                                setChildrenCounter(childrenCounter + 1)
                              }
                            >
                              <Add />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              disabled={childrenCounter === 0}
                              color="primary"
                              onClick={() =>
                                setChildrenCounter(childrenCounter - 1)
                              }
                            >
                              <Remove />
                            </IconButton>
                          </div>
                          <div className="left">{childrenCounter}</div>
                        </div>
                      </Col>
                      <Col lg="4" md="4" sm="12" xs="12" className="booking">
                        <div className="days">
                          <span>
                            {Math.ceil(
                              Math.abs(endDate - startDate) /
                                (1000 * 60 * 60 * 24)
                            ) + 1}
                            &nbsp; days |
                          </span>{" "}
                          from &#8377;
                          {((Math.ceil(
                            Math.abs(endDate - startDate) /
                              (1000 * 60 * 60 * 24)
                          ) +
                            1) /
                            (parseInt(packages[0].numberOfNights) + 1)) *
                            parseInt(packages[0].packagePrice)}
                        </div>
                        <Button onClick={toggleDrawer("right", true)}>
                          Book Now
                        </Button>
                        <div className="review">
                          {" "}
                          <Star style={{ color: "#D03000" }} />
                          <Star style={{ color: "#D03000" }} />
                          <Star style={{ color: "#D03000" }} />
                          <Star style={{ color: "#D03000" }} />
                          <Star style={{ color: "grey" }} /> 10 reviews
                        </div>
                        <Drawer
                          anchor={"right"}
                          open={state["right"]}
                          onClose={toggleDrawer("right", false)}
                        >
                          {list("right")}
                        </Drawer>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container
              className="package-details"
              style={{ maxWidth: "1300px" }}
              id="overview"
            >
              <Row>
                <Col lg="9" md="9" sm="12" xs="12" className="right-side">
                  <div className="overview">
                    <h3>Overview</h3>
                    <p>{packages[0].packageDescription}</p>
                  </div>
                  <div className={"package-itinerary"}>
                    {packages[0].itinerary?.length > 0 &&
                      packages[0].itinerary.map((e, i) => {
                        return (
                          <Accordion
                            expanded={expanded === `panel${i + 1}`}
                            onChange={handleChange(`panel${i + 1}`)}
                            className="item"
                          >
                            <AccordionSummary
                              expandIcon={<div className="element"></div>}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="item-head"
                            >
                              <Typography className={classes.heading}>
                                {/* <Avatar
                                  alt={`Day ${e.day}`}
                                  src=""
                                  className={classes.large}
                                />{" "} */}
                                <FiberManualRecord />
                                Day {e.day}
                              </Typography>
                              <Typography
                                className={classes.secondaryHeading + " title"}
                              >
                                {e.heading}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="item-body">
                              <Row>
                                <Col lg="12" className="body-1">
                                  <Typography>{e.cityName}</Typography>
                                  <Typography>{e.description}</Typography>
                                </Col>
                                {e.type ? (
                                  <>
                                    {e.type.length > 0 &&
                                      e.type.map((elm, ind) => {
                                        if (elm.name === "Flight") {
                                          return (
                                            <>
                                              <Row className="itinerary-data-user-step-flight">
                                                <Col lg="3">
                                                  <img
                                                    src="/images/airport.png"
                                                    alt="flight-image"
                                                    style={{
                                                      height: "100px",
                                                      width: "100px",
                                                    }}
                                                  />
                                                </Col>
                                                <Col
                                                  lg="9"
                                                  className="user-step-flight"
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                    className="ml-2"
                                                  >
                                                    <b>{elm.departureTime}</b>
                                                    <p>{elm.fromCity}</p>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                    className="ml-2"
                                                  >
                                                    ({" "}
                                                    {parseInt(elm.arrivalTime) -
                                                      parseInt(
                                                        elm.departureTime
                                                      )}{" "}
                                                    hr)
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                    className="ml-2"
                                                  >
                                                    <b>{elm.arrivalTime}</b>
                                                    <p>{elm.toCity}</p>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                    className="ml-2"
                                                  >
                                                    <b>Baggage</b>
                                                    <p>
                                                      cabin- {elm.cabinBggage}kg
                                                      &nbsp; &nbsp; checkin-{" "}
                                                      {elm.checkBaggage}kg
                                                    </p>
                                                  </div>
                                                </Col>
                                              </Row>
                                              <br />
                                            </>
                                          );
                                        }
                                        if (elm.name === "Transport") {
                                          return (
                                            <>
                                              <Row className="itinerary-data-user-step-transport">
                                                <Col lg="3">
                                                  <img
                                                    src="/images/0.png"
                                                    style={{
                                                      width: "80%",
                                                      borderRadius: "10px",
                                                    }}
                                                    alt="transport"
                                                  />
                                                </Col>
                                                <Col lg="9">
                                                  <h4>
                                                    <b>Transfer</b>
                                                  </h4>
                                                  <p>
                                                    To -{" "}
                                                    {cities.length > 0 &&
                                                      cities.map(
                                                        (item, place) => {
                                                          if (
                                                            item.cityId ==
                                                            elm.toCity
                                                          ) {
                                                            return item.cityName;
                                                          }
                                                        }
                                                      )}
                                                  </p>
                                                  <p>
                                                    {transport.length > 0 &&
                                                      transport.map(
                                                        (item, place) => {
                                                          if (
                                                            item.transportId ===
                                                            elm.typeTransport
                                                          ) {
                                                            return item.transportType;
                                                          }
                                                        }
                                                      )}
                                                  </p>
                                                  <p>
                                                    {vehicleType.length > 0 &&
                                                      vehicleType.map(
                                                        (item, place) => {
                                                          if (
                                                            item.vehicleId ===
                                                            elm.vehicleType
                                                          ) {
                                                            return item.vehicleType;
                                                          }
                                                        }
                                                      )}
                                                  </p>
                                                </Col>
                                              </Row>
                                              <br />
                                            </>
                                          );
                                        }
                                        if (elm.name === "Note") {
                                          return (
                                            <>
                                              <br />
                                              <Typography
                                                variant="subtitle2"
                                                gutterBottom
                                              >
                                                Note -{" "}
                                                <b> {elm.nodeDescription}</b>
                                              </Typography>
                                              <br />
                                            </>
                                          );
                                        }
                                        if (elm.name === "End of the day") {
                                          return (
                                            <>
                                              <Row className="itinerary-data-user-step-end">
                                                <Col lg="3">
                                                  <img
                                                    src="/images/drink.png"
                                                    alt="end"
                                                    style={{
                                                      height: "100px",
                                                      width: "100px",
                                                    }}
                                                  />
                                                </Col>
                                                <Col lg="9">
                                                  <h4>{elm.name}</h4>
                                                  {elm.endDetail}
                                                </Col>
                                              </Row>
                                              <br />
                                            </>
                                          );
                                        }
                                        if (elm.name === "Sight Seeing") {
                                          return (
                                            <>
                                              <Row
                                                className="itinerary-data-user-step-transport"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <Col lg="3">
                                                  <img
                                                    src="/images/0.png"
                                                    style={{
                                                      width: "80%",
                                                      borderRadius: "10px",
                                                    }}
                                                    alt="transport"
                                                  />
                                                </Col>
                                                <Col lg="9">
                                                  <div>
                                                    <h4>
                                                      <b>
                                                        Sight Seeing in{" "}
                                                        {e.cityName}
                                                      </b>
                                                    </h4>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {elm.sightSeeing}
                                                  </div>
                                                </Col>
                                              </Row>
                                              <br />
                                            </>
                                          );
                                        }
                                        if (elm.name === "Hotel") {
                                          return (
                                            hotels.length > 0 &&
                                            hotels.map((element, index) => {
                                              if (
                                                element.hotelId ===
                                                elm.hotelName
                                              ) {
                                                return (
                                                  <>
                                                    <Row
                                                      className="itinerary-data-user-step-transport"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <Col lg="3">
                                                        <img
                                                          src={
                                                            element.hotelImages
                                                              ?.length > 0 &&
                                                            baseUrlImage +
                                                              imagePath +
                                                              element
                                                                .hotelImages[0]
                                                                ?.imageName
                                                          }
                                                          style={{
                                                            width: "80%",
                                                            borderRadius:
                                                              "10px",
                                                          }}
                                                          alt="transport"
                                                        />
                                                      </Col>
                                                      <Col lg="9">
                                                        <p>
                                                          Name-
                                                          <b>
                                                            {element.hotelName}
                                                          </b>
                                                        </p>
                                                        <p>
                                                          City-
                                                          <b>
                                                            {" "}
                                                            {element.hotelCity}
                                                          </b>
                                                        </p>
                                                        <p>
                                                          Rating -{" "}
                                                          <b>
                                                            {
                                                              element.hotelRating
                                                            }
                                                          </b>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                    <br />
                                                  </>
                                                );
                                              }
                                            })
                                          );
                                        }
                                      })}
                                    <hr />
                                  </>
                                ) : (
                                  <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                  ></Typography>
                                )}
                                <Row>
                                  {cities.length > 0 &&
                                    cities.map((elm, ind) => {
                                      if (elm.cityName === e.cityName) {
                                        return (
                                          <>
                                            {elm.cityImages?.length > 0 &&
                                              elm.cityImages.map(
                                                (item, place) => {
                                                  if (place < 3) {
                                                    return (
                                                      <Col lg="4">
                                                        <img
                                                          onClick={() =>
                                                            imageClicked(
                                                              item,
                                                              place,
                                                              elm.cityImages
                                                            )
                                                          }
                                                          src={
                                                            baseUrlImage +
                                                            imagePath +
                                                            item.imageName
                                                          }
                                                          alt="day"
                                                        />
                                                      </Col>
                                                    );
                                                  }
                                                }
                                              )}
                                          </>
                                        );
                                      }
                                    })}
                                </Row>
                              </Row>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                  </div>
                  <div className="included" id="included">
                    <h3>Included</h3>
                    <Row>
                      {packages[0].inclusions?.length > 0 &&
                        packages[0].inclusions[0].split(",").map((e, i) => {
                          return (
                            <>
                              <Col lg="6" className="item-included">
                                <p>{e}</p>
                              </Col>
                              {i > 0 && i % 2 !== 0 && (
                                <Col lg="12">
                                  <hr />
                                </Col>
                              )}
                            </>
                          );
                        })}
                    </Row>
                  </div>
                  <div className="excluded" id="included">
                    <h3>Excluded</h3>
                    <Row>
                      {packages[0].exclusions?.length > 0 &&
                        packages[0].exclusions[0].split(",").map((e, i) => {
                          return (
                            <>
                              <Col lg="6" className="item-excluded">
                                <p>{e}</p>
                              </Col>
                              {i > 0 && i % 2 !== 0 && (
                                <Col lg="12">
                                  <hr />
                                </Col>
                              )}
                            </>
                          );
                        })}
                    </Row>
                  </div>
                  <div className="location" id="location">
                    <h3>Location</h3>
                    <div className="map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d54891.42287220298!2d76.6973804!3d30.698324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe19a97a37a0f2846!2sMaya%20Travels!5e0!3m2!1sen!2sin!4v1623644484602!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: "0", width: "100%" }}
                        allowfullscreen=""
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                  <div className="stories" id="sights">
                    <div className="heading">
                      <h3>Sights</h3>
                      {/* <p>All Stories</p> */}
                    </div>
                    <Row>
                      {cities.length > 0 &&
                        cities.map((e, i) => {
                          if (e.cityId === packages[0].cityName) {
                            return (
                              e.cityImages?.length > 0 &&
                              e.cityImages.map((elm, ind) => {
                                if (elm.imageType.includes("sightImage")) {
                                  return (
                                    <Col lg="4" className="sight-item">
                                      <img
                                        src={
                                          baseUrlImage +
                                          imagePath +
                                          elm.imageName
                                        }
                                        alt="story"
                                      />
                                      <div className="sight-text">
                                        {e.cityName}
                                      </div>
                                      {/* <div className="middle">
                                    Hello World
                                    </div>
                                    <div className="overlay"></div> */}
                                    </Col>
                                  );
                                }
                              })
                            );
                          }
                        })}
                    </Row>
                  </div>
                </Col>
                <Col lg="3" md="3" className="left-side hidden" style={fixed}>
                  <div className="fiexd-nav">
                    <ul>
                      <li
                        onClick={() => setActiveValue("#head")}
                        className={active === "#head" && "active"}
                      >
                        <a href="#head">Photo/Video</a>
                      </li>
                      <li
                        onClick={() => setActiveValue("#overview")}
                        className={active === "#overview" && "active"}
                      >
                        <a href="#overview">Overview</a>
                      </li>
                      <li
                        onClick={() => setActiveValue("#included")}
                        className={active === "#included" && "active"}
                      >
                        <a href="#included">Included</a>
                      </li>
                      <li
                        onClick={() => setActiveValue("#location")}
                        className={active === "#location" && "active"}
                      >
                        <a href="#location">Location</a>
                      </li>
                      <li
                        onClick={() => setActiveValue("#sights")}
                        className={active === "#sights" && "active"}
                      >
                        <a href="#sights">Sights</a>
                      </li>
                    </ul>
                  </div>
                  <div className="book-button">
                    <button onClick={toggleDrawer("right", true)}>
                      Book Now
                      <br />
                      <b>
                        From&nbsp; &#8377;
                        {((Math.ceil(
                          Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)
                        ) +
                          1) /
                          (parseInt(packages[0].numberOfNights) + 1)) *
                          parseInt(packages[0].packagePrice)}
                      </b>
                    </button>
                  </div>
                  <div className="add-favourite">
                    <div
                      className="icon"
                      style={{
                        backgroundColor: favourite
                          ? "#D03000"
                          : "rgb(209, 208, 208)",
                      }}
                      onClick={() => setFavourite(!favourite)}
                    >
                      {favourite ? (
                        <Bookmark
                          style={{
                            color: "white",
                            marginTop: "3px",
                            marginLeft: "4px",
                          }}
                        />
                      ) : (
                        <Bookmark
                          style={{
                            color: "#D03000",
                            marginTop: "3px",
                            marginLeft: "4px",
                          }}
                        />
                      )}
                    </div>
                    <p>{favourite ? "Added" : "Add"} to favourites</p>
                  </div>
                </Col>
                <Col
                  lg="3"
                  md="12"
                  className="left-side ishidden"
                  style={fixed}
                >
                  <div className="book-button">
                    <button onClick={toggleDrawer("right", true)}>
                      Book Now
                      <br />
                      <b>
                        From&nbsp; &#8377;
                        {((Math.ceil(
                          Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)
                        ) +
                          1) /
                          (parseInt(packages[0].numberOfNights) + 1)) *
                          parseInt(packages[0].packagePrice)}
                      </b>
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      ) : (
        <>
          <Alert severnity={"error"}>{"No Data Found"}</Alert>
        </>
      )}
    </>
  );
}

export default PackagesDetail;
