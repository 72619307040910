import React from "react";

function AuthFooter() {
  return (
    <>
      <footer className="py-5"></footer>
    </>
  );
}

export default AuthFooter;
