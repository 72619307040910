import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, Col, Container, Row, Table } from "reactstrap";
import { Edit, Delete, Publish, MoreVert, Add } from "@material-ui/icons";
import {
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import { baseUrl, getExclusionsEndpoint } from "../../../redux/url";
import {
  deleteExclusions,
  destroyDeleteErrorExclusions,
  filterExclusions,
  getExclusions,
} from "../../../redux/actions/exclusion";
import AddExclusion from "./AddExclusion";
import EditExclusion from "./EditExclusion";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import ImportExclusion from "./ImportInclusion";
import $ from "jquery"
import SettingSidebar from "../../../common/sidebar/SettingSidebar";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Exclusion() {
  const [showAdd, setShowAdd] = useState({});
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [open, setopen] = useState(false);
  const [open3, setopen3] = useState(false);
  const [open1, setopen1] = useState(false);
  const [open2, setopen2] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [deleted, setDeleted] = useState("");
  const [keyWord, setKeyWord] = useState("");
  const [totalLength, setTotalLength] = useState(1);
  const { isAdded } = useSelector((state) => state.addExclusion);
  const { isEdited } = useSelector((state) => state.editExclusion);
  const { isLoading, exclusions, isError } = useSelector(
    (state) => state.getExclusion
  );
  const { isDeleted, isError: deleteError } = useSelector(
    (state) => state.deleteExclusion
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl(null);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.paddingRight = "0";
  };
  const handleClose3 = () => {
    setopen3(false);
  };
  const handleClose = () => {
    setopen(false);
  };
  const handleClose1 = () => {
    setopen1(false);
  };
  const handleClose2 = () => {
    setopen2(false);
  };
  const editShowFunction = (data) => {
    setModal1(true);
    setShowAdd(data);
  };
  const deleteCityFunction = (id) => {
    dispatch(deleteExclusions({ exclusionId: id }));
  };
  const searchChangeHandler = (e) => {
    setKeyWord(e.target.value);
    if (e.target.value === "") {
      dispatch(
        getExclusions({
          skip: page * count - count,
          limit: count,
        })
      );
    } else {
      setPage(1);
      dispatch(
        filterExclusions({
          key: e.target.value,
          skip: 0,
          limit: count,
        })
      );
    }
  };
  const paginationHandler = (e, v) => {
    setPage(v);
    if (keyWord === "") {
      dispatch(
        getExclusions({
          skip: v * count - count,
          limit: count,
        })
      );
    } else {
      dispatch(
        filterExclusions({
          key: keyWord,
          skip: v * count - count,
          limit: count,
        })
      );
    }
  };
  useEffect(() => {
    window.scrollTo(0,0)
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== ""
    ) {
    } else {
      history.push("/login");
    }
    axios.get(baseUrl + getExclusionsEndpoint).then((result) => {
      setTotalLength(result.data.length);
    });
    if (isDeleted && isDeleted !== "") {
      setDeleted(isDeleted);
      setopen2(true);
    }
    if (deleteError && deleteError !== "") {
      setDeleted(deleteError);
      setopen2(true);
    }
    dispatch(
      getExclusions({
        skip: page * count - count,
        limit: count,
      })
    );
    return () => {
      return dispatch(destroyDeleteErrorExclusions());
    };
  }, [isAdded, isEdited, isDeleted,modal1]);
  return (
    <Container className="setting-container">
    <Row>
    <Col lg="3" style={{padding:0}}>
    <SettingSidebar/>
    </Col>
    <Col lg="9">
    <div className="top-100-settings">
      <Container>
        <Row>
          <Col className="order-xl-1" lg="12" xl="12">
            <Card className="bg-secondary shadow">
              <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col sm="12" lg="4">
                          <h3 className="mb-0">Exclusions</h3>
                        </Col>
                        <Col sm="12" lg="4">
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                              <TextField
                                id="input-with-icon-grid"
                                label="Search exclusions"
                                onChange={searchChangeHandler}
                              />
                            </Grid>
                          </Grid>
                        </Col>
                        <Col className="text-location" sm="12" lg="4">
                        <Button
                            color="primary"
                            onClick={() => {
                              return  setModal(true);
                            }}
                            size="sm"
                          >
                            Add Exclusion
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    {isError ? (
                      <>{isError}</>
                    ) : isLoading ? (
                      <>
                        <tbody>
                          <LinearProgress />
                        </tbody>
                      </>
                    ) : exclusions.success === false ? (
                      <>
                        <Alert severity="error">{exclusions.message}</Alert>
                      </>
                    ) : (
                      
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Icon</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        {exclusions.length > 0 &&
                          exclusions.map((row, i) => {
                            return (
                              <tbody>
                                <tr>
                                  <th scope="row">{row.exclusionName}</th>
                                  <td><i className={`${row.exclusionIcon}`}></i></td>
                                  <td>
                                    {" "}
                                    <Edit
                                      style={{ cursor: "pointer" }}
                                      onClick={() => editShowFunction(row)}
                                    />{" "}
                                  </td>
                                  <td>
                                    <Delete
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        deleteCityFunction(row._id)
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </Table>
                    )}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="float-right m-3">
              <Pagination
                count={Math.ceil(totalLength / count)}
                page={page}
                onChange={paginationHandler}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <AddExclusion modal={modal} setModal={setModal} setopen={setopen} />
      {modal1 && (
        <EditExclusion
          modal={modal1}
          setModal={setModal1}
          data={showAdd}
          setOpen={setopen1}
        />
      )}
      {/* <ImportExclusion modal={modal2} setModal={setModal2} setOpen={setopen3} />
      <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
        <Alert onClose={handleClose3} severity="success">
          "Uploaded Successfully"
        </Alert>
      </Snackbar> */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          "Added Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="success">
          "Updated Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
        <Alert onClose={handleClose2} severity="error">
          {deleted}
        </Alert>
      </Snackbar>
    </div>
    </Col>
    </Row>
    </Container>
  );
}

export default Exclusion;
