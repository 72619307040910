import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getCities, getCitiesFooter } from "../../redux/actions/package";
import { filterSettingPage } from "../../redux/actions/settings";
import { baseUrlImage, imagePath } from "../../redux/url";

function UserFooter() {
  const dispatch=useDispatch()
  const history = useHistory()
  const { isLoading: cityLoading, footerCities:cities, isError: cityError } = useSelector(
    (state) => state.getCity
  );
  const { isLoading: loading, settings, isError: error } = useSelector(
    (state) => state.getHomeSetting
  );
  useEffect(()=>{
    dispatch(getCitiesFooter({
      skip:0,
      limit:2
    }))
    dispatch(filterSettingPage({
      key: "Home",
      skip:0,
      limit:1
    }))

  },[])
  return (
    <>
      <footer className="w-100">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <h2 className="w-100 heading">About</h2>
              <p>
                We offer matchless services to the business and tourist. Since
                our inception pre-independence, provided highly efficient
                chauffeur driven cars & coaches.{" "}
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <h2 className="w-100 heading">contact us</h2>
              <ul className="footer-add pr-0">
                <li>
                  {" "}
                  <span>
                    <img src="/images/mp.png" alt="image" />
                  </span>
                  SCO - 188-190, Sec-34A, Ground Floor, Chandigarh
                </li>
                <li>
                  {" "}
                  <span>
                    <img src="/images/ph.png" alt="image" />
                  </span>
                  <a href="tel:+91 9875981976">+91 8699 12370</a>
                </li>
                <li>
                  {" "}
                  <span>
                    <img src="/images/mail.png" alt="image" />
                  </span>
                  SCO 96-97, Sector 34 A, Chandigarh
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <h2 className="w-100 heading">Latest Destinations</h2>
              <div className="footer-desti">
                <ul className="thum-img">
                {
                  cityLoading ? <CircularProgress/>:
                  cityError? <Alert severnity="error">{cityError}</Alert>:
                  cities.success==false? <><Alert severnity="error">{cities.message}</Alert></>:
                  cities.length>0&&
                  cities.map((e,i)=>{
                    return(
                      <li onClick={()=>history.push(`/city/${e.cityName}/${e.cityId}`)} style={{cursor:"pointer"}}>
                    <span>
                      <img src={baseUrlImage+imagePath+e.bannerImage[0]} alt="image" style={{width:"76px", height:"66px", borderRadius:"20px"}} />
                    </span>
                    <h4>
                      {e.cityName}<span>India</span>
                    </h4>
                  </li>
                    )
                  })
                }
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <h2 className="w-100 heading">Social Media</h2>
              <ul className="footer-social w-100 pr-0">
              {
                loading ? <CircularProgress/>:
                error? <><Alert severnity="error">{error}</Alert></>:
                settings.success==false? <><Alert severnity="error">{settings.message}</Alert></>:
                settings.length>0 && 
                settings[0].socialIcons.length>0&&
                settings[0].socialIcons.map((e,i)=>{
                 return <li className="social-link-item">
                  <a href={e.url}>
                    <i className={`fab fa-${e.icon}`} style={{fontSize:"25px", color:"white"}}></i>
                  </a>
                </li>
                })
              }
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="copy w-100">
        <h5 style={{ color: "white" }}>Copyright All Rights Reserved © 2021</h5>
      </div>
    </>
  );
}

export default UserFooter;
