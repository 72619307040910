import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  addCompany,
  destroyAddCompany,
  destroyAddCompanyError,
} from "../../../redux/actions/company";
import Select from "react-select";

function AddCompany({ modal, setModal, setopen }) {
  const [name, setName] = useState("");
  const [edit, setEdit] = useState("");
  const [companyContactDetail, setCompanyContactDetail] = useState({
    email: "",
    phoneNo: "",
    address: "",
    password:""
  });
  const [companyPersonContactDetail, setCompanyPersonContactDetail] = useState({
    email: "",
    phoneNo: "",
    name: "",
  });
  const [companyLogo, setCompanyLogo] = useState({});
  const { isLoading, isAdded, isError } = useSelector(
    (state) => state.addCompany
  );
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const formSubmitted = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("companyName", name);
    formData.append("companyLogo", companyLogo);
    formData.append("companyCategory", title);
    formData.append("companyEmail", companyContactDetail.email);
    formData.append("companyAddress", companyContactDetail.address);
    formData.append("companyPhone", companyContactDetail.phoneNo);
    formData.append("companyPassword",companyContactDetail.password)
    formData.append("companyPersonPhone", companyPersonContactDetail.phoneNo);
    formData.append("companyPersonName", companyPersonContactDetail.name);
    formData.append("companyPersonEmail", companyPersonContactDetail.email);
    formData.append("userId", localStorage.getItem("userId"));
    dispatch(addCompany(formData));
  };
  const closeModal = () => {
    setModal(false);
    setopen(true);
    setName("");
    setTitle("");
    setCompanyPersonContactDetail({
      email: "",
      phoneNo: "",
      name: "",
    });
    setCompanyContactDetail({
      email: "",
      phoneNo: "",
      address: "",
      password:""
    });
    setCompanyLogo({});
  };
  useEffect(() => {
    if (isAdded !== undefined && isAdded !== "") {
      closeModal();
    }
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setOpen(true);
    }
    return () => {
      return dispatch(destroyAddCompany()), dispatch(destroyAddCompanyError());
    };
  }, [isAdded, isError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody>
          <Container>
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Add Company"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {edit && (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="error">
                          {edit}
                        </Alert>
                      </Snackbar>
                    )}
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="company Name"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label">
                                Company Type
                              </label>
                              <Select
                                name="company-type"
                                value={{
                                  value: title,
                                  label: title,
                                }}
                                onChange={(e) => setTitle(e.value)}
                                options={[
                                  {
                                    value: "Tour and Travels",
                                    label: "Tour and Travels",
                                  },
                                  {
                                    value: "Cab",
                                    label: "Cab",
                                  },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                placeholder="Company email"
                                type="text"
                                name="email"
                                value={companyContactDetail.email}
                                onChange={(e) =>
                                  setCompanyContactDetail({
                                    ...companyContactDetail,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company PhoneNo
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Company number"
                                type="text"
                                name="phoneNo"
                                value={companyContactDetail.phoneNo}
                                onChange={(e) =>
                                  setCompanyContactDetail({
                                    ...companyContactDetail,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company Address
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Company address"
                                type="textarea"
                                rows="5"
                                name="address"
                                value={companyContactDetail.address}
                                onChange={(e) =>
                                  setCompanyContactDetail({
                                    ...companyContactDetail,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company Logo
                              </label>
                              <Input
                                className="form-control-alternative"
                                type="file"
                                onChange={(e) =>
                                  setCompanyLogo(e.target.files[0])
                                }
                                accept="image/*,.svg"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company Person Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Company person name"
                                type="text"
                                name="name"
                                value={companyPersonContactDetail.name}
                                onChange={(e) =>
                                  setCompanyPersonContactDetail({
                                    ...companyPersonContactDetail,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company Person Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                placeholder="Company person email"
                                type="email"
                                name="email"
                                value={companyPersonContactDetail.email}
                                onChange={(e) =>
                                  setCompanyPersonContactDetail({
                                    ...companyPersonContactDetail,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company Person Number
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Company person number"
                                type="text"
                                name="phoneNo"
                                value={companyPersonContactDetail.phoneNo}
                                onChange={(e) =>
                                  setCompanyPersonContactDetail({
                                    ...companyPersonContactDetail,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Password
                              </label>
                              <Input
                                className="form-control-alternative"
                                placeholder="password.."
                                type="password"
                                autoComplete="new-password"
                                name="password"
                                value={companyContactDetail.password}
                                onChange={(e) =>
                                  setCompanyContactDetail({
                                    ...companyContactDetail,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Add Company
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddCompany;
