import { InputBase, MenuItem, Select, Snackbar, withStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { destroyAddUserError, destroyUserAdd, register } from "../../../redux/actions/user";
const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      width: "100%",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);
function AddUser({ setShowRegister }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(4)
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const { loading, registered,error } = useSelector(
    (state) => state.registerUser
  );
  const [errorText,setErrorText] = useState("")
  const [open,setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const handleClose = () =>{
    setOpen(false)
  }
  const handleClose1 = () =>{
    setOpen1(false)
  }
  const fromSubmitted = (e) => {
    e.preventDefault();
    console.log(role)
    if (email !== "" && password !== "" && name !== "") {
      const info = {
        userName: name,
        email: email,
        password: password,
        userRole:role
      };
      dispatch(register(info));
    } else {
      setErrorText("Please Fill all the fields")
      setOpen1(true)
    }
  };

  useEffect(() => {
    if (registered === true) {
      setOpen(true)
      setShowRegister(false);
      setName("")
      setEmail("")
      setPassword("")
    }
    if(error !==undefined && error !==""){
      setErrorText(error)
      setOpen1(true)
    }
    return () => {
      return (
        dispatch(destroyUserAdd()),
        dispatch(destroyAddUserError())
      )
    };
  }, [registered,error]);
  return (
    <>
      <Col lg="12" md="12">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Add User</h3>
              </div>
              <Col className="text-right" xs="4">
                <Button
                  color="primary"
                  onClick={() => setShowRegister(false)}
                  size="sm"
                >
                  See All Users
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={fromSubmitted} role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    type="text"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        name="category"
                        placeholder="select category"
                        value={role}
                        onChange={(e)=>setRole(e.target.value)}
                        input={<BootstrapInput />}
                      >
                              <MenuItem value={1}>
                                Super Admin
                              </MenuItem>
                              <MenuItem value={2}>
                                Admin
                              </MenuItem>
                              <MenuItem value={3}>
                                Agency
                              </MenuItem>
                              <MenuItem value={4}>
                                User
                              </MenuItem>
                      </Select>
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic">
                <small>
                  password strength:{" "}
                  {
                    password !== "" ?
                    password.length <5 ?
                    <span className="text-danger font-weight-700">weak</span>:
                    password.length <=8 ? 
                    <span className="text-warning font-weight-700">normal</span>:
                    password.length >8 ?
                    <span className="text-success font-weight-700">strong</span>:
                    <></>:
                    <></>
                  }
                </small>
              </div>
              {/* <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row> */}
              <div>
                <Button
                  className="mt-4"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "adding...." : "Add User"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Registered Successfully
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddUser;
