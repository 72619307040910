import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { CardHeader, Col, Container, FormGroup, Input, Row } from "reactstrap";
import HeaderSetting from "./HeaderSetting";
import FooterSetting from "./FooterSetting";
import SocialSetting from "./SocialSetting";
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getHomeSettings } from "../../../../redux/actions/settings";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));


export default function NewSetting() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const {isLoading,isError,settings} = useSelector(state=>state.getHomeSetting)
  const {
    isAdded: success,
  } = useSelector((state) => state.addHomeSetting);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(()=>{
    if(success !==undefined && success!==""){
      setOpen(true)
    }
    dispatch(getHomeSettings())
  },[success])

  return (
    <div className={classes.root + " top-100-settings"}>
    {
      isLoading ? <LinearProgress/>:
      isError? <>{isError}</>:
      settings.length>0 &&
      <>
      <Accordion
        expanded={expanded === "header"}
        onChange={handleChange("header")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col sm="12" lg="12">
                  <h3 className="mb-0">Header</h3>
                </Col>
              </Row>
            </CardHeader>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <HeaderSetting setExpanded={setExpanded} setOpen={setOpen} data={settings[0]}/>
        </AccordionDetails>
      </Accordion>
      <Divider/>
      <Accordion
        expanded={expanded === "footer"}
        onChange={handleChange("footer")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col sm="12" lg="12">
                  <h3 className="mb-0">Footer</h3>
                </Col>
              </Row>
            </CardHeader>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <FooterSetting setExpanded={setExpanded} setOpen={setOpen} data={settings[0]}/>
        </AccordionDetails>
      </Accordion>
      <Divider/>
      <Accordion
        expanded={expanded === "social"}
        onChange={handleChange("social")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col sm="12" lg="12">
                  <h3 className="mb-0">Social Icons</h3>
                </Col>
              </Row>
            </CardHeader>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <SocialSetting setExpanded={setExpanded} setOpen={setOpen} data={settings[0]}/>
        </AccordionDetails>
      </Accordion>
      </>
    }
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          "Edited Successfully"
        </Alert>
      </Snackbar>
    </div>
  );
}
