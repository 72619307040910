import axios from "axios";
import {
  ADD_GALLERY_ERROR,
  ADD_GALLERY_LOADING,
  ADD_GALLERY_SUCCESS,
  DELETE_GALLERY_ERROR,
  DELETE_GALLERY_LOADING,
  DELETE_GALLERY_SUCCESS,
  DESTROY_GALLERY_ADD,
  DESTROY_GALLERY_DELETE,
  DESTROY_GALLERY_EDIT,
  DESTROY_GALLERY_ERROR_ADD_C,
  DESTROY_GALLERY_ERROR_EDIT_C,
  EDIT_GALLERY_ERROR,
  EDIT_GALLERY_LOADING,
  EDIT_GALLERY_SUCCESS,
  GET_GALLERY_ERROR,
  GET_GALLERY_FILTER_SUCCESS,
  GET_GALLERY_LOADING,
  GET_GALLERY_SUCCESS,
  GET_PARTICULAR_GALLERY_FILTER_SUCCESS,
} from "../constants";
import {
  baseUrl,
  getGalleryEndpoint,
  addGalleryEndpoint,
  editGalleryEndpoint,
  deleteGalleryEndpoint,
  getParticularGalleryEndpoint,
} from "../url";

export const getGallery = (data) => async (dispatch) => {
  dispatch({ type: GET_GALLERY_LOADING });
  try {
    if (data?.key) {
      axios({
        method: "get",
        url: `${baseUrl}${getGalleryEndpoint}?keyword=${data.key}`,
      })
        .then((result) => {
          dispatch({
            type: GET_GALLERY_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_GALLERY_ERROR,
            payload: error.message,
          });
        });
    } else if (data?.skip && data?.limit) {
      axios({
        method: "get",
        url: `${baseUrl}${getGalleryEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({
            type: GET_GALLERY_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_GALLERY_ERROR,
            payload: error.message,
          });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getGalleryEndpoint}`,
      })
        .then((result) => {
          dispatch({
            type: GET_GALLERY_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_GALLERY_ERROR,
            payload: error.message,
          });
        });
    }
  } catch (error) {
    dispatch({ type: GET_GALLERY_ERROR, payload: error.message });
  }
};

export const addGallery = (data) => async (dispatch) => {
  dispatch({ type: ADD_GALLERY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addGalleryEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_GALLERY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: ADD_GALLERY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_GALLERY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: ADD_GALLERY_ERROR, payload: error.message });
  }
};

export const editGallery = (data) => async (dispatch) => {
  dispatch({ type: EDIT_GALLERY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editGalleryEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_GALLERY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: EDIT_GALLERY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EDIT_GALLERY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: EDIT_GALLERY_ERROR, payload: error.message });
  }
};

export const deleteGallery = (data) => async (dispatch) => {
  dispatch({ type: DELETE_GALLERY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteGalleryEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_GALLERY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_GALLERY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_GALLERY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: DELETE_GALLERY_ERROR, payload: error.message });
  }
};

export const destroyAddGallery = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_GALLERY_ADD, payload: "" });
};
export const destroyEditGallery = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_GALLERY_EDIT, payload: "" });
};
export const destroyDeleteGallery = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_GALLERY_DELETE, payload: "" });
};
export const destroyAddGalleryError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_GALLERY_ERROR_ADD_C, payload: "" });
};
export const destroyEditGalleryError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_GALLERY_ERROR_EDIT_C, payload: "" });
};

export const destroyDeleteGalleryErrorC = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_GALLERY_ERROR_C", payload: "" });
};

export const filterGallery = (data) => async (dispatch) => {
  dispatch({ type: GET_GALLERY_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${getGalleryEndpoint}?skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({
          type: GET_GALLERY_FILTER_SUCCESS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GALLERY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: GET_GALLERY_ERROR, payload: error.message });
  }
};

export const getParticularGallery = (data) => async (dispatch) => {
  dispatch({ type: GET_GALLERY_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${getParticularGalleryEndpoint}?galleryId=${data.key}`,
    })
      .then((result) => {
        dispatch({
          type: GET_PARTICULAR_GALLERY_FILTER_SUCCESS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GALLERY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: GET_GALLERY_ERROR, payload: error.message });
  }
};
// export const getCityGallery = (data) => async (dispatch) => {
//   dispatch({ type: GET_GALLERY_LOADING });
//   try {
//       axios({
//         method: "get",
//         url: `${baseUrl}${getParticularGalleryEndpoint}?galleryId=${data.key}`,
//       })
//         .then((result) => {
//           dispatch({
//             type: GET_PARTICULAR_GALLERY_FILTER_SUCCESS,
//             payload: result.data,
//           });
//         })
//         .catch((error) => {
//           dispatch({
//             type: GET_GALLERY_ERROR,
//             payload: error.message,
//           });
//         });
//   } catch (error) {
//     dispatch({ type: GET_GALLERY_ERROR, payload: error.message });
//   }
// };
