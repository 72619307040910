import { AccordionActions, Button, Divider, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from "reactstrap";
import {
  addHomeSetting,
  destroyAddHomeSetting,
  destroyAddHomeSettingError,
  getHomeSettings,
} from "../../../../redux/actions/settings";

const initialState = {
  facebook: {},
  linkedIn: {},
  gmail: {},
  github: {},
  instagram:{}
};
function SocialSetting({ setExpanded, setOpen, data }) {
  const [iconUrl, setIconUrl] = useState(initialState);
  const dispatch = useDispatch();
  const {
    isLoading: loading,
    isError: error,
    isAdded: success,
  } = useSelector((state) => state.addHomeSetting);
  const { isLoading, isError, settings } = useSelector(
    (state) => state.getHomeSetting
  );
  const [open1, setOpen1] = useState(false);
  const [errorText, setErrorText] = useState("");
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleUrlChange = (e) => {
    const { name, value } = e.target;
    const iconName = e.target.attributes.iconname.nodeValue;
    setIconUrl({
      ...iconUrl,
      [name]: { name: name, url: value, icon: `fab fa-${iconName}` },
    });
  };
  const submitData = (e) => {
    console.log(iconUrl);
    e.preventDefault();
    dispatch(
      addHomeSetting({
        ...data,
        userId: localStorage.getItem("userId"),
        socialIcons: iconUrl,
      })
    );
  };
  useEffect(() => {
    if (error !== undefined && error !== "") {
      setErrorText(error);
      setOpen1(true);
    }
    if (data.socialIcons) {
      setIconUrl(data.socialIcons[0]);
    }
    return () => {
      return (
        dispatch(destroyAddHomeSetting()),
        dispatch(destroyAddHomeSettingError())
      );
    };
  }, [error]);
  return (
    <>
      <Container>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Facebook Url
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="Facebook Url"
                type="text"
                name="facebook"
                iconName="facebook"
                value={iconUrl.facebook.url}
                onChange={(e) => handleUrlChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                LinkedIn Url
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="LinkedIn Url"
                type="text"
                name="linkedIn"
                iconName="linkedin"
                value={iconUrl.linkedIn.url}
                onChange={(e) => handleUrlChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Gmail Url
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="Gmail Url"
                type="text"
                name="gmail"
                iconName="envelope"
                value={iconUrl.gmail.url}
                onChange={(e) => handleUrlChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Instagram Url
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="LinkedIn Url"
                type="text"
                name="instagram"
                iconName="instagram"
                value={iconUrl.instagram.url}
                onChange={(e) => handleUrlChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <Divider />
            <AccordionActions>
              <Button
                size="small"
                color="secondary"
                onClick={() => setExpanded(false)}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={submitData}
              >
                {loading ? "Updating" : "Update"}
              </Button>
            </AccordionActions>
          </Col>
        </Row>
      </Container>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SocialSetting;
