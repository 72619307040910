import React from "react";
import { Link } from "react-router-dom";

function HolidayHeader() {
  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }
  return (
    <>
      <div class="inner-top w-100">
        <div class="top-sec w-100">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-4">
                <ul class="social-link w-100 mr-0 mb-0 pl-0 float-left">
                  <li class="social-link-item">
                    <a href="#">
                      <img alt="image" src="/images/fb.png" />
                    </a>
                  </li>
                  <li class="social-link-item">
                    <a href="#">
                      <img alt="image" src="/images/twitter.png" />
                    </a>
                  </li>
                  <li class="social-link-item">
                    <a href="#">
                      <img alt="image" src="/images/linkdin.png" />
                    </a>
                  </li>
                  <li class="social-link-item">
                    <a href="#">
                      <img alt="image" src="/images/insta.png" />
                    </a>
                  </li>
                  <li class="social-link-item">
                    <a href="#">
                      <img alt="image" src="/images/owl.png" />
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-8">
                <ul class="address mb-0 pl-0 mr-0">
                  <li class="address-item">
                    <img alt="image" src="/images/ph.png" class="mr-1" />
                    <a href="tel:+91 9875981976" class="text-decoration-none">
                      +91 9875981976
                    </a>
                  </li>
                  <li class="address-item">
                    <img alt="image" src="/images/mail.png" class="mr-2" />
                    <a
                    href="mailto:corporate.sales@exploreholidays.in"
                    className="text-decoration-none"
                  >
                    info@exploreholidays.in
                  </a>
                  </li>
                </ul>
                <ul class="login mb-0 pl-0 mr-0">
                  <li>
                    <Link to="/contact" class="text-decoration-none">
                      Contact us
                    </Link>
                  </li>
                  {localStorage.getItem("accessToken") ? (
                    <>
                      {localStorage.getItem("isAdmin") == "true" ? (
                        <>
                          <Link to="/admin">Admin</Link>
                        </>
                      ) : (
                        <Link to="/">User</Link>
                      )}
                    </>
                  ) : (
                    <Link to="/login">Login</Link>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-sec-mobile">
        {/* <!--<div class="col-sm-3 col-md-3 col-lg-8">--> */}
        <div id="mySidenav" class="sidenav">
          <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>
            &times;
          </a>
          <a href="#">Search</a>
          <Link to="/holidays">Holidays</Link>
          <a href="#">Hotels</a>
          <a href="#">Activities & Tours</a>
          {localStorage.getItem("accessToken") ? (
            <>
              {localStorage.getItem("isAdmin") == "true" ? (
                <>
                  <Link to="/admin">Admin</Link>
                </>
              ) : (
                <Link to="/">User</Link>
              )}
            </>
          ) : (
            <Link to="/login">Login</Link>
          )}
        </div>
        <div id="main">
          <span
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={openNav}
          >
            &#9776;{" "}
          </span>
        </div>
        {/* <!--</div>--> */}
        <div class="col-sm-12 col-md-12 col-lg-12 logo ">
          {/* <Link to="/" class="logo pt-1 pb-2"><img alt="image" src="/images/logo.png"/></Link> */}
        </div>
      </div>
      {/* <!----bottom-header-sec----> */}
      <div class="bottom-sec inner-bottom w-100 float-left">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-4">
              {/* <Link to="/" class="logo"><img alt="image" src="/images/logo1.png"/></Link> */}
            </div>
            <div class="col-sm-6 col-md-8 col-lg-8">
              <nav class="navbar navbar-expand-lg navbar-light bg-none">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav mr-auto w-100">
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        <img alt="image" src="/images/grey.png" />
                      </a>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/holidays">
                        Holidays
                      </Link>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        Hotels{" "}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">
                        Activities & Tours
                      </a>
                    </li>
                    <li class="nav-item">
                      {localStorage.getItem("accessToken") ? (
                        <>
                          {localStorage.getItem("isAdmin") == "true" ? (
                            <>
                              <Link to="/admin" className="nav-link">
                                Admin
                              </Link>
                            </>
                          ) : (
                            <Link to="/" className="nav-link">
                              User
                            </Link>
                          )}
                        </>
                      ) : (
                        <Link to="/login" className="nav-link">
                          Login
                        </Link>
                      )}
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HolidayHeader;
