import axios from "axios";
import {
  ADD_BOOKING_ERROR,
  ADD_BOOKING_LOADING,
  ADD_BOOKING_SUCCESS,
  DELETE_BOOKING_ERROR,
  DELETE_BOOKING_LOADING,
  DELETE_BOOKING_SUCCESS,
  DESTROY_BOOKING_ADD,
  DESTROY_BOOKING_DELETE,
  DESTROY_BOOKING_ERROR_ADD_C,
  GET_BOOKING_ERROR,
  GET_BOOKING_LOADING,
  GET_BOOKING_SUCCESS,
} from "../constants";
import {
  baseUrl,
  getBookingEndpoint,
  addBookingEndpoint,
  deleteBookingEndpoint,
} from "../url";

export const getBooking = (data) => async (dispatch) => {
  dispatch({ type: GET_BOOKING_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getBookingEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({
            type: GET_BOOKING_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_BOOKING_ERROR,
            payload: error.message,
          });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getBookingEndpoint}`,
      })
        .then((result) => {
          dispatch({
            type: GET_BOOKING_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_BOOKING_ERROR,
            payload: error.message,
          });
        });
    }
  } catch (error) {
    dispatch({ type: GET_BOOKING_ERROR, payload: error.message });
  }
};

export const addBooking = (data) => async (dispatch) => {
  dispatch({ type: ADD_BOOKING_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addBookingEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_BOOKING_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: ADD_BOOKING_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_BOOKING_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: ADD_BOOKING_ERROR, payload: error.message });
  }
};

export const deleteBooking = (data) => async (dispatch) => {
  dispatch({ type: DELETE_BOOKING_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteBookingEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_BOOKING_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_BOOKING_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_BOOKING_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: DELETE_BOOKING_ERROR, payload: error.message });
  }
};

export const destroyAddBooking = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_BOOKING_ADD, payload: "" });
};
export const destroyDeleteBooking = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_BOOKING_DELETE, payload: "" });
};
export const destroyAddBookingError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_BOOKING_ERROR_ADD_C, payload: "" });
};
export const destroyDeleteBookingErrorC = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_BOOKING_ERROR_C", payload: "" });
};

export const filterBooking = (data) => async (dispatch) => {
  dispatch({ type: GET_BOOKING_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${getBookingEndpoint}?skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({
          type: GET_BOOKING_SUCCESS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_BOOKING_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: GET_BOOKING_ERROR, payload: error.message });
  }
};
