import React, { useEffect } from "react";
import { useHistory } from "react-router";

function Home({ classes }) {
  const history = useHistory();
  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== ""
    ) {
      // history.push("/admin/packages")
    } else {
      history.push("/login");
    }
  }, []);
  return <div className="top-100">ADMIN HOME SCREEN</div>;
}

export default Home;
