import axios from "axios";
import {
  ADD_TRANSPORT_CATEGORY_ERROR,
  ADD_TRANSPORT_CATEGORY_LOADING,
  ADD_TRANSPORT_CATEGORY_SUCCESS,
  ADD_VEHICLE_TYPE_CATEGORY_ERROR,
  ADD_VEHICLE_TYPE_CATEGORY_LOADING,
  ADD_VEHICLE_TYPE_CATEGORY_SUCCESS,
  DELETE_TRANSPORT_CATEGORY_ERROR,
  DELETE_TRANSPORT_CATEGORY_LOADING,
  DELETE_TRANSPORT_CATEGORY_SUCCESS,
  DELETE_VEHICLE_TYPE_CATEGORY_ERROR,
  DELETE_VEHICLE_TYPE_CATEGORY_LOADING,
  DELETE_VEHICLE_TYPE_CATEGORY_SUCCESS,
  DESTROY_TRANSPORT_CATEGORY_ADD,
  DESTROY_TRANSPORT_CATEGORY_DELETE,
  DESTROY_TRANSPORT_CATEGORY_EDIT,
  DESTROY_TRANSPORT_ERROR_ADD_C,
  DESTROY_TRANSPORT_ERROR_EDIT_C,
  DESTROY_VEHICLE_TYPE_CATEGORY_ADD,
  DESTROY_VEHICLE_TYPE_CATEGORY_DELETE,
  DESTROY_VEHICLE_TYPE_CATEGORY_EDIT,
  DESTROY_VEHICLE_TYPE_ERROR_ADD_C,
  DESTROY_VEHICLE_TYPE_ERROR_EDIT_C,
  EDIT_TRANSPORT_CATEGORY_ERROR,
  EDIT_TRANSPORT_CATEGORY_LOADING,
  EDIT_TRANSPORT_CATEGORY_SUCCESS,
  EDIT_VEHICLE_TYPE_CATEGORY_ERROR,
  EDIT_VEHICLE_TYPE_CATEGORY_LOADING,
  EDIT_VEHICLE_TYPE_CATEGORY_SUCCESS,
  GET_TRANSPORT_CATEGORY_ERROR,
  GET_TRANSPORT_CATEGORY_LOADING,
  GET_TRANSPORT_CATEGORY_SUCCESS,
  GET_VEHICLE_TYPE_CATEGORY_ERROR,
  GET_VEHICLE_TYPE_CATEGORY_LOADING,
  GET_VEHICLE_TYPE_CATEGORY_SUCCESS,
} from "../constants";
import {
  baseUrl,
  getTransportEndpoint,
  addTransportEndpoint,
  editTransportEndpoint,
  deleteTransportEndPoint,
  getVehicleEndpoint,
  addVehicleEndpoint,
  editVehicleEndpoint,
  deleteVehicleEndPoint,
} from "../url";
export const getTransport = (data) => async (dispatch) => {
  dispatch({ type: GET_TRANSPORT_CATEGORY_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getTransportEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({
            type: GET_TRANSPORT_CATEGORY_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_TRANSPORT_CATEGORY_ERROR,
            payload: error.message,
          });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getTransportEndpoint}`,
      })
        .then((result) => {
          dispatch({
            type: GET_TRANSPORT_CATEGORY_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_TRANSPORT_CATEGORY_ERROR,
            payload: error.message,
          });
        });
    }
  } catch (error) {
    dispatch({ type: GET_TRANSPORT_CATEGORY_ERROR, payload: error.message });
  }
};

export const addTransport = (data) => async (dispatch) => {
  dispatch({ type: ADD_TRANSPORT_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addTransportEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_TRANSPORT_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: ADD_TRANSPORT_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_TRANSPORT_CATEGORY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: ADD_TRANSPORT_CATEGORY_ERROR, payload: error.message });
  }
};

export const editTransport = (data) => async (dispatch) => {
  dispatch({ type: EDIT_TRANSPORT_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editTransportEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_TRANSPORT_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: EDIT_TRANSPORT_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EDIT_TRANSPORT_CATEGORY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: EDIT_TRANSPORT_CATEGORY_ERROR, payload: error.message });
  }
};

export const deleteTransport = (data) => async (dispatch) => {
  dispatch({ type: DELETE_TRANSPORT_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteTransportEndPoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_TRANSPORT_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_TRANSPORT_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_TRANSPORT_CATEGORY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: DELETE_TRANSPORT_CATEGORY_ERROR, payload: error.message });
  }
};

export const destroyAddTransport = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_TRANSPORT_CATEGORY_ADD, payload: "" });
};
export const destroyEditTransport = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_TRANSPORT_CATEGORY_EDIT, payload: "" });
};
export const destroyDeleteTransport = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_TRANSPORT_CATEGORY_DELETE, payload: "" });
};
export const destroyAddTransportError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_TRANSPORT_ERROR_ADD_C, payload: "" });
};
export const destroyEditTransportError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_TRANSPORT_ERROR_EDIT_C, payload: "" });
};

export const destroyDeleteTransportErrorC = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_TRANSPORT_ERROR_C", payload: "" });
};

export const getVehicleType = (data) => async (dispatch) => {
  dispatch({ type: GET_VEHICLE_TYPE_CATEGORY_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getVehicleEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({
            type: GET_VEHICLE_TYPE_CATEGORY_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_VEHICLE_TYPE_CATEGORY_ERROR,
            payload: error.message,
          });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getVehicleEndpoint}`,
      })
        .then((result) => {
          dispatch({
            type: GET_VEHICLE_TYPE_CATEGORY_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_VEHICLE_TYPE_CATEGORY_ERROR,
            payload: error.message,
          });
        });
    }
  } catch (error) {
    dispatch({ type: GET_VEHICLE_TYPE_CATEGORY_ERROR, payload: error.message });
  }
};

export const addVehicleType = (data) => async (dispatch) => {
  dispatch({ type: ADD_VEHICLE_TYPE_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addVehicleEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_VEHICLE_TYPE_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: ADD_VEHICLE_TYPE_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_VEHICLE_TYPE_CATEGORY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: ADD_VEHICLE_TYPE_CATEGORY_ERROR, payload: error.message });
  }
};

export const editVehicleType = (data) => async (dispatch) => {
  dispatch({ type: EDIT_VEHICLE_TYPE_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editVehicleEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_VEHICLE_TYPE_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: EDIT_VEHICLE_TYPE_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EDIT_VEHICLE_TYPE_CATEGORY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({
      type: EDIT_VEHICLE_TYPE_CATEGORY_ERROR,
      payload: error.message,
    });
  }
};

export const deleteVehicleType = (data) => async (dispatch) => {
  dispatch({ type: DELETE_VEHICLE_TYPE_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteVehicleEndPoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_VEHICLE_TYPE_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_VEHICLE_TYPE_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_VEHICLE_TYPE_CATEGORY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({
      type: DELETE_VEHICLE_TYPE_CATEGORY_ERROR,
      payload: error.message,
    });
  }
};

export const destroyAddVehicleType = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_VEHICLE_TYPE_CATEGORY_ADD, payload: "" });
};
export const destroyEditVehicleType = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_VEHICLE_TYPE_CATEGORY_EDIT, payload: "" });
};
export const destroyDeleteVehicleType = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_VEHICLE_TYPE_CATEGORY_DELETE, payload: "" });
};
export const destroyAddVehicleTypeError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_VEHICLE_TYPE_ERROR_ADD_C, payload: "" });
};
export const destroyEditVehicleTypeError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_VEHICLE_TYPE_ERROR_EDIT_C, payload: "" });
};

export const destroyDeleteVehicleTypeErrorC = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_VEHICLE_TYPE_ERROR_C", payload: "" });
};

export const filterVehicleType = (data) => async (dispatch) => {
    dispatch({ type: GET_VEHICLE_TYPE_CATEGORY_LOADING });
    try {
        axios({
          method: "get",
          url: `${baseUrl}${getVehicleEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
        })
          .then((result) => {
            dispatch({
              type: GET_VEHICLE_TYPE_CATEGORY_SUCCESS,
              payload: result.data,
            });
          })
          .catch((error) => {
            dispatch({
              type: GET_VEHICLE_TYPE_CATEGORY_ERROR,
              payload: error.message,
            });
          });
    } catch (error) {
      dispatch({ type: GET_VEHICLE_TYPE_CATEGORY_ERROR, payload: error.message });
    }
  };

  export const filterTransport = (data) => async (dispatch) => {
    dispatch({ type: GET_TRANSPORT_CATEGORY_LOADING });
    try {
        axios({
          method: "get",
          url: `${baseUrl}${getTransportEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
        })
          .then((result) => {
            dispatch({
              type: GET_TRANSPORT_CATEGORY_SUCCESS,
              payload: result.data,
            });
          })
          .catch((error) => {
            dispatch({
              type: GET_TRANSPORT_CATEGORY_ERROR,
              payload: error.message,
            });
          });
    } catch (error) {
      dispatch({ type: GET_TRANSPORT_CATEGORY_ERROR, payload: error.message });
    }
  };