import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import {
  deleteCityImage,
  destroyDeleteErrorImageCity,
  destroyEditError,
  destroyHotelEdit,
  editHotel,
  getCities,
} from "../../../redux/actions/package";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Select from "react-select"
import { baseUrlImage, imagePath } from "../../../redux/url";

function EditHotel({ modal, setModal, setopen, info }) {
  const [edit, setEdit] = useState("");
  const [files, setFiles] = useState({});
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    name: "",
    contact: "",
    address: "",
    rating: "",
    cityName:""
  });
  const {
    isLoading: deleteImageLoading,
    isError: deleteImageError,
    imageDeleted,
  } = useSelector((state) => state.deleteCityImage);
  const [open1, setOpen1] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [imgId, setImgId] = useState("");
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const { loading, error, edited } = useSelector((state) => state.editHotel);
  const {isLoading, isError, cities} = useSelector(state=>state.getCity)
  const onChangeHandler = (e) => {
    if(e.target){
      const { name, value } = e.target;
      setData({
        ...data,
        [name]: value,
      });
    }
    else{
      setData({
        ...data,
        ["cityName"]: e.value,
      });
    }
  };

  const toggle = () => setModal(!modal);

  const formSubmitted = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("hotelId", info._id);
    formData.append("hotelName", data.name);
    formData.append("contactNumber", data.contact);
    formData.append("contactAddress", data.address);
    formData.append("hotelRating", data.rating);
    formData.append("hotelCity", data.cityName);
    formData.append("userId", localStorage.getItem("userId"))
    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        formData.append("hotelImage", files[i]);
      }
    }
    dispatch(editHotel(formData));
  };
  const closeModal = () => {
    setModal(false);
    setopen(true);
  };
  const deleteImageFunction = (id, imgIdf,data) => {
    dispatch(
      deleteCityImage({
        imageId: id,
      })
    );
    setImgId(imgIdf);
    document.getElementById(imgIdf).style.display = "none";
    // setSight(sight.filter(elm=>elm!==data))
  };
  useEffect(() => {
    dispatch(getCities())
    if (edited !== undefined && edited !== "") {
      closeModal();
    }
    if (error !== undefined && error !== "") {
      setEdit(error);
      setOpen(true);
    }
    if (deleteImageError !== undefined && deleteImageError !== "") {
      setDeleteText(deleteImageError);
      setOpen1(true);
      document.getElementById(imgId).style.display = "flex";
    }
    setData({
      name: info.hotelName,
      contact: info.contactNumber,
      address: info.contactAddress,
      rating: info.hotelRating,
      cityName:info.hotelCity
    });
    setImages(info.images);
    return () => {
      return dispatch(destroyHotelEdit()), dispatch(destroyEditError("hotel"), dispatch(destroyDeleteErrorImageCity()));
    };
  }, [edited, error, deleteImageError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            {edit && (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {edit}
                </Alert>
              </Snackbar>
            )}
            <Snackbar
                open={open1}
                autoHideDuration={6000}
                onClose={handleClose1}
              >
                <Alert onClose={handleClose1} severity="error">
                  {deleteText}
                </Alert>
              </Snackbar>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{"Edit Hotel"}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Fill the form
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Hotel Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Hotel Name"
                              type="text"
                              name="name"
                              value={data.name}
                              onChange={onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          for="exampleSelect"
                        >
                          City Name
                        </label>
                        <Select
                        name="cityName"
                          value={{value:data.cityName,label:data.cityName}}
                          onChange={onChangeHandler}
                          options={
                            cities.length > 0 &&
                            cities.map((e, i) => {
                              return { value: e.cityName, label: e.cityName };
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Contact Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Contact Number"
                              type="text"
                              name="contact"
                              value={data.contact}
                              onChange={onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label>Contact Address</label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Contact Address"
                              rows="4"
                              type="textarea"
                              name="address"
                              value={data.address}
                              onChange={onChangeHandler}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              for="exampleSelect"
                            >
                              Rating
                            </label>
                            <Input
                              type="select"
                              className="form-control-alternative"
                              name="select"
                              id="exampleSelect"
                              name="rating"
                              value={data.rating}
                              onChange={onChangeHandler}
                            >
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Add Images
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="file"
                              name="images"
                              onChange={(e) => setFiles(e.target.files)}
                              multiple
                              accept="image/*"
                            />
                          </FormGroup>
                          <Col lg="12" style={{display:"flex"}}>
                          {info.hotelImages?.length > 0 &&
                              info.hotelImages.map((elm, ind) => {
                                  return (
                                    <div
                                      id={elm.imageId}
                                      style={{
                                        display: "flex",
                                        margin: "0 5px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Avatar
                                        src={baseUrlImage + imagePath+ elm.imageName}
                                      />
                                      <Button
                                        style={{
                                          color: "white",
                                          cursor: "pointer",
                                          backgroundColor: "black",
                                          boxShadow: "none",
                                          padding: "0",
                                          height: "20px",
                                          width: "20px",
                                        }}
                                        onClick={() =>
                                          deleteImageFunction(
                                            elm._id,
                                            elm.imageId
                                          )
                                        }
                                        disabled={deleteImageLoading}
                                      >
                                        X
                                      </Button>
                                    </div>
                                  );
                              })}
                          </Col>
                        </Col>
                      </>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={formSubmitted}>
            Edit Hotel
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditHotel;
