import { AccordionActions, Button, Divider, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import {
  addHomeSetting,
  destroyAddHomeSetting,
  destroyAddHomeSettingError,
  getHomeSettings,
} from "../../../../redux/actions/settings";

const initialStateFooter = {
  footerText: "",
  footerAboutData: "",
  footerContactAddress: "",
  footerContactNo: "",
  footerContactEmail: "",
};
function FooterSetting({ setExpanded, setOpen, data }) {
  const [footerData, setFooterData] = useState(initialStateFooter);
  const dispatch = useDispatch();
  const {
    isLoading: loading,
    isError: error,
    isAdded: success,
  } = useSelector((state) => state.addHomeSetting);
  const {isLoading, isError,settings} = useSelector(state=>state.getHomeSetting)
  const [open1, setOpen1] = useState(false);
  const [errorText, setErrorText] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const submitData = () => {
    dispatch(addHomeSetting({
      ...data,
      userId:localStorage.getItem('userId'),
      footer:footerData
    }));
  };
  const handleFooterDataChange = (e) => {
    const { name, value } = e.target;
    setFooterData({
      ...footerData,
      [name]: value,
    });
  };
  useEffect(() => {
    if (error !== undefined && error !== "") {
      setErrorText(error);
      setOpen1(true);
    }
    if(data.footer){
      setFooterData(data.footer[0])
    }
    return () => {
      return (
        dispatch(destroyAddHomeSetting()),
        dispatch(destroyAddHomeSettingError())
      );
    };
  }, [error]);
  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Footer Text
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="footer text"
                type="text"
                name="footerText"
                value={footerData.footerText}
                onChange={(e) => handleFooterDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Footer About Data
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="footer about data"
                type="text"
                name="footerAboutData"
                value={footerData.footerAboutData}
                onChange={(e) => handleFooterDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Footer Contact Address
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="contact address"
                type="text"
                name="footerContactAddress"
                value={footerData.footerContactAddress}
                onChange={(e) => handleFooterDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Footer Contact Number
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="contact no"
                type="text"
                name="footerContactNo"
                value={footerData.footerContactNo}
                onChange={(e) => handleFooterDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Footer Contact Email
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="contact email"
                type="text"
                name="footerContactEmail"
                value={footerData.footerContactEmail}
                onChange={(e) => handleFooterDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <Divider />
            <AccordionActions>
              <Button
                size="small"
                color="secondary"
                onClick={() => setExpanded(false)}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={submitData}
              >
                {loading ? "Updating" : "Update"}
              </Button>
            </AccordionActions>
          </Col>
        </Row>
      </Container>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default FooterSetting;
