import { Card, CardActionArea, CardMedia, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import HeaderHome from "../../common/headers/HeaderHome";
import { cityBlogFilter } from "../../redux/actions/package";
import { baseUrl, baseUrlImage, imagePath } from "../../redux/url";

function BlogCityName(props) {
  // const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [cityData, setCityData] = useState({});
  const { isLoading: loading, isError, filteredBlogs: data } = useSelector(
    (state) => state.getFilterBlog
  );
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(baseUrl + "/getCity?id=" + props.match.params.cityId)
      .then((result) => {
        setCityData(result.data[0]);
      });
    dispatch(
      cityBlogFilter({
        key: props.match.params.cityName,
      })
    );
  }, []);
  return (
    <>
      <HeaderHome
        url={baseUrlImage+imagePath+cityData.bannerImage}
        content={cityData.cityDescription}
        name={cityData.cityName}
      />
      <Container>
        <Row>
          <h2 className="w-100 float-left heading-second">
            Blogs of {props.match.params.cityName}
          </h2>
          {loading ? (
            <>Loading blogs....</>
          ) : isError ? (
            <>{isError}</>
          ) : data.length > 0 ? (
            data.map((e, i) => {
              return (
                <Col
                  lg="6"
                  style={{ marginTop: "100px", marginBottom: "100px" }}
                >
                  <Card>
                    <CardActionArea>
                      <CardMedia />
                      <Typography gutterBottom variant="h5" component="h2">
                        {e.blogTitle}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {e.blogDescription}
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Col>
              );
            })
          ) : (
            <>
              <h4 className="w-100 float-left heading">
                Currently no blogs available{" "}
              </h4>
            </>
          )}
        </Row>
      </Container>
    </>
  );
}

export default BlogCityName;
