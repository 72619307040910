import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cityBlogFilter,
  cityPackageFilter,
  cityPackagesData,
  filterCities,
  getPackageCategories,
} from "../../../redux/actions/package";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import img1 from "../../../assets/images/best.png";
import { CircularProgress } from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import HolidayHeader from "../../../common/headers/HolidayHeader";
import "../../../assets/css/flexslider.css";
import { Alert } from "@material-ui/lab";
import { baseUrlImage, imagePath } from "../../../redux/url";
import { Helmet } from "react-helmet";
import UserNavbar from "../../../common/navbar/UserNavbar";

function UserHome() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, isError } = useSelector((state) => state.getPackage);
  const {
    isLoading: cityPackageLoading,
    isError: cityPackageError,
    filteredPackages: packages,
  } = useSelector((state) => state.getFilterPackage);
  const {
    isLoading: cityPackageDataLoading,
    isError: cityPackageDataError,
    packageCity: cityPackageData,
  } = useSelector((state) => state.getAllCityPackage);
  const {
    isLoading: cityBlogLoading,
    isError: cityBlogError,
    filteredBlogs: blogs,
  } = useSelector((state) => state.getFilterBlog);
  const { packageCategory } = useSelector((state) => state.getPackageCategory);
  const [to, setTo] = useState("");
  const query = new URLSearchParams(useLocation().search);

  const getCityPackages = (info) => {
    dispatch(
      cityPackagesData({
        limit: 9,
      })
    );
  };

  const DidYouKnowFunction = ({ name }) => {
    if (packages.success == false) {
      return (
        <h1>
          <Alert severity="error">{packages.message}</Alert>
        </h1>
      );
    } else {
      const category =
        packageCategory.length > 0 &&
        packageCategory.filter((e, i) => {
          return e.packageCategory == name;
        });
      const didYouKnow =
        packages.length > 0 &&
        packages.filter((e, i) => {
          return e.packageCategory == category[0]?.packageCategoryId;
        });
      return (
        <>
          {cityPackageLoading ? (
            <CircularProgress />
          ) : cityPackageError ? (
            <>
              <Alert severity="error">{cityPackageError}</Alert>
            </>
          ) : (
            <OwlCarousel
              className="owl-theme"
              margin={10}
              responsiveClass={true}
              nav
              responsive={{
                0: {
                  items: 1,
                  loop: didYouKnow.length > 0,
                },
                450: {
                  items: 2,
                  loop: didYouKnow.length > 1,
                },
                600: {
                  items: 3,
                  loop: didYouKnow.length > 3,
                },
                1000: {
                  items: 5,
                  loop: didYouKnow.length > 4,
                },
              }}
            >
              {didYouKnow.length > 0 ? (
                didYouKnow.map((e, i) => {
                  return (
                    <div
                      className="item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(
                          `/package/${e.packageId}?start=${query.get(
                            "start"
                          )}&end=${query.get("end")}&adult=${query.get(
                            "adult"
                          )}&children=${query.get("children")}`
                        )
                      }
                    >
                      <img
                        alt="package"
                        src={
                          e.images.length > 0 &&
                          baseUrlImage +
                            imagePath +
                            e.packageImages[0]?.imageName
                        }
                        style={{ height: "290px" }}
                        className="img-fluid"
                      />
                      <h3>{e.packageName}</h3>
                      <h5>
                        From- {e.travelValidityFrom}
                        <br />
                        To- {e.travelValidityTo}{" "}
                      </h5>
                    </div>
                  );
                })
              ) : (
                <>
                  <Alert severity="error">No Data Found</Alert>
                </>
              )}
            </OwlCarousel>
          )}
        </>
      );
    }
  };

  const GetOffersPackage = ({ name }) => {
    if (packages.success == false) {
      return (
        <h1>
          <Alert severity="error">{packages.message}</Alert>
        </h1>
      );
    } else {
      const category =
        packageCategory.length > 0 &&
        packageCategory.filter((e, i) => {
          return e.packageCategory == name;
        });
      const didYouKnow =
        packages.length > 0 &&
        packages.filter((e, i) => {
          return e.packageCategory == category[0]?.packageCategoryId;
        });
      return (
        <>
          {cityPackageLoading ? (
            <CircularProgress />
          ) : cityPackageError ? (
            <>
              <Alert severity="error">{cityPackageError}</Alert>
            </>
          ) : (
            <OwlCarousel
              className="owl-theme"
              margin={10}
              items={5}
              responsiveClass={true}
              nav
              responsive={{
                0: {
                  items: 1,
                  loop: didYouKnow.length > 0,
                },
                450: {
                  items: 2,
                  loop: didYouKnow.length > 1,
                },
                600: {
                  items: 3,
                  loop: didYouKnow.length > 3,
                },
                1000: {
                  items: 5,
                  loop: didYouKnow.length > 4,
                },
              }}
            >
              {didYouKnow.length > 0 ? (
                didYouKnow.map((e, i) => {
                  return (
                    <div
                      className="slide"
                      onClick={() =>
                        history.push(
                          `/package/${e.packageId}?start=${query.get(
                            "start"
                          )}&end=${query.get("end")}&adult=${query.get(
                            "adult"
                          )}&children=${query.get("children")}`
                        )
                      }
                    >
                      <div className="offer-img">
                        <img
                          alt="image"
                          src={
                            e.images.length > 0 &&
                            baseUrlImage +
                              imagePath +
                              e.packageImages[0]?.imageName
                          }
                          style={{ height: "140px" }}
                          className="w-100 frst-img"
                        />{" "}
                      </div>
                      <div className="off-cont">
                        <h3 className="w-100">{e.packageName}</h3>
                        <h6>T&C's Apply</h6>
                        <a href="#" className="book-nw">
                          Book Now
                        </a>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <Alert severity="error">No Data Found</Alert>
                </>
              )}
            </OwlCarousel>
          )}
        </>
      );
    }
  };
  const GetSignaturesData = () => {
    if (packages.success == false) {
      return (
        <h1>
          <Alert severity="error">{packages.message}</Alert>
        </h1>
      );
    } else {
      const category =
        packageCategory.length > 0 &&
        packageCategory.filter((e, i) => {
          return e.packageCategory == "signatures";
        });
      const signatures =
        packages.length > 0 &&
        packages.filter((e, i) => {
          return e.packageCategory == category[0]?.packageCategoryId;
        });
      return (
        <>
          {signatures.length > 0 ? (
            signatures.map((e, i) => {
              if (i <= 3) {
                return (
                  <>
                    {cityPackageLoading ? (
                      <CircularProgress />
                    ) : cityPackageError ? (
                      <>{<Alert severity="error">{cityPackageError}</Alert>}</>
                    ) : (
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                        history.push(
                          `/package/${e.packageId}?start=${query.get(
                            "start"
                          )}&end=${query.get("end")}&adult=${query.get(
                            "adult"
                          )}&children=${query.get("children")}`
                        )
                      }
                      >
                        <img
                          alt="image"
                          src={
                            e.images.length > 0 &&
                            baseUrlImage +
                              imagePath +
                              e.packageImages[0]?.imageName
                          }
                          style={{ height: "290px" }}
                          className="w-100"
                        />
                        <h5>
                          From- {e.travelValidityFrom}
                          <br />
                          To- {e.travelValidityTo}{" "}
                        </h5>
                        <h4 className="w-100">{e.packageName}</h4>
                      </li>
                    )}
                  </>
                );
              }
            })
          ) : (
            <>
              <Alert severity="error">No Data Found</Alert>
            </>
          )}
        </>
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTo(query.get("to"));
    dispatch(
      cityPackageFilter({
        key: query.get("id"),
      })
    );
    getCityPackages();
    dispatch(
      cityBlogFilter({
        key: query.get("to"),
      })
    );
    dispatch(getPackageCategories());
    return () => {};
  }, [ query.get("id"), query.get("to")]);
  return (
    <>
      <Helmet>
        <title>Maya Travels :Holiday</title>
      </Helmet>
      {/* <!---destination-menu---> */}
      <div className="destination-link w-100" style={{ marginTop: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <ul className="hand-pic w-100">
                <li>
                  <h4 className="w-100 m-0">
                    From City<span className="w-100">{"All"}</span>India
                  </h4>
                </li>
                <li>
                  <h4 className="w-100 m-0">
                    To City/Country
                    <span className="w-100">{to}</span>
                    India
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <!--holiday---> */}
      {/* <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="maldiv w-100">
              <img
                alt="image"
                src="/images/Group 14.png"
                className="w-100"
                onClick={() => history.push("/")}
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <!--did-you-know---> */}
      <div className="best-selling w-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h2 className="heading-1 w-100 mb-0">Did You Know?</h2>
              <h4 className="heading-2 w-100">
                Our best-selling packages are the cheapest ones! Grab amazing
                deals on flight + hotel holidays!
              </h4>
              {isLoading ? (
                <>
                  <CircularProgress />{" "}
                </>
              ) : (
                <DidYouKnowFunction name="Did You Know" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <!--honeymoon---> */}
      <div className="hny-spcl w-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h2 className="heading-1 w-100 mb-0">Honeymoon Special</h2>
              <h4 className="heading-2 w-100">
                Explore the world with your Soulmate!
              </h4>
              {isLoading ? (
                <>
                  <CircularProgress />{" "}
                </>
              ) : (
                <DidYouKnowFunction name="Honeymoon" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <!--super--> */}
      <div className="supr-ofer w-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h3>
                Super Offers
                <br />
                <span>See our super offers</span>
              </h3>
              {/* <ul className="super-menu">
                <li>
                  <a href="#">Holidays</a>
                </li>
                <li>
                  <a href="#">Domestic Holidays</a>
                </li>
                <li>
                  <a href="#">Domestic Holidays </a>
                </li>
                <li>
                  <a href="#">Holidays</a>
                </li>
              </ul> */}
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <section className="customer-logos slider">
                <GetOffersPackage name="super offers" />
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* <!---signature---> */}
      <div className="signature w-100">
        <div className="container">
          <div className="row">
            <div className="sig-top-sec">
              <img alt="image" src="/images/Group 13.png" className="w-100" />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <h1 className="heading-1">Signature</h1>
              <p className="heading-2">
                Experience the lesser-known side of India, with holiday packages
                curated by skilled storytellers and travel experts.
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <ul className="sig-slider">
                <GetSignaturesData />
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <!----allcntry-sec---> */}
      <div className="all-cntry">
        <div className="container">
          <div className="row">
            {cityPackageDataLoading ? (
              <CircularProgress />
            ) : cityPackageDataError ? (
              <>
                <Alert severity="error">{cityPackageDataError}</Alert>
              </>
            ) : cityPackageData.success == false ? (
              <>
                <Alert severity="error">{cityPackageData.message}</Alert>
              </>
            ) : cityPackageData.length > 0 ? (
              cityPackageData.map((e, i) => {
                return (
                  <div
                    className="col-sm-12 col-md-6 col-lg-4"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(`/holiday?to=${e.city.cityName}&id=${e.city.cityId}`)
                    }
                  >
                    <div className="left-sec">
                      {e.city.cityImages[0].imageName && (
                        <img
                          style={{
                            width: "97px",
                            height: "97px",
                            borderRadius: "10px",
                          }}
                          alt="city"
                          src={
                            baseUrlImage +
                            imagePath +
                            e.city.cityImages[0].imageName
                          }
                        />
                      )}
                    </div>
                    <div className="ryt-sec">
                      <h3 className="w-100 float-left">{e.city.cityName}</h3>
                      <p className="w-100 float-left">
                        {e.Package.map((elm, ind) => {
                          if (ind < 3) {
                            return <i>{elm.packageName},</i>;
                          }
                        })}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <Alert severity="error">No Data Found</Alert>
              </>
            )}
          </div>
        </div>
      </div>

      {/* <!---blog-sec--> */}
      <div className="blog-sec">
        <div className="hny-spcl w-100">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <h2 className="heading-1 w-100 mb-0">Travel Blog</h2>
                <h4 className="heading-2 w-100">
                  Explore the world with your Soulmate!
                </h4>
                <Link to={`/blog`} className="view">
                  View All
                </Link>
                {cityBlogLoading ? (
                  <CircularProgress />
                ) : cityBlogError ? (
                  <>
                    <Alert severity="error">{cityBlogError}</Alert>
                  </>
                ) : blogs.success == false ? (
                  <>
                    <h1>
                      <Alert severity="error">{blogs.message}</Alert>
                    </h1>
                  </>
                ) : (
                  <OwlCarousel
                    className="owl-theme"
                    margin={10}
                    items={5}
                    responsiveClass={true}
                    nav
                    responsive={{
                      0: {
                        items: 1,
                        loop: blogs.length > 0,
                      },
                      450: {
                        items: 2,
                        loop: blogs.length > 1,
                      },
                      600: {
                        items: 3,
                        loop: blogs.length > 3,
                      },
                      1000: {
                        items: 5,
                        loop: blogs.length > 4,
                      },
                    }}
                  >
                    {blogs.length > 0 ? (
                      blogs.map((e, i) => {
                        return (
                          <div className="item">
                            <Link
                              to={`/blog/${e.slug}?category=${e.blogCategory[0]?.value}`}
                            >
                              <img
                                alt="image"
                                src={baseUrlImage + imagePath + e.bannerImage}
                                style={{ height: "296px" }}
                                alt=""
                                className="img-fluid"
                              />
                              <h5>{e.blogTitle}</h5>
                            </Link>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <Alert severity="error">No Data Found</Alert>
                      </>
                    )}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserHome;
