import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import {
  destroyUserEdit,
  destroyUserError,
  editUser,
} from "../../../redux/actions/user";

function EditUser({ info, modal, setModal, setOpen }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [edit, setEdit] = useState("");
  const [open, setopen] = useState(false);
  const dispatch = useDispatch();
  const { loading, error, edited } = useSelector((state) => state.editUser);
  const handleClose = () => {
    setopen(false);
  };
  const formSubmitted = (e) => {
    e.preventDefault();
    const element = {
      userId: info._id,
      userName: name,
      email: email,
    };
    dispatch(editUser(element));
  };
  const toggle = () => {
    setModal(false);
  };
  const closeModal = () => {
    setModal(false);
    setOpen(true);
  };
  useEffect(() => {
    if (error !== undefined && error !== "") {
      setEdit(error);
      setopen(true);
    }
    if (edited !== undefined && edited !== "") {
      closeModal();
    }
    setName(info.userName);
    setEmail(info.email);
    return () => {
      return dispatch(destroyUserEdit()), dispatch(destroyUserError());
    };
  }, [error, edited]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            {edit && (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {edit}
                </Alert>
              </Snackbar>
            )}
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Edit User"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                User Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="City name"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                User Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={formSubmitted}>
            Edit User
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditUser;
