import {
  ADD_TRANSPORT_CATEGORY_ERROR,
  ADD_TRANSPORT_CATEGORY_LOADING,
  ADD_TRANSPORT_CATEGORY_SUCCESS,
  ADD_VEHICLE_TYPE_CATEGORY_ERROR,
  ADD_VEHICLE_TYPE_CATEGORY_LOADING,
  ADD_VEHICLE_TYPE_CATEGORY_SUCCESS,
  DELETE_TRANSPORT_CATEGORY_ERROR,
  DELETE_TRANSPORT_CATEGORY_LOADING,
  DELETE_TRANSPORT_CATEGORY_SUCCESS,
  DELETE_VEHICLE_TYPE_CATEGORY_ERROR,
  DELETE_VEHICLE_TYPE_CATEGORY_LOADING,
  DELETE_VEHICLE_TYPE_CATEGORY_SUCCESS,
  DESTROY_TRANSPORT_CATEGORY_ADD,
  DESTROY_TRANSPORT_CATEGORY_EDIT,
  DESTROY_TRANSPORT_ERROR_ADD_C,
  DESTROY_TRANSPORT_ERROR_EDIT_C,
  DESTROY_VEHICLE_TYPE_CATEGORY_ADD,
  DESTROY_VEHICLE_TYPE_CATEGORY_EDIT,
  DESTROY_VEHICLE_TYPE_ERROR_ADD_C,
  DESTROY_VEHICLE_TYPE_ERROR_EDIT_C,
  EDIT_TRANSPORT_CATEGORY_ERROR,
  EDIT_TRANSPORT_CATEGORY_LOADING,
  EDIT_TRANSPORT_CATEGORY_SUCCESS,
  EDIT_VEHICLE_TYPE_CATEGORY_ERROR,
  EDIT_VEHICLE_TYPE_CATEGORY_LOADING,
  EDIT_VEHICLE_TYPE_CATEGORY_SUCCESS,
  GET_TRANSPORT_CATEGORY_ERROR,
  GET_TRANSPORT_CATEGORY_LOADING,
  GET_TRANSPORT_CATEGORY_SUCCESS,
  GET_VEHICLE_TYPE_CATEGORY_ERROR,
  GET_VEHICLE_TYPE_CATEGORY_LOADING,
  GET_VEHICLE_TYPE_CATEGORY_SUCCESS,
} from "../constants";

const initialStateCategory = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  vehicleType: [],
  transport: [],
};

export function addVehicleType(state = initialStateCategory, action) {
  switch (action.type) {
    case ADD_VEHICLE_TYPE_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case ADD_VEHICLE_TYPE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_VEHICLE_TYPE_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_VEHICLE_TYPE_CATEGORY_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_VEHICLE_TYPE_ERROR_ADD_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getVehicleType(state = initialStateCategory, action) {
  switch (action.type) {
    case GET_VEHICLE_TYPE_CATEGORY_LOADING:
      return { ...state, isLoading: true, vehicleType: [] };
    case GET_VEHICLE_TYPE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, vehicleType: action.payload };
    case GET_VEHICLE_TYPE_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        vehicleType: [],
        isError: action.payload,
      };
    default:
      return state;
  }
}

export function editVehicleType(state = initialStateCategory, action) {
  switch (action.type) {
    case EDIT_VEHICLE_TYPE_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case EDIT_VEHICLE_TYPE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_VEHICLE_TYPE_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_VEHICLE_TYPE_CATEGORY_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_VEHICLE_TYPE_ERROR_EDIT_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function deleteVehicleType(state = initialStateCategory, action) {
  switch (action.type) {
    case DELETE_VEHICLE_TYPE_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case DELETE_VEHICLE_TYPE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_VEHICLE_TYPE_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_VEHICLE_TYPE_ERROR_C":
      return { ...state, isError: action.payload, isDeleted: action.payload };
    default:
      return state;
  }
}

export function addTransport(state = initialStateCategory, action) {
  switch (action.type) {
    case ADD_TRANSPORT_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case ADD_TRANSPORT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_TRANSPORT_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_TRANSPORT_CATEGORY_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_TRANSPORT_ERROR_ADD_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getTransport(state = initialStateCategory, action) {
  switch (action.type) {
    case GET_TRANSPORT_CATEGORY_LOADING:
      return { ...state, isLoading: true, transport: [] };
    case GET_TRANSPORT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, transport: action.payload };
    case GET_TRANSPORT_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        transport: [],
        isError: action.payload,
      };
    default:
      return state;
  }
}

export function editTransport(state = initialStateCategory, action) {
  switch (action.type) {
    case EDIT_TRANSPORT_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case EDIT_TRANSPORT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_TRANSPORT_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_TRANSPORT_CATEGORY_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_TRANSPORT_ERROR_EDIT_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function deleteTransport(state = initialStateCategory, action) {
  switch (action.type) {
    case DELETE_TRANSPORT_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case DELETE_TRANSPORT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_TRANSPORT_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_TRANSPORT_ERROR_C":
      return { ...state, isError: action.payload, isDeleted: action.payload };
    default:
      return state;
  }
}
