import {
  DELETE_USER_LOADING,
  DELETE_USER_SUCCESS,
  DETETE_USER_ERROR,
  EDIT_USER_ERROR,
  EDIT_USER_LOADING,
  EDIT_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_ERROR,
  USER_REGISTER_LOADING,
  USER_REGISTER_SUCCESS,
} from "../constants";

const initialState = {
  loading: false,
  loggedIn: false,
  registered: false,
  users: [],
  error: "",
  edited: "",
  deleted: "",
};

export function userLogin(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_LOADING:
      return {...state, loading: true };
    case USER_LOGIN_SUCCESS:
      return { ...state,loading: false, loggedIn: action.payload };
    case USER_LOGIN_ERROR:
      return {...state, loading: false, error: action.payload };
    case "DESTROY_USER_LOGIN":
      return { ...state, loggedIn: action.payload };
    default:
      return state;
  }
}

export function userRegister(state = initialState, action) {
  switch (action.type) {
    case USER_REGISTER_LOADING:
      return {...state, loading: true };
    case USER_REGISTER_SUCCESS:
      return { ...state,loading: false, registered: action.payload };
    case USER_REGISTER_ERROR:
      return { ...state,loading: false, error: action.payload };
    case "DESTROY_USER_ADD":
      return { ...state, registered: action.payload };
    case "DESTROY_ERROR_ADD_USER":
      return {...state, error:action.payload}
    default:
      return state;
  }
}

export function editUser(state = initialState, action) {
  switch (action.type) {
    case EDIT_USER_LOADING:
      return {...state, loading: true };
    case EDIT_USER_SUCCESS:
      return { ...state,loading: false, edited: action.payload };
    case EDIT_USER_ERROR:
      return {...state, loading: false, error: action.payload };
    case "DESTROY_USER_EDIT":
      return { ...state, edited: action.payload };
    case "DESTROY_USER_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export function getUsers(state = initialState, action) {
  switch (action.type) {
    case GET_USER_LOADING:
      return {...state, loading: true, users: [] };
    case GET_USER_SUCCESS:
      return {...state, loading: false, users: action.payload };
    case GET_USER_ERROR:
      return {...state, loading: false, users: [], error: action.payload };

    default:
      return state;
  }
}

export function deleteUser(state = initialState, action) {
  switch (action.type) {
    case DELETE_USER_LOADING:
      return {...state, loading: true };
    case DELETE_USER_SUCCESS:
      return {...state, loading: false, deleted: action.payload };
    case DETETE_USER_ERROR:
      return {...state, loading: false, error: action.payload };
    case "DESTROY_USER_DELETE_ERROR":
      return {...state,loading:false,error:action.payload}
      case "DESTROY_USER_DELETE_DELETED":
        return {...state,loading:false,deleted:action.payload}
    default:
      return state;
  }
}

// export function userLogout(state = initialState, action) {
//   switch (action.type) {
//     case USER_LOGOUT:
//       return { isLoading: false, loggedIn: action.payload };
//     default:
//       return state;
//   }
// }
