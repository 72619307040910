import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import HolidayHeader from "../../common/headers/HolidayHeader";
import { getCities } from "../../redux/actions/package";
import { baseUrlImage, imagePath } from "../../redux/url";
import GetLengthBlog from "./GetLengthBlog";

function CityBlogs() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading: cityLoading, cities, isError: cityError } = useSelector(
    (state) => state.getCity
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCities());
  }, []);
  return (
    <>
      <HolidayHeader />
      <div className="most-popular w-100">
        <div className="container">
          <div className="row">
            <h4 className="w-100 float-left heading">Travel</h4>
            <h2 className="w-100 float- left heading-second">
              <Link to="/blog">Blogs</Link>
            </h2>{" "}
            {cityError ? (
              <>{cityError}</>
            ) : cityLoading ? (
              <>Loading blogs.....</>
            ) : (
              cities.map((e, i) => {
                return (
                  <div
                    className="col-xs-12 col-sm-6 col-md-6 col-lg-3"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(`/blog/${e.cityName}/${e.cityId}`)
                    }
                  >
                    <div className="destini">
                      <img
                      style={{height:"350px"}}
                        alt="image"
                        src={baseUrlImage+imagePath+e.bannerImage}
                        className="w-100"
                      />
                      <h5>
                        <GetLengthBlog name={e.cityName} />
                      </h5>
                      <h3>{e.cityName}</h3>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CityBlogs;
