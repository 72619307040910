import axios from "axios";
import {
  ADD_FAVPURITE_PACKAGE_ERROR,
  ADD_FAVPURITE_PACKAGE_LOADING,
  ADD_FAVPURITE_PACKAGE_SUCCESS,
  DELETE_FAVPURITE_PACKAGE_ERROR,
  DELETE_FAVPURITE_PACKAGE_LOADING,
  DELETE_FAVPURITE_PACKAGE_SUCCESS,
  DESTROY_FAVPURITE_PACKAGE_ADD,
  DESTROY_FAVPURITE_PACKAGE_DELETE,
  DESTROY_FAVPURITE_PACKAGE_ERROR_ADD_C,
  GET_FAVPURITE_PACKAGE_ERROR,
  GET_FAVPURITE_PACKAGE_FILTER_SUCCESS,
  GET_FAVPURITE_PACKAGE_LOADING,
  GET_FAVPURITE_PACKAGE_SUCCESS,
} from "../constants";
import {
  baseUrl,
  getFavouriteEndpoint,
  addFavouriteEndpoint,
  deleteFavouriteEndpoint,
} from "../url";

export const getFavouritePackage = (data) => async (dispatch) => {
    new Promise(function(resolve, reject) {
        dispatch({ type: GET_FAVPURITE_PACKAGE_LOADING });
        try {
          if (data?.key) {
            axios({
              method: "get",
              url: `${baseUrl}${getFavouriteEndpoint}?keyword=${data.key}`,
            })
              .then((result) => {
                dispatch({
                  type: GET_FAVPURITE_PACKAGE_SUCCESS,
                  payload: result.data,
                });
                resolve(result.data)
              })
              .catch((error) => {
                dispatch({
                  type: GET_FAVPURITE_PACKAGE_ERROR,
                  payload: error.message,
                });
                reject(error.message)
              });
          } else if (data?.skip && data?.limit) {
            axios({
              method: "get",
              url: `${baseUrl}${getFavouriteEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
            })
              .then((result) => {
                dispatch({
                  type: GET_FAVPURITE_PACKAGE_SUCCESS,
                  payload: result.data,
                });
                resolve(result.data)
              })
              .catch((error) => {
                dispatch({
                  type: GET_FAVPURITE_PACKAGE_ERROR,
                  payload: error.message,
                });
                reject(error.message)
              });
          } else {
            axios({
              method: "get",
              url: `${baseUrl}${getFavouriteEndpoint}`,
            })
              .then((result) => {
                dispatch({
                  type: GET_FAVPURITE_PACKAGE_SUCCESS,
                  payload: result.data,
                });
                resolve(result.data)
              })
              .catch((error) => {
                dispatch({
                  type: GET_FAVPURITE_PACKAGE_ERROR,
                  payload: error.message,
                });
                reject(error.message)
              });
          }
        } catch (error) {
          dispatch({ type: GET_FAVPURITE_PACKAGE_ERROR, payload: error.message });
          reject(error.message)
        }
    })
};

export const addFavouritePackage = (data) => async (dispatch) => {
  dispatch({ type: ADD_FAVPURITE_PACKAGE_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addFavouriteEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_FAVPURITE_PACKAGE_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: ADD_FAVPURITE_PACKAGE_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_FAVPURITE_PACKAGE_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: ADD_FAVPURITE_PACKAGE_ERROR, payload: error.message });
  }
};

export const deleteFavouritePackage = (data) => async (dispatch) => {
  dispatch({ type: DELETE_FAVPURITE_PACKAGE_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteFavouriteEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_FAVPURITE_PACKAGE_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_FAVPURITE_PACKAGE_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_FAVPURITE_PACKAGE_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: DELETE_FAVPURITE_PACKAGE_ERROR, payload: error.message });
  }
};

export const destroyAddFavouritePackage = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_FAVPURITE_PACKAGE_ADD, payload: "" });
};
export const destroyDeleteFavouritePackage = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_FAVPURITE_PACKAGE_DELETE, payload: "" });
};
export const destroyAddFavouriteErrorPackage = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_FAVPURITE_PACKAGE_ERROR_ADD_C, payload: "" });
};
export const destroyDeleteFavouritePackageErrorC = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_FAVPURITE_PACKAGE_ERROR_C", payload: "" });
};

export const filterFavouritePackage = (data) => async (dispatch) => {
  dispatch({ type: GET_FAVPURITE_PACKAGE_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${getFavouriteEndpoint}?skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({
          type: GET_FAVPURITE_PACKAGE_FILTER_SUCCESS,
          payload: result.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_FAVPURITE_PACKAGE_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: GET_FAVPURITE_PACKAGE_ERROR, payload: error.message });
  }
};