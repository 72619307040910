var routes = [
    {
      path: "",
      name: "Dashboard",
      icon: "ni ni-tv-2 text-primary",
      layout: "/admin",
    },
    {
      path: "/bookings",
      name: "Bookings",
      icon: "ni ni-calendar-grid-58 text-pink",
      layout: "/admin",
    },
    {
      path: "/packages",
      name: "Packages",
      icon: "ni ni-planet text-blue",
      layout: "/admin",
    },
    {
      path: "/city",
      name: "City",
      icon: "ni ni-pin-3 text-orange",
      layout: "/admin",
    },
    {
      path: "/hotel",
      name: "Hotels",
      icon: "ni ni-single-02 text-yellow",
      layout: "/admin",
    },
    {
      path: "/users",
      name: "Users",
      icon: "ni ni-bullet-list-67 text-red",
      layout: "/admin",
    },
    {
      path: "/blog",
      name: "Blog",
      icon: "ni ni-books text-purple",
      layout: "/admin",
    },
    {
      path: "/company",
      name: "Company",
      icon: "ni ni-shop text-blue",
      layout: "/admin",
    },
    {
      path: "/gallery",
      name: "Gallery",
      icon: "fas fa-image text-yellow",
      layout: "/admin",
    },
  ];
  export default routes;
  