export const baseUrl = "https://www.mayatravels.xyz:3003";
export const baseUrlImage = "https://www.mayatravels.xyz";
export const addPackageEndPoint = "/addPackage";
export const editPackageEndpoint = "/updatePackage";
export const addCityEndPoint = "/saveCity";
export const updateCityEndpoint = "/updateCity";
export const addHotelEndPoint = "/saveHotel";
export const editHotelEndPoint = "/updateHotel";
export const getCityEndpoint = "/viewCities";
export const getHotelEndPoint = "/viewHotels";
export const getPackageEndpoint = "/viewPackages";
export const loginEndpoint = "/signIn";
export const registerEndpoint = "/signUp";
export const deleteHotelEndpoint = "/deleteHotel";
export const deleteCityEndpoint = "/deleteCity";
export const deletePackageEndpoint = "/deletePackage";
export const getUserEndPoint = "/viewUsersList";
export const editUserEndpoint = "/updateUser";
export const deleteUserEndpoint = "/deleteUser";
export const getBlogEndpoint = "/viewBlogList";
export const editBlogEndpoint = "/updateBlog";
export const addBlogEndpoint = "/addBlog";
export const deleteBlogEndPoint = "/deleteBlog";
export const getCategoryEndpoint = "/viewCategoryList";
export const editCategoryEndpoint = "/updateCategory";
export const addCategoryEndpoint = "/addCategory";
export const deleteCategoryEndPoint = "/deleteCategory";
export const filterHotelEndpoint = "/hotelFilter";
export const filterPackageEndpoint = "/packageFilter";
export const filterCityEndpoint = "/cityFilter";
export const filterUserEndpoint = "/userFilter";
export const filterBlogEndpoint = "/blogFilter";
export const filterBlogCategoryEndpoint = "/categoryFilter";
export const addHomeSettingEndpoint = "/siteSettings";
export const editHomeSettingEndpoint = "/updateSiteSettings";
export const deleteHomeSettingEndpoint = "/deleteSiteSetting";
export const filterHomeSettingEndpoint = "/pageFilter";
export const getHomeSettingEndpoint = "/viewSettingList";
export const addLocationEndpoint = "/addLocation";
export const editLocationEndpoint = "/updateLocation";
export const deleteLocationEndpoint = "/deleteLocation";
export const filterLocationEndpoint = "/locationFilter";
export const getLocationEndpoint = "/viewLocationList";
export const addExclusionsEndpoint = "/addExclusion";
export const editExclusionsEndpoint = "/updateExclusion";
export const deleteExclusionsEndpoint = "/deleteExclusion";
export const filterExclusionEndpoint = "/exclusionFilter";
export const getExclusionsEndpoint = "/viewExclusionList";
export const addInclusionsEndpoint = "/addInclusion";
export const editInclusionsEndpoint = "/updateInclusion";
export const deleteInclusionsEndpoint = "/deleteInclusion";
export const filterInclusionEndpoint = "/inclusionFilter";
export const getInclusionsEndpoint = "/viewInclusionList";
export const importHotelEndpoint = "/importHotel";
export const importPackageEndpoint = "/importPackage";
export const importBlogEndpoint = "/importBlogFile";
export const importCategoryEndpoint = "/importCategoryFile";
export const importLocationEndpoint = "/importLocationFile";
export const importCityEndpoint = "/importCityFile";
export const importSettingEndpoint = "/importSiteSettingFile";
export const packageCategoryFilter = "/packageCategoryFilter"
export const cityBlogFilterEndpoint="/cityBlogFilter"
export const cityPackageFilterEndpoint = "/getPackageByDate"
export const allCityPackagesEndpoint = "/allCityPackages"
export const imagePath = "/immigration-apis/src/uploads/"
export const filterHotelIdEndpoint = "/getHotel"
export const getAdEndpoint="/viewAds"
export const addAdEndpoint="/addAd"
export const editAdEndpoint="/updateAd"
export const deleteAdEndpoint="/deleteAd"
export const  filterAdEndpoint="/filterAds"
export const subscribeEmail = "/subscribe"
export const addFavEndpoint="/addFavouriteDestination"
export const deleteFavEndpoint = "/deleteFavouriteDestination"
export const getFavEndpoint = "/allFavouriteCityPackages"
export const getBlogBySlug = "/getBlogBySlug"
export const categoryBlogs = "/getBlogByCategory"
export const editorPicksEndpoint = "/editorBlogs"
export const addCompanyEndpoint = "/addCompany";
export const editCompanyEndpoint = "/updateCompany";
export const deleteCompanyEndpoint = "/deleteCompany";
export const filterCompanyEndpoint = "/companyFilter";
export const getCompanyEndpoint = "/viewCompaniesList";
export const deleteCityImageEndpoint = "/deleteImage"
export const getPackageCategoryEndpoint = "/viewPackageCategories";
export const editPackageCategoryEndpoint = "/updatePackageCategory";
export const addPackageCategoryEndpoint = "/addPackageCategory";
export const deletePackageCategoryEndPoint = "/deletePackageCategory";
export const filterPackageCategoryEndpoint = "/packageCategoryFilter";
export const importPackageCategoryEndpoint = "/importPackageCategoryFile";
export const getTransportEndpoint = "/viewTransports";
export const editTransportEndpoint = "/updateTransport";
export const addTransportEndpoint = "/addTransport";
export const deleteTransportEndPoint = "/deleteTransport";
export const importTransportEndpoint = "/importTransportFile";
export const getVehicleEndpoint = "/viewVehicles";
export const editVehicleEndpoint = "/updateVehicle";
export const addVehicleEndpoint = "/addVehicle";
export const deleteVehicleEndPoint = "/deleteVehicle";
export const importVehicleEndpoint = "/importVehicleFile";
export const getGalleryEndpoint = "/viewGallery";
export const editGalleryEndpoint = "/updateGallery";
export const addGalleryEndpoint = "/createGallery";
export const deleteGalleryEndpoint = "/deleteGallery";
export const deleteGalleryImageEndpoint = "/deleteGalleryImage"
export const getParticularGalleryEndpoint = "/getGallery"
export const addBookingEndpoint = "/bookPackage";
export const deleteBookingEndpoint = "/deleteBooking";
export const getBookingEndpoint = "/viewBookedPackages";
export const getFavouriteEndpoint = "/allFavouritePackages"
export const addFavouriteEndpoint = "/addFavouritePackage"
export const deleteFavouriteEndpoint = "/removeFavouritePackage"