import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { Delete } from "@material-ui/icons";
import { Grid, LinearProgress, Snackbar, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import { baseUrl, getBookingEndpoint } from "../../../redux/url";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import {
  deleteBooking,
  destroyDeleteBookingErrorC,
  filterBooking,
  getBooking,
} from "../../../redux/actions/booking";
import { getUsers } from "../../../redux/actions/user";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Booking() {
  const [showAdd, setShowAdd] = useState({});
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [open, setopen] = useState(false);
  const [open3, setopen3] = useState(false);
  const [open1, setopen1] = useState(false);
  const [open2, setopen2] = useState(false);
  const [page, setPage] = useState(1);
  const [deleted, setDeleted] = useState("");
  const [totalLength, setTotalLength] = useState(1);
  const [count, setCount] = useState(10);
  const [keyWord, setKeyWord] = useState("");
  const { isAdded } = useSelector((state) => state.addBooking);
  const { users } = useSelector((state) => state.getUser);
  const { isLoading, bookings, isError } = useSelector(
    (state) => state.getBooking
  );
  const { isDeleted, isError: deleteError } = useSelector(
    (state) => state.deleteBooking
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl(null);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.paddingRight = "0";
  };
  const handleClose2 = () => {
    setopen2(false);
  };
  const deleteCityFunction = (id) => {
    dispatch(deleteBooking({ bookingId: id }));
  };
  const searchChangeHandler = (e) => {
    setKeyWord(e.target.value);
    if (e.target.value === "") {
      dispatch(
        getBooking({
          skip: page * count - count,
          limit: count,
        })
      );
    } else {
      setPage(1);
      dispatch(
        filterBooking({
          key: e.target.value,
          skip: 0,
          limit: count,
        })
      );
    }
  };
  const paginationHandler = (e, v) => {
    setPage(v);
    if (keyWord === "") {
      dispatch(
        getBooking({
          skip: v * count - count,
          limit: count,
        })
      );
    } else {
      dispatch(
        filterBooking({
          key: keyWord,
          skip: v * count - count,
          limit: count,
        })
      );
    }
  };
  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== ""
    ) {
    } else {
      history.push("/login");
    }
    axios.get(baseUrl + getBookingEndpoint).then((result) => {
      setTotalLength(result.data.length);
    });
    if (isDeleted && isDeleted !== "") {
      setDeleted(isDeleted);
      setopen2(true);
    }
    if (deleteError && deleteError !== "") {
      setDeleted(deleteError);
      setopen2(true);
    }
    dispatch(
      getBooking({
        skip: page * count - count,
        limit: count,
      })
    );
    dispatch(getUsers());
    return () => {
      return dispatch(destroyDeleteBookingErrorC());
    };
  }, [isAdded, isDeleted, modal2]);
  return (
    <div>
      <Container className="top-100">
        <Row>
          <Col className="order-xl-1" lg="12" xl="12">
            <Card className="bg-secondary shadow">
              <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col sm="12" lg="4">
                          <h3 className="mb-0">Bookings</h3>
                        </Col>
                        <Col sm="12" lg="4">
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                              <TextField
                                id="input-with-icon-grid"
                                label="Search bookings"
                                onChange={searchChangeHandler}
                              />
                            </Grid>
                          </Grid>
                        </Col>
                      </Row>
                    </CardHeader>
                    {isError ? (
                      <>{isError}</>
                    ) : isLoading ? (
                      <>
                        <tbody>
                          <LinearProgress />
                        </tbody>
                      </>
                    ) : bookings.success === false ? (
                      <>
                        <Alert severity="error">{bookings.message}</Alert>
                      </>
                    ) : (
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Booked By</th>
                            <th scope="col">Package Name</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Persons</th>
                            <th scope="col">From</th>
                            <th scope="col">To</th>
                            <th scope="col">Price</th>
                            <th scope="col">Address</th>
                            <th scope="col">Note</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        {bookings.length > 0 &&
                          bookings.map((row, i) => {
                            return (
                              <tbody>
                                <tr>
                                  <th>
                                    {users.length > 0 &&
                                      users.map((e, ind) => {
                                        return (
                                          e._id === row.userId && e.userName
                                        );
                                      })}
                                  </th>
                                  <th>{row.packageName}</th>
                                  <td>
                                    {row.userFirstName + " " + row.userLastName}
                                  </td>
                                  <td>{row.userEmail}</td>
                                  <td>{row.userPhoneNumber}</td>
                                  <td>
                                    <li type="none">Adult: {row.adult}</li>
                                    <li type="none">Children:{row.children}</li>
                                  </td>
                                  <td>{row.checkIn}</td>
                                  <td>{row.checkOut}</td>
                                  <td>{row.packagePrice}</td>
                                  <td>{row.Address}</td>
                                  <td>{row.note}</td>
                                  <td>
                                    <Delete
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        deleteCityFunction(row._id)
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </Table>
                    )}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="float-right m-3">
              <Pagination
                count={Math.ceil(totalLength / count)}
                page={page}
                onChange={paginationHandler}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {
        <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
          <Alert onClose={handleClose2} severity="error">
            {deleted}
          </Alert>
        </Snackbar>
      }
    </div>
  );
}

export default Booking;
