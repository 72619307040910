import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import {
  Edit,
  Delete,
  Add,
  Publish,
  MoreVert,
  CheckCircle,
  Cancel,
} from "@material-ui/icons";
import {
  Avatar,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Pagination } from "@material-ui/lab";
import {
  deleteBlogs,
  destroyDeleteErrorB,
  filterBlogs,
  getBlogs,
  getCategory,
} from "../../../redux/actions/blog";
import AddBlog from "./AddBlog";
import EditBlog from "./EditBlog";
import axios from "axios";
import {
  baseUrl,
  baseUrlImage,
  getBlogEndpoint,
  imagePath,
} from "../../../redux/url";
import ImportBlog from "./ImportBlog";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getUsers } from "../../../redux/actions/user";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Blog() {
  var found = false;
  const [showAdd, setShowAdd] = useState({});
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [open, setopen] = useState(false);
  const [open1, setopen1] = useState(false);
  const [open3, setopen3] = useState(false);
  const [open2, setopen2] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [deleted, setDeleted] = useState("");
  const [keyWord, setKeyWord] = useState("");
  const [copied, setCopied] = useState(false);
  const [totalLength, setTotalLength] = useState(1);
  const { isAdded } = useSelector((state) => state.addBlog);
  const { isEdited } = useSelector((state) => state.editBlog);
  const { category } = useSelector((state) => state.getCategory);
  const { isLoading, blogs, isError } = useSelector((state) => state.getBlog);
  const { users } = useSelector((state) => state.getUser);
  const { isDeleted, isError: deleteError } = useSelector(
    (state) => state.deleteBlog
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl(null);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.paddingRight = "0";
  };
  const handleClose = () => {
    setopen(false);
  };
  const handleClose1 = () => {
    setopen1(false);
  };
  const handleClose2 = () => {
    setopen2(false);
  };
  const handleClose3 = () => {
    setopen3(false);
  };
  const handleClose5 = () => {
    setOpen4(false);
  };
  const editShowFunction = (data) => {
    setModal1(true);
    setShowAdd(data);
  };
  const deleteCityFunction = (id) => {
    dispatch(deleteBlogs({ blogId: id }));
  };
  const searchChangeHandler = (e) => {
    setKeyWord(e.target.value);
    if (e.target.value === "") {
      dispatch(
        getBlogs({
          skip: page * count - count,
          limit: count,
        })
      );
    } else {
      setPage(1);
      dispatch(
        filterBlogs({
          key: e.target.value,
          skip: 0,
          limit: count,
        })
      );
    }
  };
  const paginationHandler = (e, v) => {
    setPage(v);
    if (keyWord === "") {
      dispatch(
        getBlogs({
          skip: v * count - count,
          limit: count,
        })
      );
    } else {
      dispatch(
        filterBlogs({
          key: keyWord,
          skip: v * count - count,
          limit: count,
        })
      );
    }
  };
  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== ""
    ) {
    } else {
      history.push("/login");
    }
    axios.get(baseUrl + getBlogEndpoint).then((result) => {
      // alert(result.data.length)
      setTotalLength(result.data.length);
    });
    if (isDeleted && isDeleted !== "") {
      setDeleted(isDeleted);
      setopen2(true);
    }
    if (deleteError && deleteError !== "") {
      setDeleted(deleteError);
      setopen2(true);
    }
    dispatch(getCategory());
    dispatch(getUsers());
    dispatch(
      getBlogs({
        skip: page * count - count,
        limit: count,
      })
    );
    return () => {
      return dispatch(destroyDeleteErrorB());
    };
  }, [isAdded, isEdited, isDeleted, modal2]);
  return (
    <div>
      <Container className="top-100">
        <Row>
          <Col className="order-xl-1" lg="12" xl="12">
            <Card className="bg-secondary shadow">
              <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="5" lg="4">
                          <h3 className="mb-0">Blogs</h3>
                        </Col>
                        <Col xs="7" lg="4">
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item>
                              <TextField
                                id="input-with-icon-grid"
                                label="Search Blogs"
                                onChange={searchChangeHandler}
                              />
                            </Grid>
                          </Grid>
                        </Col>
                        <Col className="text-right" lg="4" xs="12">
                          <Button
                            color="primary"
                            onClick={() => {
                              return handleClose4(), setModal(true);
                            }}
                            size="sm"
                          >
                            Add Blog
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => {
                              return (
                                handleClose4(), setModal2(true), setPage(1)
                              );
                            }}
                            size="sm"
                          >
                            Import Blog
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    {isError ? (
                      <>{isError}</>
                    ) : isLoading ? (
                      <>
                        <tbody>
                          <LinearProgress />
                        </tbody>
                      </>
                    ) : blogs.success === false ? (
                      <>
                        <Alert severity="error">{blogs.message}</Alert>
                      </>
                    ) : (
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Featured</th>
                            <th scope="col">Editor Pick</th>
                            <th scope="col">City Name</th>
                            <th scope="col">Banner Image</th>
                            <th scope="col">Posted By</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        {blogs.length > 0 &&
                          blogs.map((row, i) => {
                            return (
                              <tbody>
                                <tr>
                                  <th scope="row">
                                    {row.blogTitle || ""}
                                    <br />
                                    <ul className="blog-category">
                                      {row.blogCategory.length > 0
                                        ? row.blogCategory.map((e, i) => {
                                            return <li>{e.value}</li>;
                                          })
                                        : row.blogCategory}
                                    </ul>
                                  </th>
                                  <td>
                                    {row.isFeatured ? (
                                      <CheckCircle />
                                    ) : (
                                      <Cancel />
                                    )}
                                  </td>
                                  <td>
                                    {row.isEditorPick ? (
                                      <CheckCircle />
                                    ) : (
                                      <Cancel />
                                    )}
                                  </td>
                                  <td>{row.cityName || ""}</td>
                                  <td>
                                    {
                                      row.blogImages?.length > 0 && (
                                        <Avatar
                                          src={
                                            baseUrlImage +
                                            imagePath +
                                            row.blogImages[0].imageName
                                          }
                                        />
                                      )}
                                  </td>
                                  <td>{users.length>0&&users.map((elm,ind)=>{
                                    if(elm._id===row.postedBy){
                                      return elm.userName
                                    }
                                  })}</td>
                                  <td>
                                    {" "}
                                    <Edit
                                      style={{ cursor: "pointer" }}
                                      onClick={() => editShowFunction(row)}
                                    />{" "}
                                  </td>
                                  <td>
                                    <Delete
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        deleteCityFunction(row._id)
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </Table>
                    )}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="float-right m-3">
              <Pagination
                count={Math.ceil(totalLength / count)}
                page={page}
                onChange={paginationHandler}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <AddBlog modal={modal} setModal={setModal} setopen={setopen} />
      <ImportBlog modal={modal2} setModal={setModal2} setOpen={setopen3} />
      {modal1 && (
        <EditBlog
          modal={modal1}
          setModal={setModal1}
          data={showAdd}
          setOpen={setopen1}
        />
      )}{" "}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          "Added Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
        <Alert onClose={handleClose3} severity="success">
          "Uploaded Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="success">
          "Updated Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open4} autoHideDuration={6000} onClose={handleClose5}>
        <Alert onClose={handleClose5} severity="success">
          "Copied Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
        <Alert onClose={handleClose2} severity="error">
          {deleted}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Blog;
