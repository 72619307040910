import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import {
  destroyAddUserError,
  destroyUserAdd,
  register,
} from "../redux/actions/user";

function Register() {
  const history=useHistory()
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const { loading, registered, error } = useSelector(
    (state) => state.registerUser
  );
  const [errorText, setErrorText] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const fromSubmitted = (e) => {
    e.preventDefault();
    if (email !== "" && password !== "" && name !== "" && checked) {
      const info = {
        userName: name,
        email: email,
        password: password,
        userRole: 4,
      };
      dispatch(register(info));
    } else {
      setErrorText("Please Fill all the fields");
      setOpen1(true);
    }
  };

  useEffect(() => {
    if (registered === true) {
      setOpen(true);
      setName("");
      setEmail("");
      setPassword("");
      setChecked(false);
      setTimeout(
        function() {
            history.push("/login")
        }
        .bind(this),
        1000
    );
    }
    if (error !== undefined && error !== "") {
      setErrorText(error);
      setOpen1(true);
    }
    return () => {
      return dispatch(destroyUserAdd()), dispatch(destroyAddUserError());
    };
  }, [registered, error]);
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={fromSubmitted} role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    type="text"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic">
                <small>
                  password strength:{" "}
                  {password !== "" ? (
                    password.length < 5 ? (
                      <span className="text-danger font-weight-700">weak</span>
                    ) : password.length <= 8 ? (
                      <span className="text-warning font-weight-700">
                        normal
                      </span>
                    ) : password.length > 8 ? (
                      <span className="text-success font-weight-700">
                        strong
                      </span>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </small>
              </div>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  className="mt-4"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "signing up" : "Signup"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Registered Successfully
        </Alert>
      </Snackbar>
    </>
  );
}

export default Register;
