import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getCitiesDataHead } from "../../redux/actions/package";
import { filterLocation } from "../../redux/actions/settings";

function HeaderHome(props) {
  const { isLoading, isError, locations } = useSelector(
    (state) => state.getLocation
  );
  const {
    isLoading: cityLoading,
    isError: cityError,
    headCities,
  } = useSelector((state) => state.getCityHead);
  const dispatch = useDispatch();
  const history = useHistory();
  const [defaultValues, setDefaultValues] = useState({
    url: "/images/banner-img01.jpg",
    content: `<h1>DISCOVER YOUR VACATIONS WITH US</h1><p>We offer matchless services to the business and tourist. Since our inception pre-independence, provided highly efficient chauffeur driven cars & coaches.</p>`,
    name: "Home",
    icons: [
      {
        icon: "facebook",
        url: "http://facebook.com",
      },
      {
        icon: "twitter",
        url: "http://twitter.com",
      },
      {
        icon: "linkedin",
        url: "http://linkedIn.com",
      },
      {
        icon: "google",
        url: "http://gmail.com",
      },
      {
        icon: "github",
        url: "http://github.com",
      },
    ],
  });
  const [searchInput, setSearchInput] = useState("");
  const [showList, setShowList] = useState(false);
  const [showListTo, setShowListTo] = useState(false);
  const [fromCity, setFromCity] = useState("");
  const [toCity, setToCity] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [activeButton, setActiveButton] = useState("Hotels");
  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }
  function openCity(cityName) {
    setShowList(false);
    setActiveButton(cityName);
    var i;
    var x = document.getElementsByClassName("city");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
      x[i].classList.remove("active");
    }
    document.getElementById(cityName).style.display = "block";
    document.getElementById(cityName).classList.add("active");
  }
  const locationSearchHandler = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value === "") {
      setShowList(false);
    } else {
      setShowList(true);
      dispatch(
        filterLocation({
          key: e.target.value,
        })
      );
    }
  };
  const locationSearchHandlerTo = (e) => {
    setToCity(e.target.value);
    if (e.target.value === "") {
      setShowListTo(false);
    } else {
      setShowListTo(true);
      dispatch(
        getCitiesDataHead({
          key: e.target.value,
        })
      );
    }
  };
  const holidaySearch = (e) => {
    setShowList(false);
    e.preventDefault();
    if (fromCity === "") {
      setAlertColor("red");
    } else if (toCity === "") {
      setAlertColor("red");
    } else if (locations.success === false) {
      setAlertColor("red");
    } else {
      setAlertColor("");
      history.push(`/holiday/?from=${fromCity}&to=${toCity}`);
    }
  };
  return (
    <>
      <div className="top-sec w-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <ul className="social-link w-100 mr-0 mb-0 pl-0 float-left">
                {props.icons ? (
                  props.icons.length > 0 ? (
                    props.icons.map((e, i) => {
                      return (
                        <>
                          <li className="social-link-item">
                            <a href={`${e.url}`}>
                              <i
                                class={`fab fa-${e.icon}`}
                                style={{ color: "white" }}
                              ></i>
                            </a>
                          </li>
                        </>
                      );
                    })
                  ) : (
                    <>
                      {" "}
                      {defaultValues.icons.map((e, i) => {
                        return (
                          <>
                            <li className="social-link-item">
                              <a href={`${e.url}`}>
                                <i
                                  class={`fab fa-${e.icon}`}
                                  style={{ color: "white" }}
                                ></i>
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </>
                  )
                ) : (
                  <>
                    {defaultValues.icons.map((e, i) => {
                      return (
                        <>
                          <li className="social-link-item">
                            <a href={`${e.url}`}>
                              <i
                                class={`fab fa-${e.icon}`}
                                style={{ color: "white" }}
                              ></i>
                            </a>
                          </li>
                        </>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <ul className="address mb-0 pl-0 mr-0">
                <li className="address-item">
                  <img alt="image" src="/images/ph.png" className="mr-1" />
                  <a href="tel:+91 9875981976" className="text-decoration-none">
                    +91 9875981976
                  </a>
                </li>
                <li className="address-item">
                  <img alt="image" src="/images/mail.png" className="mr-2" />
                  <a
                    href="mailto:corporate.sales@exploreholidays.in"
                    className="text-decoration-none"
                  >
                    info@exploreholidays.in
                  </a>
                </li>
              </ul>
              <ul className="login mb-0 pl-0 mr-0">
                <li>
                  <Link to="/contact" className="text-decoration-none">
                    Contact us
                  </Link>
                </li>
                <li>
                  {localStorage.getItem("accessToken") ? (
                    <>
                      {localStorage.getItem("isAdmin") == "true" ? (
                        <>
                          <Link to="/admin">Admin</Link>
                        </>
                      ) : (
                        <Link to="/">User</Link>
                      )}
                    </>
                  ) : (
                    <Link to="/login">Login</Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-sec-mobile">
        {/* <!--<div className="col-sm-3 col-md-3 col-lg-8">--> */}
        <div id="mySidenav" className="sidenav">
          <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
            &times;
          </a>
          <a href="#">Search</a>
          <Link to="/holidays">Holidays</Link>
          <a href="#">Hotels</a>
          <a href="#">Activities & Tours</a>
          {localStorage.getItem("accessToken") ? (
            <>
              {localStorage.getItem("isAdmin") == "true" ? (
                <>
                  <Link to="/admin" className="nav-link">
                    Admin
                  </Link>
                </>
              ) : (
                <Link to="/" className="nav-link">
                  User
                </Link>
              )}
            </>
          ) : (
            <Link to="/login" className="nav-link">
              Login
            </Link>
          )}
        </div>
        <div id="main">
          <span
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={openNav}
          >
            &#9776;{" "}
          </span>
        </div>
        {/* <!--</div>--> */}
        <div className="col-sm-12 col-md-12 col-lg-12 logo ">
          {/* <Link to="/" className="logo pt-1 pb-2"><img alt="image" src="/images/logo.png"/></Link> */}
        </div>
      </div>

      {/* <!----bottom-header-sec----> */}
      <div className="banner-sec w-100">
        <img
          alt="image"
          src={props.url ? props.url : defaultValues.url}
          className="long-banner w-100"
        />
        <img
          alt="image"
          src={props.url ? props.url : defaultValues.url}
          className="short-banner w-100"
        />
        <img
          alt="image"
          src={props.url ? props.url : defaultValues.url}
          className="mobile-banner w-100"
        />
        <div className="bottom-sec w-100 float-left">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                {/* <Link to="/" className="logo"><img alt="image" src="/images/logo.png"/></Link> */}
                {/* <Link to="/" className="logo dsk-hide"><img alt="image" src="/images/logo1.png"/></Link> */}
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8">
                <nav className="navbar navbar-expand-lg navbar-light bg-none">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mr-auto w-100">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <img
                            alt="image"
                            src="/images/search.png"
                            className="search"
                          />{" "}
                          <img
                            alt="image"
                            src="/images/grey.png"
                            className="stick-srch"
                          />
                        </a>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/holidays">
                          Holidays
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Hotels{" "}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Activities & Tours
                        </a>
                      </li>
                      <li className="nav-item">
                        {localStorage.getItem("accessToken") ? (
                          <>
                            {localStorage.getItem("isAdmin") == "true" ? (
                              <>
                                <Link to="/admin" className="nav-link">
                                  Admin
                                </Link>
                              </>
                            ) : (
                              <Link to="/" className="nav-link">
                                User
                              </Link>
                            )}
                          </>
                        ) : (
                          <Link to="/login" className="nav-link">
                            Login
                          </Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="dsktop"></div>

        <div className="banner-content w-100">
          <div className="container">
            <div className="row">
              <div
                className="col-sm-12 col-md-12 col-lg-9"
                dangerouslySetInnerHTML={{
                  __html: props.content ? props.content : defaultValues.content,
                }}
              >
              </div>
            </div>
          </div>
        </div>

        {props.name && props.name.toLowerCase() === "home" && (
          <div className="form">
            <div className="detail-sec">
              <div className="container">
                <div className="row">
                  <div className="w3-bar w3-black">
                    <button
                      className={`w3-bar-item w3-button ${
                        activeButton === "Holidays" && "active"
                      }`}
                      onClick={() => openCity("Holidays")}
                    >
                      <img alt="image" src="/images/holi.png" />
                      Holidays
                    </button>
                    {/* <button
                    className="w3-bar-item w3-button"
                    onClick={() => openCity("Cabs")}
                  >
                    <img alt="image" src="/images/car.png" /> Cabs{" "}
                  </button> */}
                    <button
                      className={`w3-bar-item w3-button ${
                        activeButton === "Hotels" && "active"
                      }`}
                      onClick={() => openCity("Hotels")}
                    >
                      <img alt="image" src="/images/hotel.png" /> Hotels
                    </button>
                    <button
                      className={`w3-bar-item w3-button ${
                        activeButton === "Activities" && "active"
                      }`}
                      onClick={() => openCity("Activities")}
                    >
                      <img alt="image" src="/images/activitie.png" /> Activities
                      & Tours
                    </button>
                    {/* <button
                    className="w3-bar-item w3-button active"
                    onClick={() => openCity("Flights")}
                  >
                    <img alt="image" src="/images/flight.png" /> Flights
                  </button> */}
                  </div>

                  {/* <!-----> */}
                  <div
                    id="Flights"
                    className="city"
                    style={{ display: "none" }}
                  >
                    <div className="box-content">
                      <ul className="transfer">
                        <li>
                          <label>Flying from</label>
                          <input type="text" placeholder=" Delhi, India" />
                        </li>
                        <li>
                          <label>Flying To</label>
                          <input type="text" placeholder="Mumbai, India" />
                        </li>
                        <li className="One-way">
                          <label>One way</label>
                          <div className="one">One way</div>
                        </li>

                        <li className="return">
                          {" "}
                          <span className="time">
                            <label>Round Trip</label>
                            <input type="text" placeholder="Round Trip" />
                          </span>
                        </li>
                        <li>
                          <div className="depart">
                            <label>Depart</label>
                            <input type="text" placeholder="06-03-2021" />
                          </div>
                        </li>
                        <li>
                          <div className="depart">
                            <label>Return</label>
                            <input type="text" placeholder="06-03-2021" />
                          </div>
                        </li>
                        <li>
                          <label>Travels</label>
                          <input type="text" placeholder="Passenger(S)" />
                        </li>

                        <li>
                          <input type="submit" value="Search" />
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div id="Hotels" className="city active">
                    <div className="box-content  hotel">
                      <ul className="transfer">
                        <li>
                          <label>I want to go to</label>
                          {showList && (
                            <label
                              className="search-label-location"
                              style={{
                                overflowY: "scroll",
                                overflowX: "hidden",
                                maxHeight: "100px",
                              }}
                            >
                              {isError ? (
                                <>{isError}</>
                              ) : isLoading ? (
                                <>{"loading...."}</>
                              ) : locations.length > 0 ? (
                                <>
                                  {locations.map((e, i) => {
                                    return (
                                      <div
                                        className="search-list-header"
                                        style={{
                                          cursor: "pointer",
                                          paddingBottom: "10px",
                                        }}
                                        onClick={() => {
                                          return (
                                            setSearchInput(e.location),
                                            setShowList(false)
                                          );
                                        }}
                                      >
                                        {e.location}
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>NotFound</>
                              )}
                            </label>
                          )}
                          <span className="img-form">
                            <img
                              alt="image"
                              src="/images/map.png"
                              className=" float-left"
                            />
                          </span>
                          <input
                            type="text"
                            placeholder="Goa,India"
                            value={searchInput}
                            onChange={locationSearchHandler}
                          />
                        </li>
                        <li>
                          <div className="depart">
                            <label>Travel Dates</label>
                            <span className="img-form">
                              <img
                                alt="image"
                                src="/images/col.png"
                                className=" float-left"
                              />
                            </span>
                            <input
                              type="text"
                              style={{ width: "100% !important" }}
                              placeholder="12-Mar-2021 - 13-Mar-2021"
                            />
                          </div>
                        </li>
                        <li>
                          <label>Travelers</label>
                          <span className="img-form">
                            <img
                              alt="image"
                              src="/images/prsn.png"
                              className=" float-left"
                            />
                          </span>
                          <input
                            type="text"
                            placeholder="1 Rooms(5)1 Guest(s)"
                          />
                        </li>

                        <li>
                          <input type="submit" value="Search" />
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div id="Cabs" className="city" style={{ display: "none" }}>
                    <div className="box-content  hotel">
                      <ul className="transfer">
                        <li>
                          <label>Pickup Location</label>
                          <span className="img-form">
                            <img
                              alt="image"
                              src="/images/map.png"
                              className=" float-left"
                            />
                          </span>
                          <input type="text" placeholder="Goa,India" />
                        </li>
                        <li>
                          <label>Drop-off Location</label>
                          <span className="img-form">
                            <img
                              alt="image"
                              src="/images/map.png"
                              className=" float-left"
                            />
                          </span>
                          <input type="text" placeholder="Goa,India" />
                        </li>
                        <li>
                          <div className="depart">
                            <label>Pickup Date</label>
                            <span className="img-form">
                              <img
                                alt="image"
                                src="/images/col.png"
                                className=" float-left"
                              />
                            </span>
                            <input
                              type="text"
                              placeholder="12-Mar-2021 - 13-Mar-2021"
                            />
                          </div>
                        </li>

                        <li className="time">
                          <label>Time</label>
                          <div className="one">11:0</div>
                        </li>
                        <li>
                          <div className="depart">
                            <label>Return date</label>
                            <span className="img-form">
                              <img
                                alt="image"
                                src="/images/col.png"
                                className=" float-left"
                              />
                            </span>
                            <input
                              type="text"
                              placeholder="12-Mar-2021 - 13-Mar-2021"
                            />
                          </div>
                        </li>
                        <li className="time">
                          <label>Time</label>
                          <div className="one">11:0</div>
                        </li>
                        <li>
                          <input type="submit" value="Search" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    id="Activities"
                    className="city"
                    style={{ display: "none" }}
                  >
                    <div className="box-content  hotel">
                      <ul className="transfer">
                        <li>
                          <label>I want to go to</label>
                          <span className="img-form">
                            <img
                              alt="image"
                              src="/images/map.png"
                              className=" float-left"
                            />
                          </span>
                          <input type="text" placeholder="Goa,India" />
                        </li>
                        <li>
                          <div className="depart">
                            <label>Travel Dates</label>
                            <span className="img-form">
                              <img
                                alt="image"
                                src="/images/col.png"
                                className=" float-left"
                              />
                            </span>
                            <input
                              type="text"
                              placeholder="12-Mar-2021 - 13-Mar-2021"
                            />
                          </div>
                        </li>
                        <li>
                          <label>Travelers</label>
                          <span className="img-form">
                            <img
                              alt="image"
                              src="/images/prsn.png"
                              className=" float-left"
                            />
                          </span>
                          <input type="text" placeholder="1 Guest(s)" />
                        </li>

                        <li>
                          <input type="submit" value="Search" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    id="Holidays"
                    className="city active"
                    style={{ display: "none" }}
                  >
                    <div className="box-content  hotel">
                      <ul className="transfer">
                        <li>
                          <label style={{ color: alertColor }}>From</label>
                          {showList && (
                            <label
                              className="search-label-location"
                              style={{
                                overflowY: "scroll",
                                overflowX: "hidden",
                                maxHeight: "100px",
                              }}
                            >
                              {isError ? (
                                <>{isError}</>
                              ) : isLoading ? (
                                <>{"loading...."}</>
                              ) : locations.length > 0 ? (
                                <>
                                  {locations.map((e, i) => {
                                    return (
                                      <div
                                        className="search-list-header"
                                        style={{
                                          cursor: "pointer",
                                          paddingBottom: "10px",
                                        }}
                                        onClick={() => {
                                          return (
                                            setFromCity(e.location),
                                            setShowList(false)
                                          );
                                        }}
                                      >
                                        {e.location}
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>NotFound</>
                              )}
                            </label>
                          )}
                          <span className="img-form">
                            <img
                              alt="image"
                              src="/images/map.png"
                              className=" float-left"
                            />
                          </span>
                          <input
                            type="text"
                            placeholder="Origin"
                            value={fromCity}
                            onChange={(e) => {
                              return (
                                locationSearchHandler(e),
                                setFromCity(e.target.value)
                              );
                            }}
                          />
                        </li>
                        <li>
                          <div className="depart">
                            <label style={{ color: alertColor }}>To</label>
                            {showListTo && (
                              <label
                                className="search-label-location"
                                style={{
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  maxHeight: "100px",
                                }}
                              >
                                {cityError ? (
                                  <>{cityError}</>
                                ) : cityLoading ? (
                                  <>{"loading...."}</>
                                ) : headCities.length > 0 ? (
                                  <>
                                    {headCities.map((e, i) => {
                                      return (
                                        <div
                                          className="search-list-header"
                                          style={{
                                            cursor: "pointer",
                                            paddingBottom: "5px",
                                            paddingTop:"5px"
                                          }}
                                          onClick={() => {
                                            return (
                                              setToCity(e.cityName),
                                              setShowListTo(false)
                                            );
                                          }}
                                        >
                                          {e.cityName}
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>NotFound</>
                                )}
                              </label>
                            )}
                            <span className="img-form">
                              <img
                                alt="image"
                                src="/images/map.png"
                                className=" float-left"
                              />
                            </span>
                            <input
                              type="text"
                              placeholder="Destination"
                              value={toCity}
                              onChange={locationSearchHandlerTo}
                            />
                          </div>
                        </li>
                        <li>
                          <label>Travelers</label>
                          <span className="img-form">
                            <img
                              alt="image"
                              src="/images/prsn.png"
                              className=" float-left"
                            />
                          </span>
                          <input type="text" placeholder="optional" />
                        </li>

                        <li>
                          <input
                            type="submit"
                            value="Search"
                            onClick={holidaySearch}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default HeaderHome;
