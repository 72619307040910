import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  destroyEditCategory,
  destroyEditCategoryError,
  editCategory,
} from "../../../redux/actions/blog";

function EditCategory({ modal, setModal, data, setOpen }) {
  const [name, setName] = useState("");
  const [open, setopen] = useState(true);
  const [edit, setEdit] = useState("");
  const dispatch = useDispatch();
  const { isLoading, isError, isEdited } = useSelector(
    (state) => state.editCategory
  );
  const handleClose = () => {
    setopen(false);
  };
  const toggle = () => setModal(!modal);

  const formSubmitted = (e) => {
    e.preventDefault();
    dispatch(
      editCategory({
        blogCategory: name,
        categoryId: data._id,
        userId: localStorage.getItem("userId"),
      })
    );
    setName("");
  };
  const closeModal = () => {
    setModal(false);
    setOpen(true);
  };
  useEffect(() => {
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setopen(true);
    }
    if (isEdited !== undefined && isEdited !== "") {
      closeModal();
    }
    setName(data.blogCategory);
    return () => {
      return (
        dispatch(destroyEditCategory()), dispatch(destroyEditCategoryError())
      );
    };
  }, [isEdited, isError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            {edit && (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {edit}
                </Alert>
              </Snackbar>
            )}
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Edit Category"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Category
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Category name"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Edit Category
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditCategory;
