import axios from "axios";
import {
  ADD_COMPANY_ERROR,
  ADD_COMPANY_LOADING,
  ADD_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
  DELETE_COMPANY_LOADING,
  DELETE_COMPANY_SUCCESS,
  DESTROY_COMPANY_ADD,
  DESTROY_COMPANY_DELETE,
  DESTROY_COMPANY_EDIT,
  DESTROY_ERROR_ADD_COMPANY,
  DESTROY_ERROR_EDIT_COMPANY,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_LOADING,
  EDIT_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
} from "../constants";
import {
  addCompanyEndpoint,
  deleteCompanyEndpoint,
  editCompanyEndpoint,
  filterCompanyEndpoint,
  getCompanyEndpoint,
  baseUrl
} from "../url";

export const getCompany = (data) => async (dispatch) => {
  dispatch({ type: GET_COMPANY_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getCompanyEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_COMPANY_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_COMPANY_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getCompanyEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_COMPANY_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_COMPANY_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_COMPANY_ERROR, payload: error.message });
  }
};

export const addCompany = (data) => async (dispatch) => {
  dispatch({ type: ADD_COMPANY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addCompanyEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_COMPANY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: ADD_COMPANY_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_COMPANY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_COMPANY_ERROR, payload: error.message });
  }
};

export const editCompany = (data) => async (dispatch) => {
  dispatch({ type: EDIT_COMPANY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editCompanyEndpoint}`,
      data: data,
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then((result) => {
        if (result.data.success !== false && result.data.status !== false) {
          dispatch({
            type: EDIT_COMPANY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: EDIT_COMPANY_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_COMPANY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_COMPANY_ERROR, payload: error.message });
  }
};

export const deleteCompany = (data) => async (dispatch) => {
  dispatch({ type: DELETE_COMPANY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteCompanyEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_COMPANY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_COMPANY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_COMPANY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_COMPANY_ERROR, payload: error.message });
  }
};

export const destroyAddCompany = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_COMPANY_ADD, payload: "" });
};
export const destroyEditCompany = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_COMPANY_EDIT, payload: "" });
};
export const destroyDeleteCompany = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_COMPANY_DELETE, payload: "" });
};
export const destroyAddCompanyError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_ADD_COMPANY, payload: "" });
};
export const destroyEditCompanyError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_EDIT_COMPANY, payload: "" });
};
export const destroyDeleteErrorCompany = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_COMPANY", payload: "" });
};

export const filterCompany = (data) => async (dispatch) => {
  dispatch({ type: GET_COMPANY_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterCompanyEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_COMPANY_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_COMPANY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_COMPANY_ERROR, payload: error.message });
  }
};
