import Alert from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Col, Container, Row } from "reactstrap";
import UserNavbar from "../common/navbar/UserNavbar";
import notFound from "../assets/img/new/notFound.png"
import { Helmet } from "react-helmet";

function NotFound() {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  return (
    <>
    <Helmet>
      <title>Not found</title>
    </Helmet>
      <UserNavbar/>
      <div className="not-found">
      <Container>
      <Row>
        <Col lg="12" className="not-found-image">
        <img src={notFound} alt="page not found"/>
        </Col>
      </Row>
      </Container>
      </div>
    </>
  );
}

export default NotFound;
