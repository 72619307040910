import axios from "axios";
import {
  DESTROY_ERROR_ADD_LOCATION,
  DESTROY_ERROR_EDIT_LOCATION,
  ADD_HOME_SETTING_ERROR,
  ADD_HOME_SETTING_LOADING,
  ADD_HOME_SETTING_SUCCESS,
  ADD_LOCATION_ERROR,
  ADD_LOCATION_LOADING,
  ADD_LOCATION_SUCCESS,
  DELETE_HOME_SETTING_ERROR,
  DELETE_HOME_SETTING_LOADING,
  DELETE_HOME_SETTING_SUCCESS,
  DELETE_LOCATION_ERROR,
  DELETE_LOCATION_LOADING,
  DELETE_LOCATION_SUCCESS,
  DESTROY_ERROR_ADD_HOME,
  DESTROY_ERROR_EDIT_HOME,
  DESTROY_HOME_SETTING_ADD,
  DESTROY_HOME_SETTING_DELETE,
  DESTROY_HOME_SETTING_EDIT,
  DESTROY_LOCATION_ADD,
  DESTROY_LOCATION_DELETE,
  DESTROY_LOCATION_EDIT,
  EDIT_HOME_SETTING_ERROR,
  EDIT_HOME_SETTING_LOADING,
  EDIT_HOME_SETTING_SUCCESS,
  EDIT_LOCATION_ERROR,
  EDIT_LOCATION_LOADING,
  EDIT_LOCATION_SUCCESS,
  GET_HOME_SETTING_ERROR,
  GET_HOME_SETTING_LOADING,
  GET_HOME_SETTING_SUCCESS,
  GET_LOCATION_ERROR,
  GET_LOCATION_LOADING,
  GET_LOCATION_SUCCESS,
  GET_AD_ERROR,
  GET_AD_SUCCESS,
  GET_AD_LOADING,
  DESTROY_ERROR_EDIT_AD,
  DESTROY_ERROR_ADD_AD,
  DESTROY_AD_DELETE,
  DESTROY_AD_EDIT,
  DESTROY_AD_ADD,
  DELETE_AD_ERROR,
  DELETE_AD_SUCCESS,
  DELETE_AD_LOADING,
  EDIT_AD_ERROR,
  EDIT_AD_SUCCESS,
  EDIT_AD_LOADING,
  ADD_AD_ERROR,
  ADD_AD_SUCCESS,
  ADD_AD_LOADING,
} from "../constants";
import {
  baseUrl,
  getHomeSettingEndpoint,
  filterLocationEndpoint,
  deleteLocationEndpoint,
  editLocationEndpoint,
  addLocationEndpoint,
  getLocationEndpoint,
  filterHomeSettingEndpoint,
  addHomeSettingEndpoint,
  editHomeSettingEndpoint,
  deleteHomeSettingEndpoint,
  getAdEndpoint,
  editAdEndpoint,
  deleteAdEndpoint,
  addAdEndpoint,
  filterAdEndpoint
} from "../url";

export const getHomeSettings = (data) => async (dispatch) => {
  dispatch({ type: GET_HOME_SETTING_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getHomeSettingEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_HOME_SETTING_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_HOME_SETTING_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getHomeSettingEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_HOME_SETTING_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_HOME_SETTING_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_HOME_SETTING_ERROR, payload: error.message });
  }
};

export const addHomeSetting = (data) => async (dispatch) => {
  dispatch({ type: ADD_HOME_SETTING_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addHomeSettingEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_HOME_SETTING_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: ADD_HOME_SETTING_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_HOME_SETTING_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_HOME_SETTING_ERROR, payload: error.message });
  }
};

export const editHomeSetting = (data) => async (dispatch) => {
  dispatch({ type: EDIT_HOME_SETTING_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editHomeSettingEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_HOME_SETTING_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: EDIT_HOME_SETTING_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_HOME_SETTING_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_HOME_SETTING_ERROR, payload: error.message });
  }
};

export const deleteHomeSetting = (data) => async (dispatch) => {
  dispatch({ type: DELETE_HOME_SETTING_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteHomeSettingEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_HOME_SETTING_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_HOME_SETTING_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_HOME_SETTING_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_HOME_SETTING_ERROR, payload: error.message });
  }
};

export const destroyAddHomeSetting = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_HOME_SETTING_ADD, payload: "" });
};
export const destroyEditHomeSetting = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_HOME_SETTING_EDIT, payload: "" });
};
export const destroyDeleteHomeSetting = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_HOME_SETTING_DELETE, payload: "" });
};
export const destroyAddHomeSettingError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_ADD_HOME, payload: "" });
};
export const destroyEditHomeSettingError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_EDIT_HOME, payload: "" });
};
export const destroyDeleteError = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR", payload: "" });
};

export const filterSettingPage = (data) => async (dispatch) => {
  dispatch({ type: GET_HOME_SETTING_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterHomeSettingEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_HOME_SETTING_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_HOME_SETTING_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_HOME_SETTING_ERROR, payload: error.message });
  }
};

export const getLocation = (data) => async (dispatch) => {
  dispatch({ type: GET_LOCATION_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getLocationEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_LOCATION_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_LOCATION_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getLocationEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_LOCATION_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_LOCATION_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_LOCATION_ERROR, payload: error.message });
  }
};

export const addLocation = (data) => async (dispatch) => {
  dispatch({ type: ADD_LOCATION_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addLocationEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_LOCATION_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: ADD_LOCATION_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_LOCATION_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_LOCATION_ERROR, payload: error.message });
  }
};

export const editLocation = (data) => async (dispatch) => {
  dispatch({ type: EDIT_LOCATION_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editLocationEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_LOCATION_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: EDIT_LOCATION_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_LOCATION_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_LOCATION_ERROR, payload: error.message });
  }
};

export const deleteLocation = (data) => async (dispatch) => {
  dispatch({ type: DELETE_LOCATION_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteLocationEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_LOCATION_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_LOCATION_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_LOCATION_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_LOCATION_ERROR, payload: error.message });
  }
};

export const destroyAddLocation = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_LOCATION_ADD, payload: "" });
};
export const destroyEditLocation = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_LOCATION_EDIT, payload: "" });
};
export const destroyDeleteLocation = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_LOCATION_DELETE, payload: "" });
};
export const destroyAddLocationError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_ADD_LOCATION, payload: "" });
};
export const destroyEditLocationError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_EDIT_LOCATION, payload: "" });
};
export const destroyDeleteErrorLocation = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_LOCATION", payload: "" });
};

export const filterLocation = (data) => async (dispatch) => {
  dispatch({ type: GET_LOCATION_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterLocationEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_LOCATION_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_LOCATION_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_LOCATION_ERROR, payload: error.message });
  }
};

export const getAd = (data) => async (dispatch) => {
  dispatch({ type: GET_AD_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getAdEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_AD_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_AD_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getAdEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_AD_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_AD_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_AD_ERROR, payload: error.message });
  }
};

export const addAd = (data) => async (dispatch) => {
  dispatch({ type: ADD_AD_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addAdEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_AD_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: ADD_AD_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_AD_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_AD_ERROR, payload: error.message });
  }
};

export const editAd = (data) => async (dispatch) => {
  dispatch({ type: EDIT_AD_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editAdEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_AD_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: EDIT_AD_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_AD_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_AD_ERROR, payload: error.message });
  }
};

export const deleteAd = (data) => async (dispatch) => {
  dispatch({ type: DELETE_AD_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteAdEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_AD_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_AD_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_AD_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_AD_ERROR, payload: error.message });
  }
};

export const destroyAddAd = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_AD_ADD, payload: "" });
};
export const destroyEditAd = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_AD_EDIT, payload: "" });
};
export const destroyDeleteAd = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_AD_DELETE, payload: "" });
};
export const destroyAddAdError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_ADD_AD, payload: "" });
};
export const destroyEditAdError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_EDIT_AD, payload: "" });
};
export const destroyDeleteErrorAd = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_AD", payload: "" });
};

export const filterAd = (data) => async (dispatch) => {
  dispatch({ type: GET_AD_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterAdEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_AD_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_AD_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_AD_ERROR, payload: error.message });
  }
};

