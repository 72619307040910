export const ADD_PACKAGE_LOADING = "ADD_PACKAGE_LOADING";
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_ERROR = "ADD_PACKAGE_ERROR";

export const GET_PACKAGE_LOADING = "GET_PACKAGE_LOADING";
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS";
export const GET_PACKAGE_ERROR = "GET_PACKAGE_ERROR";

export const EDIT_PACKAGE_LOADING = "EDIT_PACKAGE_LOADING";
export const EDIT_PACKAGE_SUCCESS = "EDIT_PACKAGE_SUCCESS";
export const EDIT_PACKAGE_ERROR = "EDIT_PACKAGE_ERROR";

export const DELETE_PACKAGE_LOADING = "DELETE_PACKAGE_LOADING";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_ERROR = "DELETE_PACKAGE_ERROR";

export const ADD_CITY_LOADING = "ADD_CITY_LOADING";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_ERROR = "ADD_CITY_ERROR";
export const DESTROY_ADD_CITY = "DESTROY_ADD_CITY";

export const ADD_HOTEL_LOADING = "ADD_HOTEL_LOADING";
export const ADD_HOTEL_SUCCESS = "ADD_HOTEL_SUCCESS";
export const ADD_HOTEL_ERROR = "ADD_HOTEL_ERROR";
export const DESTROY_ADD_HOTEL = "DESTROY_ADD_HOTEL";

export const EDIT_CITY_LOADING = "EDIT_CITY_LOADING";
export const EDIT_CITY_SUCCESS = "EDIT_CITY_SUCCESS";
export const EDIT_CITY_ERROR = "EDIT_CITY_ERROR";

export const EDIT_HOTEL_LOADING = "EDIT_CITY_LOADING";
export const EDIT_HOTEL_SUCCESS = "EDIT_CITY_SUCCESS";
export const EDIT_HOTEL_ERROR = "EDIT_CITY_ERROR";
export const DESTROY_EDIT_HOTEL = "DESTROY_EDIT_HOTEL";

export const GET_CITY_LOADING = "GET_CITY_LOADING";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";

export const GET_HOTEL_LOADING = "GET_HOTEL_LOADING";
export const GET_HOTEL_SUCCESS = "GET_HOTEL_SUCCESS";
export const GET_HOTEL_ERROR = "GET_HOTEL_ERROR";
export const FILTER_HOTEL = "FILTER_HOTEL";

export const DELETE_HOTEL_LOADING = "DELETE_HOTEL_LOADING";
export const DELETE_HOTEL_SUCCESS = "DELETE_HOTEL_SUCCESS";
export const DELETE_HOTEL_ERROR = "DELETE_HOTEL_ERROR";

export const DELETE_CITY_LOADING = "DELETE_CITY_LOADING";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_ERROR = "DELETE_CITY_ERROR";

export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const USER_REGISTER_LOADING = "USER_REGISTER_LOADING";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const EDIT_USER_LOADING = "EDIT_USER_LOADING";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DETETE_USER_ERROR = "DELETE_USER_ERROR";

export const USER_LOGOUT = "USER_LOGOUT";

export const DESTROY_EDITED = "DESTROY_EDITED";

export const DESTROY_ERROR_CITY = "DESTROY_ERROR_CITY";
export const DESTROY_ERROR_HOTEL = "DESTROY_ERROR_HOTEL";
export const DESTROY_ERROR_PACKAGE = "DESTROY_ERROR_PACKAGE";

export const DESTROY_ERROR_CITY_EDIT = "DESTROY_ERROR_CITY_EDIT";
export const DESTROY_ERROR_HOTEL_EDIT = "DESTROY_ERROR_HOTEL_EDIT";
export const DESTROY_ERROR_PACKAGE_EDIT = "DESTROY_ERROR_PACKAGE_EDIT";

export const ADD_BLOG_LOADING = "ADD_BLOG_LOADING";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS";
export const ADD_BLOG_ERROR = "ADD_BLOG_ERROR";
export const DESTROY_BLOG_ADD = "DESTROY_BLOG_ADD";
export const DESTROY_ERROR_ADD_B = "DESTROY_ERROR_ADD_B";

export const EDIT_BLOG_LOADING = "EDIT_BLOG_LOADING";
export const EDIT_BLOG_SUCCESS = "EDIT_BLOG_SUCCESS";
export const EDIT_BLOG_ERROR = "EDIT_BLOG_ERROR";
export const DESTROY_BLOG_EDIT = "DESTROY_BLOG_EDIT";
export const DESTROY_ERROR_EDIT_B = "DESTROY_ERROR_EDIT_B";

export const GET_BLOG_LOADING = "GET_BLOG_LOADING";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_ERROR = "GET_BLOG_ERROR";
export const GET_BLOG_BY_SLUG_SUCCESS = "GET_BLOG_BY_SLUG_SUCCESS"
export const GET_BLOG_BY_CATEGORY_SUCCESS = "GET_BLOG_BY_CATEGORY_SUCCESS"

export const GET_BLOG_EDITOR_SUCCESS = "GET_BLOG_EDITOR_SUCCESS";
export const GET_BLOG_EDITOR_LOADING = "GET_BLOG_EDITOR_LOADING";
export const GET_BLOG_EDITOR_ERROR = "GET_BLOG_EDITOR_ERROR";

export const DELETE_BLOG_LOADING = "DELETE_BLOG_LOADING";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_ERROR = "DELETE_BLOG_ERROR";
export const DESTROY_BLOG_DELETE = "DESTROY_BLOG_DELETE";

export const ADD_CATEGORY_LOADING = "ADD_CATEGORY_LOADING";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR";
export const DESTROY_CATEGORY_ADD = "DESTROY_CATEGORY_ADD";
export const DESTROY_ERROR_ADD_C = "DESTROY_ERROR_ADD_C";

export const EDIT_CATEGORY_LOADING = "EDIT_CATEGORY_LOADING";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_ERROR = "EDIT_CATEGORY_ERROR";
export const DESTROY_CATEGORY_EDIT = "DESTROY_CATEGORY_EDIT";
export const DESTROY_ERROR_EDIT_C = "DESTROY_ERROR_EDIT_C";

export const GET_CATEGORY_LOADING = "GET_CATEGORY_LOADING";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";

export const DELETE_CATEGORY_LOADING = "DELETE_CATEGORY_LOADING";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";
export const DESTROY_CATEGORY_DELETE = "DESTROY_CATEGORY_DELETE";

export const ADD_HOME_SETTING_LOADING = "ADD_HOME_SETTING_LOADING";
export const ADD_HOME_SETTING_SUCCESS = "ADD_HOME_SETTING_SUCCESS";
export const ADD_HOME_SETTING_ERROR = "ADD_HOME_SETTING_ERROR";
export const DESTROY_HOME_SETTING_ADD = "DESTROY_HOME_SETTING_ADD";
export const DESTROY_ERROR_ADD_HOME = "DESTROY_ERROR_ADD_HOME";

export const EDIT_HOME_SETTING_LOADING = "EDIT_HOME_SETTING_LOADING";
export const EDIT_HOME_SETTING_SUCCESS = "EDIT_HOME_SETTING_SUCCESS";
export const EDIT_HOME_SETTING_ERROR = "EDIT_HOME_SETTING_ERROR";
export const DESTROY_HOME_SETTING_EDIT = "DESTROY_HOME_SETTING_EDIT";
export const DESTROY_ERROR_EDIT_HOME = "DESTROY_ERROR_EDIT_HOME";

export const GET_HOME_SETTING_LOADING = "GET_HOME_SETTING_LOADING";
export const GET_HOME_SETTING_SUCCESS = "GET_HOME_SETTING_SUCCESS";
export const GET_HOME_SETTING_ERROR = "GET_HOME_SETTING_ERROR";

export const DELETE_HOME_SETTING_LOADING = "DELETE_HOME_SETTING_LOADING";
export const DELETE_HOME_SETTING_SUCCESS = "DELETE_HOME_SETTING_SUCCESS";
export const DELETE_HOME_SETTING_ERROR = "DELETE_HOME_SETTING_ERROR";
export const DESTROY_HOME_SETTING_DELETE = "DESTROY_HOME_SETTING_DELETE";

export const ADD_AD_LOADING = "ADD_AD_LOADING";
export const ADD_AD_SUCCESS = "ADD_AD_SUCCESS";
export const ADD_AD_ERROR = "ADD_AD_ERROR";
export const DESTROY_AD_ADD = "DESTROY_AD_ADD";
export const DESTROY_ERROR_ADD_AD = "DESTROY_ERROR_ADD_AD";

export const EDIT_AD_LOADING = "EDIT_AD_LOADING";
export const EDIT_AD_SUCCESS = "EDIT_AD_SUCCESS";
export const EDIT_AD_ERROR = "EDIT_AD_ERROR";
export const DESTROY_AD_EDIT = "DESTROY_AD_EDIT";
export const DESTROY_ERROR_EDIT_AD = "DESTROY_ERROR_EDIT_AD";

export const GET_AD_LOADING = "GET_AD_LOADING";
export const GET_AD_SUCCESS = "GET_AD_SUCCESS";
export const GET_AD_ERROR = "GET_AD_ERROR";

export const DELETE_AD_LOADING = "DELETE_AD_LOADING";
export const DELETE_AD_SUCCESS = "DELETE_AD_SUCCESS";
export const DELETE_AD_ERROR = "DELETE_AD_ERROR";
export const DESTROY_AD_DELETE = "DESTROY_AD_DELETE";

export const ADD_EXCLUSIONS_LOADING = "ADD_EXCLUSIONS_LOADING";
export const ADD_EXCLUSIONS_SUCCESS = "ADD_EXCLUSIONS_SUCCESS";
export const ADD_EXCLUSIONS_ERROR = "ADD_EXCLUSIONS_ERROR";
export const DESTROY_EXCLUSIONS_ADD = "DESTROY_EXCLUSIONS_ADD";
export const DESTROY_ERROR_ADD_EXCLUSIONS = "DESTROY_ERROR_ADD_EXCLUSIONS";

export const EDIT_EXCLUSIONS_LOADING = "EDIT_EXCLUSIONS_LOADING";
export const EDIT_EXCLUSIONS_SUCCESS = "EDIT_EXCLUSIONS_SUCCESS";
export const EDIT_EXCLUSIONS_ERROR = "EDIT_EXCLUSIONS_ERROR";
export const DESTROY_EXCLUSIONS_EDIT = "DESTROY_EXCLUSIONS_EDIT";
export const DESTROY_ERROR_EDIT_EXCLUSIONS = "DESTROY_ERROR_EDIT_EXCLUSIONS";

export const GET_EXCLUSIONS_LOADING = "GET_EXCLUSIONS_LOADING";
export const GET_EXCLUSIONS_SUCCESS = "GET_EXCLUSIONS_SUCCESS";
export const GET_EXCLUSIONS_ERROR = "GET_EXCLUSIONS_ERROR";

export const DELETE_EXCLUSIONS_LOADING = "DELETE_EXCLUSIONS_LOADING";
export const DELETE_EXCLUSIONS_SUCCESS = "DELETE_EXCLUSIONS_SUCCESS";
export const DELETE_EXCLUSIONS_ERROR = "DELETE_EXCLUSIONS_ERROR";
export const DESTROY_EXCLUSIONS_DELETE = "DESTROY_EXCLUSIONS_DELETE";

export const ADD_INCLUSIONS_LOADING = "ADD_INCLUSIONS_LOADING";
export const ADD_INCLUSIONS_SUCCESS = "ADD_INCLUSIONS_SUCCESS";
export const ADD_INCLUSIONS_ERROR = "ADD_INCLUSIONS_ERROR";
export const DESTROY_INCLUSIONS_ADD = "DESTROY_INCLUSIONS_ADD";
export const DESTROY_ERROR_ADD_INCLUSIONS = "DESTROY_ERROR_ADD_INCLUSIONS";

export const EDIT_INCLUSIONS_LOADING = "EDIT_INCLUSIONS_LOADING";
export const EDIT_INCLUSIONS_SUCCESS = "EDIT_INCLUSIONS_SUCCESS";
export const EDIT_INCLUSIONS_ERROR = "EDIT_INCLUSIONS_ERROR";
export const DESTROY_INCLUSIONS_EDIT = "DESTROY_INCLUSIONS_EDIT";
export const DESTROY_ERROR_EDIT_INCLUSIONS = "DESTROY_ERROR_EDIT_INCLUSIONS";

export const GET_INCLUSIONS_LOADING = "GET_INCLUSIONS_LOADING";
export const GET_INCLUSIONS_SUCCESS = "GET_INCLUSIONS_SUCCESS";
export const GET_INCLUSIONS_ERROR = "GET_INCLUSIONS_ERROR";

export const DELETE_INCLUSIONS_LOADING = "DELETE_INCLUSIONS_LOADING";
export const DELETE_INCLUSIONS_SUCCESS = "DELETE_INCLUSIONS_SUCCESS";
export const DELETE_INCLUSIONS_ERROR = "DELETE_INCLUSIONS_ERROR";
export const DESTROY_INCLUSIONS_DELETE = "DESTROY_INCLUSIONS_DELETE";

export const GET_CITY_PACKAGE_LOADING = "GET_CITY_PACKAGE_LOADING";
export const GET_CITY_PACKAGE_SUCCESS = "GET_CITY_PACKAGE_SUCCESS";
export const GET_CITY_PACKAGE_ERROR = "GET_CITY_PACKAGE_ERROR";

export const GET_ALL_CITY_PACKAGE_LOADING = "GET_ALL_CITY_PACKAGE_LOADING";
export const GET_ALL_CITY_PACKAGE_SUCCESS = "GET_ALL_CITY_PACKAGE_SUCCESS";
export const GET_ALL_CITY_PACKAGE_ERROR = "GET_ALL_CITY_PACKAGE_ERROR";

export const GET_CITY_BLOG_LOADING = "GET_CITY_BLOG_LOADING";
export const GET_CITY_BLOG_SUCCESS = "GET_CITY_BLOG_SUCCESS";
export const GET_CITY_BLOG_ERROR = "GET_CITY_BLOG_ERROR";

export const GET_ALL_CITY_HEAD_LOADING = "GET_ALL_CITY_HEAD_LOADING";
export const GET_ALL_CITY_HEAD_SUCCESS = "GET_ALL_CITY_HEAD_SUCCESS";
export const GET_ALL_CITY_HEAD_ERROR = "GET_ALL_CITY_HEAD_ERROR";

export const ADD_LOCATION_LOADING = "ADD_LOCATION_LOADING";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_ERROR = "ADD_LOCATION_ERROR";
export const DESTROY_LOCATION_ADD = "DESTROY_LOCATION_ADD";
export const DESTROY_ERROR_ADD_LOCATION = "DESTROY_ERROR_ADD_LOCATION";

export const EDIT_LOCATION_LOADING = "EDIT_LOCATION_LOADING";
export const EDIT_LOCATION_SUCCESS = "EDIT_LOCATION_SUCCESS";
export const EDIT_LOCATION_ERROR = "EDIT_LOCATION_ERROR";
export const DESTROY_LOCATION_EDIT = "DESTROY_LOCATION_EDIT";
export const DESTROY_ERROR_EDIT_LOCATION = "DESTROY_ERROR_EDIT_LOCATION";

export const GET_LOCATION_LOADING = "GET_LOCATION_LOADING";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_ERROR = "GET_LOCATION_ERROR";

export const DELETE_LOCATION_LOADING = "DELETE_LOCATION_LOADING";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR";
export const DESTROY_LOCATION_DELETE = "DESTROY_LOCATION_DELETE";


export const ADD_COMPANY_LOADING = "ADD_COMPANY_LOADING";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_ERROR = "ADD_COMPANY_ERROR";
export const DESTROY_COMPANY_ADD = "DESTROY_COMPANY_ADD";
export const DESTROY_ERROR_ADD_COMPANY = "DESTROY_ERROR_ADD_COMPANY";

export const EDIT_COMPANY_LOADING = "EDIT_COMPANY_LOADING";
export const EDIT_COMPANY_SUCCESS = "EDIT_COMPANY_SUCCESS";
export const EDIT_COMPANY_ERROR = "EDIT_COMPANY_ERROR";
export const DESTROY_COMPANY_EDIT = "DESTROY_COMPANY_EDIT";
export const DESTROY_ERROR_EDIT_COMPANY = "DESTROY_ERROR_EDIT_COMPANY";

export const GET_COMPANY_LOADING = "GET_COMPANY_LOADING";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";

export const DELETE_COMPANY_LOADING = "DELETE_COMPANY_LOADING";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_ERROR = "DELETE_COMPANY_ERROR";
export const DESTROY_COMPANY_DELETE = "DESTROY_COMPANY_DELETE";

export const DELETE_CITY_IMAGES_LOADING = "DELETE_CITY_IMAGES_LOADING";
export const DELETE_CITY_IMAGES_SUCCESS = "DELETE_CITY_IMAGES_SUCCESS";
export const DELETE_CITY_IMAGES_ERROR = "DELETE_CITY_IMAGES_ERROR";

export const ADD_PACKAGE_CATEGORY_LOADING = "ADD_PACKAGE_CATEGORY_LOADING";
export const ADD_PACKAGE_CATEGORY_SUCCESS = "ADD_PACKAGE_CATEGORY_SUCCESS";
export const ADD_PACKAGE_CATEGORY_ERROR = "ADD_PACKAGE_CATEGORY_ERROR"
export const DESTROY_PACKAGE_CATEGORY_ADD = "DESTROY_PACKAGE_CATEGORY_ADD";
export const DESTROY_PACKAGE_ERROR_ADD_C = "DESTROY_PACKAGE_ERROR_ADD_C";

export const EDIT_PACKAGE_CATEGORY_LOADING = "EDIT_PACKAGE_CATEGORY_LOADING";
export const EDIT_PACKAGE_CATEGORY_SUCCESS = "EDIT_PACKAGE-CATEGORY_SUCCESS";
export const EDIT_PACKAGE_CATEGORY_ERROR = "EDIT_PACKAGE_CATEGORY_ERROR";
export const DESTROY_PACKAGE_CATEGORY_EDIT = "DESTROY_PACKAGE_CATEGORY_EDIT";
export const DESTROY_PACKAGE_ERROR_EDIT_C = "DESTROY_PACKAGE_ERROR_EDIT_C";

export const GET_PACKAGE_CATEGORY_LOADING = "GET_PACKAGE_CATEGORY_LOADING";
export const GET_PACKAGE_CATEGORY_SUCCESS = "GET_PACKAGE_CATEGORY_SUCCESS";
export const GET_PACKAGE_CATEGORY_ERROR = "GET_PACKAGE_CATEGORY_ERROR";

export const DELETE_PACKAGE_CATEGORY_LOADING = "DELETE_PACKAGE_CATEGORY_LOADING";
export const DELETE_PACKAGE_CATEGORY_SUCCESS = "DELETE_PACKAGE_CATEGORY_SUCCESS";
export const DELETE_PACKAGE_CATEGORY_ERROR = "DELETE_PACKAGE_CATEGORY_ERROR";
export const DESTROY_PACKAGE_CATEGORY_DELETE = "DESTROY_PACKAGE_CATEGORY_DELETE";

export const ADD_VEHICLE_TYPE_CATEGORY_LOADING = "ADD_VEHICLE_TYPE_CATEGORY_LOADING";
export const ADD_VEHICLE_TYPE_CATEGORY_SUCCESS = "ADD_VEHICLE_TYPE_CATEGORY_SUCCESS";
export const ADD_VEHICLE_TYPE_CATEGORY_ERROR = "ADD_VEHICLE_TYPE_CATEGORY_ERROR"
export const DESTROY_VEHICLE_TYPE_CATEGORY_ADD = "DESTROY_VEHICLE_TYPE_CATEGORY_ADD";
export const DESTROY_VEHICLE_TYPE_ERROR_ADD_C = "DESTROY_VEHICLE_TYPE_ERROR_ADD_C";

export const EDIT_VEHICLE_TYPE_CATEGORY_LOADING = "EDIT_VEHICLE_TYPE_CATEGORY_LOADING";
export const EDIT_VEHICLE_TYPE_CATEGORY_SUCCESS = "EDIT_VEHICLE_TYPE_CATEGORY_SUCCESS";
export const EDIT_VEHICLE_TYPE_CATEGORY_ERROR = "EDIT_VEHICLE_TYPE_CATEGORY_ERROR";
export const DESTROY_VEHICLE_TYPE_CATEGORY_EDIT = "DESTROY_VEHICLE_TYPE_CATEGORY_EDIT";
export const DESTROY_VEHICLE_TYPE_ERROR_EDIT_C = "DESTROY_VEHICLE_TYPE_ERROR_EDIT_C";

export const GET_VEHICLE_TYPE_CATEGORY_LOADING = "GET_VEHICLE_TYPE_CATEGORY_LOADING";
export const GET_VEHICLE_TYPE_CATEGORY_SUCCESS = "GET_VEHICLE_TYPE_CATEGORY_SUCCESS";
export const GET_VEHICLE_TYPE_CATEGORY_ERROR = "GET_VEHICLE_TYPE_CATEGORY_ERROR";

export const DELETE_VEHICLE_TYPE_CATEGORY_LOADING = "DELETE_VEHICLE_TYPE_CATEGORY_LOADING";
export const DELETE_VEHICLE_TYPE_CATEGORY_SUCCESS = "DELETE_VEHICLE_TYPE_CATEGORY_SUCCESS";
export const DELETE_VEHICLE_TYPE_CATEGORY_ERROR = "DELETE_VEHICLE_TYPE_CATEGORY_ERROR";
export const DESTROY_VEHICLE_TYPE_CATEGORY_DELETE = "DESTROY_VEHICLE_TYPE_CATEGORY_DELETE";

export const ADD_TRANSPORT_CATEGORY_LOADING = "ADD_TRANSPORT_CATEGORY_LOADING";
export const ADD_TRANSPORT_CATEGORY_SUCCESS = "ADD_TRANSPORT_CATEGORY_SUCCESS";
export const ADD_TRANSPORT_CATEGORY_ERROR = "ADD_TRANSPORT_CATEGORY_ERROR"
export const DESTROY_TRANSPORT_CATEGORY_ADD = "DESTROY_TRANSPORT_CATEGORY_ADD";
export const DESTROY_TRANSPORT_ERROR_ADD_C = "DESTROY_TRANSPORT_ERROR_ADD_C";

export const EDIT_TRANSPORT_CATEGORY_LOADING = "EDIT_TRANSPORT_CATEGORY_LOADING";
export const EDIT_TRANSPORT_CATEGORY_SUCCESS = "EDIT_TRANSPORT_CATEGORY_SUCCESS";
export const EDIT_TRANSPORT_CATEGORY_ERROR = "EDIT_TRANSPORT_CATEGORY_ERROR";
export const DESTROY_TRANSPORT_CATEGORY_EDIT = "DESTROY_TRANSPORT_CATEGORY_EDIT";
export const DESTROY_TRANSPORT_ERROR_EDIT_C = "DESTROY_TRANSPORT_ERROR_EDIT_C";

export const GET_TRANSPORT_CATEGORY_LOADING = "GET_TRANSPORT_CATEGORY_LOADING";
export const GET_TRANSPORT_CATEGORY_SUCCESS = "GET_TRANSPORT_CATEGORY_SUCCESS";
export const GET_TRANSPORT_CATEGORY_ERROR = "GET_TRANSPORT_CATEGORY_ERROR";

export const DELETE_TRANSPORT_CATEGORY_LOADING = "DELETE_TRANSPORT_CATEGORY_LOADING";
export const DELETE_TRANSPORT_CATEGORY_SUCCESS = "DELETE_TRANSPORT_CATEGORY_SUCCESS";
export const DELETE_TRANSPORT_CATEGORY_ERROR = "DELETE_TRANSPORT_CATEGORY_ERROR";
export const DESTROY_TRANSPORT_CATEGORY_DELETE = "DESTROY_TRANSPORT_CATEGORY_DELETE";

export const ADD_GALLERY_LOADING = "ADD_GALLERY_LOADING";
export const ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS";
export const ADD_GALLERY_ERROR = "ADD_GALLERY_ERROR"
export const DESTROY_GALLERY_ADD = "DESTROY_GALLERY_ADD";
export const DESTROY_GALLERY_ERROR_ADD_C = "DESTROY_GALLERY_ERROR_ADD_C";

export const EDIT_GALLERY_LOADING = "EDIT_GALLERY_LOADING";
export const EDIT_GALLERY_SUCCESS = "EDIT_GALLERY_SUCCESS";
export const EDIT_GALLERY_ERROR = "EDIT_GALLERY_ERROR";
export const DESTROY_GALLERY_EDIT = "DESTROY_GALLERY_EDIT";
export const DESTROY_GALLERY_ERROR_EDIT_C = "DESTROY_GALLERY_ERROR_EDIT_C";

export const GET_GALLERY_LOADING = "GET_GALLERY_LOADING";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FILTER_SUCCESS = "GET_GALLERY_FILTER_SUCCESS";
export const GET_PARTICULAR_GALLERY_FILTER_SUCCESS = "GET_PARTICULAR_GALLERY_FILTER_SUCCESS";
export const GET_GALLERY_ERROR = "GET_GALLERY_ERROR";

export const DELETE_GALLERY_LOADING = "DELETE_GALLERY_LOADING";
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS";
export const DELETE_GALLERY_ERROR = "DELETE_GALLERY_ERROR";
export const DESTROY_GALLERY_DELETE = "DESTROY_GALLERY_DELETE";

export const ADD_FAVPURITE_PACKAGE_LOADING = "ADD_FAVPURITE_PACKAGE_LOADING";
export const ADD_FAVPURITE_PACKAGE_SUCCESS = "ADD_FAVPURITE_PACKAGE_SUCCESS";
export const ADD_FAVPURITE_PACKAGE_ERROR = "ADD_FAVPURITE_PACKAGE_ERROR"
export const DESTROY_FAVPURITE_PACKAGE_ADD = "DESTROY_FAVPURITE_PACKAGE_ADD";
export const DESTROY_FAVPURITE_PACKAGE_ERROR_ADD_C = "DESTROY_FAVPURITE_PACKAGE_ERROR_ADD_C";

export const GET_FAVPURITE_PACKAGE_LOADING = "GET_FAVPURITE_PACKAGE_LOADING";
export const GET_FAVPURITE_PACKAGE_SUCCESS = "GET_FAVPURITE_PACKAGE_SUCCESS";
export const GET_FAVPURITE_PACKAGE_FILTER_SUCCESS = "GET_FAVPURITE_PACKAGE_FILTER_SUCCESS";
export const GET_PARTICULAR_FAVPURITE_PACKAGE_FILTER_SUCCESS = "GET_PARTICULAR_FAVPURITE_PACKAGE_FILTER_SUCCESS";
export const GET_FAVPURITE_PACKAGE_ERROR = "GET_FAVPURITE_PACKAGE_ERROR";

export const DELETE_FAVPURITE_PACKAGE_LOADING = "DELETE_FAVPURITE_PACKAGE_LOADING";
export const DELETE_FAVPURITE_PACKAGE_SUCCESS = "DELETE_FAVPURITE_PACKAGE_SUCCESS";
export const DELETE_FAVPURITE_PACKAGE_ERROR = "DELETE_FAVPURITE_PACKAGE_ERROR";
export const DESTROY_FAVPURITE_PACKAGE_DELETE = "DESTROY_FAVPURITE_PACKAGE_DELETE";

export const ADD_BOOKING_LOADING = "ADD_BOOKING_LOADING";
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS";
export const ADD_BOOKING_ERROR = "ADD_BOOKING_ERROR"
export const DESTROY_BOOKING_ADD = "DESTROY_BOOKING_ADD";
export const DESTROY_BOOKING_ERROR_ADD_C = "DESTROY_BOOKING_ERROR_ADD_C";

// export const EDIT_BOOKING_LOADING = "EDIT_BOOKING_LOADING";
// export const EDIT_BOOKING_SUCCESS = "EDIT_BOOKING_SUCCESS";
// export const EDIT_BOOKING_ERROR = "EDIT_BOOKING_ERROR";
// export const DESTROY_BOOKING_EDIT = "DESTROY_BOOKING_EDIT";
// export const DESTROY_BOOKING_ERROR_EDIT_C = "DESTROY_BOOKING_ERROR_EDIT_C";

export const GET_BOOKING_LOADING = "GET_BOOKING_LOADING";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_ERROR = "GET_BOOKING_ERROR";

export const DELETE_BOOKING_LOADING = "DELETE_BOOKING_LOADING";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_ERROR = "DELETE_BOOKING_ERROR";
export const DESTROY_BOOKING_DELETE = "DESTROY_BOOKING_DELETE";

