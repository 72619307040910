import {
  ADD_BLOG_ERROR,
  ADD_BLOG_LOADING,
  ADD_BLOG_SUCCESS,
  ADD_CATEGORY_ERROR,
  ADD_CATEGORY_LOADING,
  ADD_CATEGORY_SUCCESS,
  DELETE_BLOG_ERROR,
  DELETE_BLOG_LOADING,
  DELETE_BLOG_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_LOADING,
  DELETE_CATEGORY_SUCCESS,
  DESTROY_BLOG_ADD,
  DESTROY_BLOG_EDIT,
  DESTROY_CATEGORY_ADD,
  DESTROY_CATEGORY_EDIT,
  DESTROY_ERROR_ADD_B,
  DESTROY_ERROR_ADD_C,
  DESTROY_ERROR_EDIT_B,
  DESTROY_ERROR_EDIT_C,
  EDIT_BLOG_ERROR,
  EDIT_BLOG_LOADING,
  EDIT_BLOG_SUCCESS,
  EDIT_CATEGORY_ERROR,
  EDIT_CATEGORY_LOADING,
  EDIT_CATEGORY_SUCCESS,
  GET_BLOG_BY_CATEGORY_SUCCESS,
  GET_BLOG_BY_SLUG_SUCCESS,
  GET_BLOG_EDITOR_ERROR,
  GET_BLOG_EDITOR_LOADING,
  GET_BLOG_EDITOR_SUCCESS,
  GET_BLOG_ERROR,
  GET_BLOG_LOADING,
  GET_BLOG_SUCCESS,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_LOADING,
  GET_CATEGORY_SUCCESS,
  GET_CITY_BLOG_ERROR,
  GET_CITY_BLOG_LOADING,
  GET_CITY_BLOG_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  blogs: [],
  filteredBlogs: [],
  filterEditor: [],
  slugBlog: [],
  categoryBlog: [],
};
const initialStateCategory = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  category: [],
};

export function addBlog(state = initialState, action) {
  switch (action.type) {
    case ADD_BLOG_LOADING:
      return { ...state, isLoading: true };
    case ADD_BLOG_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_BLOG_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_BLOG_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_ERROR_ADD_B:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function addCategory(state = initialStateCategory, action) {
  switch (action.type) {
    case ADD_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case ADD_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_CATEGORY_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_ERROR_ADD_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getBlog(state = initialState, action) {
  switch (action.type) {
    case GET_BLOG_LOADING:
      return { ...state, isLoading: true };
    case GET_BLOG_SUCCESS:
      return { ...state, isLoading: false, blogs: action.payload };
    case GET_BLOG_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case GET_BLOG_BY_SLUG_SUCCESS:
      return { ...state, isLoading: false, slugBlog: action.payload };
    case GET_BLOG_BY_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, categoryBlog: action.payload };
    default:
      return state;
  }
}

export function filterEditorPick(state = initialState, action) {
  switch (action.type) {
    case GET_BLOG_EDITOR_LOADING:
      return { ...state, isLoading: true };
    case GET_BLOG_EDITOR_SUCCESS:
      return { ...state, isLoading: false, filterEditor: action.payload };
    case GET_BLOG_EDITOR_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    default:
      return state;
  }
}

export function getCategory(state = initialStateCategory, action) {
  switch (action.type) {
    case GET_CATEGORY_LOADING:
      return { ...state, isLoading: true, category: [] };
    case GET_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, category: action.payload };
    case GET_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        category: [],
        isError: action.payload,
      };
    default:
      return state;
  }
}

export function editBlog(state = initialState, action) {
  switch (action.type) {
    case EDIT_BLOG_LOADING:
      return { ...state, isLoading: true };
    case EDIT_BLOG_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_BLOG_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_BLOG_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_ERROR_EDIT_B:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function editCategory(state = initialStateCategory, action) {
  switch (action.type) {
    case EDIT_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case EDIT_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_CATEGORY_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_ERROR_EDIT_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function deleteBlog(state = initialState, action) {
  switch (action.type) {
    case DELETE_BLOG_LOADING:
      return { ...state, isLoading: true };
    case DELETE_BLOG_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_BLOG_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_B":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}

export function deleteCategory(state = initialStateCategory, action) {
  switch (action.type) {
    case DELETE_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case DELETE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_C":
      return { ...state, isError: action.payload, isDeleted: action.payload };
    default:
      return state;
  }
}

export function cityBlogFilter(state = initialState, action) {
  switch (action.type) {
    case GET_CITY_BLOG_LOADING:
      return { ...state, isLoading: true };
    case GET_CITY_BLOG_SUCCESS:
      return { ...state, isLoading: false, filteredBlogs: action.payload };
    case GET_CITY_BLOG_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    default:
      return state;
  }
}
