import { FiberManualRecord, Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { getCities } from "../../../redux/actions/package";
import OwlCarousel from "react-owl-carousel";
import { Button, LinearProgress } from "@material-ui/core";
import { filterGallery, getGallery } from "../../../redux/actions/gallery";
import { Alert } from "@material-ui/lab";
import { baseUrlImage, imagePath } from "../../../redux/url";
import { useHistory } from "react-router";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

function GalleryMain() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cities } = useSelector((state) => state.getCity);
  const {
    isLoading,
    isError,
    filterGallery: galleryData,
    gallery,
  } = useSelector((state) => state.getGallery);
  const [menuOption, setMenuOption] = useState(false);
  const [destination, setDestination] = useState("");
  const [showDestination, setShowDestination] = useState(false);
  const [count, setCount] = useState(4);
  const [autoPlay, setAutoPlay] = useState({});
  const autoPlayFunction = (name, value) => {
    setAutoPlay({
      ...autoPlay,
      [name]: value,
    });
  };
  const searchGallery = (e) => {
    e.preventDefault();
    setShowDestination(true);
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator
        {...props}
        style={{ cursor: "pointer" }}
        onClick={() => searchGallery()}
      >
        <Search />
      </components.DropdownIndicator>
    );
  };
  const loadMoreFunction = () => {
    dispatch(
      filterGallery({
        skip: 0,
        limit: count + 4,
      })
    );
    setCount(count + 4);
    setDestination("");
    setShowDestination(false);
  };
  useEffect(() => {
    setShowDestination(false);
    dispatch(getCities());
    dispatch(
      filterGallery({
        skip: 0,
        limit: count,
      })
    );
    dispatch(getGallery());
  }, []);
  return (
    <Container
      style={{ marginTop: "100px", maxWidth: "1300px" }}
      className="gallery"
    >
      <Row>
        <Col lg="12" md="12" sm="12" xs="12" className="head">
          <h3 onClick={() => setShowDestination(false)}>Gallery</h3>
          <form onSubmit={searchGallery}>
            <Select
              name="cities"
              className="form-select"
              menuIsOpen={menuOption}
              placeholder={"Destination"}
              components={{ DropdownIndicator }}
              value={destination}
              onInputChange={(inp) => {
                if (inp !== "") {
                  return setMenuOption(true);
                } else {
                  return setMenuOption(false);
                }
              }}
              onChange={(e, j) => {
                return setDestination(e);
              }}
              options={
                cities.length > 0 &&
                cities.map((e, i) => {
                  return {
                    value: e.cityId,
                    label: e.cityName,
                  };
                })
              }
            />
          </form>
        </Col>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <Alert severity="error">{isError}</Alert>
        ) : showDestination ? (
          gallery.length > 0 &&
          gallery.map((e, i) => {
            return (
              e.cityName === destination.value && (
                <Col
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  className="image-section"
                  onMouseOver={() => autoPlayFunction(`a${i}`, true)}
                  onMouseOut={() => autoPlayFunction(`a${i}`, false)}
                  onClick={() => history.push(`/gallery/${e.galleryId}`)}
                >
                  <div className="top">
                    <div className="city-name">
                      {" "}
                      <FiberManualRecord
                        style={{ color: "#D03000", marginBottom: "10px" }}
                      />
                      {cities.length > 0 &&
                        cities.map((elm, ind) => {
                          if (elm.cityId === e.cityName) {
                            return elm.cityName;
                          }
                        })}
                    </div>
                    <h3>{e.tittle}</h3>
                  </div>
                  <OwlCarousel
                    className="owl-theme"
                    margin={10}
                    loop={false}
                    autoplay={autoPlay[`a${i}`]}
                    autoplayTimeout={800}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      450: {
                        items: 1,
                      },
                      600: {
                        items: 2,
                      },
                      1000: {
                        items: 3,
                      },
                    }}
                  >
                    {e.galleryImages.map((elm, ind) => {
                      return (
                        <div className="item">
                          <img
                            src={baseUrlImage + imagePath + elm.imageName}
                            alt={"item" + i}
                          />
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </Col>
              )
            );
          })
        ) : (
          galleryData.length > 0 &&
          galleryData.map((e, i) => {
            return (
              <Col
                lg="6"
                md="6"
                sm="12"
                xs="12"
                className="image-section"
                onMouseOver={() => autoPlayFunction(`a${i}`, true)}
                onMouseOut={() => autoPlayFunction(`a${i}`, false)}
                onClick={() => history.push(`/gallery/${e.galleryId}`)}
              >
                <div className="top">
                  <div className="city-name">
                    {" "}
                    <FiberManualRecord
                      style={{ color: "#D03000", marginBottom: "10px" }}
                    />
                    {cities.length > 0 &&
                      cities.map((elm, ind) => {
                        if (elm.cityId === e.cityName) {
                          return elm.cityName;
                        }
                      })}
                  </div>
                  <h3>{e.tittle}</h3>
                </div>
                <OwlCarousel
                  className="owl-theme"
                  margin={10}
                  loop={false}
                  autoplay={autoPlay[`a${i}`]}
                  autoplayTimeout={800}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    450: {
                      items: 1,
                    },
                    600: {
                      items: 2,
                    },
                    1000: {
                      items: 3,
                    },
                  }}
                >
                  {e.galleryImages.map((elm, ind) => {
                    return (
                      <div className="item">
                        <img
                          src={baseUrlImage + imagePath + elm.imageName}
                          alt={"item" + i}
                        />
                      </div>
                    );
                  })}
                </OwlCarousel>
              </Col>
            );
          })
        )}
        <Col lg="12" sm="12" md="12" xs="12" className="load-more">
          <Button
            disabled={gallery.length === galleryData.length}
            onClick={loadMoreFunction}
          >
            Load More
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default GalleryMain;
