import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { InputBase, makeStyles, MenuItem, Select, Snackbar, withStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  addTransport,
  destroyAddTransport,
  destroyAddTransportError,
} from "../../../redux/actions/transport";
import { getCities } from "../../../redux/actions/package";
import { addGallery, destroyAddGallery, destroyAddGalleryError } from "../../../redux/actions/gallery";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function AddGallery({ modal, setModal, setopen }) {
  const classes = useStyles();
  const [city, setCity] = React.useState("");
  const handleChange = (event) => {
    setCity(event.target.value);
  };
  const [name, setName] = useState("");
  const [edit, setEdit] = useState("");
  const [images, setImages] = useState([])
  const [description, setDescription] = useState("")
  const [done, setDone] = useState(false);
  const { isLoading, isAdded, isError } = useSelector(
    (state) => state.addGallery
  );
  const { cities } = useSelector(
    (state) => state.getCity
  );
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const formSubmitted = (e) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('cityName',city)
    formData.append('tittle',name)
    formData.append('cityDescription',description)
    formData.append('userId',localStorage.getItem("userId"))
    for(var i=0; i<images.length; i++){
        formData.append('galleryImage',images[i])
    }
    dispatch(
      addGallery(formData)
    ).then((result) => {});
    // closeModal()
    setDone(isAdded);
  };
  const closeModal = () => {
    setModal(false);
    setopen(true);
    setCity("")
    setName("")
    setDescription("")
    setImages([])
  };
  useEffect(() => {
    dispatch(getCities())
    if (isAdded !== undefined && isAdded !== "") {
      closeModal();
    }
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setOpen(true);
    }
    return () => {
      return (
        dispatch(destroyAddGallery()), dispatch(destroyAddGalleryError())
      );
    };
  }, [isAdded, isError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody>
          <Container>
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Add Type"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {edit && (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="error">
                          {edit}
                        </Alert>
                      </Snackbar>
                    )}
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                City
                              </label>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                name="cityName"
                                value={city}
                                onChange={handleChange}
                                input={<BootstrapInput />}
                              >
                                {cities.length > 0 &&
                                  cities.map((e, i) => {
                                    return (
                                      <MenuItem value={e.cityId}>
                                        {e.cityName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Title
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Title"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Description
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Description"
                                type="textarea"
                                rows={"5"}
                                name="name"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Images
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-first-name"
                        placeholder="cut off date"
                        type="file"
                        name="bannerImage"
                        onChange={(e)=>setImages(e.target.files)}
                        accept="image/*"
                        multiple
                      />
                    </FormGroup>
                  </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Add
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddGallery;
