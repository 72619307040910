import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../redux/url";

function GetLengthBlog(props) {
  const [length, setLength] = useState(0);
  useEffect(() => {
    axios
      .get(`${baseUrl}/cityBlogFilter?keyword=${props.name}`)
      .then((result) => {
        setLength(result.data.length || 0);
      });
  }, []);
  return (
    <>
      <> {length > 1 ? <>{length} blogs</> : <>{length} blog</>}</>
    </>
  );
}

export default GetLengthBlog;
