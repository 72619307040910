import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import AdminFooter from "../common/footer/AdminFooter";
import AdminNavbar from "../common/navbar/AdminNavbar";
import Sidebar from "../common/sidebar/Sidebar";
import "../assets/css/style.css";

const Layout = ({ children }) => (
  <>
    <Sidebar
      logo={{
        innerLink: "/admin/",
        imgSrc: require("../assets/img/brand/nextjs_argon_black.png"),
        imgAlt: "...",
      }}
    />
    <div className="main-content">
      <AdminNavbar brandText={"Hello"} />
      {children}
    </div>
  </>
);

const AdminLayout = ({ component: Component, ...rest }) => {
  const history = useHistory();
  useEffect(() => {}, []);
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default AdminLayout;
