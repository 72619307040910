import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import { Alert, Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import UserNavbar from "../../../common/navbar/UserNavbar";
import { getBlogByCategory, getBlogs } from "../../../redux/actions/blog";
import { baseUrlImage, imagePath } from "../../../redux/url";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});
function AllBlogs(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = new URLSearchParams(props.location.search);
  const { isLoading, isError, blogs, categoryBlog } = useSelector(
    (state) => state.getBlog
  );
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(9);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const paginationHandler = (event, value) => {
    if (value !== page) {
      setPage(value);
      dispatch(
        getBlogs({
          skip: value * count - count,
          limit: count,
        })
      );
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getBlogs());
    dispatch(
      getBlogByCategory({
        category: query.get("category"),
      })
    );
  }, []);
  return (
    <>
      <Container className="blog-main-page">
        <Row className="blog-main-page-row">
          <Col lg="12" className="blog-main-page-intro">
            <Carousel activeIndex={index} onSelect={handleSelect} fade>
              {query.get("category") !== null ? (
                isLoading ? (
                  <></>
                ) : isError ? (
                  <>
                    <Alert severity="error">{isError}</Alert>
                  </>
                ) : (
                  categoryBlog.length > 0 &&
                  categoryBlog.map((e, i) => {
                    if (e.isFeatured === true) {
                      return (
                        <Carousel.Item className="featured-crousel">
                          <Row>
                            <Col
                              lg="6"
                              md="6"
                              sm="12"
                              className="intro-content"
                            >
                              <div>
                                <span className="span-1">Featured</span>
                                <span className="span-2">
                                  {query.get("category")}
                                </span>
                              </div>
                              <div>
                                <Link
                                  to={`/blog/${e.slug}?category=${e.blogCategory[0]?.value}`}
                                >
                                  <h5>
                                    {e.blogTitle.length > 40 ? (
                                      <>{e.blogTitle.slice(0, 40)}...</>
                                    ) : (
                                      <>{e.blogTitle}</>
                                    )}
                                  </h5>
                                </Link>

                                <p>
                                  {e.blogIntroduction?.length > 200 ? (
                                    <>{e.blogIntroduction.slice(0, 200)}...</>
                                  ) : (
                                    <>{e.blogIntroduction}</>
                                  )}
                                </p>
                              </div>
                              <Button
                                onClick={() =>
                                  history.push(
                                    `/blog/${e.slug}?category=${e.blogCategory[0]?.value}`
                                  )
                                }
                              >
                                Read More <ArrowRight />
                              </Button>
                            </Col>
                            <Col lg="6" md="6" sm="6" className="intro-image">
                              {e.blogImages?.length > 0 && (
                                <img
                                  src={
                                    baseUrlImage +
                                    imagePath +
                                    e.blogImages[0].imageName
                                  }
                                  alt="blog-main"
                                />
                              )}
                            </Col>
                          </Row>
                        </Carousel.Item>
                      );
                    }
                  })
                )
              ) : isLoading ? (
                <></>
              ) : isError ? (
                <>
                  <Alert severity="error">{isError}</Alert>
                </>
              ) : (
                blogs.length > 0 &&
                blogs.map((e, i) => {
                  if (e.isFeatured === true) {
                    return (
                      <Carousel.Item className="featured-crousel">
                        <Row>
                          <Col lg="6" md="6" sm="12" className="intro-content">
                            <div>
                              <span className="span-1">Featured</span>
                              <span className="span-2">
                                {e.blogCategory[0]?.label}
                              </span>
                            </div>
                            <div>
                              <Link
                                to={`/blog/${e.slug}?category=${e.blogCategory[0]?.value}`}
                              >
                                <h5>
                                  {e.blogTitle.length > 40 ? (
                                    <>{e.blogTitle.slice(0, 40)}...</>
                                  ) : (
                                    <>{e.blogTitle}</>
                                  )}
                                </h5>
                              </Link>

                              <p>
                                {e.blogIntroduction?.length > 200 ? (
                                  <>{e.blogIntroduction.slice(0, 200)}...</>
                                ) : (
                                  <>{e.blogIntroduction}</>
                                )}
                              </p>
                            </div>
                            <Button
                              onClick={() =>
                                history.push(
                                  `/blog/${e.slug}?category=${e.blogCategory[0]?.value}`
                                )
                              }
                            >
                              Read More <ArrowRight />
                            </Button>
                          </Col>
                          <Col lg="6" md="6" sm="6" className="intro-image">
                            {e.blogImages?.length > 0 && (
                              <img
                                src={
                                  baseUrlImage +
                                  imagePath +
                                  e.blogImages[0].imageName
                                }
                                alt="blog-main"
                              />
                            )}
                          </Col>
                        </Row>
                      </Carousel.Item>
                    );
                  }
                })
              )}
            </Carousel>
          </Col>
        </Row>
        <Row className="blog-latest-article">
          <Col lg="12" className="blog-latest-head">
            <h6>Blogs</h6>
          </Col>
          <Col lg="12">
            <Row>
              {query.get("category") !== null ? (
                isLoading ? (
                  <>
                    <LinearProgress />
                  </>
                ) : isError ? (
                  <></>
                ) : (
                  categoryBlog.length > 0 &&
                  categoryBlog.map((e, i) => {
                    return (
                      <Col
                        lg="4"
                        md="4"
                        sm="6"
                        xs="12"
                        className="blog-latest-card"
                      >
                        <Card className={classes.root}>
                          <CardActionArea
                            onClick={() =>
                              history.push(
                                `/blog/${e.slug}?category=${query.get(
                                  "category"
                                )}`
                              )
                            }
                          >
                            {e.blogImages?.length > 0 && (
                              <CardMedia
                                className={classes.media}
                                image={
                                  baseUrlImage +
                                  imagePath +
                                  e.blogImages[0].imageName
                                }
                                title={e.blogTitle}
                              />
                            )}

                            <CardContent>
                              <div>
                                <span className="span-1">
                                  {query.get("category")}
                                </span>
                                <span className="span-2">{e.cityName}</span>
                              </div>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                {e.blogTitle}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Col>
                    );
                  })
                )
              ) : isLoading ? (
                <>
                  <LinearProgress />
                </>
              ) : isError ? (
                <></>
              ) : (
                blogs.length > 0 &&
                blogs.map((e, i) => {
                  return (
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xs="12"
                      className="blog-latest-card"
                    >
                      <Card className={classes.root}>
                        <CardActionArea
                          onClick={() =>
                            history.push(
                              `/blog/${e.slug}?category=${e.blogCategory[0]?.value}`
                            )
                          }
                        >
                          {e.blogImages?.length > 0 && (
                            <CardMedia
                              className={classes.media}
                              image={
                                baseUrlImage +
                                imagePath +
                                e.blogImages[0].imageName
                              }
                              title={e.blogTitle}
                            />
                          )}
                          <CardContent>
                            <div>
                              <span className="span-1">
                                {e.blogCategory[1]
                                  ? e.blogCategory[1].label
                                  : e.blogCategory[0].label}
                              </span>
                              <span className="span-2">{e.cityName}</span>
                            </div>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              {e.blogTitle}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
          </Col>
          <Col lg="12" style={{ marginBottom: "50px" }}>
            <Pagination
              count={Math.ceil(blogs.length / count)}
              className="editor-pagination"
              page={page}
              onChange={paginationHandler}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AllBlogs;
