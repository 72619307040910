import { ADD_COMPANY_ERROR, ADD_COMPANY_LOADING, ADD_COMPANY_SUCCESS, DELETE_COMPANY_ERROR, DELETE_COMPANY_LOADING, DELETE_COMPANY_SUCCESS, DESTROY_COMPANY_ADD, DESTROY_COMPANY_EDIT, DESTROY_ERROR_ADD_COMPANY, DESTROY_ERROR_EDIT_COMPANY, EDIT_COMPANY_ERROR, EDIT_COMPANY_LOADING, EDIT_COMPANY_SUCCESS, GET_COMPANY_ERROR, GET_COMPANY_LOADING, GET_COMPANY_SUCCESS } from "../constants";

const initialStateCompany = {
    isLoading: false,
    isError: "",
    isEdited: "",
    isAdded: "",
    isDeleted: "",
    companies: [],
  };
export function addCompany(state = initialStateCompany, action) {
    switch (action.type) {
      case ADD_COMPANY_LOADING:
        return { ...state, isLoading: true };
      case ADD_COMPANY_SUCCESS:
        return { ...state, isLoading: false, isAdded: action.payload };
      case ADD_COMPANY_ERROR:
        return { ...state, isLoading: false, isError: action.payload };
      case DESTROY_COMPANY_ADD:
        return { ...state, isAdded: action.payload };
      case DESTROY_ERROR_ADD_COMPANY:
        return { ...state, isError: action.payload };
      default:
        return state;
    }
  }
  
  export function getCompany(state = initialStateCompany, action) {
    switch (action.type) {
      case GET_COMPANY_LOADING:
        return {...state, isLoading: true, companies: [] };
      case GET_COMPANY_SUCCESS:
        return {...state, isLoading: false, companies: action.payload };
      case GET_COMPANY_ERROR:
        return {...state, isLoading: false, companies: [], isError: action.payload };
      default:
        return state;
    }
  }
  
  export function editCompany(state = initialStateCompany, action) {
    switch (action.type) {
      case EDIT_COMPANY_LOADING:
        return { ...state, isLoading: true };
      case EDIT_COMPANY_SUCCESS:
        return { ...state, isLoading: false, isEdited: action.payload };
      case EDIT_COMPANY_ERROR:
        return { ...state, isLoading: false, isError: action.payload };
      case DESTROY_COMPANY_EDIT:
        return { ...state, isEdited: action.payload };
      case DESTROY_ERROR_EDIT_COMPANY:
        return { ...state, isError: action.payload };
      default:
        return state;
    }
  }
  
  export function deleteCompany(state = initialStateCompany, action) {
    switch (action.type) {
      case DELETE_COMPANY_LOADING:
        return { ...state, isLoading: true };
      case DELETE_COMPANY_SUCCESS:
        return { ...state, isLoading: false, isDeleted: action.payload };
      case DELETE_COMPANY_ERROR:
        return { ...state, isLoading: false, isError: action.payload };
      case "DESTROY_DELETE_ERROR_COMPANY":
        return { ...state, isDeleted: action.payload, isError: action.payload };
      default:
        return state;
    }
  }