import {
  ADD_BOOKING_ERROR,
  ADD_BOOKING_LOADING,
  ADD_BOOKING_SUCCESS,
  DELETE_BOOKING_ERROR,
  DELETE_BOOKING_LOADING,
  DELETE_BOOKING_SUCCESS,
  DESTROY_BOOKING_ADD,
  DESTROY_BOOKING_ERROR_ADD_C,
  GET_BOOKING_ERROR,
  GET_BOOKING_LOADING,
  GET_BOOKING_SUCCESS,
} from "../constants";

const initialStateCategory = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  bookings: [],
};

export function addBooking(state = initialStateCategory, action) {
  switch (action.type) {
    case ADD_BOOKING_LOADING:
      return { ...state, isLoading: true };
    case ADD_BOOKING_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_BOOKING_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_BOOKING_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_BOOKING_ERROR_ADD_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getBooking(state = initialStateCategory, action) {
  switch (action.type) {
    case GET_BOOKING_LOADING:
      return { ...state, isLoading: true };
    case GET_BOOKING_SUCCESS:
      return { ...state, isLoading: false, bookings: action.payload };
    case GET_BOOKING_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    default:
      return state;
  }
}
export function deleteBooking(state = initialStateCategory, action) {
  switch (action.type) {
    case DELETE_BOOKING_LOADING:
      return { ...state, isLoading: true };
    case DELETE_BOOKING_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_BOOKING_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_BOOKING_ERROR_C":
      return { ...state, isError: action.payload, isDeleted: action.payload };
    default:
      return state;
  }
}
