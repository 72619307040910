import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, InputBase, MenuItem, Select, Snackbar, withStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { destroyEditTransport, destroyEditTransportError, editTransport } from "../../../redux/actions/transport";
import { deleteCityImage, destroyDeleteErrorImageCity, getCities } from "../../../redux/actions/package";
import { destroyEditGallery, destroyEditGalleryError, editGallery } from "../../../redux/actions/gallery";
import { baseUrlImage, imagePath } from "../../../redux/url";

const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

function EditGallery({ modal, setModal, data, setOpen }) {
    const [city, setCity] = React.useState("");
  const handleChange = (event) => {
    setCity(event.target.value);
  };
  const [name, setName] = useState("");
  const [edit, setEdit] = useState("");
  const [images, setImages] = useState([])
  const [description, setDescription] = useState("")
  const [open, setopen] = useState(true);
  const dispatch = useDispatch();
  const {
    isLoading: deleteImageLoading,
    isError: deleteImageError,
    imageDeleted,
  } = useSelector((state) => state.deleteCityImage);
  const [open1, setOpen1] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [imgId, setImgId] = useState("");
  const handleClose1 = () => {
    setOpen1(false);
  };
  const { isLoading, isError, isEdited } = useSelector(
    (state) => state.editGallery
  );
  const {cities} = useSelector(state=>state.getCity)
  const handleClose = () => {
    setopen(false);
  };
  const toggle = () => setModal(!modal);

  const formSubmitted = (e) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('cityName',city)
    formData.append('tittle',name)
    formData.append('cityDescription',description)
    formData.append('userId',localStorage.getItem("userId"))
    for(var i=0; i<images.length; i++){
        formData.append('galleryImage',images[i])
    }
    formData.append('galleryId',data._id)
    dispatch(
      editGallery(formData)
    );
  };
  const closeModal = () => {
    setModal(false);
    setOpen(true);
  };
  const deleteImageFunction = (id, imgIdf,data) => {
    dispatch(
      deleteCityImage({
        imageId: id,
        type:"gallery"
      })
    );
    setImgId(imgIdf);
    document.getElementById(imgIdf).style.display = "none";
    // setSight(sight.filter(elm=>elm!==data))
  };
  useEffect(() => {
      dispatch(getCities())
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setopen(true);
    }
    if (isEdited !== undefined && isEdited !== "") {
      closeModal();
    }
    if (deleteImageError !== undefined && deleteImageError !== "") {
        setDeleteText(deleteImageError);
        setOpen1(true);
        document.getElementById(imgId).style.display = "flex";
      }
    setCity(data.cityName);
    setName(data.tittle)
    setDescription(data.cityDescription)
    return () => {
      return (
        dispatch(destroyEditGallery()), dispatch(destroyEditGalleryError(),dispatch(destroyDeleteErrorImageCity()))
      );
    };
  }, [isEdited, isError,deleteImageError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            {edit && (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {edit}
                </Alert>
              </Snackbar>
            )}
            <Snackbar
                open={open1}
                autoHideDuration={6000}
                onClose={handleClose1}
              >
                <Alert onClose={handleClose1} severity="error">
                  {deleteText}
                </Alert>
              </Snackbar>
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Edit Transport"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                      <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                City
                              </label>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                name="cityName"
                                value={city}
                                onChange={handleChange}
                                input={<BootstrapInput />}
                              >
                                {cities.length > 0 &&
                                  cities.map((e, i) => {
                                    return (
                                      <MenuItem value={e.cityId}>
                                        {e.cityName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Title
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Title"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Description
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Description"
                                type="textarea"
                                rows={"5"}
                                name="name"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Images
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-first-name"
                        placeholder="cut off date"
                        type="file"
                        name="bannerImage"
                        onChange={(e)=>setImages(e.target.files)}
                        accept="image/*"
                        multiple
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12" style={{display:"flex"}}>
                  {data.galleryImages?.length > 0 &&
                                data.galleryImages.map((elm, ind) => {
                                    return (
                                      <div
                                        key={elm.galleryId}
                                        id={elm.galleryId}
                                        style={{
                                          display: "flex",
                                          margin: "0 5px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Avatar
                                          src={baseUrlImage + imagePath + elm.imageName}
                                        />
                                        <Button
                                          style={{
                                            color: "white",
                                            cursor: "pointer",
                                            backgroundColor: "black",
                                            boxShadow: "none",
                                            width: "20px",
                                            height: "20px",
                                            padding: "0",
                                          }}
                                          onClick={() =>
                                            deleteImageFunction(
                                              elm._id,
                                              elm.galleryId
                                            )
                                          }
                                          disabled={deleteImageLoading}
                                        >
                                          X
                                        </Button>
                                      </div>
                                    );
                                })}
                  </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Edit
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditGallery;
