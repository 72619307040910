import React, { useEffect, useState } from "react";
import { Col, Container, InputGroup, InputGroupAddon, Row } from "reactstrap";
import UserNavbar from "../../common/navbar/UserNavbar";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  FilledInput,
  FormHelperText,
  IconButton,
  InputAdornment,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowLeft,
  ArrowRightAlt,
  GitHub,
  Search,
} from "@material-ui/icons";
import Select, { components } from "react-select";
import bellPng from "../../assets/img/new/bell-alarm.png";
import { Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCities, getFavourite } from "../../redux/actions/package";
import { Alert } from "@material-ui/lab";
import { baseUrlImage, imagePath } from "../../redux/url";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import bell from "../../assets/img/new/bell.png";
import cheap from "../../assets/img/new/cheap.png";
import hotel from "../../assets/img/new/hotel.png";
import easy from "../../assets/img/new/easy.png";
import { Link } from "react-router-dom";
import { getFavouritePackage } from "../../redux/actions/favourite";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});
function NewMainScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState("");
  const [open, setOpen] = useState(false);
  const [destination, setDestination] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectChildern, setSelectChildern] = useState("");
  const [selectAdult, setSelectAdult] = useState("");
  const [fieldError, setFieldError] = useState(false);
  const [inputError, setInputError] = useState({ name: "", text: "" });
  const [menuOption, setMenuOption] = useState(false);
  var count = 0;
  const { isLoading, isError, favourites } = useSelector(
    (state) => state.getFav
  );
  const {
    isLoadng: headLoading,
    isError: headError,
    settings,
  } = useSelector((state) => state.getHomeSetting);
  const {
    isLoading: citiesLoading,
    isError: citiesError,
    cities,
  } = useSelector((state) => state.getCity);
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <Search />
      </components.DropdownIndicator>
    );
  };

  const packageSearchedClicked = (e) => {
    e.preventDefault();
    if (destination === "") {
      setInputError({
        name: "destination",
        text: "is required",
      });
      setFieldError(true);
    } else if (toDate === "") {
      setInputError({
        name: "Date",
        text: "is required",
      });
      setFieldError(true);
    } else if (fromDate === "") {
      setInputError({
        name: "Date",
        text: "is required",
      });
      setFieldError(true);
    } else if (selectAdult === "") {
      setInputError({
        name: "Number of Adult",
        text: "is required",
      });
      setFieldError(true);
    } else if (selectChildern === "") {
      setInputError({
        name: "number of children",
        text: "is required",
      });
      setFieldError(true);
    } else {
      history.push(
        `/holiday?start=${fromDate}&end=${toDate}&to=${destination.label}&id=${destination.value}&adult=${selectAdult.value}&children=${selectChildern.value}`
      );
      setFieldError(false);
    }
  };
  const cityClicked = (nameCity, id) => {
    history.push();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isError !== undefined && isError !== "") {
      setErrorText(isError);
      setOpen(true);
    }
    if (citiesError !== undefined && citiesError !== "") {
      setErrorText(isError);
      setOpen(true);
    }
    dispatch(getCities());
    dispatch(getFavourite());
  }, [isError, citiesError, headError]);
  return (
    <div className="new">
      {isLoading ? (
        <></>
      ) : isError ? (
        <></>
      ) : (
        settings.length > 0 &&
        settings[0].header && (
          <>
            {" "}
            <Helmet>
              <title>{settings[0].header[0].pageTitle}</title>
            </Helmet>
          </>
        )
      )}
      <Container>
        <Row className="main-head">
          {headLoading ? (
            <></>
          ) : headError ? (
            <></>
          ) : (
            settings.length > 0 &&
            settings[0].header &&
            settings[0].header[0].headerNotice !== "" &&
            settings[0].header[0].headerNotice !== undefined && (
              <marquee
                width="100%"
                direction="left"
                height="30%"
                bgcolor="#e7d52e"
                color="white"
                className="marquee-tag"
              >
                {settings[0].header[0].headerNotice}
              </marquee>
            )
          )}
          <Col lg="6" sm="12" md="6" xl="6" className="main-head-col">
            <div className="main-head-content">
              <div className="main-head-heading">
                <Col lg="12" sm="12">
                  {headLoading ? (
                    <></>
                  ) : headError ? (
                    <>
                      <Alert severity="error">{isError}</Alert>
                    </>
                  ) : (
                    settings.length > 0 &&
                    settings[0].header && <>{settings[0].header[0].heading}</>
                  )}
                  {/* Travel around the world */}
                </Col>
              </div>
              <div className="main-head-description">
                <Col lg="12" sm="12">
                  {headLoading ? (
                    <></>
                  ) : headError ? (
                    <></>
                  ) : (
                    settings.length > 0 &&
                    settings[0].header && (
                      <>{settings[0].header[0].description}</>
                    )
                  )}
                </Col>
              </div>
              <div className="main-head-form">
                <Container>
                  <Row>
                    {fieldError && (
                      <Col lg="12">
                        <Alert severity="error">
                          {inputError.name + " " + inputError.text}
                        </Alert>
                      </Col>
                    )}
                    <Col lg="12">
                      <div className="destination-list"></div>
                      <Select
                        name="cities"
                        className="form-select"
                        menuIsOpen={menuOption}
                        placeholder={"select destination"}
                        components={{ DropdownIndicator }}
                        value={destination}
                        onInputChange={(inp) => {
                          if (inp !== "") {
                            return setMenuOption(true);
                          } else {
                            return setMenuOption(false);
                          }
                        }}
                        onChange={(e, j) => {
                          return setDestination(e);
                        }}
                        options={
                          cities.length > 0 &&
                          cities.map((e, i) => {
                            return {
                              value: e.cityId,
                              label: e.cityName,
                            };
                          })
                        }
                      />
                    </Col>
                    <Col lg="12" sm="12">
                      <InputGroup
                        style={{
                          backgroundColor: "#F1F2FF",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <TextField
                          id="date"
                          type="date"
                          value={fromDate}
                          onChange={(e) => {
                            return setFromDate(e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <TextField
                            id="date"
                            type="date"
                            value={toDate}
                            onChange={(e) => {
                              return setToDate(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col lg="6" sm="12">
                      <Select
                        className="form-select"
                        value={selectAdult}
                        onChange={(e) => setSelectAdult(e)}
                        options={[
                          { value: 1, label: 1 },
                          { value: 2, label: 2 },
                          { value: 3, label: 3 },
                          { value: 4, label: 4 },
                          { value: 5, label: 5 },
                          { value: 6, label: 6 },
                          { value: 7, label: 7 },
                          { value: 8, label: 8 },
                          { value: 9, label: 9 },
                          { value: 10, label: 10 },
                        ]}
                        placeholder={"Select Adult"}
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <Select
                        className="form-select"
                        value={selectChildern}
                        placeholder={"Select children"}
                        onChange={(e) => setSelectChildern(e)}
                        options={[
                          { value: 0, label: 0 },
                          { value: 1, label: 1 },
                          { value: 2, label: 2 },
                          { value: 3, label: 3 },
                          { value: 4, label: 4 },
                          { value: 5, label: 5 },
                          { value: 6, label: 6 },
                          { value: 7, label: 7 },
                          { value: 8, label: 8 },
                          { value: 9, label: 9 },
                          { value: 10, label: 10 },
                        ]}
                      />
                    </Col>
                    <Col lg="12" sm="12">
                      <Button
                        className="button-search"
                        variant="contained"
                        color="primary"
                        onClick={packageSearchedClicked}
                        style={{ backgroundColor: "#0003DC", width: "100%" }}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Col>

          <Col
            lg="6"
            sm="12"
            md="6"
            xl="6"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {headLoading ? (
              <></>
            ) : headError ? (
              <></>
            ) : (
              settings.length > 0 &&
              settings[0].header && (
                <>
                  {settings[0].siteImages?.length > 0 &&
                    settings[0].siteImages.map((e, i) => {
                      if (e.imageType === "site-headerImage") {
                        return (
                          <img
                            src={baseUrlImage + imagePath + e.imageName}
                            alt="head.."
                            className="main-head-image"
                          />
                        );
                      }
                    })}
                </>
              )
            )}
          </Col>
        </Row>
        <div className="main-member">
          <Row>
            <Col lg="12" sm="12" md="12">
              <div className="main-member-head">Only for Travel members</div>
              <div className="main-member-description">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor
              </div>
            </Col>
          </Row>
          <div className="main-member-cards">
            <Row>
              <Col lg="3" sm="6" md="6">
                <Card className="main-member-card">
                  <CardActionArea>
                    <CardContent>
                      <div className="card-image">
                        <img src={bell} alt="hotel" />
                      </div>
                      <Typography gutterBottom variant="h5" component="h2">
                        Price Alerts
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        eirmod tempor
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      <ArrowRightAlt />
                    </Button>
                  </CardActions>
                </Card>
              </Col>
              <Col lg="3" sm="6" md="6">
                <Card className="main-member-card">
                  <CardActionArea>
                    <CardContent>
                      <div className="card-image">
                        <img src={easy} alt="hotel" />
                      </div>
                      <Typography gutterBottom variant="h5" component="h2">
                        Easy Reschedule
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        eirmod tempor
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      <ArrowRightAlt />
                    </Button>
                  </CardActions>
                </Card>
              </Col>
              <Col lg="3" sm="6" md="6">
                <Card className="main-member-card">
                  <CardActionArea>
                    <CardContent>
                      <div className="card-image">
                        <img src={cheap} alt="hotel" />
                      </div>
                      <Typography gutterBottom variant="h5" component="h2">
                        Cheap airline tickets
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        eirmod tempor
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      <ArrowRightAlt />
                    </Button>
                  </CardActions>
                </Card>
              </Col>
              <Col lg="3" sm="6" md="6">
                <Card className="main-member-card">
                  <CardActionArea>
                    <CardContent>
                      <div className="card-image">
                        <img src={hotel} alt="hotel" />
                      </div>
                      <Typography gutterBottom variant="h5" component="h2">
                        Find hotels quickly
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        eirmod tempor
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      <ArrowRightAlt />
                    </Button>
                  </CardActions>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Row xl="1">
          <div className="main-fav-destination">
            <Col lg="12">
              <div className="main-fav-destination-head">
                The Most Favorite Destinations
              </div>
            </Col>
            {cities.length > 0 &&
              cities.map((e, i) => {
                if (e.isFavourite === true) {
                  count = count + 1;
                  return (
                    <>
                      <Col lg="12" className="main-fav-destination-head-col">
                        <Container>
                          <Row
                            className={
                              count % 2 !== 0
                                ? "main-fav-destination-head-reversed-col"
                                : ""
                            }
                          >
                            <Col
                              lg="6"
                              md="6"
                              className="main-fav-destination-head-subcol"
                            >
                              <Link
                                to={`/holiday?to=${e.cityName}&id=${e.cityId}`}
                              >
                                <div className="main-fav-destination-subhead">
                                  {e.cityName}
                                </div>
                              </Link>
                              <div
                                className="main-fav-destination-subhead-des"
                                dangerouslySetInnerHTML={{
                                  __html: e.cityDescription || "",
                                }}
                              ></div>
                            </Col>
                            <Col lg="6" md="6">
                              {e.cityImages?.length > 0 &&
                                e.cityImages.map((elm, ind) => {
                                  if (elm.imageType === "bannerImage") {
                                    return (
                                      <img
                                        src={
                                          e.cityImages.length > 0 &&
                                          baseUrlImage +
                                            imagePath +
                                            elm.imageThumbnail
                                        }
                                        alt="fav-destimage"
                                      />
                                    );
                                  }
                                })}
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </>
                  );
                }
              })}
          </div>
        </Row>
        <Row>
          <div className="main-customer-review">
            <Col lg="12">
              <div className="main-customer-review-heading">
                Customer Reviews
              </div>
            </Col>
            <Col lg="12" className="main-customer-review-col">
              <Row className="main-customer-review-card slide">
                <Col
                  lg="4"
                  md="4"
                  sm="4"
                  xs="4"
                  className="main-customer-review-card-info"
                >
                  <img src="/images/1.png" alt="sender-image" />
                  <span>Úrsula Corberó</span>
                </Col>
                <Col
                  lg="8"
                  md="8"
                  sm="8"
                  xs="8"
                  className="main-customer-review-card-review-info"
                >
                  <div className="main-customer-review-card-review-star">
                    ***** 5.0(bali)
                  </div>
                  <div className="main-customer-review-card-review-head">
                    Absolutely Fantastic
                  </div>
                  <div className="main-customer-review-card-review-des">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum.
                  </div>
                  <div className="main-customer-review-card-review-date">
                    11/03/2020
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </Row>
        <div className="main-destination-pack">
          <Row>
            <Col lg="12" className="main-destination-pack-head-col">
              <h6>Packages</h6>
              <h5>Favorite destination</h5>
              <p>Domestic & Foreign</p>
            </Col>
            {favourites &&
              favourites.length > 0 &&
              favourites.map((e, i) => {
                if (i < 3) {
                  return (
                    <Col
                      lg="4"
                      md="4"
                      sm="6"
                      xs="12"
                      className="main-destination-pack-cards"
                    >
                      <Card className={classes.root}>
                        <CardActionArea
                          onClick={() =>
                            history.push(`/package/${e.packageId}`)
                          }
                        >
                          {e.packageImages?.length > 0 &&
                            e.packageImages.map((elm, ind) => {
                              if (elm.imageType === "package-bannerImage") {
                                return (
                                  <CardMedia
                                    className={classes.media}
                                    image={
                                      baseUrlImage +
                                      imagePath +
                                      elm.imageThumbnail
                                    }
                                  />
                                );
                              }
                            })}
                          {/* <CardMedia
                            className={classes.media}
                            image={
                              baseUrlImage + imagePath +
                              e[0].packageImages?.length > 0 &&
                              e[0].packageImages.map((elm,ind)=>{
                                if(elm.imageType==="package-bannerImage"){
                                  return  elm.imageName
                                }
                              })
                            }
                            title={e[0].packageName}
                          /> */}
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                            >
                              {e.packageName}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              <span>
                                {parseInt(e.numberOfNights) + 1}days
                                {baseUrlImage +
                                  imagePath +
                                  e.packageImages?.length >
                                  0 &&
                                  e.packageImages.map((elm, ind) => {
                                    if (
                                      elm.imageType === "package-bannerImage"
                                    ) {
                                      return elm.imageName;
                                    }
                                  })}
                              </span>
                              &nbsp;&nbsp;<span>1 Persons</span>
                              &nbsp;&nbsp;
                              <span className="money">${e.packagePrice}</span>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button size="small" color="primary">
                            Book
                          </Button>
                        </CardActions>
                      </Card>
                    </Col>
                  );
                }
              })}
          </Row>
        </div>
      </Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default NewMainScreen;
