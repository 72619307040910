import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { getCities } from "../../../redux/actions/package";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  addBlogs,
  destroyAddBlog,
  destroyAddBlogError,
  getCategory,
} from "../../../redux/actions/blog";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";

function AddBlog({ modal, setModal, setopen }) {
  const [name, setName] = useState([]);
  const [edit, setEdit] = useState("");
  const [done, setDone] = useState(false);
  const [city, setCity] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [featured, setFeatured] = useState(false);
  const [editorPick, setEditorPick] = useState(false);
  const { category } = useSelector((state) => state.getCategory);
  const { cities } = useSelector((state) => state.getCity);
  const { isLoading, isAdded, isError } = useSelector((state) => state.addBlog);
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState("");
  const [bannerImage, setBannerImage] = useState([]);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const formSubmitted = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "blogDescription",
      draftToHtml(convertToRaw(description.getCurrentContent())).toString()
    );
    formData.append("isFeatured", featured);
    formData.append("isEditorPick", editorPick);
    formData.append("userId", localStorage.getItem("userId"));
    for (var i = 0; i < name.length; i++) {
      formData.append(`blogCategory[${i}][label]`, name[i].label);
      formData.append(`blogCategory[${i}][value]`, name[i].value);
    }
    // formData.append('blogCategory',name)
    formData.append("blogTitle", title);
    formData.append("blogIntroduction", introduction);
    formData.append("cityName", city);
    formData.append("bannerImage", bannerImage[0]);
    dispatch(addBlogs(formData)).then((result) => {});
    setDone(isAdded);
  };
  const closeModal = () => {
    setModal(false);
    setopen(true);
    setName("");
    setTitle("");
    setBannerImage({});
    setDescription(EditorState.createEmpty());
    setCity("");
    setEditorPick(false);
    setFeatured(false);
    setIntroduction("");
  };
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };
  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
        reject(error);
      };
    });
  }
  useEffect(() => {
    dispatch(getCategory());
    dispatch(getCities());
    if (isAdded !== undefined && isAdded !== "") {
      closeModal();
    }
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setOpen(true);
    }
    return () => {
      return dispatch(destroyAddBlog()), dispatch(destroyAddBlogError());
    };
  }, [isAdded, isError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody>
          <Container>
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Add Blog"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {edit && (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="error">
                          {edit}
                        </Alert>
                      </Snackbar>
                    )}
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Title
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Title"
                                type="text"
                                name="name"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Introduction text
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="intro text"
                                type="textarea"
                                rows="3"
                                name="intro"
                                value={introduction}
                                onChange={(e) =>
                                  setIntroduction(e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label>Blog Description</label>
                              <Editor
                                editorState={description}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                  inline: { inDropdown: true },
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: { inDropdown: true },
                                  image: {
                                    uploadCallback: uploadImageCallBack,
                                    alt: { present: true, mandatory: true },
                                    previewImage: true,
                                  },
                                }}
                              />{" "}
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label">
                                is Featured
                              </label>
                              <Select
                                value={{
                                  label: JSON.stringify(featured),
                                  value: featured,
                                }}
                                onChange={(elm) => setFeatured(elm.value)}
                                options={[
                                  { value: true, label: "true" },
                                  { value: false, label: "false" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label">
                                is Editor pick
                              </label>
                              <Select
                                value={{
                                  label: JSON.stringify(editorPick),
                                  value: editorPick,
                                }}
                                onChange={(elm) => setEditorPick(elm.value)}
                                options={[
                                  { value: true, label: "true" },
                                  { value: false, label: "false" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                for="exampleSelect"
                              >
                                City Name
                              </label>
                              {/* <Input
                                type="select"
                                className="form-control-alternative"
                                name="select"
                                id="exampleSelect"
                                name="cityName"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              >
                                <option selected></option>
                                {cities.length > 0 &&
                                  cities.map((e, i) => {
                                    return <option>{e.cityName}</option>;
                                  })}
                              </Input> */}
                              <Select
                                name="cityName"
                                value={{ value: city, label: city }}
                                onChange={(selected) => setCity(selected.value)}
                                options={
                                  cities.length > 0 &&
                                  cities.map((e, i) => {
                                    return {
                                      value: e.cityName,
                                      label: e.cityName,
                                    };
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                for="exampleSelect"
                              >
                                Category
                              </label>
                              {/* <Input
                                type="select"
                                className="form-control-alternative"
                                name="select"
                                id="exampleSelect"
                                name="cityName"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              >
                                <option selected></option>
                                {category.length > 0 &&
                                  category.map((e, i) => {
                                    return { value: e.blogCategory, label: e.blogCategory }
                                  })}
                              </Input> */}
                              <Select
                                name="category"
                                isMulti
                                placeholder="select category"
                                value={name}
                                onChange={(e, j) => {
                                  return console.log(e, j), setName(e);
                                }}
                                options={
                                  category.length > 0 &&
                                  category.map((e, i) => {
                                    return {
                                      value: e.blogCategory,
                                      label: e.blogCategory,
                                    };
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Add Banner Image
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="file"
                                name="images"
                                onChange={(e) => setBannerImage(e.target.files)}
                                accept="image/*"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Add Blog
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddBlog;
