import { ADD_GALLERY_ERROR, ADD_GALLERY_LOADING, ADD_GALLERY_SUCCESS, DELETE_GALLERY_ERROR, DELETE_GALLERY_LOADING, DELETE_GALLERY_SUCCESS, DESTROY_GALLERY_ADD, DESTROY_GALLERY_EDIT, DESTROY_GALLERY_ERROR_ADD_C, DESTROY_GALLERY_ERROR_EDIT_C, EDIT_GALLERY_ERROR, EDIT_GALLERY_LOADING, EDIT_GALLERY_SUCCESS, GET_GALLERY_ERROR, GET_GALLERY_FILTER_SUCCESS, GET_GALLERY_LOADING, GET_GALLERY_SUCCESS, GET_PARTICULAR_GALLERY_FILTER_SUCCESS } from "../constants";

const initialStateCategory = {
    isLoading: false,
    isError: "",
    isEdited: "",
    isAdded: "",
    isDeleted: "",
    gallery:[],
    filterGallery:[],
    parGallery:[]
};

export function addGallery(state = initialStateCategory, action) {
    switch (action.type) {
      case ADD_GALLERY_LOADING:
        return { ...state, isLoading: true };
      case ADD_GALLERY_SUCCESS:
        return { ...state, isLoading: false, isAdded: action.payload };
      case ADD_GALLERY_ERROR:
        return { ...state, isLoading: false, isError: action.payload };
      case DESTROY_GALLERY_ADD:
        return { ...state, isAdded: action.payload };
      case DESTROY_GALLERY_ERROR_ADD_C:
        return { ...state, isError: action.payload };
      default:
        return state;
    }
  }
  
  export function getGallery(state = initialStateCategory, action) {
    switch (action.type) {
      case GET_GALLERY_LOADING:
        return { ...state, isLoading: true };
      case GET_GALLERY_SUCCESS:
        return { ...state, isLoading: false, gallery: action.payload };
        case GET_GALLERY_FILTER_SUCCESS:
        return { ...state, isLoading: false, filterGallery: action.payload };
      case GET_PARTICULAR_GALLERY_FILTER_SUCCESS:
        return { ...state, isLoading: false, parGallery: action.payload };
      case GET_GALLERY_ERROR:
        return {
          ...state,
          isLoading: false,
          isError: action.payload,
        };
      default:
        return state;
    }
  }
  
  export function editGallery(state = initialStateCategory, action) {
    switch (action.type) {
      case EDIT_GALLERY_LOADING:
        return { ...state, isLoading: true };
      case EDIT_GALLERY_SUCCESS:
        return { ...state, isLoading: false, isEdited: action.payload };
      case EDIT_GALLERY_ERROR:
        return { ...state, isLoading: false, isError: action.payload };
      case DESTROY_GALLERY_EDIT:
        return { ...state, isEdited: action.payload };
      case DESTROY_GALLERY_ERROR_EDIT_C:
        return { ...state, isError: action.payload };
      default:
        return state;
    }
  }
  
  export function deleteGallery(state = initialStateCategory, action) {
    switch (action.type) {
      case DELETE_GALLERY_LOADING:
        return { ...state, isLoading: true };
      case DELETE_GALLERY_SUCCESS:
        return { ...state, isLoading: false, isDeleted: action.payload };
      case DELETE_GALLERY_ERROR:
        return { ...state, isLoading: false, isError: action.payload };
      case "DESTROY_DELETE_GALLERY_ERROR_C":
        return { ...state, isError: action.payload, isDeleted: action.payload };
      default:
        return state;
    }
  }
  
  