import axios from "axios";
import {
  ADD_INCLUSIONS_ERROR,
  ADD_INCLUSIONS_LOADING,
  ADD_INCLUSIONS_SUCCESS,
  DELETE_INCLUSIONS_ERROR,
  DELETE_INCLUSIONS_LOADING,
  DELETE_INCLUSIONS_SUCCESS,
  DESTROY_ERROR_ADD_INCLUSIONS,
  DESTROY_ERROR_EDIT_INCLUSIONS,
  DESTROY_INCLUSIONS_ADD,
  DESTROY_INCLUSIONS_DELETE,
  DESTROY_INCLUSIONS_EDIT,
  EDIT_INCLUSIONS_ERROR,
  EDIT_INCLUSIONS_LOADING,
  EDIT_INCLUSIONS_SUCCESS,
  GET_INCLUSIONS_ERROR,
  GET_INCLUSIONS_LOADING,
  GET_INCLUSIONS_SUCCESS,
} from "../constants";
import {
  baseUrl,
  getInclusionsEndpoint,
  addInclusionsEndpoint,
  editInclusionsEndpoint,
  deleteInclusionsEndpoint,
  filterInclusionEndpoint,
} from "../url";

export const getInclusions = (data) => async (dispatch) => {
  dispatch({ type: GET_INCLUSIONS_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getInclusionsEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_INCLUSIONS_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_INCLUSIONS_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getInclusionsEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_INCLUSIONS_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_INCLUSIONS_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_INCLUSIONS_ERROR, payload: error.message });
  }
};

export const addInclusions = (data) => async (dispatch) => {
  dispatch({ type: ADD_INCLUSIONS_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addInclusionsEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_INCLUSIONS_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: ADD_INCLUSIONS_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_INCLUSIONS_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_INCLUSIONS_ERROR, payload: error.message });
  }
};

export const editInclusions = (data) => async (dispatch) => {
  dispatch({ type: EDIT_INCLUSIONS_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editInclusionsEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_INCLUSIONS_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: EDIT_INCLUSIONS_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_INCLUSIONS_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_INCLUSIONS_ERROR, payload: error.message });
  }
};

export const deleteInclusions = (data) => async (dispatch) => {
  dispatch({ type: DELETE_INCLUSIONS_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteInclusionsEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_INCLUSIONS_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_INCLUSIONS_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_INCLUSIONS_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_INCLUSIONS_ERROR, payload: error.message });
  }
};

export const destroyAddInclusions = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_INCLUSIONS_ADD, payload: "" });
};
export const destroyEditInclusions = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_INCLUSIONS_EDIT, payload: "" });
};
export const destroyDeleteInclusions = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_INCLUSIONS_DELETE, payload: "" });
};
export const destroyAddInclusionsError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_ADD_INCLUSIONS, payload: "" });
};
export const destroyEditInclusionsError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_EDIT_INCLUSIONS, payload: "" });
};
export const destroyDeleteErrorInclusions = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_INCLUSIONS", payload: "" });
};

export const filterInclusions = (data) => async (dispatch) => {
  dispatch({ type: GET_INCLUSIONS_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterInclusionEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_INCLUSIONS_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_INCLUSIONS_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_INCLUSIONS_ERROR, payload: error.message });
  }
};
