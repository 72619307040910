import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  addExclusions,
  destroyAddExclusion,
  destroyAddExclusionError,
} from "../../../redux/actions/exclusion";
import FontIconPicker from "@fonticonpicker/react-fonticonpicker";
import { icons } from "../icons";

function AddExclusion({ modal, setModal, setopen }) {
  const [name, setName] = useState("");
  const [edit, setEdit] = useState("");
  const [done, setDone] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const { isLoading, isAdded, isError } = useSelector(
    (state) => state.addExclusion
  );
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (value) => {
    setTitle(value);
  };
  const dispatch = useDispatch();
  const iconProps = {
    icons: icons,
    theme: "bluegrey",
    renderUsing: "class",
    value: title,
    onChange: handleChange,
    isMulti: false,
    closeOnSelect: true,
  };
  const formSubmitted = (e) => {
    e.preventDefault();
    dispatch(
      addExclusions({
        exclusionName: name,
        exclusionIcon: title,
        userId: localStorage.getItem("userId"),
      })
    ).then((result) => {});
    setDone(isAdded);
  };
  const closeModal = () => {
    setModal(false);
    setopen(true);
    setName("");
    setTitle("");
  };
  useEffect(() => {
    if (isAdded !== undefined && isAdded !== "") {
      closeModal();
    }
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setOpen(true);
    }
    setTitle("");
    setName("");
    return () => {
      return (
        dispatch(destroyAddExclusion()), dispatch(destroyAddExclusionError())
      );
    };
  }, [isAdded, isError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody>
          <Container>
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Add Exclusion"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {edit && (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="error">
                          {edit}
                        </Alert>
                      </Snackbar>
                    )}
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="exclusion Name"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label">Icon</label>
                              <FontIconPicker {...iconProps} />
                              {/* <Input
                                className="form-control-alternative"
                                placeholder="icon of exclusion"
                                type="text"
                                name="description"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                              /> */}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Add Exclusion
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddExclusion;
