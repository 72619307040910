import { combineReducers } from "redux";
import {
  addBlog,
  addCategory,
  cityBlogFilter,
  deleteBlog,
  deleteCategory,
  editBlog,
  editCategory,
  filterEditorPick,
  getBlog,
  getCategory,
} from "./reducer/blog";
import { addBooking, deleteBooking, getBooking } from "./reducer/booking";
import {
  addCompany,
  deleteCompany,
  editCompany,
  getCompany,
} from "./reducer/company";
import {
  addExclusion,
  deleteExclusion,
  editExclusion,
  getExclusion,
} from "./reducer/exclusion";
import {
  addGallery,
  deleteGallery,
  editGallery,
  getGallery,
} from "./reducer/gallery";
import {
  addInclusion,
  deleteInclusion,
  editInclusion,
  getInclusion,
} from "./reducer/inclusion";
import {
  addCity,
  addFavourite,
  addHotel,
  addPackage,
  addPackageCategory,
  cityPackageFilter,
  deleteCityImageReducer,
  deleteCityReducer,
  deleteFavourite,
  deleteHotelReducer,
  deletePackage,
  deletePackageCategory,
  editCity,
  editHotel,
  editPackage,
  editPackageCategory,
  getAllCItiesData,
  getAllCitiesHead,
  getCity,
  getFavourite,
  getHotel,
  getHotelIdData,
  getPackage,
  getPackageCategory,
} from "./reducer/package";
import {
  addAd,
  addHomeSetting,
  addLocation,
  deleteAd,
  deleteHomeSetting,
  deleteLocation,
  editAd,
  editHomeSettings,
  editLocation,
  getAd,
  getHomeSettings,
  getLocation,
} from "./reducer/settings";
import {
  addTransport,
  addVehicleType,
  deleteTransport,
  deleteVehicleType,
  editTransport,
  editVehicleType,
  getTransport,
  getVehicleType,
} from "./reducer/transport";
import {
  deleteUser,
  editUser,
  getUsers,
  userLogin,
  userRegister,
} from "./reducer/user";
import {
  addFavouritePackage,
  getFavouritePackage,
  deleteFavouritePackage,
} from "./reducer/favourite";

const reducer = combineReducers({
  package: addPackage,
  getPackage: getPackage,
  city: addCity,
  getCity: getCity,
  editCity: editCity,
  user: userLogin,
  registerUser: userRegister,
  addHotel: addHotel,
  editHotel: editHotel,
  getHotel: getHotel,
  deleteH: deleteHotelReducer,
  deleteC: deleteCityReducer,
  deleteP: deletePackage,
  editPackage: editPackage,
  getUser: getUsers,
  editUser: editUser,
  deleteUser: deleteUser,
  addBlog: addBlog,
  editBlog: editBlog,
  getBlog: getBlog,
  deleteBlog: deleteBlog,
  addCategory: addCategory,
  editCategory: editCategory,
  getCategory: getCategory,
  deleteCategory: deleteCategory,
  getHomeSetting: getHomeSettings,
  addHomeSetting: addHomeSetting,
  deleteHomeSetting: deleteHomeSetting,
  editHomeSetting: editHomeSettings,
  addLocation: addLocation,
  editLocation: editLocation,
  getLocation: getLocation,
  deleteLocation: deleteLocation,
  getExclusion: getExclusion,
  addExclusion: addExclusion,
  editExclusion: editExclusion,
  deleteExclusion: deleteExclusion,
  getInclusion: getInclusion,
  addInclusion: addInclusion,
  editInclusion: editInclusion,
  deleteInclusion: deleteInclusion,
  getFilterPackage: cityPackageFilter,
  getAllCityPackage: getAllCItiesData,
  getFilterBlog: cityBlogFilter,
  getCityHead: getAllCitiesHead,
  getHotelId: getHotelIdData,
  addAd: addAd,
  editAd: editAd,
  getAd: getAd,
  deleteAd: deleteAd,
  addFav: addFavourite,
  getFav: getFavourite,
  deleteFav: deleteFavourite,
  filterEditor: filterEditorPick,
  addCompany: addCompany,
  editCompany: editCompany,
  getCompany: getCompany,
  deleteCompany: deleteCompany,
  deleteCityImage: deleteCityImageReducer,
  addPackageCategory: addPackageCategory,
  editPackageCategory: editPackageCategory,
  getPackageCategory: getPackageCategory,
  deletePackageCategory: deletePackageCategory,
  addTransport: addTransport,
  editTransport: editTransport,
  getTransport: getTransport,
  deleteTransport: deleteTransport,
  addVehicleType: addVehicleType,
  editVehicleType: editVehicleType,
  getVehicleType: getVehicleType,
  deleteVehicleType: deleteVehicleType,
  addGallery: addGallery,
  editGallery: editGallery,
  getGallery: getGallery,
  deleteGallery: deleteGallery,
  getBooking: getBooking,
  deleteBooking: deleteBooking,
  addBooking: addBooking,
  addFavouritePackage: addFavouritePackage,
  getFavouritePackage: getFavouritePackage,
  deleteFavouritePackage: deleteFavouritePackage,
});

export default reducer;
