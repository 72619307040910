import {
  ADD_EXCLUSIONS_ERROR,
  ADD_EXCLUSIONS_LOADING,
  ADD_EXCLUSIONS_SUCCESS,
  DELETE_EXCLUSIONS_ERROR,
  DELETE_EXCLUSIONS_LOADING,
  DELETE_EXCLUSIONS_SUCCESS,
  DESTROY_ERROR_ADD_EXCLUSIONS,
  DESTROY_ERROR_EDIT_EXCLUSIONS,
  DESTROY_EXCLUSIONS_ADD,
  DESTROY_EXCLUSIONS_EDIT,
  EDIT_EXCLUSIONS_ERROR,
  EDIT_EXCLUSIONS_LOADING,
  EDIT_EXCLUSIONS_SUCCESS,
  GET_EXCLUSIONS_ERROR,
  GET_EXCLUSIONS_LOADING,
  GET_EXCLUSIONS_SUCCESS,
} from "../constants";

const initialStateHome = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  exclusions: [],
};

export function addExclusion(state = initialStateHome, action) {
  switch (action.type) {
    case ADD_EXCLUSIONS_LOADING:
      return { ...state, isLoading: true };
    case ADD_EXCLUSIONS_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_EXCLUSIONS_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_EXCLUSIONS_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_ERROR_ADD_EXCLUSIONS:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getExclusion(state = initialStateHome, action) {
  switch (action.type) {
    case GET_EXCLUSIONS_LOADING:
      return {...state, isLoading: true, exclusions: [] };
    case GET_EXCLUSIONS_SUCCESS:
      return {...state, isLoading: false, exclusions: action.payload };
    case GET_EXCLUSIONS_ERROR:
      return {...state, isLoading: false, exclusions: [], isError: action.payload };
    default:
      return state;
  }
}

export function editExclusion(state = initialStateHome, action) {
  switch (action.type) {
    case EDIT_EXCLUSIONS_LOADING:
      return { ...state, isLoading: true };
    case EDIT_EXCLUSIONS_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_EXCLUSIONS_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_EXCLUSIONS_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_ERROR_EDIT_EXCLUSIONS:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function deleteExclusion(state = initialStateHome, action) {
  switch (action.type) {
    case DELETE_EXCLUSIONS_LOADING:
      return { ...state, isLoading: true };
    case DELETE_EXCLUSIONS_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_EXCLUSIONS_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_EXCLUSIONS":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}
