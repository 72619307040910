import axios from "axios";
import {
  ADD_CITY_ERROR,
  ADD_CITY_LOADING,
  ADD_CITY_SUCCESS,
  ADD_PACKAGE_ERROR,
  ADD_PACKAGE_LOADING,
  ADD_PACKAGE_SUCCESS,
  GET_CITY_ERROR,
  GET_CITY_LOADING,
  GET_CITY_SUCCESS,
  EDIT_CITY_ERROR,
  EDIT_CITY_LOADING,
  EDIT_CITY_SUCCESS,
  ADD_HOTEL_LOADING,
  ADD_HOTEL_SUCCESS,
  ADD_HOTEL_ERROR,
  EDIT_HOTEL_LOADING,
  EDIT_HOTEL_SUCCESS,
  EDIT_HOTEL_ERROR,
  GET_HOTEL_LOADING,
  GET_HOTEL_SUCCESS,
  GET_HOTEL_ERROR,
  GET_PACKAGE_LOADING,
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_ERROR,
  DELETE_HOTEL_LOADING,
  DELETE_HOTEL_SUCCESS,
  DELETE_HOTEL_ERROR,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_ERROR,
  DELETE_CITY_LOADING,
  DELETE_PACKAGE_LOADING,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_ERROR,
  EDIT_PACKAGE_LOADING,
  EDIT_PACKAGE_SUCCESS,
  EDIT_PACKAGE_ERROR,
  DESTROY_EDITED,
  DESTROY_ADD_CITY,
  DESTROY_ADD_HOTEL,
  DESTROY_EDIT_HOTEL,
  DESTROY_ERROR_CITY,
  DESTROY_ERROR_HOTEL,
  DESTROY_ERROR_PACKAGE,
  DESTROY_ERROR_CITY_EDIT,
  DESTROY_ERROR_HOTEL_EDIT,
  DESTROY_ERROR_PACKAGE_EDIT,
  GET_CITY_PACKAGE_LOADING,
  GET_CITY_PACKAGE_SUCCESS,
  GET_CITY_PACKAGE_ERROR,
  GET_CITY_BLOG_LOADING,
  GET_CITY_BLOG_SUCCESS,
  GET_CITY_BLOG_ERROR,
  GET_ALL_CITY_PACKAGE_LOADING,
  GET_ALL_CITY_PACKAGE_SUCCESS,
  GET_ALL_CITY_PACKAGE_ERROR,
  GET_ALL_CITY_HEAD_LOADING,
  GET_ALL_CITY_HEAD_SUCCESS,
  GET_ALL_CITY_HEAD_ERROR,
  DELETE_CITY_IMAGES_LOADING,
  DELETE_CITY_IMAGES_SUCCESS,
  DELETE_CITY_IMAGES_ERROR,
  GET_PACKAGE_CATEGORY_LOADING,
  GET_PACKAGE_CATEGORY_SUCCESS,
  GET_PACKAGE_CATEGORY_ERROR,
  ADD_PACKAGE_CATEGORY_LOADING,
  ADD_PACKAGE_CATEGORY_SUCCESS,
  ADD_PACKAGE_CATEGORY_ERROR,
  EDIT_PACKAGE_CATEGORY_LOADING,
  EDIT_PACKAGE_CATEGORY_SUCCESS,
  EDIT_PACKAGE_CATEGORY_ERROR,
  DELETE_PACKAGE_CATEGORY_LOADING,
  DELETE_PACKAGE_CATEGORY_SUCCESS,
  DELETE_PACKAGE_CATEGORY_ERROR,
  DESTROY_PACKAGE_CATEGORY_ADD,
  DESTROY_PACKAGE_CATEGORY_EDIT,
  DESTROY_PACKAGE_CATEGORY_DELETE,
  DESTROY_PACKAGE_ERROR_ADD_C,
  DESTROY_PACKAGE_ERROR_EDIT_C,
} from "../constants";
import {
  baseUrl,
  addCityEndPoint,
  getPackageEndpoint,
  filterPackageEndpoint,
  editPackageEndpoint,
  deleteHotelEndpoint,
  deleteCityEndpoint,
  deletePackageEndpoint,
  updateCityEndpoint,
  getHotelEndPoint,
  addHotelEndPoint,
  getCityEndpoint,
  editHotelEndPoint,
  addPackageEndPoint,
  filterHotelEndpoint,
  filterCityEndpoint,
  packageCategoryFilter,
  cityBlogFilterEndpoint,
  cityPackageFilterEndpoint,
  allCityPackagesEndpoint,
  filterHotelIdEndpoint,
  addFavEndpoint,
  deleteFavEndpoint,
  getFavEndpoint,
  deleteCityImageEndpoint,
  addPackageCategoryEndpoint,
  editPackageCategoryEndpoint,
  getPackageCategoryEndpoint,
  deletePackageCategoryEndPoint,
  filterPackageCategoryEndpoint,
  deleteGalleryImageEndpoint
} from "../url";

export const getPackage = (data) => async (dispatch) => {
  dispatch({ type: GET_PACKAGE_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getPackageEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_PACKAGE_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_PACKAGE_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getPackageEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_PACKAGE_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_PACKAGE_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_PACKAGE_ERROR, payload: error.message });
  }
};

export const getPackageDetail = (data) => async (dispatch) => {
  dispatch({ type: GET_PACKAGE_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}/getPackage?&id=${data.id}`,
    })
      .then((result) => {
        dispatch({ type: GET_PACKAGE_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_PACKAGE_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_PACKAGE_ERROR, payload: error.message });
  }
};

export const addPackage = (data) => async (dispatch) => {
  dispatch({ type: ADD_PACKAGE_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addPackageEndPoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({ type: ADD_PACKAGE_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: ADD_PACKAGE_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_PACKAGE_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_PACKAGE_ERROR, payload: error.message });
  }
};

export const editPackage = (data) => async (dispatch) => {
  dispatch({ type: EDIT_PACKAGE_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editPackageEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_PACKAGE_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: EDIT_PACKAGE_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_PACKAGE_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_PACKAGE_ERROR, payload: error.message });
  }
};

export const addCity = (data) => async (dispatch) => {
  dispatch({ type: ADD_CITY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addCityEndPoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({ type: ADD_CITY_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: ADD_CITY_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_CITY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_CITY_ERROR, payload: error.message });
  }
};

export const addHotel = (data) => async (dispatch) => {
  dispatch({ type: ADD_HOTEL_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addHotelEndPoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({ type: ADD_HOTEL_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: ADD_HOTEL_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_HOTEL_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_HOTEL_ERROR, payload: error.message });
  }
};

export const getCities = (data) => async (dispatch) => {
  dispatch({ type: GET_CITY_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getCityEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_CITY_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_CITY_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getCityEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_CITY_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_CITY_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_CITY_ERROR, payload: error.message });
  }
};

export const getCitiesFooter = (data) => async (dispatch) => {
  dispatch({ type: GET_CITY_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${getCityEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: "GET_CITY_SUCCESS_FOOTER", payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_CITY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_CITY_ERROR, payload: error.message });
  }
};

export const getCitiesDataHead = (data) => async (dispatch) => {
  dispatch({ type: GET_ALL_CITY_HEAD_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterCityEndpoint}?keyword=${data.key}`,
    })
      .then((result) => {
        dispatch({ type: GET_ALL_CITY_HEAD_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_ALL_CITY_HEAD_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_ALL_CITY_HEAD_ERROR, payload: error.message });
  }
};

export const getHotelIdData = (data) => async (dispatch) => {
  dispatch({ type: "GET_ALL_HOTEL_LOADING" });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterHotelIdEndpoint}?id=${data.id}`,
    })
      .then((result) => {
        dispatch({ type: "GET_ALL_HOTEL_SUCCESS", payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_ALL_HOTEL_ERROR", payload: error.message });
      });
  } catch (error) {
    dispatch({ type: "GET_ALL_HOTEL_ERROR", payload: error.message });
  }
};

export const editCity = (data) => async (dispatch) => {
  dispatch({ type: EDIT_CITY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${updateCityEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({ type: EDIT_CITY_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: EDIT_CITY_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_CITY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_CITY_ERROR, payload: error.message });
  }
};

export const editHotel = (data) => async (dispatch) => {
  dispatch({ type: EDIT_HOTEL_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editHotelEndPoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({ type: EDIT_HOTEL_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: EDIT_HOTEL_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_HOTEL_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_HOTEL_ERROR, payload: error.message });
  }
};

export const getHotel = (data) => async (dispatch) => {
  dispatch({ type: GET_HOTEL_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getHotelEndPoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_HOTEL_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_HOTEL_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getHotelEndPoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_HOTEL_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_HOTEL_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_HOTEL_ERROR, payload: error.message });
  }
};

export const deleteHotel = (data) => async (dispatch) => {
  dispatch({ type: DELETE_HOTEL_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteHotelEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_HOTEL_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: DELETE_HOTEL_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_HOTEL_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_HOTEL_ERROR, payload: error.message });
  }
};

export const deleteCity = (data) => async (dispatch) => {
  dispatch({ type: DELETE_CITY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteCityEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({ type: DELETE_CITY_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: DELETE_CITY_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_CITY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_CITY_ERROR, payload: error.message });
  }
};

export const deleteCityImage = (data) => async (dispatch) => {
  dispatch({ type: DELETE_CITY_IMAGES_LOADING });
  try {
    if(data.type){
      axios({
        method: "post",
        url: `${baseUrl}${deleteGalleryImageEndpoint}`,
        data: data,
      })
        .then((result) => {
          if (result.data.status) {
            dispatch({
              type: DELETE_CITY_IMAGES_SUCCESS,
              payload: result.data.message,
            });
          } else {
            dispatch({
              type: DELETE_CITY_IMAGES_ERROR,
              payload: result.data.message,
            });
          }
        })
        .catch((error) => {
          dispatch({ type: DELETE_CITY_IMAGES_ERROR, payload: error.message });
        });
    } else{
      axios({
        method: "post",
        url: `${baseUrl}${deleteCityImageEndpoint}`,
        data: data,
      })
        .then((result) => {
          if (result.data.status) {
            dispatch({
              type: DELETE_CITY_IMAGES_SUCCESS,
              payload: result.data.message,
            });
          } else {
            dispatch({
              type: DELETE_CITY_IMAGES_ERROR,
              payload: result.data.message,
            });
          }
        })
        .catch((error) => {
          dispatch({ type: DELETE_CITY_IMAGES_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: DELETE_CITY_IMAGES_ERROR, payload: error.message });
  }
};

export const deletePackage = (data) => async (dispatch) => {
  dispatch({ type: DELETE_PACKAGE_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deletePackageEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_PACKAGE_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_PACKAGE_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_PACKAGE_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_PACKAGE_ERROR, payload: error.message });
  }
};

export const destroyEdited = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_EDITED, payload: "" });
};

export const destroyCityAdd = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ADD_CITY, payload: "" });
};

export const destroyPackAdd = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_ADD_PACKAGE", payload: "" });
};

export const destroyHotelAdd = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ADD_HOTEL, payload: "" });
};

export const destroyHotelEdit = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_EDIT_HOTEL, payload: "" });
};

export const destroyPackEdit = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_EDIT_PACKAGE", payload: "" });
};

export const destroyError = (data) => async (dispatch) => {
  if (data == "city") {
    dispatch({ type: DESTROY_ERROR_CITY, payload: "" });
  } else if (data == "hotel") {
    dispatch({ type: DESTROY_ERROR_HOTEL, payload: "" });
  } else if (data == "package") {
    dispatch({ type: DESTROY_ERROR_PACKAGE, payload: "" });
  } else {
  }
};

export const destroyEditError = (data) => async (dispatch) => {
  if (data == "city") {
    dispatch({ type: DESTROY_ERROR_CITY_EDIT, payload: "" });
  } else if (data == "hotel") {
    dispatch({ type: DESTROY_ERROR_HOTEL_EDIT, payload: "" });
  } else if (data == "package") {
    dispatch({ type: DESTROY_ERROR_PACKAGE_EDIT, payload: "" });
  } else {
  }
};
export const filterHotel = (data) => async (dispatch) => {
  dispatch({ type: GET_HOTEL_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterHotelEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_HOTEL_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_HOTEL_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_HOTEL_ERROR, payload: error.message });
  }
};
export const filterCities = (data) => async (dispatch) => {
  dispatch({ type: GET_CITY_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterCityEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_CITY_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_CITY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_CITY_ERROR, payload: error.message });
  }
};

export const filterPackage = (data) => async (dispatch) => {
  dispatch({ type: GET_PACKAGE_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterPackageEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_PACKAGE_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_PACKAGE_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_PACKAGE_ERROR, payload: error.message });
  }
};

export const getPackageCategory = (data) => async (dispatch) => {
  dispatch({ type: GET_PACKAGE_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${packageCategoryFilter}?keyword=${data.key}`,
    })
      .then((result) => {
        dispatch({ type: GET_PACKAGE_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_PACKAGE_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_PACKAGE_ERROR, payload: error.message });
  }
};

export const destroyDeleteErrorCity = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_CITY", payload: "" });
};
export const destroyDeleteErrorImageCity = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_IMAGES_CITY", payload: "" });
};
export const destroyDeleteErrorHotel = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_HOTEL", payload: "" });
};
export const destroyDeleteErrorPackage = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_PACKAGE", payload: "" });
};

export const cityPackageFilter = (data) => async (dispatch) => {
  dispatch({ type: GET_CITY_PACKAGE_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${cityPackageFilterEndpoint}?startDate=${data.start}&endDate=${data.end}&destination=${data.key}`,
    })
      .then((result) => {
        dispatch({ type: GET_CITY_PACKAGE_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_CITY_PACKAGE_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_CITY_PACKAGE_ERROR, payload: error.message });
  }
};

export const cityBlogFilter = (data) => async (dispatch) => {
  dispatch({ type: GET_CITY_BLOG_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${cityBlogFilterEndpoint}?keyword=${data.key}`,
    })
      .then((result) => {
        dispatch({ type: GET_CITY_BLOG_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_CITY_BLOG_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_CITY_BLOG_ERROR, payload: error.message });
  }
};

export const cityPackagesData = (data) => async (dispatch) => {
  dispatch({ type: GET_ALL_CITY_PACKAGE_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${allCityPackagesEndpoint}?skip=0&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_ALL_CITY_PACKAGE_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_ALL_CITY_PACKAGE_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_ALL_CITY_PACKAGE_ERROR, payload: error.message });
  }
};

export const getFavourite = (data) => async (dispatch) => {
  dispatch({ type: "GET_FAVOURITE_LOADING" });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getPackageEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: "GET_FAVOURITE_SUCCESS", payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: "GET_FAVOURITE_ERROR", payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getFavEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: "GET_FAVOURITE_SUCCESS", payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: "GET_FAVOURITE_ERROR", payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: "GET_FAVOURITE_ERROR", payload: error.message });
  }
};

export const addFavourite = (data) => async (dispatch) => {
  dispatch({ type: "ADD_FAVOURITE_LOADING" });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addFavEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: "ADD_FAVOURITE_SUCCESS",
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: "ADD_FAVOURITE_ERROR",
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "ADD_FAVOURITE_ERROR", payload: error.message });
      });
  } catch (error) {
    dispatch({ type: "ADD_FAVOURITE_ERROR", payload: error.message });
  }
};

export const deleteFavourite = (data) => async (dispatch) => {
  dispatch({ type: "DELETE_FAVOURITE_LOADING" });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteFavEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: "DELETE_FAVOURITE_SUCCESS",
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: "DELETE_FAVOURITE_ERROR",
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "DELETE_FAVOURITE_ERROR", payload: error.message });
      });
  } catch (error) {
    dispatch({ type: "DELETE_FAVOURITE_ERROR", payload: error.message });
  }
};

export const destroyAddFavourite = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_FAVOURITE_ADD", payload: "" });
};
export const destroyDeleteFavourite = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_FAVOURITE_DELETE", payload: "" });
};
export const destroyAddFavouriteError = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_ERROR_ADD_FAVOURITE", payload: "" });
};
export const destroyDeleteFavouriteError = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_FAVOURITE", payload: "" });
};

// Package Category

export const getPackageCategories = (data) => async (dispatch) => {
  dispatch({ type: GET_PACKAGE_CATEGORY_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getPackageCategoryEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({
            type: GET_PACKAGE_CATEGORY_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_PACKAGE_CATEGORY_ERROR,
            payload: error.message,
          });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getPackageCategoryEndpoint}`,
      })
        .then((result) => {
          dispatch({
            type: GET_PACKAGE_CATEGORY_SUCCESS,
            payload: result.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: GET_PACKAGE_CATEGORY_ERROR,
            payload: error.message,
          });
        });
    }
  } catch (error) {
    dispatch({ type: GET_PACKAGE_CATEGORY_ERROR, payload: error.message });
  }
};

export const addPackageCategory = (data) => async (dispatch) => {
  dispatch({ type: ADD_PACKAGE_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addPackageCategoryEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_PACKAGE_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: ADD_PACKAGE_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_PACKAGE_CATEGORY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_PACKAGE_CATEGORY_ERROR, payload: error.message });
  }
};

export const editPackageCategory = (data) => async (dispatch) => {
  dispatch({ type: EDIT_PACKAGE_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editPackageCategoryEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_PACKAGE_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: EDIT_PACKAGE_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_PACKAGE_CATEGORY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_PACKAGE_CATEGORY_ERROR, payload: error.message });
  }
};

export const deletePackageCategory = (data) => async (dispatch) => {
  dispatch({ type: DELETE_PACKAGE_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deletePackageCategoryEndPoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_PACKAGE_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_PACKAGE_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PACKAGE_CATEGORY_ERROR,
          payload: error.message,
        });
      });
  } catch (error) {
    dispatch({ type: DELETE_PACKAGE_CATEGORY_ERROR, payload: error.message });
  }
};

export const destroyAddPackageCategory = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_PACKAGE_CATEGORY_ADD, payload: "" });
};
export const destroyEditPackageCategory = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_PACKAGE_CATEGORY_EDIT, payload: "" });
};
export const destroyDeletePackageCategory = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_PACKAGE_CATEGORY_DELETE, payload: "" });
};
export const destroyAddPackageCategoryError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_PACKAGE_ERROR_ADD_C, payload: "" });
};
export const destroyEditPackageCategoryError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_PACKAGE_ERROR_EDIT_C, payload: "" });
};

export const filterPackageCategory = (data) => async (dispatch) => {
  dispatch({ type: GET_PACKAGE_CATEGORY_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${getPackageCategoryEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_PACKAGE_CATEGORY_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_PACKAGE_CATEGORY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_PACKAGE_CATEGORY_ERROR, payload: error.message });
  }
};

export const destroyDeletePackageErrorC = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_PACKAGE_ERROR_C", payload: "" });
};
