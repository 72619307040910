import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
    addAd,
  addLocation,
  destroyAddAd,
  destroyAddAdError,
  destroyAddLocation,
  destroyAddLocationError,
} from "../../../redux/actions/settings";
import Select from "react-select"

function AddAdvertisment({ modal, setModal, setopen }) {
  const [name, setName] = useState("");
  const [edit, setEdit] = useState("");
  const [done, setDone] = useState(false);
  const { isLoading, isAdded, isError } = useSelector(
    (state) => state.addAd
  );
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState("");
  const [imageFile, setImageFile] = useState({});
  const [code, setCode] = useState("");
  const [adLocation, setAdLocation] = useState("")
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const formSubmitted = (e) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('adType',name)
    formData.append("userId", localStorage.getItem("userId"));
    if(name==="image"){
    formData.append('adImage',imageFile)
    formData.append('adLink',title)
    }
    if(name==="code"){
    formData.append('adCode',code)
    }
    formData.append('adLocation',adLocation)
    dispatch(
      addAd(formData)
    ).then((result) => {});
    setDone(isAdded);
  };
  const closeModal = () => {
    setModal(false);
    setopen(true);
    setTitle("")
    setName("")
    setImageFile({})
    setCode("")
    setAdLocation("")
  };
  useEffect(() => {
    if (isAdded !== undefined && isAdded !== "") {
      closeModal();
    }
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setOpen(true);
    }
    return () => {
      return (
        dispatch(destroyAddAd()), dispatch(destroyAddAdError())
      );
    };
  }, [isAdded, isError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody>
          <Container>
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Add Advertisment"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {edit && (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="error">
                          {edit}
                        </Alert>
                      </Snackbar>
                    )}
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Advertisment Type
                              </label>
                              <Select
                                value={{
                                  label: name.toUpperCase(),
                                  value: name,
                                }}
                                onChange={(element) => setName(element.value)}
                                options={[
                                  {
                                    value: "image",
                                    label: "IMAGE",
                                  },
                                  {
                                    value: "code",
                                    label: "CODE",
                                  },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          {name === "image" && (
                            <>
                              <Col lg="12">
                                <FormGroup>
                                  <label>Image</label>
                                  <Input
                                    className="form-control-alternative"
                                    type="file"
                                    name="description"
                                    onChange={(e) =>
                                      setImageFile(e.target.files[0])
                                    }
                                    accept="image/*"
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="12">
                                <FormGroup>
                                  <label>Advertisment Image Link</label>
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="Image link"
                                    type="text"
                                    name="description"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          )}
                          {name === "code" && (
                            <>
                              <Col lg="12">
                                <FormGroup>
                                  <label>Advertisment Code</label>
                                  <Input
                                    className="form-control-alternative"
                                    placeholder="enter code"
                                    type="text"
                                    name="code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          )}
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Advertisment Location
                              </label>
                              <Select
                                value={{
                                  label: adLocation,
                                  value: adLocation,
                                }}
                                onChange={(element) => setAdLocation(element.value)}
                                options={[
                                  {
                                    value: "Holiday Bottom",
                                    label: "Holiday Bottom",
                                  },
                                  {
                                    value: "Holiday Top",
                                    label: "Holiday Top",
                                  },
                                  {
                                    value: "Package Left",
                                    label: "Package Left",
                                  },
                                  {
                                    value: "Package Right",
                                    label: "Package Right",
                                  },
                                  {
                                    value: "Package Top",
                                    label: "Package Top",
                                  },
                                  {
                                    value: "Package Bottom",
                                    label: "Package Bottom",
                                  }
                                ]}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Add Advertisment
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddAdvertisment;
