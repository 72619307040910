import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  makeStyles,
  Snackbar,
  Select as NSelect,
  Typography,
  withStyles,
  InputBase,
  MenuItem,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { makeArray } from "jquery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { getExclusions } from "../../../redux/actions/exclusion";
import { getInclusions } from "../../../redux/actions/inclusions";
import {
  getCitiesDataHead,
  getPackageCategories,
} from "../../../redux/actions/package";
import {
  addPackage,
  destroyError,
  destroyPackAdd,
  getCities,
  getHotel,
} from "../../../redux/actions/package";
import Select from "react-select";
import { getCompany } from "../../../redux/actions/company";
import { getTransport, getVehicleType } from "../../../redux/actions/transport";

const initialState = {
  name: "",
  description: "",
  nights: 0,
  validityFrom: "",
  validityTo: "",
  cutOff: "",
  bookingValidityFrom: "",
  bookingValidityTo: "",
  travelScope: "",
  type: "",
  isOnline: false,
  cityName: "",
  hotelName: "",
  category: "",
  companyId: "",
  price: "",
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    width: "100%",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
function AddPackage({ modal, setModal, setOpen }) {
  const classes = useStyles();
  const [modal3, setModal3] = useState(false);
  const [departure, setDeparture] = useState("");
  const [departureCity, setDepartureCity] = useState([]);
  const [inclusions, setInclusions] = useState([]);
  const [exclusions, setExclusions] = useState([]);
  const [otherExclusions, setOtherExclusions] = useState("");
  const [otherExclusionsArray, setOtherExclusionsArray] = useState([]);
  const [otherInclusion, setOtherInclusion] = useState("");
  const [otherInclusionArray, setOtherInclusionArray] = useState([]);
  const [files, setFiles] = useState([]);
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState([]);
  const dispatch = useDispatch();
  const [itineraryDummyArray, setItineraryDummyArray] = useState([1]);
  const [itinerary, setItinerary] = useState([
    {
      description: "",
      heading: "",
      type: [],
      cityName: "",
      hotelName: "",
    },
  ]);
  const [stepTypeField, setStepTypeField] = useState("");
  const { isLoading, isError, isAdded } = useSelector((state) => state.package);
  const { hotels } = useSelector((state) => state.getHotel);
  const { cities } = useSelector((state) => state.getCity);
  const { companies } = useSelector((state) => state.getCompany);
  const { packageCategory } = useSelector((state) => state.getPackageCategory);
  const { transport } = useSelector((state) => state.getTransport);
  const { vehicleType } = useSelector((state) => state.getVehicleType);
  const [bannerImage, setBannerImage] = useState([])
  const {
    isLoading: headCityLoading,
    isError: headCityError,
    headCities,
  } = useSelector((state) => state.getCityHead);
  const {
    exclusions: exclusionsItems,
    isError: exclusionsError,
    isLoading: exclusionsLoading,
  } = useSelector((state) => state.getExclusion);
  const {
    inclusions: inclusionsItems,
    isError: inclusionsError,
    isLoading: inclusionsLoading,
  } = useSelector((state) => state.getInclusion);
  const [open, setopen] = useState(false);

  const [edit, setEdit] = useState("");
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name == "nights") {
      setItineraryDummyArray([]);
      for (let i = 0; i <= parseInt(value); i++) {
        setItineraryDummyArray((old) => [...old, i]);
        if (itinerary.length === 0) {
          setItinerary((old) => [
            ...old,
            {
              description: "",
              heading: "",
              type: [],
              cityName: "",
              hotelName: "",
            },
          ]);
        }
      }
      if (itinerary.length !== 0) {
        if (itinerary.length > parseInt(value)) {
          let newArr = [...itinerary];
          const splicedArray = newArr.slice(0, parseInt(value) + 1);
          setItinerary(splicedArray);
        } else {
          for (let i = 1; i <= parseInt(value) + 1 - itinerary.length; i++) {
            setItinerary((old) => [
              ...old,
              {
                description: "",
                heading: "",
                type: [],
                cityName: "",
                hotelName: "",
              },
            ]);
          }
        }
      }
    }
    setData({
      ...data,
      [name]: value,
    });
  };
  const stepChanged = (i) => {
    setStepTypeField({});
    const arr = [...itinerary];
    arr[i]["type"].push({ name: "" });
    setItinerary(arr);
    setStep((old) => [...old, Math.random()]);
  };
  const handleClose = () => {
    setopen(false);
  };
  // const itineraryFieldChanged = (e, index, ind, isCheckbox) => {
  //   let newArr = [...itinerary];
  //   if (e.target) {
  //     if (ind !== undefined) {
  //       if (isCheckbox !== undefined) {
  //         if (e.target.checked) {
  //           if (newArr[index]["type"][ind]["SightSeeing"] !== undefined) {
  //             newArr[index]["type"][ind]["SightSeeing"].push(e.target.value);
  //           } else {
  //             newArr[index]["type"][ind]["SightSeeing"] = [];
  //             newArr[index]["type"][ind]["SightSeeing"].push(e.target.value);
  //           }
  //           setItinerary(newArr);
  //         } else {
  //           var indexItem = newArr[index]["type"][ind]["SightSeeing"].indexOf(
  //             e.target.value
  //           );
  //           newArr[index]["type"][ind]["SightSeeing"].splice(indexItem, 1);
  //           setItinerary(newArr);
  //         }
  //       } else {
  //         newArr[index]["type"][ind][e.target.name] = e.target.value;
  //         setItinerary(newArr);
  //       }
  //     } else {
  //       newArr[index][e.target.name] = e.target.value;
  //       setItinerary(newArr);
  //     }
  //   } else if (e.subName) {
  //     if (e.value === "Sight Seeing") {
  //       dispatch(
  //         getCitiesDataHead({
  //           key: itinerary[index]["cityName"],
  //         })
  //       );
  //     }

  //     newArr[index][e.name][ind][e.subName] = e.value;
  //     setItinerary(newArr);
  //   } else {
  //     newArr[index][e.name] = e.value;
  //     setItinerary(newArr);
  //   }
  // };
  const itineraryFieldChanged = (e, i, ind, subname) => {
    const { name, value } = e.target;
    let newArr = [...itinerary];
    if (name === "cityName") {
      dispatch(
        getCitiesDataHead({
          key: value,
        })
      );
    }
    if (ind !== undefined) {
      newArr[i][name][ind][subname] = value;
    } else {
      newArr[i][name] = value;
    }
    setItinerary(newArr);
  };
  const addDepartureClicked = (e) => {
    e.preventDefault();
    setDepartureCity((old) => {
      return [...old, departure];
    });
    setDeparture("");
  };
  const checkBoxChangeHandler = (e) => {
    const target = e.target;
    const value = target.value;
    if (target.checked) {
      setInclusions((old) => {
        return [...old, value];
      });
    } else {
      setInclusions(inclusions.filter((item) => item !== value));
    }
  };
  const excCheckBoxChange = (e) => {
    const target = e.target;
    const value = target.value;
    if (target.checked) {
      setExclusions((old) => {
        return [...old, value];
      });
    } else {
      setExclusions(inclusions.filter((item) => item !== value));
    }
  };
  const onChangeHandlerImage = (e) => {
    setFiles(e.target.files);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const deleteStepFunction = (i, ind) => {
    const arr = [...itinerary];
    arr[i]["type"].splice(ind, 1);
    setItinerary(arr);
  };
  const toggle = () => setModal(!modal);

  const formSubmitted = (e) => {
    if (files.length > 0) {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData();
      formData.append("packageName", data.name);
      formData.append("packageDescription", data.description);
      formData.append("numberOfNights", data.nights);
      formData.append("packageCategory", data.category);
      formData.append("companyId", data.companyId);
      formData.append("packagePrice", data.price);
      formData.append("userId", localStorage.getItem("userId"));
      formData.append(
        "departureCity",
        departureCity.length > 0 ? departureCity : departure
      );
      formData.append("inclusions", inclusions);
      formData.append(
        "otherInclusions",
        otherInclusionArray.length > 0 ? otherInclusionArray : otherInclusion
      );
      formData.append("exclusions", exclusions);
      formData.append(
        "otherExclusions",
        otherExclusionsArray.length > 0 ? otherExclusionsArray : otherExclusions
      );
      formData.append("cityName", data.cityName);
      for (let i = 0; i <= parseInt(data.nights); i++) {
        formData.append(`itinerary[${i}][day]`, i + 1);
        formData.append(`itinerary[${i}][cityName]`, itinerary[i]["cityName"]);
        formData.append(
          `itinerary[${i}][description]`,
          itinerary[i]["description"]
        );
        formData.append(`itinerary[${i}][heading]`, itinerary[i]["heading"]);
        itinerary[i]["type"].length > 0 &&
          itinerary[i]["type"].map((elm, ind) => {
            if (elm.name === "Transport") {
              return (
                formData.append(
                  `itinerary[${i}][type][${ind}][name]`,
                  itinerary[i]["type"][ind]["name"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][toCity]`,
                  itinerary[i]["type"][ind]["toCity"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][typeTransport]`,
                  itinerary[i]["type"][ind]["typeTransport"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][vehicleType]`,
                  itinerary[i]["type"][ind]["vehicleType"]
                )
              );
            }
            if (elm.name === "Flight") {
              return (
                formData.append(
                  `itinerary[${i}][type][${ind}][name]`,
                  itinerary[i]["type"][ind]["name"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][toCity]`,
                  itinerary[i]["type"][ind]["toCity"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][fromCity]`,
                  itinerary[i]["type"][ind]["fromCity"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][departureTime]`,
                  itinerary[i]["type"][ind]["departureTime"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][arrivalTime]`,
                  itinerary[i]["type"][ind]["arrivalTime"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][checkBaggage]`,
                  itinerary[i]["type"][ind]["checkBaggage"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][cabinBggage]`,
                  itinerary[i]["type"][ind]["cabinBaggage"]
                )
              );
            }
            if (elm.name === "Note") {
              return (
                formData.append(
                  `itinerary[${i}][type][${ind}][name]`,
                  itinerary[i]["type"][ind]["name"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][nodeDescription]`,
                  itinerary[i]["type"][ind]["nodeDescription"]
                )
              );
            }
            if (elm.name === "End of the day") {
              return (
                formData.append(
                  `itinerary[${i}][type][${ind}][name]`,
                  itinerary[i]["type"][ind]["name"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][endDetail]`,
                  itinerary[i]["type"][ind]["endDetail"]
                )
              );
            }
            if (elm.name === "Hotel") {
              return (
                formData.append(
                  `itinerary[${i}][type][${ind}][name]`,
                  itinerary[i]["type"][ind]["name"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][hotelName]`,
                  itinerary[i]["type"][ind]["hotelName"]
                )
              );
            }
            if (elm.name === "Sight Seeing") {
              return (
                formData.append(
                  `itinerary[${i}][type][${ind}][name]`,
                  itinerary[i]["type"][ind]["name"]
                ),
                formData.append(
                  `itinerary[${i}][type][${ind}][sightSeeing]`,
                  itinerary[i]["type"][ind]["sightSeeing"]
                )
              );
            }
          });
      }
      for (var i = 0; i < files.length; i++) {
        formData.append("packageImage", files[i]);
      }
      for(var j = 0; j<bannerImage.length;j++){
        formData.append("bannerImage",bannerImage[j])
      }
      dispatch(addPackage(formData));
      setLoading(false);
    } else {
      setEdit("Please fill all the fields");
      setopen(true);
    }
  };
  const closeModal = () => {
    setModal(false);
    setOpen(true);
    setData(initialState);
    setItinerary({
      description: "",
      heading: "",
      type: [],
      cityName: "",
      hotelName: "",
    });
    setFiles([]);
    setDeparture("");
    setDepartureCity([]);
    setExclusions([]);
    setInclusions([]);
    setOtherExclusionsArray([]);
    setOtherInclusionArray([]);
    setOtherInclusion("");
    setOtherExclusions("");
  };
  useEffect(() => {
    dispatch(getCities());
    dispatch(getHotel());
    dispatch(getExclusions());
    dispatch(getInclusions());
    dispatch(getCompany());
    dispatch(getPackageCategories());
    dispatch(getTransport());
    dispatch(getVehicleType());
    if (isAdded && isAdded !== "") {
      closeModal();
    }
    if (isError && isError !== "") {
      setEdit(isError);
      setopen(true);
    }
    return () => {
      return dispatch(destroyPackAdd()), dispatch(destroyError("package"));
    };
  }, [isAdded,isError]);
  return (
    <div>
      <Container className="top-100">
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {edit}
            </Alert>
          </Snackbar>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="5">
                <h3 className="mb-0">{"Add Package"}</h3>
              </Col>
              <Col className="text-right" xs="7">
                <Button
                  color="primary"
                  onClick={(e) => setModal(false)}
                  size="sm"
                >
                  View All Packages
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">Fill the form</h6>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Package Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-username"
                        placeholder="Package name"
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={onChangeHandler}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label>Package Description</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="description"
                        rows="4"
                        type="textarea"
                        name="description"
                        value={data.description}
                        onChange={onChangeHandler}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" for="exampleSelect">
                        Package Category
                      </label>
                      <NSelect
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        name="category"
                        placeholder="select category"
                        value={data.category}
                        onChange={onChangeHandler}
                        input={<BootstrapInput />}
                      >
                        {packageCategory.length > 0 &&
                          packageCategory.map((e, i) => {
                            return (
                              <MenuItem value={e.packageCategoryId}>
                                {e.packageCategory}
                              </MenuItem>
                            );
                          })}
                      </NSelect>

                      {/* <Select
                        value={data.category}
                        onChange={onChangeHandler}
                        options={
                          packageCategory.length > 0 &&
                          packageCategory.map((e, i) => {
                            return {
                              value: e.packageCategoryId,
                              label: e.packageCategory,
                              name: "category",
                            };
                          })
                        }
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" for="exampleSelect">
                        City Name
                      </label>

                      <NSelect
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        name="cityName"
                        value={data.cityName}
                        onChange={onChangeHandler}
                        input={<BootstrapInput />}
                      >
                        {cities.length > 0 &&
                          cities.map((e, i) => {
                            return (
                              <MenuItem value={e.cityId}>
                                {e.cityName}
                              </MenuItem>
                            );
                          })}
                      </NSelect>
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" for="exampleSelect">
                        No. of Nights
                      </label>
                      <NSelect
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        name="nights"
                        value={data.nights}
                        onChange={onChangeHandler}
                        input={<BootstrapInput />}
                      >
                        {[
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20,
                        ].length > 0 &&
                          [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20,
                          ].map((e, i) => {
                            return <MenuItem value={e}>{e}</MenuItem>;
                          })}
                      </NSelect>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="pl-lg-4">
                <Row>
                  <FormGroup>
                    <label className="form-control-label">Inclusions</label>
                    <div className="input-check-div">
                      {inclusionsError ? (
                        <>{exclusionsError}</>
                      ) : inclusionsLoading ? (
                        <>
                          <CircularProgress />
                        </>
                      ) : (
                        inclusionsItems.length > 0 &&
                        inclusionsItems.map((e, i) => {
                          return (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  value={e.inclusionName}
                                  onChange={checkBoxChangeHandler}
                                />{" "}
                                {e.inclusionName}
                              </Label>
                            </FormGroup>
                          );
                        })
                      )}
                    </div>
                  </FormGroup>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-address"
                      >
                        Other Inclusions
                      </label>
                      <InputGroup>
                        <Input
                          className="form-control-alternative"
                          id="input-inclusions"
                          placeholder="Other inclusions"
                          type="text"
                          value={otherInclusion}
                          onChange={(e) => setOtherInclusion(e.target.value)}
                        />
                        <InputGroupAddon addonType="append">
                          <Button
                            variant="danger"
                            onClick={(e) => {
                              return (
                                e.preventDefault(),
                                setOtherInclusionArray((old) => {
                                  return [...old, otherInclusion];
                                }),
                                setOtherInclusion("")
                              );
                            }}
                          >
                            Add
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                      {otherInclusionArray.length > 0 &&
                        otherInclusionArray.map((e) => {
                          return (
                            <div>
                              {e}{" "}
                              <i
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() =>
                                  setOtherInclusionArray(
                                    otherInclusionArray.filter(
                                      (elm) => elm !== e
                                    )
                                  )
                                }
                              >
                                X
                              </i>
                            </div>
                          );
                        })}
                    </FormGroup>
                  </Col>
                  <FormGroup>
                    <label className="form-control-label">Exclusions</label>
                    <div className="input-check-div">
                      {exclusionsError ? (
                        <>{exclusionsError}</>
                      ) : exclusionsLoading ? (
                        <>
                          <CircularProgress />
                        </>
                      ) : (
                        exclusionsItems.length > 0 &&
                        exclusionsItems.map((e, i) => {
                          return (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  value={e.exclusionName}
                                  onChange={excCheckBoxChange}
                                />{" "}
                                {e.exclusionName}
                              </Label>
                            </FormGroup>
                          );
                        })
                      )}
                    </div>
                  </FormGroup>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-address"
                      >
                        Other Exclusions
                      </label>
                      <InputGroup>
                        <Input
                          className="form-control-alternative"
                          id="input-address"
                          type="text"
                          placeholder="other exclusions"
                          value={otherExclusions}
                          onChange={(e) => setOtherExclusions(e.target.value)}
                        />
                        <InputGroupAddon addonType="append">
                          <Button
                            variant="danger"
                            onClick={(e) => {
                              return (
                                e.preventDefault,
                                setOtherExclusionsArray((old) => {
                                  return [...old, otherExclusions];
                                }),
                                setOtherExclusions("")
                              );
                            }}
                          >
                            Add
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                      {otherExclusionsArray.length > 0 &&
                        otherExclusionsArray.map((e) => {
                          return (
                            <div>
                              {e}{" "}
                              <i
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() =>
                                  setOtherExclusionsArray(
                                    otherExclusionsArray.filter(
                                      (elm) => elm !== e
                                    )
                                  )
                                }
                              >
                                X
                              </i>
                            </div>
                          );
                        })}
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label" for="exampleSelect">
                        Company Id
                      </label>
                      <NSelect
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={data.companyId}
                        name="companyId"
                        onChange={onChangeHandler}
                        input={<BootstrapInput />}
                      >
                        {companies.length > 0 &&
                          companies.map((e, i) => {
                            return (
                              <MenuItem value={e.companyId}>
                                {e.companyName}
                              </MenuItem>
                            );
                          })}
                      </NSelect>
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Price (&#8377;)
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-username"
                        placeholder="Price"
                        type="number"
                        name="price"
                        value={data.price}
                        onChange={onChangeHandler}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Button
                        color="primary"
                        onClick={(e) => {
                          return e.preventDefault(), setModal3(true);
                        }}
                      >
                        Add Itinerary
                      </Button>{" "}
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Upload Banner Image
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-first-name"
                        placeholder="cut off date"
                        type="file"
                        name="bannerImage"
                        onChange={(e)=>setBannerImage(e.target.files)}
                        accept="image/*"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Upload Images
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-first-name"
                        placeholder="cut off date"
                        type="file"
                        name="cutOff"
                        onChange={onChangeHandlerImage}
                        multiple
                        accept="image/*"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <Button
                      color="primary"
                      disabled={isLoading}
                      onClick={formSubmitted}
                    >
                      Add Package
                    </Button>{" "}
                  </Col>
                  <Col lg="6">
                    <Button color="secondary" onClick={toggle}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
      {modal3 && (
        <Modal isOpen={modal3} toggle={() => setModal3(false)}>
          <ModalBody>
            <Container>
              <Row>
                <Col className="order-xl-1" lg="12" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">{"Itinerary"}</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {itineraryDummyArray.length > 0 ? (
                        itineraryDummyArray.map((e, i) => {
                          return (
                            <Accordion
                              expanded={expanded === `panel${i + 1}`}
                              onChange={handleChange(`panel${i + 1}`)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Typography className={classes.heading}>
                                  Day {i + 1}
                                </Typography>
                                <Typography
                                  className={classes.secondaryHeading}
                                >
                                  <Input
                                    type="text"
                                    placeholder={"enter heading"}
                                    name={"heading"}
                                    value={itinerary[i]["heading"]}
                                    onChange={(element) =>
                                      itineraryFieldChanged(element, i)
                                    }
                                  />
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  <FormGroup>
                                    <Input
                                      type="text"
                                      placeholder={"enter description"}
                                      name={"description"}
                                      onChange={(element) =>
                                        itineraryFieldChanged(element, i)
                                      }
                                      value={itinerary[i]["description"]}
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      for="exampleSelect"
                                    >
                                      City Name
                                    </label>
                                    <NSelect
                                      labelId="demo-customized-select-label"
                                      id="demo-customized-select"
                                      value={itinerary[i]["cityName"]}
                                      name="cityName"
                                      onChange={(element) =>
                                        itineraryFieldChanged(element, i)
                                      }
                                      input={<BootstrapInput />}
                                    >
                                      {cities.length > 0 &&
                                        cities.map((e, i) => {
                                          return (
                                            <MenuItem value={e.cityName}>
                                              {e.cityName}
                                            </MenuItem>
                                          );
                                        })}
                                    </NSelect>
                                    {/* <Select
                                      value={{
                                        label: itinerary[i]["cityName"],
                                        value: itinerary[i]["cityName"],
                                      }}
                                      onChange={(element) =>
                                        itineraryFieldChanged(element, i)
                                      }
                                      options={
                                        cities.length > 0 &&
                                        cities.map((e, i) => {
                                          return {
                                            value: e.cityName,
                                            label: e.cityName,
                                            name: "cityName",
                                          };
                                        })
                                      }
                                    /> */}
                                  </FormGroup>
                                  {itinerary[i]["type"].length > 0 &&
                                    itinerary[i]["type"].map((elm, ind) => {
                                      return (
                                        <>
                                          <label className="form-control-label">
                                            {" "}
                                            Step {ind + 1}{" "}
                                            <i
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                deleteStepFunction(i, ind)
                                              }
                                            >
                                              delete step
                                            </i>
                                          </label>
                                          <FormGroup>
                                            <label className="form-control-label">
                                              {" "}
                                              Step Type
                                            </label>
                                            <NSelect
                                              labelId="demo-customized-select-label"
                                              id="demo-customized-select"
                                              value={
                                                itinerary[i]["type"][ind][
                                                  "name"
                                                ]
                                              }
                                              name="type"
                                              onChange={(element) => {
                                                return itineraryFieldChanged(
                                                  element,
                                                  i,
                                                  ind,
                                                  "name"
                                                );
                                              }}
                                              input={<BootstrapInput />}
                                            >
                                              <MenuItem value={"Transport"}>
                                                {"Transport"}
                                              </MenuItem>
                                              <MenuItem value={"Sight Seeing"}>
                                                {"Sight Seeing"}
                                              </MenuItem>
                                              <MenuItem value={"Note"}>
                                                {"Note"}
                                              </MenuItem>
                                              <MenuItem value={"Hotel"}>
                                                {"Hotel"}
                                              </MenuItem>
                                              <MenuItem
                                                value={"End of the day"}
                                              >
                                                {"End of the day"}
                                              </MenuItem>
                                              <MenuItem value={"Flight"}>
                                                {"Flight"}
                                              </MenuItem>
                                            </NSelect>
                                            {/* <Select
                                              value={{
                                                label:
                                                  itinerary[i]["type"][ind][
                                                    "name"
                                                  ],
                                                value:
                                                  itinerary[i]["type"][ind][
                                                    "name"
                                                  ],
                                              }}
                                              onChange={(element) => {
                                                return (
                                                  itineraryFieldChanged(
                                                    element,
                                                    i,
                                                    ind
                                                  ),
                                                  setStepTypeField({
                                                    value: element.value,
                                                    ind: ind,
                                                  })
                                                );
                                              }}
                                              options={[
                                                {
                                                  value: "Transport",
                                                  label: "Transport",
                                                  name: "type",
                                                  subName: "name",
                                                },
                                                {
                                                  value: "Sight Seeing",
                                                  label: "Sight Seeing",
                                                  name: "type",
                                                  subName: "name",
                                                },
                                                {
                                                  value: "Note",
                                                  label: "Note",
                                                  name: "type",
                                                  subName: "name",
                                                },
                                                {
                                                  value: "Hotel",
                                                  label: "Hotel",
                                                  name: "type",
                                                  subName: "name",
                                                },
                                                {
                                                  value: "End of the day",
                                                  label: "End of the day",
                                                  name: "type",
                                                  subName: "name",
                                                },
                                                {
                                                  value: "Flight",
                                                  label: "Flight",
                                                  name: "type",
                                                  subName: "name",
                                                },
                                              ]}
                                            /> */}
                                            {itinerary[i]["type"][ind][
                                              "name"
                                            ] === "Flight" && (
                                              <>
                                                <FormGroup>
                                                  <label className="form-control-label">
                                                    Enter details
                                                  </label>
                                                  <Input
                                                    type="text"
                                                    placeholder={"from city"}
                                                    name={"type"}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "fromCity"
                                                      )
                                                    }
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "fromCity"
                                                      ]
                                                    }
                                                  />
                                                  <Input
                                                    type="time"
                                                    placeholder={
                                                      "departure time"
                                                    }
                                                    name={"type"}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "departureTime"
                                                      )
                                                    }
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "departureTime"
                                                      ]
                                                    }
                                                  />
                                                  <Input
                                                    type="time"
                                                    placeholder={"arrival time"}
                                                    name={"type"}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "arrivalTime"
                                                      )
                                                    }
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "arrivalTime"
                                                      ]
                                                    }
                                                  />
                                                  <Input
                                                    type="text"
                                                    placeholder={"To city"}
                                                    name={"type"}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "toCity"
                                                      )
                                                    }
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "toCity"
                                                      ]
                                                    }
                                                  />
                                                  <Input
                                                    type="text"
                                                    placeholder={
                                                      "cabin baggage"
                                                    }
                                                    name={"type"}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "cabinBaggage"
                                                      )
                                                    }
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "cabinBaggage"
                                                      ]
                                                    }
                                                  />
                                                  <Input
                                                    type="text"
                                                    placeholder={
                                                      "check-in baggage"
                                                    }
                                                    name={"type"}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "checkBaggage"
                                                      )
                                                    }
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "checkBaggage"
                                                      ]
                                                    }
                                                  />
                                                </FormGroup>
                                              </>
                                            )}
                                            {itinerary[i]["type"][ind][
                                              "name"
                                            ] === "Transport" && (
                                              <>
                                                <FormGroup>
                                                  <label>To city</label>
                                                  <NSelect
                                                    labelId="demo-customized-select-label"
                                                    id="demo-customized-select"
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "toCity"
                                                      ]
                                                    }
                                                    name="type"
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "toCity"
                                                      )
                                                    }
                                                    input={<BootstrapInput />}
                                                  >
                                                    {cities.length > 0 &&
                                                      cities.map((e, i) => {
                                                        return (
                                                          <MenuItem
                                                            value={e.cityId}
                                                          >
                                                            {e.cityName}
                                                          </MenuItem>
                                                        );
                                                      })}
                                                  </NSelect>
                                                  {/* <Input
                                                    type="text"
                                                    placeholder={"enter city"}
                                                    name={"type"}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "toCity"
                                                      )
                                                    }
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "toCity"
                                                      ]
                                                    }
                                                  /> */}
                                                </FormGroup>
                                                <FormGroup>
                                                  <label>
                                                    Type of transport
                                                  </label>
                                                  <NSelect
                                                    labelId="demo-customized-select-label"
                                                    id="demo-customized-select"
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "typeTransport"
                                                      ]
                                                    }
                                                    name="type"
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "typeTransport"
                                                      )
                                                    }
                                                    input={<BootstrapInput />}
                                                  >
                                                    {transport.length > 0 &&
                                                      transport.map((e, i) => {
                                                        return (
                                                          <MenuItem
                                                            value={
                                                              e.transportId
                                                            }
                                                          >
                                                            {e.transportType}
                                                          </MenuItem>
                                                        );
                                                      })}
                                                  </NSelect>
                                                  {/* <Input
                                                    type="text"
                                                    placeholder={
                                                      "enter Type of transport"
                                                    }
                                                    name={"type"}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "typeTransport"
                                                      )
                                                    }
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "typeTransport"
                                                      ]
                                                    }
                                                  /> */}
                                                </FormGroup>
                                                <FormGroup>
                                                  <label>Vehicle Type</label>
                                                  <NSelect
                                                    labelId="demo-customized-select-label"
                                                    id="demo-customized-select"
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "vehicleType"
                                                      ]
                                                    }
                                                    name="type"
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "vehicleType"
                                                      )
                                                    }
                                                    input={<BootstrapInput />}
                                                  >
                                                    {vehicleType.length > 0 &&
                                                      vehicleType.map(
                                                        (e, i) => {
                                                          return (
                                                            <MenuItem
                                                              value={
                                                                e.vehicleId
                                                              }
                                                            >
                                                              {e.vehicleType}
                                                            </MenuItem>
                                                          );
                                                        }
                                                      )}
                                                  </NSelect>
                                                  {/* <Input
                                                    type="text"
                                                    placeholder={
                                                      "enter vehile type"
                                                    }
                                                    name={"type"}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "vehicleType"
                                                      )
                                                    }
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "vehicleType"
                                                      ]
                                                    }
                                                  /> */}
                                                </FormGroup>
                                              </>
                                            )}

                                            {itinerary[i]["type"][ind][
                                              "name"
                                            ] === "Sight Seeing" && (
                                              <>
                                                <label>
                                                  select sight seeing
                                                </label>
                                                <NSelect
                                                  labelId="demo-customized-select-label"
                                                  id="demo-customized-select"
                                                  value={
                                                    itinerary[i]["type"][ind][
                                                      "sightSeeing"
                                                    ]
                                                  }
                                                  name="type"
                                                  onChange={(element) =>
                                                    itineraryFieldChanged(
                                                      element,
                                                      i,
                                                      ind,
                                                      "sightSeeing"
                                                    )
                                                  }
                                                  input={<BootstrapInput />}
                                                >
                                                  {headCities[0] &&
                                                    headCities[0].sightSeeing
                                                      .length > 0 &&
                                                    headCities[0].sightSeeing.map(
                                                      (it, p) => {
                                                        return (
                                                          <MenuItem
                                                            value={it.header}
                                                          >
                                                            {it.header}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </NSelect>
                                                {/* <Select
                                                  value={{
                                                    label:
                                                      itinerary[i]["type"][ind][
                                                        "sightSeeing"
                                                      ],
                                                    value:
                                                      itinerary[i]["type"][ind][
                                                        "sightSeeing"
                                                      ],
                                                  }}
                                                  onChange={(element) =>
                                                    itineraryFieldChanged(
                                                      element,
                                                      i,
                                                      ind
                                                    )
                                                  }
                                                  options={
                                                    headCities[0] &&
                                                    headCities[0].sightSeeing
                                                      .length > 0 &&
                                                    headCities[0].sightSeeing.map(
                                                      (it, p) => {
                                                        return {
                                                          value: it.header,
                                                          label: it.header,
                                                          name: "type",
                                                          subName:
                                                            "sightSeeing",
                                                        };
                                                      }
                                                    )
                                                  }
                                                /> */}
                                              </>
                                            )}
                                            {itinerary[i]["type"][ind][
                                              "name"
                                            ] === "Note" && (
                                              <FormGroup>
                                                <label>Description</label>
                                                <Input
                                                  type="text"
                                                  placeholder={
                                                    "enter description"
                                                  }
                                                  name={"type"}
                                                  onChange={(element) =>
                                                    itineraryFieldChanged(
                                                      element,
                                                      i,
                                                      ind,
                                                      "nodeDescription"
                                                    )
                                                  }
                                                  value={
                                                    itinerary[i]["type"][ind][
                                                      "nodeDescription"
                                                    ]
                                                  }
                                                />
                                              </FormGroup>
                                            )}
                                            {itinerary[i]["type"][ind][
                                              "name"
                                            ] === "End of the day" && (
                                              <FormGroup>
                                                <label>Type Detail</label>
                                                <Input
                                                  type="text"
                                                  placeholder={"enter detail"}
                                                  name={"endDetail"}
                                                  onChange={(element) =>
                                                    itineraryFieldChanged(
                                                      element,
                                                      i,
                                                      ind,
                                                      "endDetail"
                                                    )
                                                  }
                                                  value={
                                                    itinerary[i]["type"][ind][
                                                      "endDetail"
                                                    ]
                                                  }
                                                />
                                              </FormGroup>
                                            )}
                                            {itinerary[i]["type"][ind][
                                              "name"
                                            ] === "Hotel" && (
                                              <>
                                                <FormGroup>
                                                  <label
                                                    className="form-control-label"
                                                    for="exampleSelect"
                                                  >
                                                    Hotel Name
                                                  </label>
                                                  <NSelect
                                                    labelId="demo-customized-select-label"
                                                    id="demo-customized-select"
                                                    value={
                                                      itinerary[i]["type"][ind][
                                                        "hotelName"
                                                      ]
                                                    }
                                                    name="type"
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind,
                                                        "hotelName"
                                                      )
                                                    }
                                                    input={<BootstrapInput />}
                                                  >
                                                    {hotels.length > 0 &&
                                                      hotels.map((it, p) => {
                                                        return (
                                                          <MenuItem
                                                            value={it.hotelId}
                                                          >
                                                            {it.hotelName},
                                                            {it.hotelCity}
                                                          </MenuItem>
                                                        );
                                                      })}
                                                  </NSelect>
                                                  {/* <Select
                                                    value={{
                                                      label:
                                                        hotels.length > 0 &&
                                                        hotels.map(
                                                          (item, place) => {
                                                            if (
                                                              item.hotelId ===
                                                              itinerary[i][
                                                                "type"
                                                              ][ind][
                                                                "hotelName"
                                                              ]
                                                            ) {
                                                              return (
                                                                item.hotelName +
                                                                "," +
                                                                item.hotelCity
                                                              );
                                                            }
                                                          }
                                                        ),
                                                      value:
                                                        itinerary[i]["type"][
                                                          ind
                                                        ]["hotelName"],
                                                    }}
                                                    onChange={(element) =>
                                                      itineraryFieldChanged(
                                                        element,
                                                        i,
                                                        ind
                                                      )
                                                    }
                                                    options={
                                                      hotels.length > 0 &&
                                                      hotels.map((e, i) => {
                                                        return {
                                                          value: e.hotelId,
                                                          label:
                                                            e.hotelName +
                                                            "," +
                                                            e.hotelCity,
                                                          name: "type",
                                                          subName: "hotelName",
                                                        };
                                                      })
                                                    }
                                                  /> */}
                                                </FormGroup>
                                              </>
                                            )}
                                          </FormGroup>
                                        </>
                                      );
                                    })}
                                  <Button onClick={() => stepChanged(i)}>
                                    Add Step (total steps -{" "}
                                    {itinerary[i]["type"].length})
                                  </Button>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })
                      ) : (
                        <>select no. of nights first</>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setModal3(false)}>
              Save
            </Button>
            <Button color="secondary" onClick={() => setModal3(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export default AddPackage;
