import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import inside from "../../../assets/img/new/blogInside.png";
import banner from "../../../assets/img/new/blogBanner.png";
import UserNavbar from "../../../common/navbar/UserNavbar";
import {
  Email,
  Facebook,
  GitHub,
  LinkedIn,
  Twitter,
  WhatsApp,
} from "@material-ui/icons";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogByCategory,
  getBlogs,
  getBlogSLug,
} from "../../../redux/actions/blog";
import { baseUrlImage, imagePath } from "../../../redux/url";
import { Alert } from "@material-ui/lab";
import { DiscussionEmbed, CommentCount } from "disqus-react";
import OwlCarousel from "react-owl-carousel";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { getUsers } from "../../../redux/actions/user";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});
function BlogDetail(props) {
  const classes = useStyles();
  const history = useHistory();
  const query = new URLSearchParams(props.location.search);
  const dispatch = useDispatch();
  const { isLoading, isError, slugBlog, categoryBlog } = useSelector(
    (state) => state.getBlog
  );
  const { users } = useSelector((state) => state.getUser);
  var blogTitle = "";
  var dbId = "";
  var no = 0;
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUsers());
    dispatch(
      getBlogSLug({
        slug: props.match.params.id,
      })
    );
    dispatch(
      getBlogByCategory({
        category: query.get("category"),
      })
    );
  }, [props.match.params.id, query.get("category")]);
  return (
    <>
      <div className="blog-detail">
        {isLoading ? (
          <>
            <LinearProgress style={{ marginTop: "100px" }} />
          </>
        ) : isError ? (
          <>
            <Alert severity="error">{isError}</Alert>
          </>
        ) : (
          slugBlog.length > 0 &&
          slugBlog.map((e, i) => {
            blogTitle = e.blogTitle;
            dbId = e._id;
            return (
              <>
                <div className="banner-image">
                {
                  e.blogImages?.length>0&&
                  <img
                    src={baseUrlImage + imagePath + e.blogImages[0].imageName}
                    alt="blog-detail-banner"
                  />
                }
                </div>
                <div className="blog-info">
                  <Container>
                    <Row>
                      <Col lg="12">
                        <Paper>
                          <div className="blog-info-head">
                            <Button
                              variant="contained"
                              onClick={() =>
                                history.push(
                                  `/Blogs?category=${e.blogCategory[0].value}`
                                )
                              }
                            >
                              {e.blogCategory[0].label}
                            </Button>
                            <h4>{e.blogTitle}</h4>
                          </div>
                          <div
                            className="blog-info-content-1"
                            dangerouslySetInnerHTML={{
                              __html: e.blogDescription,
                            }}
                          ></div>
                        </Paper>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </>
            );
          })
        )}
        <div className="blog-info">
          <Container>
            <Row>
              <Col lg="12">
                <div className="blog-share">
                  <Paper className="share-paper">
                    <h4>Share</h4>
                    <div className="blog-share-icon">
                      <FacebookShareButton url={window.location.href}>
                        <IconButton aria-label="delete">
                          <Facebook />
                        </IconButton>
                      </FacebookShareButton>
                      <EmailShareButton url={window.location.href}>
                        <IconButton aria-label="delete">
                          <Email />
                        </IconButton>
                      </EmailShareButton>
                      <WhatsappShareButton url={window.location.href}>
                        <IconButton aria-label="delete">
                          <WhatsApp />
                        </IconButton>
                      </WhatsappShareButton>
                      <TwitterShareButton url={window.location.href}>
                        <IconButton aria-label="delete">
                          <Twitter />
                        </IconButton>
                      </TwitterShareButton>
                    </div>
                  </Paper>
                </div>
              </Col>
              <Col lg="12">
                <div className="related-blogs">
                  <Paper>
                    <h5>You Might Also like</h5>
                    <div className="related-cards">
                      <Row>
                        {categoryBlog.length > 0 &&
                          categoryBlog.map((e, i) => {
                            if (e.slug !==props.match.params.id && no<3) {
                              no++;
                              return (
                                <Col lg="4">
                                  <Card>
                                    <CardActionArea>
                                      <Link
                                        to={`/blog/${e.slug}?category=${e.blogCategory[0]?.value}`}
                                      >
                                      {
                                        e.blogImages?.length>0&&
                                        <CardMedia
                                          className={classes.media}
                                          image={
                                            baseUrlImage +
                                            imagePath +
                                            e.blogImages[0].imageName
                                          }
                                          title={e.blogCategory[0]?.label}
                                        />
                                      }
                                      </Link>
                                      <CardContent>
                                        <Link
                                          to={`/Blogs?category=${e.blogCategory[0]?.value}`}
                                        >
                                          <Button
                                            variant="contained"
                                            className="tag-button"
                                          >
                                            {e.blogCategory[0]?.label}
                                          </Button>
                                        </Link>
                                        <div>
                                          <i className="fas fa-phone">
                                            {users.length > 0 &&
                                              users.map((elm, ind) => {
                                                if (elm._id === e.postedBy) {
                                                  return elm.userName;
                                                }
                                              })}
                                          </i>
                                          <i className="fas fa-phone">
                                            {e.postedOn}
                                          </i>
                                        </div>
                                        <Link
                                          to={`/blog/${e.slug}?category=${e.blogCategory[0]?.value}`}
                                        >
                                          <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="h2"
                                          >
                                            {e.blogTitle}
                                          </Typography>
                                        </Link>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Col>
                              );
                            }
                          })}
                      </Row>
                    </div>
                  </Paper>
                </div>
              </Col>

              <Col lg="12">
                <div id="disqus_thread"></div>
                <DiscussionEmbed
                  shortname="maya-travels"
                  config={{
                    url: window.location.href,
                    identifier: dbId,
                    title: blogTitle,
                  }}
                />
              </Col>
              {/* <Col lg="12">
                <div className="blog-comments">
                  <Paper>
                    <h6>Comments(3)</h6>
                    <Row>
                      <Col lg="12">
                        <div className="comment">
                          <div className="comment-avatar">
                            <Avatar src={banner} />
                          </div>
                          <div className="comment-info">
                            <div className="comment-name">Mike Bhand</div>
                            <div className="comment-upload">
                              15, Aug 2021, 24:00
                            </div>
                            <div className="comment-data">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Pellentesque malesuada lectus lacus, id
                              consequat ante tincidunt et. Integer eros nunc,
                              porta sed sapien quis, viverra fermentum sem
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="comment">
                          <div className="comment-avatar">
                            <Avatar src={banner} />
                          </div>
                          <div className="comment-info">
                            <div className="comment-name">Mike Bhand</div>
                            <div className="comment-upload">
                              15, Aug 2021, 24:00
                            </div>
                            <div className="comment-data">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Pellentesque malesuada lectus lacus, id
                              consequat ante tincidunt et. Integer eros nunc,
                              porta sed sapien quis, viverra fermentum sem
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="comment">
                          <div className="comment-avatar">
                            <Avatar src={banner} />
                          </div>
                          <div className="comment-info">
                            <div className="comment-name">Mike Bhand</div>
                            <div className="comment-upload">
                              15, Aug 2021, 24:00
                            </div>
                            <div className="comment-data">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Pellentesque malesuada lectus lacus, id
                              consequat ante tincidunt et. Integer eros nunc,
                              porta sed sapien quis, viverra fermentum sem
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Paper>
                </div>
              </Col>
              <Col lg="12">
                <div className="blog-post-comment">
                  <Paper>
                    <h4>Leave a reply</h4>
                    <Row className="post-comment-form">
                      <Col lg="12" className="post-comment-form-field">
                        <TextField
                          id="outlined-basic"
                          label="NAME"
                          variant="outlined"
                        />
                      </Col>
                      <Col lg="12" className="post-comment-form-field">
                        <TextField
                          id="outlined-basic"
                          label="EMAIL ADDRESS"
                          variant="outlined"
                        />
                      </Col>
                      <Col lg="12" className="post-comment-form-field">
                        <TextField
                          id="outlined-basic"
                          label="WEBSITE"
                          variant="outlined"
                        />
                      </Col>
                      <Col lg="12" className="post-comment-form-field">
                        <TextField
                          id="outlined-basic"
                          label="COMMENT"
                          multiline
                          rows={7}
                          variant="outlined"
                        />
                      </Col>
                      <Col lg="12" className="post-comment-form-button">
                        <Button>Post a comment</Button>
                      </Col>
                    </Row>
                  </Paper>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default BlogDetail;
