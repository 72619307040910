import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { deleteCityImage, destroyDeleteErrorImageCity, getCities } from "../../../redux/actions/package";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  destroyEditBlog,
  destroyEditBlogError,
  editBlogs,
  getCategory,
} from "../../../redux/actions/blog";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { baseUrlImage, imagePath } from "../../../redux/url";

function EditBlog({ modal, setModal, data, setOpen }) {
  const [name, setName] = useState("");
  const [open, setopen] = useState(true);
  const [edit, setEdit] = useState("");
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [featured, setFeatured] = useState(false);
  const [editorPick, setEditorPick] = useState(false);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [bannerImage, setBannerImage] = useState([]);
  const dispatch = useDispatch();
  const { isLoading, isError, isEdited } = useSelector(
    (state) => state.editBlog
  );
  const { category } = useSelector((state) => state.getCategory);
  const { cities } = useSelector((state) => state.getCity);
  const {
    isLoading: deleteImageLoading,
    isError: deleteImageError,
    imageDeleted,
  } = useSelector((state) => state.deleteCityImage);
  const [open1, setOpen1] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [imgId, setImgId] = useState("");
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose = () => {
    setopen(false);
  };
  const toggle = () => setModal(!modal);
  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
        reject(error);
      };
    });
  }
  const formSubmitted = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (var i = 0; i < name.length; i++) {
      formData.append(`blogCategory[${i}][label]`, name[i].label);
      formData.append(`blogCategory[${i}][value]`, name[i].value);
    }
    formData.append("blogTitle", title);
    formData.append(
      "blogDescription",
      draftToHtml(convertToRaw(description.getCurrentContent())).toString()
    );
    formData.append("isFeatured", featured);
    formData.append("userId", localStorage.getItem("userId"));
    formData.append("isEditorPick", editorPick);
    formData.append("cityName", city);
    formData.append("bannerImage", bannerImage[0]);
    formData.append("blogIntroduction", introduction);
    formData.append("blogId", data._id);
    dispatch(editBlogs(formData));
  };
  const closeModal = () => {
    setModal(false);
    setOpen(true);
  };
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };
  const deleteImageFunction = (id, imgIdf,data) => {
    dispatch(
      deleteCityImage({
        imageId: id,
      })
    );
    setImgId(imgIdf);
    document.getElementById(imgIdf).style.display = "none";
    // setSight(sight.filter(elm=>elm!==data))
  };
  useEffect(() => {
    dispatch(getCategory());
    dispatch(getCities());
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setopen(true);
    }
    if (isEdited !== undefined && isEdited !== "") {
      closeModal();
    }
    if (deleteImageError !== undefined && deleteImageError !== "") {
      setDeleteText(deleteImageError);
      setOpen1(true);
      document.getElementById(imgId).style.display = "flex";
    }
    setName(data.blogCategory);
    setTitle(data.blogTitle);
    setCity(data.cityName);
    setFeatured(data.isFeatured);
    setEditorPick(data.isEditorPick);
    setIntroduction(data.blogIntroduction);
    data.blogDescription &&
      setDescription(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data.blogDescription)
          )
        )
      );
    return () => {
      return dispatch(destroyEditBlog()), dispatch(destroyEditBlogError(),dispatch(destroyDeleteErrorImageCity()));
    };
  }, [isEdited, isError, deleteImageError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            {edit && (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {edit}
                </Alert>
              </Snackbar>
            )}
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Edit Blog"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Title
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Title"
                                type="text"
                                name="name"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Introduction text
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="intro text"
                                type="textarea"
                                rows="3"
                                name="intro"
                                value={introduction}
                                onChange={(e) =>
                                  setIntroduction(e.target.value)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label>Blog Description</label>
                              <Editor
                                editorState={description}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                  inline: { inDropdown: true },
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: { inDropdown: true },
                                  image: {
                                    uploadCallback: uploadImageCallBack,
                                    alt: { present: true, mandatory: true },
                                    previewImage: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                for="exampleSelect"
                              >
                                Category
                              </label>
                              {/* <Input
                                type="select"
                                className="form-control-alternative"
                                name="select"
                                id="exampleSelect"
                                name="cityName"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              >
                                <option selected></option>
                                {category.length > 0 &&
                                  category.map((e, i) => {
                                    return (
                                      <option value={e._id}>
                                        {e.blogCategory}
                                      </option>
                                    );
                                  })}
                              </Input> */}
                              <Select
                                name="category"
                                isMulti
                                value={name}
                                onChange={(e) => setName(e)}
                                options={
                                  category.length > 0 &&
                                  category.map((e, i) => {
                                    return {
                                      value: e.blogCategory,
                                      label: e.blogCategory,
                                    };
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label">
                                is Featured
                              </label>
                              <Select
                                value={{
                                  label: JSON.stringify(featured),
                                  value: featured,
                                }}
                                onChange={(elm) => setFeatured(elm.value)}
                                options={[
                                  { value: true, label: "true" },
                                  { value: false, label: "false" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label">
                                is Editor pick
                              </label>
                              <Select
                                value={{
                                  label: JSON.stringify(editorPick),
                                  value: editorPick,
                                }}
                                onChange={(elm) => setEditorPick(elm.value)}
                                options={[
                                  { value: true, label: "true" },
                                  { value: false, label: "false" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                for="exampleSelect"
                              >
                                City Name
                              </label>
                              {/* <Input
                                type="select"
                                className="form-control-alternative"
                                name="select"
                                id="exampleSelect"
                                name="cityName"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              >
                                <option selected></option>
                                {cities.length > 0 &&
                                  cities.map((e, i) => {
                                    return <option>{e.cityName}</option>;
                                  })}
                              </Input> */}
                              <Select
                                name="cityName"
                                value={{ value: city, label: city }}
                                onChange={(selected) => setCity(selected.value)}
                                options={
                                  cities.length > 0 &&
                                  cities.map((e, i) => {
                                    return {
                                      value: e.cityName,
                                      label: e.cityName,
                                    };
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Add Banner Image
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="file"
                                name="images"
                                onChange={(e) => setBannerImage(e.target.files)}
                                accept="image/*"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="!2" style={{display:"flex"}}>
                          {data.blogImages?.length > 0 &&
                              data.blogImages.map((elm, ind) => {
                                  return (
                                    <div
                                      id={elm.imageId}
                                      style={{
                                        display: "flex",
                                        margin: "0 5px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Avatar
                                        src={baseUrlImage + imagePath+ elm.imageName}
                                      />
                                      <Button
                                        style={{
                                          color: "white",
                                          cursor: "pointer",
                                          backgroundColor: "black",
                                          boxShadow: "none",
                                          padding: "0",
                                          height: "20px",
                                          width: "20px",
                                        }}
                                        onClick={() =>
                                          deleteImageFunction(
                                            elm._id,
                                            elm.imageId
                                          )
                                        }
                                        disabled={deleteImageLoading}
                                      >
                                        X
                                      </Button>
                                    </div>
                                  );
                              })}
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Edit blog
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditBlog;
