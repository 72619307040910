import {
  Instagram,
  LocationOn,
  Facebook,
  Email,
  LinkedIn,
  Cancel,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import UserNavbar from "../../common/navbar/UserNavbar";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getHomeSettings } from "../../redux/actions/settings";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import axios from "axios";
import { baseUrl } from "../../redux/url";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

function ContactUs() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [open, setopen] = useState(false);
  const handleClose = () => {
    setopen(false);
  };
  const { isLoading, isError, settings } = useSelector(
    (state) => state.getHomeSetting
  );
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const formSubmtted = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      userName: name,
      email: email,
      phone: phoneNo,
      subject:subject,
      message: details,
    };
    axios({
      method: "post",
      url: `${baseUrl}/contactUs`,
      data: data,
    })
      .then((result) => {
        setMessage(result.data);
        setopen(true);
        setName("");
        setEmail("");
        setPhoneNo("");
        setSubject("");
        setDetails("");
        setLoading(false);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    window.scrollTo(0,0)
    dispatch(getHomeSettings());
  }, []);
  const list = (anchor) => (
    <div
      className={
        clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        }) + " drawer-list popup"
      }
      role="presentation"
      style={{ width: "100%" }}
    >
      <h3>Contact Us</h3>
      <p>
        But I must explain to you how all this mistaken idea of denouncing
        pleasure and praising pain
      </p>
      <form
        className={classes.form + " drawer-form"}
        noValidate
        onSubmit={formSubmtted}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Phone No"
          name="phone"
          autoComplete="off"
          value={phoneNo}
          onChange={(e) => setPhoneNo(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="subject"
          label="Subject"
          name="subject"
          autoComplete={"off"}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="details"
          label="Enter Details"
          multiline
          rows={4}
          type="text"
          id="details"
          autoComplete={"off"}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          className="contact-submit"
          disabled={loading}
        >
          {loading ? "Submitting" : "Submit"}
        </Button>
      </form>
      <div className="close" onClick={toggleDrawer("right", false)}></div>
    </div>
  );
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={message.success ? "success" : "error"}
        >
          {message.message}
        </Alert>
      </Snackbar>
      <UserNavbar />
      <Container className="contact">
        <Row>
          <Col lg="12" className="contact-text">
            <h3>Let’s have a talk together</h3>
            <p>
              But I must explain to you how all this mistaken idea of denouncing
              pleasure and praising pain was born and I will give you a complete
              account of the system, and expound the actual teachings of the
              great explorer of the truth, the master-builder of human
              happiness. No one rejects, dislikes, or avoids pleasure itself,
              because it is pleasure, but because those who do not know how to
              pursue pleasure rationally encounter consequences that are
              extremely painful.
            </p>
          </Col>
          <Col lg="12" className="contact-info">
            <Row>
              <Col lg="5" className="contact-address">
                {isLoading ? (
                  <CircularProgress />
                ) : isError ? (
                  <>{isError}</>
                ) : (
                  settings.length > 0 &&
                  settings[0].footer &&
                  settings[0].footer[0] && (
                    <>
                      <div className="contact-details">
                        <div className="phone">
                          <a
                            href={`tel:${settings[0].footer[0].footerContactNo}`}
                          >
                            <h5>{settings[0].footer[0].footerContactNo}</h5>
                          </a>
                          <p>Round the clock support</p>
                        </div>
                        <div className="email">
                          <a
                            href={`mailto:${settings[0].footer[0].footerContactEmail}`}
                            className="text-decoration-none"
                          >
                            <h5> {settings[0].footer[0].footerContactEmail}</h5>
                          </a>
                          <p>For any questions</p>
                        </div>
                      </div>
                      <div className="detail-address">
                        <LocationOn />
                        <h5>{settings[0].footer[0].footerContactAddress}</h5>
                      </div>
                      <div className="social-icons">
                        <>
                          {settings[0].socialIcons[0].facebook &&
                            settings[0].socialIcons[0].facebook.url !== "" && (
                              <a href={settings[0].socialIcons[0].facebook.url}>
                                <Facebook />
                              </a>
                            )}
                          &nbsp;
                          {settings[0].socialIcons[0].instagram &&
                            settings[0].socialIcons[0].instagram.url !== "" && (
                              <a
                                href={settings[0].socialIcons[0].instagram.url}
                              >
                                <Instagram />
                              </a>
                            )}
                          &nbsp;
                          {settings[0].socialIcons[0].gmail &&
                            settings[0].socialIcons[0].gmail.url !== "" && (
                              <a href={settings[0].socialIcons[0].gmail.url}>
                              <i class="fab fa-google"></i>
                              </a>
                            )}
                          &nbsp;
                          {settings[0].socialIcons[0].linkedIn &&
                            settings[0].socialIcons[0].linkedIn.url !== "" && (
                              <a href={settings[0].socialIcons[0].linkedIn.url}>
                                <LinkedIn />
                              </a>
                            )}
                        </>
                      </div>
                      <div className="contact-button">
                        <Button onClick={toggleDrawer("right", true)}>
                          Contact us
                        </Button>
                      </div>
                      <Drawer
                        anchor={"right"}
                        open={state["right"]}
                        onClose={toggleDrawer("right", false)}
                        className="drawer"
                      >
                        {/* <IconButton
                          aria-label="delete"
                          onClick={toggleDrawer("right", false)}
                          className={classes.margin + " close-button"}
                        >
                          <Cancel fontSize="large" />
                        </IconButton> */}
                        {list("right")}
                      </Drawer>
                    </>
                  )
                )}
              </Col>
              <Col lg="7" className="contact-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d54891.42287220298!2d76.6973804!3d30.698324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe19a97a37a0f2846!2sMaya%20Travels!5e0!3m2!1sen!2sin!4v1623644484602!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: "0", width: "100%" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactUs;
