import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import { Button } from "bootstrap";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Nav, Navbar, NavItem, NavLink, Row } from "reactstrap";
import TabsApp from "./TabsApp";

function SettingSidebar() {
  const location = useLocation();
  const activeRoute = (routeName) => {
    if (location.pathname == routeName) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="navbar navbar-light bg-white tab-settings-bar">
        <NavItem>
          <Link to="/admin/settings/site">
            <NavLink href="#pablo" active={activeRoute("/admin/settings/site")}>
              <i className="ni ni-key-25 text-pink" />
              {"Site Setting"}
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/admin/settings/blogCategory">
            <NavLink
              href="#pablo"
              active={activeRoute("/admin/settings/blogCategory")}
            >
              <i className="ni ni-tag text-orange" />
              {"Blog Category"}
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/admin/settings/location">
            <NavLink
              href="#pablo"
              active={activeRoute("/admin/settings/location")}
            >
              <i className="ni ni-compass-04 text-blue" />
              {"Location"}
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/admin/settings/advertisment">
            <NavLink
              href="#pablo"
              active={activeRoute("/admin/settings/advertisment")}
            >
              <i className="ni ni-album-2 text-yellow" />
              {"Advertisment"}
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/admin/settings/inclusion">
            <NavLink
              href="#pablo"
              active={activeRoute("/admin/settings/inclusion")}
            >
              <i className="ni ni-check-bold text-green" />
              {"Inclusion"}
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/admin/settings/exclusion">
            <NavLink
              href="#pablo"
              active={activeRoute("/admin/settings/exclusion")}
            >
              <i className="ni ni-check-bold text-red" />
              {"Exclusion"}
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/admin/settings/packageCategory">
            <NavLink
              href="#pablo"
              active={activeRoute("/admin/settings/packageCategory")}
            >
              <i className="ni ni-tag text-orange" />
              {"Package Category"}
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/admin/settings/transport">
            <NavLink
              href="#pablo"
              active={activeRoute("/admin/settings/transport")}
            >
              <i className="ni ni-delivery-fast text-pink" />
              {"Transport Type"}
            </NavLink>
          </Link>
        </NavItem>
        <NavItem>
          <Link to="/admin/settings/vehicle">
            <NavLink
              href="#pablo"
              active={activeRoute("/admin/settings/vehicle")}
            >
              <i className="ni ni-bus-front-12 text-blue" />
              {"Vehicle Type"}
            </NavLink>
          </Link>
        </NavItem>
      </div>
      <Navbar
        className="navbar-vertical navbar-light bg-white setting-sidebar"
        expand="md"
        id="sidenav-main"
      >
        <div className="nested-sidebar">
          <NavItem>
            <Link to={window.location.pathname}>
              <NavLink href="#pablo"></NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/admin/settings/site">
              <NavLink
                href="#pablo"
                active={activeRoute("/admin/settings/site")}
              >
                <i className="ni ni-key-25 text-pink" />
                {"Site Setting"}
              </NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/admin/settings/blogCategory">
              <NavLink
                href="#pablo"
                active={activeRoute("/admin/settings/blogCategory")}
              >
                <i className="ni ni-tag text-orange" />
                {"Blog Category"}
              </NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/admin/settings/location">
              <NavLink
                href="#pablo"
                active={activeRoute("/admin/settings/location")}
              >
                <i className="ni ni-compass-04 text-blue" />
                {"Location"}
              </NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/admin/settings/advertisment">
              <NavLink
                href="#pablo"
                active={activeRoute("/admin/settings/advertisment")}
              >
                <i className="ni ni-album-2 text-yellow" />
                {"Advertisment"}
              </NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/admin/settings/inclusion">
              <NavLink
                href="#pablo"
                active={activeRoute("/admin/settings/inclusion")}
              >
                <i className="ni ni-check-bold text-green" />
                {"Inclusion"}
              </NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/admin/settings/exclusion">
              <NavLink
                href="#pablo"
                active={activeRoute("/admin/settings/exclusion")}
              >
                <i className="ni ni-check-bold text-red" />
                {"Exclusion"}
              </NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/admin/settings/packageCategory">
              <NavLink
                href="#pablo"
                active={activeRoute("/admin/settings/packageCategory")}
              >
                <i className="ni ni-tag text-orange" />
                {"Package Category"}
              </NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/admin/settings/transport">
              <NavLink
                href="#pablo"
                active={activeRoute("/admin/settings/transport")}
              >
                <i className="ni ni-delivery-fast text-pink" />
                {"Transport Type"}
              </NavLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/admin/settings/vehicle">
              <NavLink
                href="#pablo"
                active={activeRoute("/admin/settings/vehicle")}
              >
                <i className="ni ni-bus-front-12 text-blue" />
                {"Vehicle Type"}
              </NavLink>
            </Link>
          </NavItem>
        </div>
      </Navbar>
    </>
  );
}

export default SettingSidebar;
