import {
  AccordionActions,
  Avatar,
  Button,
  Divider,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import { deleteCityImage, destroyDeleteErrorImageCity } from "../../../../redux/actions/package";
import {
  addHomeSetting,
  destroyAddHomeSetting,
  destroyAddHomeSettingError,
  getHomeSettings,
} from "../../../../redux/actions/settings";
import { baseUrlImage, imagePath } from "../../../../redux/url";

const initialState = {
  pageTitle: "",
  metaTagName: "",
  metaTagDescription: "",
  headerNotice: "",
  heading: "",
  description: "",
};
function HeaderSetting({ setExpanded, setOpen, data }) {
  const dispatch = useDispatch();
  const [favImage, setFavImage] = useState({});
  const [headImage, setHeadImage] = useState({});
  const [bannerImages, setBannerImages] = useState([]);
  const [headData, setHeadData] = useState(initialState);
  const {
    isLoading: loading,
    isError: error,
    isAdded: success,
  } = useSelector((state) => state.addHomeSetting);
  const { isLoading, isError, settings } = useSelector(
    (state) => state.getHomeSetting
  );
  const [open1, setOpen1] = useState(false);
  const [errorText, setErrorText] = useState("");
  const {
    isLoading: deleteImageLoading,
    isError: deleteImageError,
    imageDeleted,
  } = useSelector((state) => state.deleteCityImage);
  const [open2, setOpen2] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [imgId, setImgId] = useState("");
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleHeadDataChange = (e) => {
    const { name, value } = e.target;
    setHeadData({
      ...headData,
      [name]: value,
    });
  };
  const deleteImageFunction = (id, imgIdf, data) => {
    dispatch(
      deleteCityImage({
        imageId: id
      })
    );
    setImgId(imgIdf);
    document.getElementById(imgIdf).style.display = "none";
    // setSight(sight.filter(elm=>elm!==data))
  };
  function buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        if (key === "bannerImage") {
          formData.append(`header[${key}]`, data[key]);
        } else {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        }
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(`header[${parentKey}]`, value);
    }
  }
  const dataSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("favImage", favImage[0]);
    formData.append("headerImage", headImage[0]);
    formData.append("userId", localStorage.getItem("userId"));
    for (var i = 0; i < bannerImages.length; i++) {
      formData.append(`bannerImages${i}`, bannerImages[i]);
    }
    buildFormData(formData, headData);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    //    for (var key of formData.keys()) {
    //     console.log(key);
    //  }
    dispatch(addHomeSetting(formData));
  };
  useEffect(() => {
    if (error !== undefined && error !== "") {
      setErrorText(error);
      setOpen1(true);
    }
    if (data.header && data.header[0]) {
      setHeadData(data.header[0]);
    }
    if (deleteImageError !== undefined && deleteImageError !== "") {
      setDeleteText(deleteImageError);
      setOpen1(true);
      document.getElementById(imgId).style.display = "flex";
    }
    return () => {
      return (
        dispatch(destroyAddHomeSetting()),
        dispatch(destroyAddHomeSettingError()),
        dispatch(destroyDeleteErrorImageCity())
      );
    };
  }, [error, deleteImageError]);
  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Page Title
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="page title"
                type="text"
                name="pageTitle"
                value={headData.pageTitle}
                onChange={(e) => handleHeadDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Meta tag name
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="Meta tag"
                type="text"
                name="metaTagName"
                value={headData.metaTagName}
                onChange={(e) => handleHeadDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Meta tag description
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="Meta tag description"
                type="text"
                name="metaTagDescription"
                value={headData.metaTagDescription}
                onChange={(e) => handleHeadDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Favicon
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                type="file"
                name="tag"
                onChange={(e) => setFavImage(e.target.files)}
                accept="image/*"
              />
            </FormGroup>
          </Col>
          {data.siteImages?.length > 0 &&
              data.siteImages.map((e, i) => {
                if (e.imageType === "site-favImage") {
                  return (
                    <div
                      key={e.imageId}
                      id={e.imageId}
                      style={{
                        display: "flex",
                        margin: "0 5px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar src={baseUrlImage + imagePath + e.imageName} />
                      <Button
                        style={{
                          color: "white",
                          cursor: "pointer",
                          backgroundColor: "black",
                          minWidth:"20px",
                          boxShadow: "none",
                          width: "20px",
                          height: "20px",
                          padding: "0",
                        }}
                        onClick={() =>
                          deleteImageFunction(e._id, e.imageId)
                        }
                        disabled={deleteImageLoading}
                      >
                        X
                      </Button>
                    </div>
                  );
                }
              })}
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Header Notice
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="header notice"
                type="text"
                name="headerNotice"
                value={headData.headerNotice}
                onChange={(e) => handleHeadDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Heading
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="heading home page"
                type="text"
                name="heading"
                maxLength="50"
                value={headData.heading}
                onChange={(e) => handleHeadDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Description
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                placeholder="head description"
                type="textarea"
                rows="4"
                maxLength="150"
                name="description"
                value={headData.description}
                onChange={(e) => handleHeadDataChange(e)}
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Head Image
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                type="file"
                onChange={(e) => setHeadImage(e.target.files)}
                accept="image/*"
              />
            </FormGroup>
          </Col>
          <Col lg="12" style={{ display: "flex" }}>
            {data.siteImages?.length > 0 &&
              data.siteImages.map((e, i) => {
                if (e.imageType === "site-headerImage") {
                  return (
                    <div
                      key={e.imageId}
                      id={e.imageId}
                      style={{
                        display: "flex",
                        margin: "0 5px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar src={baseUrlImage + imagePath + e.imageName} />
                      <Button
                        style={{
                          color: "white",
                          cursor: "pointer",
                          backgroundColor: "black",
                          boxShadow: "none",
                          minWidth:"20px",
                          width: "20px",
                          height: "20px",
                          padding: "0",
                        }}
                        onClick={() =>
                          deleteImageFunction(e._id, e.imageId)
                        }
                        disabled={deleteImageLoading}
                      >
                        X
                      </Button>
                    </div>
                  );
                }
              })}
          </Col>
          <Col lg="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Banner Images
              </label>
              <Input
                className="form-control-alternative"
                id="input-username"
                type="file"
                onChange={(e) => setBannerImages(e.target.files)}
                multiple
                accept="image/*"
              />
            </FormGroup>
          </Col>
          <Col lg="12">
            {/* {(headData.bannerImage &&
              typeof headData.bannerImage === "string") ||
            headData.bannerImage instanceof String ? (
              <>
                {headData.bannerImage.split(",").map((e, i) => {
                  return <Avatar src={baseUrlImage + imagePath + e} />;
                })}
              </>
            ) : (
              <>
                {headData.bannerImage &&
                  headData.bannerImage.length > 0 &&
                  headData.bannerImage.map((e, i) => {
                    return <Avatar src={baseUrlImage + imagePath + e} />;
                  })}
              </>
            )} */}
            <Col lg="12" style={{ display: "flex" }}>
            {data.siteImages?.length > 0 &&
              data.siteImages.map((e, i) => {
                if (e.imageType.includes("site-bannerImages")) {
                  return (
                    <div
                      key={e.imageId}
                      id={e.imageId}
                      style={{
                        display: "flex",
                        margin: "0 5px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar src={baseUrlImage + imagePath + e.imageName} />
                      <Button
                        style={{
                          color: "white",
                          cursor: "pointer",
                          backgroundColor: "black",
                          boxShadow: "none",
                          minWidth:"20px",
                          width: "20px !important",
                          height: "20px",
                          padding: "0",
                        }}
                        onClick={() =>
                          deleteImageFunction(e._id, e.imageId)
                        }
                        disabled={deleteImageLoading}
                      >
                        X
                      </Button>
                    </div>
                  );
                }
              })}
          </Col>
          </Col>
          <Col lg="12">
            <Divider />
            <AccordionActions>
              <Button
                size="small"
                color="secondary"
                onClick={() => setExpanded(false)}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={dataSubmit}
                disabled={loading}
              >
                {loading ? "Updating" : "Update"}
              </Button>
            </AccordionActions>
          </Col>
        </Row>
      </Container>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
        <Alert onClose={handleClose2} severity="error">
          {deleteText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default HeaderSetting;
