import React from 'react'

function Holiday() {
  return (
    <div>
      hloiday
    </div>
  )
}

export default Holiday
