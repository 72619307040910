import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  addCategory,
  destroyAddCategory,
  destroyAddCategoryError,
} from "../../../redux/actions/blog";
import { addPackageCategory, destroyAddPackageCategory, destroyAddPackageCategoryError } from "../../../redux/actions/package";

function AddPackageCategory({ modal, setModal, setopen }) {
  const [name, setName] = useState("");
  const [edit, setEdit] = useState("");
  const [done, setDone] = useState(false);
  const { isLoading, isAdded, isError } = useSelector(
    (state) => state.addPackageCategory
  );
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  const formSubmitted = (e) => {
    e.preventDefault();
    dispatch(
      addPackageCategory({
        packageCategory: name,
        userId: localStorage.getItem("userId"),
      })
    ).then((result) => {});
    // closeModal()
    setDone(isAdded);
  };
  const closeModal = () => {
    setModal(false);
    setopen(true);
    setName("");
  };
  useEffect(() => {
    if (isAdded !== undefined && isAdded !== "") {
      closeModal();
    }
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setOpen(true);
    }
    return () => {
      return (
        dispatch(destroyAddPackageCategory()), dispatch(destroyAddPackageCategoryError())
      );
    };
  }, [isAdded, isError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody>
          <Container>
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Add Category"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {edit && (
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <Alert onClose={handleClose} severity="error">
                          {edit}
                        </Alert>
                      </Snackbar>
                    )}
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Category Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Category name"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Add Category
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddPackageCategory;
