import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../redux/url";

function GetLength(props) {
  const [length, setLength] = useState(0);
  useEffect(() => {
    axios
      .get(`${baseUrl}/cityPackageFilter?keyword=${props.name}&skip=0`)
      .then((result) => {
        setLength(result.data.length || 0);
      });
  }, []);
  return (
    <>
      <h5>{length} tour packages</h5>
    </>
  );
}

export default GetLength;
