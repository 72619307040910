import {
  Avatar,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Add, Delete, Edit, MoreVert, Publish } from "@material-ui/icons";
import { AvatarGroup, Pagination } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Card, CardHeader, Col, Container, Row, Table } from "reactstrap";
import {
  deleteHotel,
  destroyDeleteErrorHotel,
  filterHotel,
  getHotel,
} from "../../../redux/actions/package";
import { baseUrl, baseUrlImage, getHotelEndPoint, imagePath } from "../../../redux/url";
import AddHotel from "./AddHotel";
import EditHotel from "./EditHotel";
import ImportHotel from "./ImportHotel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Hotel() {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [open, setopen] = useState(false);
  const [open1, setopen1] = useState(false);
  const [open2, setopen2] = useState(false);
  const [open3, setopen3] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [deleted, setDeleted] = useState("");
  const [totalLength, setTotalLength] = useState(1);
  const [keyWord, setKeyWord] = useState("");
  const [info, setInfo] = useState({});
  const dispatch = useDispatch();
  const { loading, hotels, error } = useSelector((state) => state.getHotel);
  const { isDeleted, error: deleteError } = useSelector(
    (state) => state.deleteH
  );
  const { success } = useSelector((state) => state.addHotel);
  const { edited } = useSelector((state) => state.editHotel);
  const handleClose = () => {
    setopen(false);
  };
  const handleClose1 = () => {
    setopen1(false);
  };
  const handleClose2 = () => {
    setopen2(false);
  };
  const handleClose3 = () => {
    setopen3(false);
  };
  const showEditFunction = (data) => {
    setInfo(data);
    setModal1(true);
  };
  const deleteHotelFunction = (id) => {
    dispatch(deleteHotel({ hotelId: id }));
    
  };
  const searchChangeHandler = (e) => {
    setKeyWord(e.target.value);
    if (e.target.value === "") {
      dispatch(
        getHotel({
          skip: page * count - count,
          limit: count,
        })
      );
    } else {
      setPage(1);
      dispatch(
        filterHotel({
          key: e.target.value,
          skip: 0,
          limit: count,
        })
      );
    }
  };
  const paginationHandler = (e, v) => {
    setPage(v);
    if (keyWord === "") {
      dispatch(
        getHotel({
          skip: v * count - count,
          limit: count,
        })
      );
    } else {
      dispatch(
        filterHotel({
          key: keyWord,
          skip: v * count - count,
          limit: count,
        })
      );
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl(null);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.paddingRight = "0";
  };
  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== ""
    ) {
    } else {
      history.push("/login");
    }
    axios.get(baseUrl + getHotelEndPoint).then((result) => {
      setTotalLength(result.data.length);
    });
    if (isDeleted && isDeleted !== "") {
      setDeleted(isDeleted);
      setopen2(true);
    }
    if (deleteError && deleteError !== "") {
      setDeleted(deleteError);
      setopen2(true);
    }
    dispatch(
      getHotel({
        skip: page * count - count,
        limit: count,
      })
    );
    return () => {
      return dispatch(destroyDeleteErrorHotel());
    };
  }, [edited, success, isDeleted, modal2]);
  return (
    <>
      <Container className="top-100">
        <Row>
          <Col className="order-xl-1" lg="12" xl="12">
            <Row>
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col sm="12" lg="4">
                        <h3 className="mb-0">Hotels</h3>
                      </Col>
                      <Col sm="12" lg="4">
                        <Grid container spacing={1} alignItems="flex-end">
                          <Grid item>
                            <TextField
                              id="input-with-icon-grid"
                              label="Search Hotels"
                              onChange={searchChangeHandler}
                            />
                          </Grid>
                        </Grid>
                      </Col>
                      <Col className="text-right" sm="12" lg="4">
                      <Button
                            color="primary"
                            onClick={() => { setModal(true);
                            }}
                            size="sm"
                          >
                            Add Hotel
                          </Button>
                          <Button
                            color="primary"
                            onClick={() => {
                              return ( setModal2(true), setPage(1)
                              );
                            }}
                            size="sm"
                          >
                            Import Hotel
                          </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  {error ? (
                    <>{error}</>
                  ) : loading ? (
                    <>
                      <LinearProgress />
                    </>
                  ) : hotels.success === false ? (
                    <>
                      <Alert severity="error">{hotels.message}</Alert>
                    </>
                  ) : (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Hotel Name</th>
                          <th scope="col">Contact Number</th>
                          <th scope="col">City Located</th>
                          <th scope="col">Contact Address</th>
                          <th scope="col">Rating</th>
                          <th scope="col">Edit</th>
                          <th scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hotels.length > 0 &&
                          hotels.map((row, i) => {
                            return (
                              <tr>
                                <th scope="row">{row.hotelName}</th>
                                <td>{row.contactNumber}</td>
                                <td>{row.hotelCity}</td>
                                <td>{row.contactAddress}</td>
                                <td>{row.hotelRating}</td>
                                <td>
                                  {" "}
                                  <Edit
                                    style={{ cursor: "pointer" }}
                                    onClick={() => showEditFunction(row)}
                                  />{" "}
                                </td>
                                <td>
                                  <Delete
                                    style={{ cursor: "pointer" }}
                                    onClick={() => deleteHotelFunction(row._id)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="float-right m-3">
              <Pagination
                count={Math.ceil(totalLength / count)}
                page={page}
                onChange={paginationHandler}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <AddHotel modal={modal} setModal={setModal} setopen={setopen} />
      {modal1 && (
        <EditHotel
          modal={modal1}
          setModal={setModal1}
          info={info}
          setopen={setopen1}
        />
      )}
      <ImportHotel modal={modal2} setModal={setModal2} setOpen={setopen3} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          "Added Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
        <Alert onClose={handleClose3} severity="success">
          "Added Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="success">
          "Edited Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
        <Alert onClose={handleClose2} severity="error">
          {deleted}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Hotel;
