import axios from "axios";
import {
  ADD_BLOG_ERROR,
  ADD_BLOG_LOADING,
  ADD_BLOG_SUCCESS,
  ADD_CATEGORY_ERROR,
  ADD_CATEGORY_LOADING,
  ADD_CATEGORY_SUCCESS,
  DELETE_BLOG_ERROR,
  DELETE_BLOG_LOADING,
  DELETE_BLOG_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_LOADING,
  DELETE_CATEGORY_SUCCESS,
  DESTROY_BLOG_ADD,
  DESTROY_BLOG_DELETE,
  DESTROY_BLOG_EDIT,
  DESTROY_CATEGORY_ADD,
  DESTROY_CATEGORY_DELETE,
  DESTROY_CATEGORY_EDIT,
  DESTROY_ERROR_ADD_B,
  DESTROY_ERROR_ADD_C,
  DESTROY_ERROR_EDIT_B,
  DESTROY_ERROR_EDIT_C,
  EDIT_BLOG_ERROR,
  EDIT_BLOG_LOADING,
  EDIT_BLOG_SUCCESS,
  EDIT_CATEGORY_ERROR,
  EDIT_CATEGORY_LOADING,
  EDIT_CATEGORY_SUCCESS,
  GET_BLOG_BY_CATEGORY_SUCCESS,
  GET_BLOG_BY_SLUG_SUCCESS,
  GET_BLOG_EDITOR_SUCCESS,
  GET_BLOG_EDITOR_LOADING,
  GET_BLOG_EDITOR_ERROR,
  GET_BLOG_ERROR,
  GET_BLOG_LOADING,
  GET_BLOG_SUCCESS,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_LOADING,
  GET_CATEGORY_SUCCESS,
} from "../constants";
import {
  baseUrl,
  getBlogEndpoint,
  addBlogEndpoint,
  deleteBlogEndPoint,
  editBlogEndpoint,
  getCategoryEndpoint,
  addCategoryEndpoint,
  editCategoryEndpoint,
  deleteCategoryEndPoint,
  filterBlogEndpoint,
  filterBlogCategoryEndpoint,
  getBlogBySlug,
  categoryBlogs,
  editorPicksEndpoint,
} from "../url";

export const getBlogs = (data) => async (dispatch) => {
  dispatch({ type: GET_BLOG_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getBlogEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_BLOG_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_BLOG_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getBlogEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_BLOG_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_BLOG_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_BLOG_ERROR, payload: error.message });
  }
};

export const addBlogs = (data) => async (dispatch) => {
  dispatch({ type: ADD_BLOG_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addBlogEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({ type: ADD_BLOG_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: ADD_BLOG_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_BLOG_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_BLOG_ERROR, payload: error.message });
  }
};

export const editBlogs = (data) => async (dispatch) => {
  dispatch({ type: EDIT_BLOG_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editBlogEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({ type: EDIT_BLOG_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: EDIT_BLOG_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_BLOG_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_BLOG_ERROR, payload: error.message });
  }
};

export const deleteBlogs = (data) => async (dispatch) => {
  dispatch({ type: DELETE_BLOG_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteBlogEndPoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({ type: DELETE_BLOG_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: DELETE_BLOG_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_BLOG_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_BLOG_ERROR, payload: error.message });
  }
};

export const getCategory = (data) => async (dispatch) => {
  dispatch({ type: GET_CATEGORY_LOADING });
  try {
    if (data) {
      axios({
        method: "get",
        url: `${baseUrl}${getCategoryEndpoint}?&skip=${data.skip}&limit=${data.limit}`,
      })
        .then((result) => {
          dispatch({ type: GET_CATEGORY_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_CATEGORY_ERROR, payload: error.message });
        });
    } else {
      axios({
        method: "get",
        url: `${baseUrl}${getCategoryEndpoint}`,
      })
        .then((result) => {
          dispatch({ type: GET_CATEGORY_SUCCESS, payload: result.data });
        })
        .catch((error) => {
          dispatch({ type: GET_CATEGORY_ERROR, payload: error.message });
        });
    }
  } catch (error) {
    dispatch({ type: GET_CATEGORY_ERROR, payload: error.message });
  }
};

export const addCategory = (data) => async (dispatch) => {
  dispatch({ type: ADD_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${addCategoryEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: ADD_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: ADD_CATEGORY_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: ADD_CATEGORY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: ADD_CATEGORY_ERROR, payload: error.message });
  }
};

export const editCategory = (data) => async (dispatch) => {
  dispatch({ type: EDIT_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editCategoryEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({
            type: EDIT_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({ type: EDIT_CATEGORY_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_CATEGORY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_CATEGORY_ERROR, payload: error.message });
  }
};

export const deleteCategory = (data) => async (dispatch) => {
  dispatch({ type: DELETE_CATEGORY_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteCategoryEndPoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({
            type: DELETE_CATEGORY_SUCCESS,
            payload: result.data.message,
          });
        } else {
          dispatch({
            type: DELETE_CATEGORY_ERROR,
            payload: result.data.message,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_CATEGORY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DELETE_CATEGORY_ERROR, payload: error.message });
  }
};

export const destroyAddCategory = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_CATEGORY_ADD, payload: "" });
};
export const destroyEditCategory = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_CATEGORY_EDIT, payload: "" });
};
export const destroyDeleteCategory = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_CATEGORY_DELETE, payload: "" });
};
export const destroyAddBlog = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_BLOG_ADD, payload: "" });
};
export const destroyEditBlog = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_BLOG_EDIT, payload: "" });
};
export const destroyDeleteBlog = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_BLOG_DELETE, payload: "" });
};
export const destroyEditBlogError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_EDIT_B, payload: "" });
};
export const destroyAddBlogError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_ADD_B, payload: "" });
};
export const destroyAddCategoryError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_ADD_C, payload: "" });
};
export const destroyEditCategoryError = (data) => async (dispatch) => {
  dispatch({ type: DESTROY_ERROR_EDIT_C, payload: "" });
};

export const filterBlogs = (data) => async (dispatch) => {
  dispatch({ type: GET_BLOG_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterBlogEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_BLOG_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_BLOG_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_BLOG_ERROR, payload: error.message });
  }
};

export const filterCategory = (data) => async (dispatch) => {
  dispatch({ type: GET_CATEGORY_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterBlogCategoryEndpoint}?keyword=${data.key}&skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_CATEGORY_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_CATEGORY_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_CATEGORY_ERROR, payload: error.message });
  }
};

export const destroyDeleteErrorB = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_B", payload: "" });
};
export const destroyDeleteErrorC = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_DELETE_ERROR_C", payload: "" });
};

export const filterBlogsEditor = (data) => async (dispatch) => {
  dispatch({ type: GET_BLOG_EDITOR_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${editorPicksEndpoint}?skip=${data.skip}&limit=${data.limit}`,
    })
      .then((result) => {
        dispatch({ type: GET_BLOG_EDITOR_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_BLOG_EDITOR_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_BLOG_EDITOR_ERROR, payload: error.message });
  }
};

export const getBlogSLug = (data) => async (dispatch) => {
  dispatch({ type: GET_BLOG_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${getBlogBySlug}?slug=${data.slug}`,
    })
      .then((result) => {
        dispatch({ type: GET_BLOG_BY_SLUG_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_BLOG_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_BLOG_ERROR, payload: error.message });
  }
};

export const getBlogByCategory = (data) => async (dispatch) => {
  dispatch({ type: GET_BLOG_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${categoryBlogs}?category=${data.category}`,
    })
      .then((result) => {
        dispatch({ type: GET_BLOG_BY_CATEGORY_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_BLOG_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_BLOG_ERROR, payload: error.message });
  }
};
