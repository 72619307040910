import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import UserNavbar from "../../../common/navbar/UserNavbar";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ArrowRight, Facebook, GitHub, LinkedIn } from "@material-ui/icons";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { filterBlogsEditor, getBlogs } from "../../../redux/actions/blog";
import { Alert, Pagination } from "@material-ui/lab";
import {
  baseUrl,
  baseUrlImage,
  editorPicksEndpoint,
  imagePath,
} from "../../../redux/url";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import right from "../../../assets/img/new/arrowRight.png";
import left from "../../../assets/img/new/arrowLeft.png";
import { getUsers } from "../../../redux/actions/user";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

function BlogMainPage() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [itemCount, setItemCount] = useState(3);
  const [totalLength, setTotalLength] = useState(1);
  const [page, setPage] = useState(1);
  var count = 0;
  const { isLoading, isError, blogs } = useSelector((state) => state.getBlog);
  const { users } = useSelector((state) => state.getUser);
  const {
    isLoading: editorLoading,
    isError: editorError,
    filterEditor,
  } = useSelector((state) => state.filterEditor);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const paginationHandler = (event, value) => {
    if (value !== page) {
      setPage(value);
      dispatch(
        filterBlogsEditor({
          skip: value * itemCount - itemCount,
          limit: itemCount,
        })
      );
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUsers());
    dispatch(getBlogs());
    dispatch(
      filterBlogsEditor({
        skip: 0,
        limit: itemCount,
      })
    );
    axios.get(baseUrl + editorPicksEndpoint).then((result) => {
      setTotalLength(result.data.length);
    });
  }, []);
  return (
    <>
      <Container>
        <div className="blog-main-page">
          <Row className="blog-main-page-row">
            <Col lg="12" className="blog-main-page-intro">
              <Carousel activeIndex={index} onSelect={handleSelect} fade>
                {isLoading ? (
                  <></>
                ) : isError ? (
                  <>
                    <Alert severity="error">{isError}</Alert>
                  </>
                ) : (
                  blogs.length > 0 &&
                  blogs.map((e, i) => {
                    if (e.isFeatured === true) {
                      return (
                        <Carousel.Item className="featured-crousel">
                          <Row>
                            <Col
                              lg="6"
                              md="6"
                              sm="12"
                              className="intro-content"
                            >
                              <div>
                                <span className="span-1">Featured</span>
                                <span className="span-2">
                                  {e.blogCategory[0]?.label}
                                </span>
                              </div>
                              <div>
                                <Link
                                  to={`/blog/${e.slug}?category=${e.blogCategory[0]?.value}`}
                                >
                                  <h5>
                                    {e.blogTitle.length > 40 ? (
                                      <>{e.blogTitle.slice(0, 40)}...</>
                                    ) : (
                                      <>{e.blogTitle}</>
                                    )}
                                  </h5>
                                </Link>

                                <p>
                                  {e.blogIntroduction?.length > 200 ? (
                                    <>{e.blogIntroduction.slice(0, 200)}...</>
                                  ) : (
                                    <>{e.blogIntroduction}</>
                                  )}
                                </p>
                              </div>
                              <Button
                                onClick={() =>
                                  history.push(
                                    `/blog/${e.slug}?category=${e.blogCategory[0]?.value}`
                                  )
                                }
                              >
                                Read More <ArrowRight />
                              </Button>
                            </Col>
                            <Col lg="6" md="6" sm="6" className="intro-image">
                            {
                              e.blogImages?.length>0&&
                              <img
                                src={baseUrlImage + imagePath + e.blogImages[0].imageName}
                                alt="blog-main"
                              />
                            }
                            </Col>
                          </Row>
                        </Carousel.Item>
                      );
                    }
                  })
                )}
              </Carousel>
            </Col>
          </Row>
          <Row className="blog-latest-article">
            <Col lg="12" className="blog-latest-head">
              <img
                src={left}
                alt="left"
                style={{ position: "relative", right: "30px", bottom: "3px" }}
              />
              <h6>latest article</h6>
              <img
                src={right}
                alt="right"
                style={{ position: "relative", left: "30px", bottom: "3px" }}
              />
            </Col>
            <Col lg="12">
              <Row>
                {isLoading ? (
                  <></>
                ) : isError ? (
                  <></>
                ) : (
                  blogs.length > 0 &&
                  blogs.map((e, i) => {
                    if (i < 3) {
                      return (
                        <Col
                          lg="4"
                          md="4"
                          sm="6"
                          xs="12"
                          className="blog-latest-card"
                        >
                          <Card className={classes.root}>
                            <CardActionArea
                              onClick={() =>
                                history.push(
                                  `/blog/${e.slug}?category=${e.blogCategory[0]?.value}`
                                )
                              }
                            >{
                              e.blogImages?.length>0&&
                              <CardMedia
                                className={classes.media}
                                image={baseUrlImage + imagePath + e.blogImages[0].imageName}
                                title={e.blogTitle}
                              />
                            }
                              <CardContent>
                                <div>
                                  <span className="span-1">
                                    {e.blogCategory[1]
                                      ? e.blogCategory[1].label
                                      : e.blogCategory[0].label}
                                  </span>
                                  <span className="span-2">{e.cityName}</span>
                                </div>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  {e.blogTitle}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Col>
                      );
                    }
                  })
                )}
              </Row>
            </Col>
            <Col lg="12" className="blog-latest-button">
              <Button onClick={() => history.push("/Blogs")}>
                See All <ArrowRight />{" "}
              </Button>
            </Col>
          </Row>
          <Row className="blog-editor-picks">
            <Col lg="12" className="blog-editor-picks-head">
              <span>EDITOR'S PICKS</span>
            </Col>
            {editorLoading ? (
              <></>
            ) : editorError ? (
              <>
                <Alert severity="error">{isError}</Alert>
              </>
            ) : filterEditor.success === false ? (
              <>
                <Alert severity="error">{filterEditor.message}</Alert>
              </>
            ) : (
              filterEditor.length > 0 &&
              filterEditor.map((e, i) => {
                if (e.isEditorPick === true) {
                  count++;
                  return (
                    <Col lg="12" className="blog-editor-column">
                      <Row>
                        <Col lg="4" md="4">
                        {
                          e.blogImages?.length>0&&
                          <img
                            src={baseUrlImage + imagePath + e.blogImages[0].imageName}
                            alt="column-image"
                          />
                        }
                        </Col>
                        <Col lg="8" md="8">
                          <div>
                            <span className="span-1">
                              {e.blogCategory[0].label}
                            </span>
                            {/* <span className="span-2">Most viewed</span> */}
                          </div>
                          <Link
                            to={`/blog/${e.slug}?category=${e.blogCategory[0]?.value}`}
                          >
                            <h5>{e.blogTitle}</h5>
                          </Link>
                          <small className="small-1">Posted by</small>
                          <small className="small-2">
                            {users.length > 0 &&
                              users.map((elm, ind) => {
                                if (elm._id === e.postedBy) {
                                  return elm.userName;
                                }
                              })}
                          </small>
                          <small className="small-1">on</small>
                          <small className="small-2">
                            {e.postedOn.slice(0, 10)}
                          </small>
                          <p>
                            {e.blogIntroduction?.length > 300 ? (
                              <>{e.blogIntroduction.slice(0, 300)}...</>
                            ) : (
                              <>{e.blogIntroduction}</>
                            )}
                          </p>
                          <div className="social-icon">
                            <Facebook />
                            <LinkedIn />
                            <GitHub />
                          </div>
                          <div className="action-button">
                            <Button
                              onClick={() =>
                                history.push(
                                  `/blog/${e.slug}?category=${e.blogCategory[0]?.value}`
                                )
                              }
                            >
                              Read More <ArrowRight />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  );
                }
              })
            )}
            {totalLength > 1 && (
              <Col lg="12" style={{ marginBottom: "50px" }}>
                <Pagination
                  count={Math.ceil(totalLength / itemCount)}
                  className="editor-pagination"
                  page={page}
                  onChange={paginationHandler}
                />
              </Col>
            )}
          </Row>
        </div>
      </Container>
    </>
  );
}

export default BlogMainPage;
