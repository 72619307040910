import {
  ADD_CITY_ERROR,
  ADD_CITY_LOADING,
  ADD_CITY_SUCCESS,
  ADD_HOTEL_ERROR,
  ADD_HOTEL_LOADING,
  ADD_HOTEL_SUCCESS,
  ADD_PACKAGE_ERROR,
  ADD_PACKAGE_LOADING,
  ADD_PACKAGE_SUCCESS,
  DELETE_CITY_ERROR,
  DELETE_CITY_IMAGES_ERROR,
  DELETE_CITY_IMAGES_LOADING,
  DELETE_CITY_IMAGES_SUCCESS,
  DELETE_CITY_LOADING,
  DELETE_CITY_SUCCESS,
  DELETE_HOTEL_ERROR,
  DELETE_HOTEL_LOADING,
  DELETE_HOTEL_SUCCESS,
  DELETE_PACKAGE_ERROR,
  DELETE_PACKAGE_LOADING,
  DELETE_PACKAGE_SUCCESS,
  DESTROY_ADD_CITY,
  DESTROY_ADD_HOTEL,
  DESTROY_EDITED,
  DESTROY_EDIT_HOTEL,
  DESTROY_ERROR_CITY,
  DESTROY_ERROR_CITY_EDIT,
  DESTROY_ERROR_HOTEL,
  DESTROY_ERROR_HOTEL_EDIT,
  DESTROY_ERROR_PACKAGE,
  DESTROY_ERROR_PACKAGE_EDIT,
  EDIT_CITY_ERROR,
  EDIT_CITY_LOADING,
  EDIT_CITY_SUCCESS,
  EDIT_HOTEL_ERROR,
  EDIT_HOTEL_LOADING,
  EDIT_HOTEL_SUCCESS,
  EDIT_PACKAGE_ERROR,
  EDIT_PACKAGE_LOADING,
  EDIT_PACKAGE_SUCCESS,
  GET_ALL_CITY_HEAD_ERROR,
  GET_ALL_CITY_HEAD_LOADING,
  GET_ALL_CITY_HEAD_SUCCESS,
  GET_ALL_CITY_PACKAGE_ERROR,
  GET_ALL_CITY_PACKAGE_LOADING,
  GET_ALL_CITY_PACKAGE_SUCCESS,
  GET_CITY_ERROR,
  GET_CITY_LOADING,
  GET_CITY_PACKAGE_ERROR,
  GET_CITY_PACKAGE_LOADING,
  GET_CITY_PACKAGE_SUCCESS,
  GET_CITY_SUCCESS,
  GET_HOTEL_ERROR,
  GET_HOTEL_LOADING,
  GET_HOTEL_SUCCESS,
  GET_PACKAGE_ERROR,
  GET_PACKAGE_LOADING,
  GET_PACKAGE_SUCCESS,
  ADD_PACKAGE_CATEGORY_LOADING,
  ADD_PACKAGE_CATEGORY_SUCCESS,
  ADD_PACKAGE_CATEGORY_ERROR,
  DESTROY_PACKAGE_CATEGORY_ADD,
  DESTROY_PACKAGE_ERROR_ADD_C,
  GET_PACKAGE_CATEGORY_LOADING,
  GET_PACKAGE_CATEGORY_SUCCESS,
  GET_PACKAGE_CATEGORY_ERROR,
  EDIT_PACKAGE_CATEGORY_LOADING,
  EDIT_PACKAGE_CATEGORY_SUCCESS,
  EDIT_PACKAGE_CATEGORY_ERROR,
  DESTROY_PACKAGE_CATEGORY_EDIT,
  DESTROY_PACKAGE_ERROR_EDIT_C,
  DELETE_PACKAGE_CATEGORY_LOADING,
  DELETE_PACKAGE_CATEGORY_SUCCESS,
  DELETE_PACKAGE_CATEGORY_ERROR
} from "../constants";

const initialState = {
  isLoading: false,
  packages: [],
  isError: "",
  isAdded: "",
  isDeleted: "",
  isEdited: "",
  filteredPackages:[],
  packageCity:[]
};
const cityInitialState = {
  isLoading: false,
  cities: [],
  isError: "",
  edited: "",
  isDeleted: "",
  footerCities:[],
  headCities:[],
  imageDeleted:""
};
const initialStateFav = {
  isLoading: false,
  isAdded: "",
  isError: "",
  favourites: [],
  isEdited: "",
  isDeleted: ""
};
const cityState = {
  loading: false,
  success: "",
  error: "",
  hotels: [],
  edited: "",
  isDeleted: "",
  filteredHotels:[],
  idHotel:[]
};
const initialStateCategory = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  packageCategory: [],
};

export function getPackage(state = initialState, action) {
  switch (action.type) {
    case GET_PACKAGE_LOADING:
      return {...state, isLoading: true };
    case GET_PACKAGE_SUCCESS:
      return {...state, isLoading: false, packages: action.payload };
    case GET_PACKAGE_ERROR:
      return {...state, isLoading: false, isError: action.payload };
    default:
      return state;
  }
}

export function addPackage(state = initialState, action) {
  switch (action.type) {
    case ADD_PACKAGE_LOADING:
      return {...state, isLoading: true };
    case ADD_PACKAGE_SUCCESS:
      return {...state, isLoading: false, isAdded: action.payload };
    case ADD_PACKAGE_ERROR:
      return { ...state,isLoading: false, isError: action.payload };
    case "DESTROY_ADD_PACKAGE":
      return { ...state, isAdded: action.payload };
    case DESTROY_ERROR_PACKAGE:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function editPackage(state = initialState, action) {
  switch (action.type) {
    case EDIT_PACKAGE_LOADING:
      return {...state, isLoading: true };
    case EDIT_PACKAGE_SUCCESS:
      return {...state, isLoading: false, isEdited: action.payload };
    case EDIT_PACKAGE_ERROR:
      return { ...state,isLoading: false, isError: action.payload };
    case "DESTROY_EDIT_PACKAGE":
      return { ...state, isEdited: action.payload };
    case DESTROY_ERROR_PACKAGE_EDIT:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function addCity(state = cityInitialState, action) {
  switch (action.type) {
    case ADD_CITY_LOADING:
      return {...state, isLoading: true };
    case ADD_CITY_SUCCESS:
      return {...state, isLoading: false, success: action.payload };
    case ADD_CITY_ERROR:
      return { ...state,isLoading: false, isError: action.payload };
    case DESTROY_ADD_CITY:
      return { ...state, success: action.payload };
    case DESTROY_ERROR_CITY:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function addHotel(state = cityState, action) {
  switch (action.type) {
    case ADD_HOTEL_LOADING:
      return {...state, loading: true };
    case ADD_HOTEL_SUCCESS:
      return {...state, loading: false, success: action.payload };
    case ADD_HOTEL_ERROR:
      return { ...state,loading: false, error: action.payload };
    case DESTROY_ADD_HOTEL:
      return { ...state, success: action.payload };
    case DESTROY_ERROR_HOTEL:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export function getCity(state = cityInitialState, action) {
  switch (action.type) {
    case GET_CITY_LOADING:
      return {...state, isLoading: true };
    case GET_CITY_SUCCESS:
      return {...state, isLoading: false, cities: action.payload };
    case GET_CITY_ERROR:
      return {...state, isLoading: false, isError: action.payload };
    case "GET_CITY_SUCCESS_FOOTER":
      return {...state,isLoading:false, footerCities:action.payload}
    default:
      return state;
  }
}

export function getHotel(state = cityState, action) {
  switch (action.type) {
    case GET_HOTEL_LOADING:
      return {...state, loading: true, hotels: [] };
    case GET_HOTEL_SUCCESS:
      return {...state, loading: false, hotels: action.payload };
    case GET_HOTEL_ERROR:
      return {...state, loading: false, hotels: [], error: action.payload };
    default:
      return state;
  }
}

export function editCity(state = cityInitialState, action) {
  switch (action.type) {
    case EDIT_CITY_LOADING:
      return {...state, isLoading: true };
    case EDIT_CITY_SUCCESS:
      return {...state, isLoading: false, edited: action.payload };
    case EDIT_CITY_ERROR:
      return { ...state,isLoading: false, isError: action.payload };
    case DESTROY_EDITED:
      return { ...state, edited: action.payload };
    case DESTROY_ERROR_CITY_EDIT:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function editHotel(state = cityState, action) {
  switch (action.type) {
    case EDIT_HOTEL_LOADING:
      return {...state, loading: true };
    case EDIT_HOTEL_SUCCESS:
      return {...state, loading: false, edited: action.payload };
    case EDIT_HOTEL_ERROR:
      return {...state, loading: false, error: action.payload };
    case DESTROY_EDIT_HOTEL:
      return { ...state, edited: action.payload };
    case DESTROY_ERROR_HOTEL_EDIT:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export function deleteHotelReducer(state = cityState, action) {
  switch (action.type) {
    case DELETE_HOTEL_LOADING:
      return {...state, loading: true };
    case DELETE_HOTEL_SUCCESS:
      return {...state, loading: false, isDeleted: action.payload };
    case DELETE_HOTEL_ERROR:
      return {...state, loading: false, error: action.payload };
    case "DESTROY_DELETE_ERROR_HOTEL":
      return { ...state, isDeleted: action.payload, error: action.payload };
    default:
      return state;
  }
}

export function deleteCityReducer(state = cityInitialState, action) {
  switch (action.type) {
    case DELETE_CITY_LOADING:
      return {...state, isLoading: true };
    case DELETE_CITY_SUCCESS:
      return {...state, isLoading: false, isDeleted: action.payload };
    case DELETE_CITY_ERROR:
      return {...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_CITY":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}

export function deleteCityImageReducer(state = cityInitialState, action) {
  switch (action.type) {
    case DELETE_CITY_IMAGES_LOADING:
      return {...state, isLoading: true };
    case DELETE_CITY_IMAGES_SUCCESS:
      return {...state, isLoading: false, imageDeleted: action.payload };
    case DELETE_CITY_IMAGES_ERROR:
      return {...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_IMAGES_CITY":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}

export function deletePackage(state = initialState, action) {
  switch (action.type) {
    case DELETE_PACKAGE_LOADING:
      return { ...state,isLoading: true };
    case DELETE_PACKAGE_SUCCESS:
      return {...state, isLoading: false, isDeleted: action.payload };
    case DELETE_PACKAGE_ERROR:
      return { ...state,isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_PACKAGE":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}

export function cityPackageFilter(state = initialState, action) {
  switch (action.type) {
    case GET_CITY_PACKAGE_LOADING:
      return {...state, isLoading: true };
    case GET_CITY_PACKAGE_SUCCESS:
      return {...state, isLoading: false, filteredPackages: action.payload };
    case GET_CITY_PACKAGE_ERROR:
      return {...state, isLoading: false, isError: action.payload };
    default:
      return state;
  }
}

export function getAllCItiesData(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CITY_PACKAGE_LOADING:
      return {...state, isLoading: true };
    case GET_ALL_CITY_PACKAGE_SUCCESS:
      return {...state, isLoading: false, packageCity: action.payload };
    case GET_ALL_CITY_PACKAGE_ERROR:
      return {...state, isLoading: false, isError: action.payload };
    default:
      return state;
  }
}


export function getAllCitiesHead(state = cityInitialState, action) {
  switch (action.type) {
    case GET_ALL_CITY_HEAD_LOADING:
      return {...state, isLoading: true };
    case GET_ALL_CITY_HEAD_SUCCESS:
      return {...state, isLoading: false, headCities: action.payload };
    case GET_ALL_CITY_HEAD_ERROR:
      return {...state, isLoading: false, isError: action.payload };
    default:
      return state;
  }
}

export function getHotelIdData(state = cityState, action) {
  switch (action.type) {
    case "GET_ALL_HOTEL_LOADING":
      return {...state, loading: true };
    case "GET_ALL_HOTEL_SUCCESS":
      return {...state, loading: false, idHotel: action.payload };
    case "GET_ALL_HOTEL_ERROR":
      return {...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export function addFavourite(state = initialStateFav, action) {
  switch (action.type) {
    case "ADD_FAVOURITE_LOADING":
      return {...state, isLoading: true };
    case "ADD_FAVOURITE_SUCCESS":
      return {...state, isLoading: false, isAdded: action.payload };
    case "ADD_FAVOURITE_ERROR":
      return { ...state,isLoading: false, isError: action.payload };
    case "DESTROY_FAVOURITE_ADD":
      return { ...state, isAdded: action.payload };
    case "DESTROY_ERROR_ADD_FAVOURITE":
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getFavourite(state = initialStateFav, action) {
  switch (action.type) {
    case "GET_FAVOURITE_LOADING":
      return {...state, isLoading: true };
    case "GET_FAVOURITE_SUCCESS":
      return {...state, isLoading: false, favourites: action.payload };
    case "GET_FAVOURITE_ERROR":
      return {...state, isLoading: false, isError: action.payload };
    default:
      return state;
  }
}

export function deleteFavourite(state = initialStateFav, action) {
  switch (action.type) {
    case "DELETE_FAVOURITE_LOADING":
      return { ...state,isLoading: true };
    case "DELETE_FAVOURITE_SUCCESS":
      return {...state, isLoading: false, isDeleted: action.payload };
    case "DELETE_FAVOURITE_ERROR":
      return { ...state,isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_FAVOURITE":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}


// Package Category


export function addPackageCategory(state = initialStateCategory, action) {
  switch (action.type) {
    case ADD_PACKAGE_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case ADD_PACKAGE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_PACKAGE_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_PACKAGE_CATEGORY_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_PACKAGE_ERROR_ADD_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getPackageCategory(state = initialStateCategory, action) {
  switch (action.type) {
    case GET_PACKAGE_CATEGORY_LOADING:
      return { ...state, isLoading: true, packageCategory: [] };
    case GET_PACKAGE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, packageCategory: action.payload };
    case GET_PACKAGE_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        packageCategory: [],
        isError: action.payload,
      };
    default:
      return state;
  }
}

export function editPackageCategory(state = initialStateCategory, action) {
  switch (action.type) {
    case EDIT_PACKAGE_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case EDIT_PACKAGE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_PACKAGE_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_PACKAGE_CATEGORY_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_PACKAGE_ERROR_EDIT_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function deletePackageCategory(state = initialStateCategory, action) {
  switch (action.type) {
    case DELETE_PACKAGE_CATEGORY_LOADING:
      return { ...state, isLoading: true };
    case DELETE_PACKAGE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_PACKAGE_CATEGORY_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_PACKAGE_ERROR_C":
      return { ...state, isError: action.payload, isDeleted: action.payload };
    default:
      return state;
  }
}
