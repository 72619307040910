import {
  CircularProgress,
  Divider,
  IconButton,
  Input,
  InputAdornment,
  Snackbar,
  TextField,
} from "@material-ui/core";
import {
  Email,
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  PermIdentity,
  Phone,
  TrendingUpTwoTone,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { getHomeSettings } from "../../redux/actions/settings";
import { baseUrl, subscribeEmail } from "../../redux/url";

function NewUserFooter() {
  const dispatch = useDispatch();
  const { isLoading, isError, settings } = useSelector(
    (state) => state.getHomeSetting
  );
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [error, setError] = useState("");
  const subscribeFunction = (e) => {
    if (email !== "") {
      e.preventDefault();
      setLoading(TrendingUpTwoTone);
      axios({
        method: "post",
        url: baseUrl + subscribeEmail,
        data: {
          email: email,
        },
      })
        .then((result) => {
          setLoading(false);
          if (result.data.success) {
            setOpen(true);
            setEmail("");
          } else {
            setError(result.data.message);
            setOpen1(true);
          }
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
          setOpen1(true);
        });
    } else {
      setError("Enter Email");
      setOpen1(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  useEffect(() => {
    dispatch(getHomeSettings());
  }, []);
  return (
    <>
      <div className="footer">
        <Container fluid>
          <Row>
            <Col lg="3" md="6" sm="6" xs="6" className="footer-company">
              <h4>Company</h4>
              <div className="hr" />
              <Link to="/">Home</Link>
              <Link to="/hotel">Hotel</Link>
              <Link to="/contact">Contact us</Link>
            </Col>
            <Col lg="3" md="6" sm="6" xs="6" className="footer-info">
              <h4>About</h4>
              <div className="hr" />
              {isLoading ? (
                <CircularProgress />
              ) : isError ? (
                <>{isError}</>
              ) : (
                settings.length > 0 &&
                settings[0].footer && (
                  <>
                    <p>{settings[0].footer[0].footerAboutData}</p>
                  </>
                )
              )}
            </Col>
            <Col lg="3" md="6" sm="6" xs="6" className="footer-info">
              <h4>contact</h4>
              <div className="hr" />
              {isLoading ? (
                <CircularProgress />
              ) : isError ? (
                <>{isError}</>
              ) : (
                settings.length > 0 &&
                settings[0].footer && (
                  <>
                    <div className="contact-address">
                      {settings[0].footer[0].footerContactAddress}
                    </div>
                    <br />
                    <div className="contact-info">
                      <a href={`tel:${settings[0].footer[0].footerContactNo}`}>
                        <Phone />
                        &nbsp;{settings[0].footer[0].footerContactNo}
                      </a>
                      <br />
                      <a
                        href={`mailto:${settings[0].footer[0].footerContactEmail}`}
                        className="text-decoration-none"
                      >
                        <Email /> {settings[0].footer[0].footerContactEmail}
                      </a>
                    </div>
                  </>
                )
              )}
              <br />
              <div className="social-icons">
                <div className="contact-address">Follow us on:</div>
                <div style={{display:"flex"}}>
                {isLoading ? (
                  <CircularProgress />
                ) : isError ? (
                  <>{isError}</>
                ) : (
                  settings.length > 0 &&
                  settings[0].socialIcons && (
                    <>
                        {settings[0].socialIcons[0].facebook &&
                        settings[0].socialIcons[0].facebook.url !== "" && (
                          <a href={settings[0].socialIcons[0].facebook.url}>
                            <Facebook />
                          </a>
                        )}&nbsp;
                      {settings[0].socialIcons[0].instagram &&
                        settings[0].socialIcons[0].instagram.url !== "" && (
                          <a href={settings[0].socialIcons[0].instagram.url}>
                            <Instagram />
                          </a>
                        )}&nbsp;
                      {settings[0].socialIcons[0].gmail &&
                        settings[0].socialIcons[0].gmail.url !== "" && (
                          <a href={settings[0].socialIcons[0].gmail.url}>
                          <i class="fab fa-google"></i>
                          </a>
                        )}&nbsp;
                      {settings[0].socialIcons[0].linkedIn &&
                        settings[0].socialIcons[0].linkedIn.url !== "" && (
                          <a href={settings[0].socialIcons[0].linkedIn.url}>
                            <LinkedIn />
                          </a>
                        )}
                    </>
                  )
                )}
              </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12" className="footer-subscribe">
              <h4>Subscribe</h4>
              <div className="hr" />
              <Input
                id="standard-adornment-password"
                type={"email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your Email"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility">
                      <PermIdentity />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Button
                onClick={subscribeFunction}
                disabled={loading}
                className="subscribe-button"
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#4447E2" }}
              >
                {loading ? "Submitting" : "Subscribe Now"}
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg="12" className="text-center footer-copy">
              {settings.length > 0 && settings[0].footer && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: settings[0].footer[0].footerText,
                  }}
                ></p>
              )}
            </Col>
          </Row>
        </Container>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            "Subscribed Successfully"
          </Alert>
        </Snackbar>
        <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
          <Alert onClose={handleClose1} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default NewUserFooter;
