import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import Login from "./components/auth/Login";
import AdminLayout from "./components/layouts/AdminLayout";
import AuthLayout from "./components/layouts/AuthLayout";
import Home from "./components/screen/Home";
import "./components/assets/plugins/nucleo/css/nucleo.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Package from "./components/screen/admin/package/Package";
import City from "./components/screen/admin/city/City";
import Hotel from "./components/screen/admin/hotel/Hotel";
import NotFound from "./components/screen/NotFound";
import Users from "./components/screen/admin/users/Users";
import UserLayout from "./components/layouts/UserLayout";
import "./components/assets/css/styles.css";
import Category from "./components/screen/admin/category/Category";
import Blog from "./components/screen/admin/blogs/Blog";
import MainScreen from "./components/screen/user/MainScreen";
import CityPackage from "./components/screen/user/CityPackage";
import PackageCity from "./components/screen/user/PackageCity";
import CityBlogs from "./components/screen/user/CityBlogs";
import BlogCityName from "./components/screen/user/BlogCityName";
import ContactUs from "./components/screen/user/ContactUs";
import Location from "./components/screen/admin/location/Location";
import UserHome from "./components/screen/user/holiday/UserHome";
import Holiday from "./components/screen/user/holiday/Holiday";
import CityTours from "./components/screen/user/CityTours";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css";
import "@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css";
import "./components/assets/css/app.css";
import NewMainScreen from "./components/screen/user/NewMainScreen";
import Settings from "./components/screen/admin/siteSettings/Settings";
import Exclusion from "./components/screen/admin/exclusion/Exclusion";
import Inclusion from "./components/screen/admin/inclusion/Inclusion";
import Advertisment from "./components/screen/admin/advertisment/Advertisment";
import BlogDetail from "./components/screen/user/blog/BlogDetail";
import BlogMainPage from "./components/screen/user/blog/BlogMainPage";
import PackagesDetail from "./components/screen/user/package/PackagesDetail";
import { useDispatch, useSelector } from "react-redux";
import { getHomeSettings } from "./components/redux/actions/settings";
import { baseUrlImage, imagePath } from "./components/redux/url";
import { Helmet } from "react-helmet";
import Company from "./components/screen/admin/company/Company";
import AllBlogs from "./components/screen/user/blog/AllBlogs";
import "./components/assets/fonts/PragmaticaMedium.otf";
import "./components/assets/fonts/GildaDisplay-Regular.ttf";
import "./components/assets/fonts/montserrat.regular.ttf";
import "./components/assets/fonts/arimo.regular.ttf";
import "./components/assets/fonts/nunito.regular.ttf";
import "./components/assets/fonts/Prata-Regular.ttf";
import PackageCategory from "./components/screen/admin/packageCategory/PackageCategory";
import Transport from "./components/screen/admin/transport/Transport";
import VehicleType from "./components/screen/admin/vehicleType/VehicleType";
import Register from "./components/auth/Register";
import Gallery from "./components/screen/admin/gallery/Gallery";
import GalleryDetailDisplay from "./components/screen/user/gallery/GalleryDetailDisplay";
import GalleryMain from "./components/screen/user/gallery/GalleryMain";
import Booking from "./components/screen/admin/booking/Booking";
import 'react-image-lightbox/style.css';

function App() {
  const dispatch = useDispatch();
  const { isLoading, isError, settings } = useSelector(
    (state) => state.getHomeSetting
  );
  useEffect(() => {
    dispatch(getHomeSettings());
  }, []);
  return (
    <>
      {isLoading ? (
        <></>
      ) : isError ? (
        <></>
      ) : (
        settings.length > 0 &&
        settings[0].header && (
          <>
            {" "}
            <Helmet>
              <title>{settings[0].header[0]?.pageTitle}</title>
              {settings[0].siteImages?.length > 0 &&
                settings[0].siteImages.map((e, i) => {
                  if (e.imageType === "site-favImage") {
                    return (
                      <link
                        rel="icon"
                        href={baseUrlImage + imagePath + e.imageName}
                      />
                    );
                  }
                })}
            </Helmet>
          </>
        )
      )}
      <Switch>
        <UserLayout path="/" exact={true} component={NewMainScreen} />
        <UserLayout path="/activity" component={MainScreen} />
        <UserLayout path="/blog/:id" component={BlogDetail} />
        <UserLayout path="/Blogs" exact component={AllBlogs} />
        <UserLayout path="/blog" exact component={BlogMainPage} />
        <AdminLayout path="/admin" exact component={Home} />
        <AdminLayout path="/admin/settings/location" component={Location} />
        <AdminLayout path="/admin/packages" component={Package} />
        <AdminLayout path="/admin/city" component={City} />
        <AdminLayout path="/admin/gallery" component={Gallery} />
        <AdminLayout path="/admin/settings/blogCategory" component={Category} />
        <AdminLayout path="/admin/settings/transport" component={Transport} />
        <AdminLayout path="/admin/settings/vehicle" component={VehicleType} />
        <AdminLayout path="/admin/settings/inclusion" component={Inclusion} />
        <AdminLayout path="/admin/settings/exclusion" component={Exclusion} />
        <AdminLayout
          path="/admin/settings/packageCategory"
          component={PackageCategory}
        />
        <AdminLayout
          path="/admin/settings/advertisment"
          component={Advertisment}
        />
        <AdminLayout path="/admin/hotel" component={Hotel} />
        <AdminLayout path="/admin/bookings" component={Booking} />
        <AdminLayout path="/admin/company" component={Company} />
        <AdminLayout path="/admin/users" component={Users} />
        <AdminLayout path="/admin/blog" component={Blog} />
        <AdminLayout path="/admin/settings/site" component={Settings} />
        <AuthLayout path="/login" component={Login} />
        <AuthLayout path="/register" component={Register} />
        <UserLayout path="/holiday" exact component={UserHome} />
        <UserLayout path="/holidays" exact component={Holiday} />
        <UserLayout path="/contact" exact component={ContactUs} />
        <UserLayout path="/gallery" exact component={GalleryMain} />
        <UserLayout
          path="/gallery/:id"
          exact
          component={GalleryDetailDisplay}
        />
        <UserLayout path="/city" exact component={PackageCity} />
        <UserLayout path="/tours" exact component={CityTours} />
        <UserLayout path="/blog" exact component={CityBlogs} />
        <UserLayout path="/blog/:cityName/:cityId" component={BlogCityName} />
        <UserLayout path="/city/:cityName/:cityId" component={CityPackage} />
        <UserLayout path="/package/:id" component={PackagesDetail} />
        <UserLayout path="/admin/:id" component={NotFound} />
        <UserLayout path="/:id" component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
