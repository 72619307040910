import { IconButton, LinearProgress, Snackbar } from "@material-ui/core";
import { Add, Delete, Edit, MoreVert, Publish } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Register from "../../../auth/Register";
import {
  deleteUser,
  destroyUserDeleteError,
  getUsers,
  destroyUserDeleteDeleted,
} from "../../../redux/actions/user";
import EditUser from "./EditUser";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import ImportUsers from "./ImportUsers";
import AddUser from "./AddUser";
import { useHistory } from "react-router";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Users() {
  const history = useHistory();
  const [showRegister, setShowRegister] = useState(false);
  const [info, setInfo] = useState([]);
  const [deletedText, setDeletedText] = useState("");
  const { loading, error, registered, users } = useSelector(
    (state) => state.getUser
  );
  const { edited } = useSelector((state) => state.editUser);
  const { deleted, error: deleteError } = useSelector(
    (state) => state.deleteUser
  );
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open3, setopen3] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl(null);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.paddingRight = "0";
  };
  const handleClose3 = () => {
    setopen3(false);
  };
  const editShowFunction = (row) => {
    setInfo(row);
    setModal(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const deleteFunctionClicked = (id) => {
    dispatch(
      deleteUser({
        userId: id,
      })
    );
  };
  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== ""
    ) {
    } else {
      history.push("/login");
    }
    dispatch(getUsers());
    if (deleted !== undefined && deleted !== "") {
      setDeletedText(deleted);
      setOpen1(true);
    }
    if (deleteError !== undefined && deleteError !== "") {
      setDeletedText(deleteError);
      setOpen1(true);
    }
    return () => {
      return (
        dispatch(destroyUserDeleteError()), dispatch(destroyUserDeleteDeleted())
      );
    };
  }, [showRegister, edited, deleted]);
  return (
    <div>
      <Container className="top-100">
        <Row>
          {showRegister ? (
            <AddUser setShowRegister={setShowRegister} />
          ) : (
            <Col className="order-xl-1" lg="12" xl="12">
              <Card className="bg-secondary shadow">
                <Row>
                  <Col className="mb-5 mb-xl-0" xl="12">
                    <Card className="shadow">
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <div className="col">
                            <h3 className="mb-0">Users</h3>
                          </div>
                          <Col className="text-right" sm="4" lg="4">
                            {users.length > 0 &&
                              users.map((e, i) => {
                                if (
                                  (e._id == localStorage.getItem("userId") &&
                                    e.userRole === 1) ||
                                  e.userRole === 2
                                ) {
                                  return (
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        return setShowRegister(true);
                                      }}
                                      size="sm"
                                    >
                                      Add User
                                    </Button>
                                  );
                                }
                              })}
                          </Col>
                        </Row>
                      </CardHeader>
                      {error ? (
                        <>{error}</>
                      ) : loading ? (
                        <>
                          <LinearProgress />{" "}
                        </>
                      ) : (
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">User Name</th>
                              <th scope="col">User Email</th>
                              <th scope="col">Role</th>
                              <th scope="col">Edit</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.length > 0 &&
                              users.map((e, i) => {
                                return (
                                  <>
                                    <tr>
                                      <th scope="row">{e.userName}</th>
                                      <td scope="row">{e.email}</td>
                                      <td scope="row">
                                        {e.userRole === 1
                                          ? "Super Admin"
                                          : e.userRole === 2
                                          ? "Admin"
                                          : e.userRole === 3
                                          ? "Agency"
                                          : "User"}{" "}
                                      </td>
                                      <td scope="row">
                                        <Edit
                                          style={{ cursor: "pointer" }}
                                          onClick={() => editShowFunction(e)}
                                        />{" "}
                                      </td>
                                      <td scope="row">
                                        <Delete
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            deleteFunctionClicked(e._id)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </Table>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
      {modal && (
        <EditUser
          modal={modal}
          setModal={setModal}
          info={info}
          setOpen={setOpen}
        />
      )}
      {/* <ImportUsers modal={modal2} setModal={setModal2} setOpen={setopen3} />
      <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
        <Alert onClose={handleClose3} severity="success">
          "Uploaded Successfully"
        </Alert>
      </Snackbar> */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          "User Edited Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="error">
          {deletedText}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Users;
