import React, { Component } from "react";
import { Route, useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import AuthFooter from "../common/footer/AuthFooter";
import "../assets/css/style.css";
import AuthNavbar from "../common/navbar/AuthNavbar";

const LoginLayout = ({ children }) => (
  <>
    <div className="main-content">
      <div className="header bg-gradient-info py-7 py-lg-8">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col lg="5" md="6">
                <h1 className="text-white">
                  {window.location.pathname
                    .slice(1, window.location.pathname.length)
                    .toUpperCase()}
                </h1>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
      {/* Page content */}
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">{children}</Row>
      </Container>
    </div>
    <AuthFooter />
  </>
);

const AuthLayout = ({ component: Component, ...rest }) => {
  const history = useHistory();
  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  }, []);
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LoginLayout>
          <Component {...matchProps} />
        </LoginLayout>
      )}
    />
  );
};

export default AuthLayout;
