import axios from "axios";
import {
  DELETE_USER_LOADING,
  DELETE_USER_SUCCESS,
  DETETE_USER_ERROR,
  EDIT_USER_ERROR,
  EDIT_USER_LOADING,
  EDIT_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  USER_REGISTER_ERROR,
  USER_REGISTER_LOADING,
  USER_REGISTER_SUCCESS,
} from "../constants";
import {
  baseUrl,
  loginEndpoint,
  registerEndpoint,
  getUserEndPoint,
  deleteUserEndpoint,
  editUserEndpoint,
  filterUserEndpoint,
} from "../url";

export const login = (data) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${loginEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status !== false) {
          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: result.data.status || true,
          });
          localStorage.setItem("accessToken", result.data.accessToken);
          localStorage.setItem("role", result.data.userRole);
          localStorage.setItem('userId',result.data.id)
          console.log(result.data)
        } else {
          dispatch({ type: USER_LOGIN_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: USER_LOGIN_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: USER_LOGIN_ERROR, payload: error.message });
  }
};
export const destroyAddUserError = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_ERROR_ADD_USER", payload: "" });
};

export const register = (data) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_LOADING });

  try {
    axios({
      method: "post",
      url: `${baseUrl}${registerEndpoint}`,
      data: data,
    })
      .then((result) => {
        dispatch({ type: USER_REGISTER_SUCCESS, payload: result.data.status });
      })
      .catch((error) => {
        dispatch({ type: USER_REGISTER_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: USER_REGISTER_ERROR, payload: error.message });
  }
};
// export const logout = (data) => async (dispatch) => {
//  dispatch({ type: USER_LOGOUT, payload: false });
//  localStorage.removeItem("accessToken")
// };

export const getUsers = (data) => async (dispatch) => {
  dispatch({ type: GET_USER_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${getUserEndPoint}`,
    })
      .then((result) => {
        dispatch({ type: GET_USER_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_USER_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_USER_ERROR, payload: error.message });
  }
};

export const editUser = (data) => async (dispatch) => {
  dispatch({ type: EDIT_USER_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${editUserEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.success !== false) {
          dispatch({ type: EDIT_USER_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: EDIT_USER_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: EDIT_USER_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: EDIT_USER_ERROR, payload: error.message });
  }
};

export const deleteUser = (data) => async (dispatch) => {
  dispatch({ type: DELETE_USER_LOADING });
  try {
    axios({
      method: "post",
      url: `${baseUrl}${deleteUserEndpoint}`,
      data: data,
    })
      .then((result) => {
        if (result.data.status) {
          dispatch({ type: DELETE_USER_SUCCESS, payload: result.data.message });
        } else {
          dispatch({ type: DETETE_USER_ERROR, payload: result.data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: DETETE_USER_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: DETETE_USER_ERROR, payload: error.message });
  }
};

export const destroyUserAdd = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_USER_ADD", payload: "" });
};

export const destroyUserLogin = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_USER_LOGIN", payload: false });
};

export const destroyUserEdit = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_USER_EDIT", payload: "" });
};

export const destroyUserError = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_USER_ERROR", payload: "" });
};

export const destroyUserDeleteError = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_USER_DELETE_ERROR", payload: "" });
};
export const destroyUserDeleteDeleted = (data) => async (dispatch) => {
  dispatch({ type: "DESTROY_USER_DELETE_DELETED", payload: "" });
};

export const filterUser = (data) => async (dispatch) => {
  dispatch({ type: GET_USER_LOADING });
  try {
    axios({
      method: "get",
      url: `${baseUrl}${filterUserEndpoint}?keyword=${data}&skip=0&limit=10`,
    })
      .then((result) => {
        dispatch({ type: GET_USER_SUCCESS, payload: result.data });
      })
      .catch((error) => {
        dispatch({ type: GET_USER_ERROR, payload: error.message });
      });
  } catch (error) {
    dispatch({ type: GET_USER_ERROR, payload: error.message });
  }
};
