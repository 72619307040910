import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Col, Container, Row } from "reactstrap";
import HeaderHome from "../../common/headers/HeaderHome";
import { cityPackageFilter } from "../../redux/actions/package";
import { baseUrl, baseUrlImage, imagePath } from "../../redux/url";

function CityPackage(props) {
  // const [cityPackage, setCityPackage] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [cityData, setCityData] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isLoading: loading,
    isError,
    filteredPackages: cityPackage,
  } = useSelector((state) => state.getFilterPackage);
  const query = new URLSearchParams(props.location.search);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(baseUrl + "/getCity?id=" + props.match.params.cityId)
      .then((result) => {
        setCityData(result.data[0]);
      });
    dispatch(
      cityPackageFilter({
        key: props.match.params.cityName,
      })
    );
  }, []);
  return (
    <>
      <HeaderHome
        url={baseUrlImage+imagePath+cityData.bannerImage || ""}
        content={cityData.cityDescription || ""}
        name={cityData.cityName || ""}
      />
      <Container>
        <Row>
          <h2 className="w-100 float-left heading-second">
            Packages of {props.match.params.cityName}
          </h2>
          {loading ? (
            <>Loading packages....</>
          ) : isError ? (
            <>{isError}</>
          ) : cityPackage.length > 0 ? (
            cityPackage.map((e, i) => {
              return (
                <Col
                  lg="3"
                  sm="6"
                  xs="12"
                  md="6"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/package/${e.packageId}`)}
                >
                  <div className="destini">
                    <img
                    style={{height:"280px"}}
                      alt="image"
                      src={baseUrlImage+imagePath+e.images[0]}
                      className="w-100"
                    />
                    <h5>
                      From- {e.travelValidityFrom}
                      <br />
                      To- {e.travelValidityTo}{" "}
                    </h5>
                    <h3>{e.packageName}</h3>
                  </div>
                </Col>
              );
            })
          ) : (
            <>
              <h4 className="w-100 float-left heading">
                Currently no package available{" "}
              </h4>
            </>
          )}
        </Row>
      </Container>
    </>
  );
}

export default CityPackage;
