import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { baseUrl } from "../../../redux/url";

function ImportUsers({ setOpen, setModal, modal }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [error, setError] = useState("");

  const formSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("users", file);
    axios({
      method: "post",
      url: `${baseUrl}${"/"}`,
      data: formData,
    })
      .then((result) => {
        if (result.data.success == false) {
          setLoading(false);
          alert(result.data.message);
          setError(result.data.message);
        } else {
          setLoading(false);
          setOpen(true);
          setFile({});
          setError("");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
        alert(error.message);
      });
  };
  useEffect(() => {
    if (!loading && error == "") {
      setModal(false);
    }
  }, [error, loading]);
  return (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalBody>
        <Container>
          <Row>
            <Col lg="12">
              <Form onSubmit={formSubmit}>
                <FormGroup>
                  <label className="form-control-label">
                    Select File For Users Module
                  </label>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Input
                      className="form-control-alternative"
                      id="fileSelect"
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  )}{" "}
                </FormGroup>
                <Button color="primary" type="submit" disabled={loading}>
                  {loading ? "submiting.." : "Submit"}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
}

export default ImportUsers;
