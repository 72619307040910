import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import {
  destroyEdited,
  destroyEditError,
  editCity,
  destroyDeleteErrorImageCity,
  deleteCityImage,
  getCities,
} from "../../../redux/actions/package";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import Select from "react-select";
import { baseUrlImage, imagePath } from "../../../redux/url";

function EditCity({ modal, setModal, data, setOpen }) {
  const [modal3, setModal3] = useState(false);
  const [sight, setSight] = useState([]);
  const [favourite, setFavourite] = useState(false);
  const [sightName, setSightName] = useState("");
  const [sightDescription, setSightDescription] = useState(
    EditorState.createEmpty()
  );
  const [name, setName] = useState("");
  const [open, setopen] = useState(true);
  const [edit, setEdit] = useState("");
  const [images, setimages] = useState([]);
  const [files, setFiles] = useState({});
  const [sightFile, setSightFile] = useState([]);
  const dispatch = useDispatch();
  const [bannerImage, setBannerImage] = useState([]);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const { isLoading, isError, edited } = useSelector((state) => state.editCity);
  const {
    isLoading: deleteImageLoading,
    isError: deleteImageError,
    imageDeleted,
  } = useSelector((state) => state.deleteCityImage);
  const [open1, setOpen1] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [imgId, setImgId] = useState("");
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose = () => {
    setopen(false);
  };
  const toggle = () => setModal(!modal);
  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }
  const formSubmitted = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("cityName", name);
    formData.append("userId", localStorage.getItem("userId"));
    formData.append(
      "cityDescription",
      draftToHtml(convertToRaw(description.getCurrentContent())).toString()
    );
    formData.append("isFavourite", favourite);
    if (sight.length !== 0) {
      for (var i = 0; i < sight.length; i++) {
        formData.append(`sightSeeing[${i}][header]`, sight[i]["header"]);
        formData.append(
          `sightSeeing[${i}][description]`,
          sight[i]["description"]
        );
        formData.append(`sightImage${i}`, sight[i]["image"]);
      }
    }
    formData.append("cityId", data._id);
    for (var i = 0; i < files.length; i++) {
      formData.append("cityImage", files[i]);
    }
    if (bannerImage.length > 0) {
      formData.append("bannerImage", bannerImage[0]);
    }
    dispatch(editCity(formData));
  };
  const closeModal = () => {
    setModal(false);
    setOpen(true);
  };
  const fileChangeHandler = (e) => {
    setFiles(e.target.files);
    setimages([]);
  };
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };
  const deleteImageFunction = (id, imgIdf,data) => {
    dispatch(
      deleteCityImage({
        imageId: id,
      })
    );
    setImgId(imgIdf);
    document.getElementById(imgIdf).style.display = "none";
    // setSight(sight.filter(elm=>elm!==data))
  };
  useEffect(() => {
    if (isError !== undefined && isError !== "") {
      setEdit(isError);
      setopen(true);
    }
    if (edited !== undefined && edited !== "") {
      closeModal();
    }
    if (deleteImageError !== undefined && deleteImageError !== "") {
      setDeleteText(deleteImageError);
      setOpen1(true);
      document.getElementById(imgId).style.display = "flex";
    }
    setName(data.cityName);
    setimages(data.images);
    setSight(data.sightSeeing);
    setFavourite(data.isFavourite);
    data.cityDescription &&
      setDescription(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data.cityDescription)
          )
        )
      );

    return () => {
      return (
        dispatch(destroyEdited()),
        dispatch(
          destroyEditError("city"),
          dispatch(destroyDeleteErrorImageCity())
        )
      );
    };
  }, [edited, isError, imageDeleted, deleteImageError]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <Container>
            {edit && (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {edit}
                </Alert>
              </Snackbar>
            )}
            {deleteText && (
              <Snackbar
                open={open1}
                autoHideDuration={6000}
                onClose={handleClose1}
              >
                <Alert onClose={handleClose1} severity="error">
                  {deleteText}
                </Alert>
              </Snackbar>
            )}
            <Row>
              <Col className="order-xl-1" lg="12" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{"Edit City"}</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Fill the form
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                City
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="City name"
                                type="text"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <Button
                                color="light"
                                onClick={() => setModal3(true)}
                              >
                                Sights
                              </Button>
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            {/* {sight.length > 0 &&
                              sight.map((e, i) => {
                                return (
                                  <div style={{ color: "red" }}>
                                    {e.header}
                                    <i
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        setSight(
                                          sight.filter((elm) => elm !== e)
                                        )
                                      }
                                    >
                                      X
                                    </i>
                                  </div>
                                );
                              })} */}
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Images
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="file"
                                name="images"
                                onChange={fileChangeHandler}
                                multiple
                                accept="image/*"
                              />
                            </FormGroup>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {data.cityImages?.length > 0 &&
                                data.cityImages.map((elm, ind) => {
                                  if (elm.imageType === `cityImage`) {
                                    return (
                                      <div
                                        key={elm.imageId}
                                        id={elm.imageId}
                                        style={{
                                          display: "flex",
                                          margin: "0 5px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Avatar
                                          src={baseUrlImage + imagePath + elm.imageName}
                                        />
                                        <Button
                                          style={{
                                            color: "white",
                                            cursor: "pointer",
                                            backgroundColor: "black",
                                            boxShadow: "none",
                                            width: "20px",
                                            height: "20px",
                                            padding: "0",
                                          }}
                                          onClick={() =>
                                            deleteImageFunction(
                                              elm._id,
                                              elm.imageId
                                            )
                                          }
                                          disabled={deleteImageLoading}
                                        >
                                          X
                                        </Button>
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Banner Image
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="file"
                                name="images"
                                onChange={(e) => setBannerImage(e.target.files)}
                                accept="image/*"
                              />
                            </FormGroup>
                            {data.cityImages?.length > 0 &&
                              data.cityImages.map((elm, ind) => {
                                if (elm.imageType === `bannerImage`) {
                                  return (
                                    <div
                                      id={elm.imageId}
                                      style={{
                                        display: "flex",
                                        margin: "0 5px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Avatar
                                        src={baseUrlImage + imagePath+ elm.imageName}
                                      />
                                      <Button
                                        style={{
                                          color: "white",
                                          cursor: "pointer",
                                          backgroundColor: "black",
                                          boxShadow: "none",
                                          padding: "0",
                                          height: "20px",
                                          width: "20px",
                                        }}
                                        onClick={() =>
                                          deleteImageFunction(
                                            elm._id,
                                            elm.imageId
                                          )
                                        }
                                        disabled={deleteImageLoading}
                                      >
                                        X
                                      </Button>
                                    </div>
                                  );
                                }
                              })}
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label className="form-control-label">
                                is favourite
                              </label>
                              <Select
                                value={{
                                  label: JSON.stringify(favourite),
                                  value: favourite,
                                }}
                                onChange={(elm) => setFavourite(elm.value)}
                                options={[
                                  { value: true, label: "true" },
                                  { value: false, label: "false" },
                                ]}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                description
                              </label>
                              <Editor
                                editorState={description}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                  inline: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: { inDropdown: true },
                                  image: {
                                    uploadCallback: uploadImageCallBack,
                                    alt: { present: true, mandatory: true },
                                    previewImage: true,
                                  },
                                }}
                              />{" "}
                            </FormGroup>{" "}
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isLoading} onClick={formSubmitted}>
            Edit City
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
        {modal3 && (
          <Modal isOpen={modal3} toggle={() => setModal3(false)}>
            <ModalBody>
              <Container>
                <Row>
                  <Col className="order-xl-1" lg="12" xl="12">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">{"Sights"}</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Sight Name
                            </label>
                            <InputGroup>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                name="sightName"
                                placeholder="name"
                                value={sightName}
                                onChange={(e) => setSightName(e.target.value)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Description
                            </label>
                            <Editor
                              editorState={sightDescription}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(e) =>
                                setSightDescription(e)
                              }
                              toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: {
                                  uploadCallback: uploadImageCallBack,
                                  alt: { present: true, mandatory: true },
                                  previewImage: true,
                                },
                              }}
                            />{" "}
                          </FormGroup>{" "}
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Image
                            </label>
                            <Input
                              className="form-control-alternative"
                              type="file"
                              name="sightImage"
                              onChange={(e) => setSightFile(e.target.files)}
                              accept="image/*"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <Button
                              color="primary"
                              disabled={
                                sightName === "" ||
                                sightDescription === "" ||
                                sightFile.length === 0
                              }
                              onClick={(e) => {
                                return (
                                  e.preventDefault(),
                                  setSight((old) => [
                                    ...old,
                                    {
                                      header: sightName,
                                      description: draftToHtml(
                                        convertToRaw(
                                          sightDescription.getCurrentContent()
                                        )
                                      ).toString(),
                                      image: sightFile[0],
                                    },
                                  ]),
                                  setSightName(""),
                                  setSightDescription(""),
                                  setSightFile([])
                                );
                              }}
                            >
                              Add
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          {sight.length > 0 &&
                            sight.map((e, i) => {
                              return (
                                <div>
                                  <b>{(e.image && e.image.name) || e.image}</b>,
                                  {e.header}
                                  <i
                                    style={{ cursor: "pointer", color: "red" }}
                                    onClick={() =>
                                      setSight(
                                        sight.filter((element) => element !== e)
                                      )
                                    }
                                  >
                                    X
                                  </i>
                                  {data.cityImages?.length > 0 &&
                                    data.cityImages.map((elm, ind) => {
                                      if (elm.imageType === `sightImage${i}`) {
                                        return (
                                          <div
                                            id={elm.imageId}
                                            style={{
                                              display: "flex",
                                              margin: "0 5px",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Avatar
                                              src={baseUrlImage +imagePath+ elm.imageName}
                                            />
                                            <Button
                                              style={{
                                                color: "white",
                                                cursor: "pointer",
                                                backgroundColor: "black",
                                                boxShadow: "none",
                                                padding: "0",
                                                height: "20px",
                                                width: "20px",
                                              }}
                                              onClick={() =>
                                                deleteImageFunction(
                                                  elm._id,
                                                  elm.imageId,e
                                                )
                                              }
                                              disabled={deleteImageLoading}
                                            >
                                              X
                                            </Button>
                                          </div>
                                        );
                                      }
                                    })}
                                </div>
                              );
                            })}
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={sight.length === 0}
                onClick={() => {
                  return setModal3(false);
                }}
              >
                Save
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  return setModal3(false), setSight(data.sightSeeing);
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </Modal>
    </div>
  );
}

export default EditCity;
