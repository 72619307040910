import {
  ADD_AD_ERROR,
  ADD_AD_LOADING,
  ADD_AD_SUCCESS,
  ADD_HOME_SETTING_ERROR,
  ADD_HOME_SETTING_LOADING,
  ADD_HOME_SETTING_SUCCESS,
  ADD_LOCATION_ERROR,
  ADD_LOCATION_LOADING,
  ADD_LOCATION_SUCCESS,
  DELETE_AD_ERROR,
  DELETE_AD_LOADING,
  DELETE_AD_SUCCESS,
  DELETE_HOME_SETTING_ERROR,
  DELETE_HOME_SETTING_LOADING,
  DELETE_HOME_SETTING_SUCCESS,
  DELETE_LOCATION_ERROR,
  DELETE_LOCATION_LOADING,
  DELETE_LOCATION_SUCCESS,
  DESTROY_AD_ADD,
  DESTROY_AD_EDIT,
  DESTROY_ERROR_ADD_AD,
  DESTROY_ERROR_ADD_HOME,
  DESTROY_ERROR_ADD_LOCATION,
  DESTROY_ERROR_EDIT_AD,
  DESTROY_ERROR_EDIT_HOME,
  DESTROY_ERROR_EDIT_LOCATION,
  DESTROY_HOME_SETTING_ADD,
  DESTROY_HOME_SETTING_EDIT,
  DESTROY_LOCATION_ADD,
  DESTROY_LOCATION_EDIT,
  EDIT_AD_ERROR,
  EDIT_AD_LOADING,
  EDIT_AD_SUCCESS,
  EDIT_HOME_SETTING_ERROR,
  EDIT_HOME_SETTING_LOADING,
  EDIT_HOME_SETTING_SUCCESS,
  EDIT_LOCATION_ERROR,
  EDIT_LOCATION_LOADING,
  EDIT_LOCATION_SUCCESS,
  GET_AD_ERROR,
  GET_AD_LOADING,
  GET_AD_SUCCESS,
  GET_HOME_SETTING_ERROR,
  GET_HOME_SETTING_LOADING,
  GET_HOME_SETTING_SUCCESS,
  GET_LOCATION_ERROR,
  GET_LOCATION_LOADING,
  GET_LOCATION_SUCCESS,
} from "../constants";

const initialStateHome = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  settings: [],
};

const initialStateLocation = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  locations: [],
};
const initialStateAd = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  ads: [],
};

export function addHomeSetting(state = initialStateHome, action) {
  switch (action.type) {
    case ADD_HOME_SETTING_LOADING:
      return { ...state, isLoading: true };
    case ADD_HOME_SETTING_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_HOME_SETTING_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_HOME_SETTING_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_ERROR_ADD_HOME:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getHomeSettings(state = initialStateHome, action) {
  switch (action.type) {
    case GET_HOME_SETTING_LOADING:
      return {...state, isLoading: true, settings: [] };
    case GET_HOME_SETTING_SUCCESS:
      return {...state, isLoading: false, settings: action.payload };
    case GET_HOME_SETTING_ERROR:
      return { ...state,isLoading: false, settings: [], isError: action.payload };
    default:
      return state;
  }
}

export function editHomeSettings(state = initialStateHome, action) {
  switch (action.type) {
    case EDIT_HOME_SETTING_LOADING:
      return { ...state, isLoading: true };
    case EDIT_HOME_SETTING_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_HOME_SETTING_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_HOME_SETTING_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_ERROR_EDIT_HOME:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function deleteHomeSetting(state = initialStateHome, action) {
  switch (action.type) {
    case DELETE_HOME_SETTING_LOADING:
      return { ...state, isLoading: true };
    case DELETE_HOME_SETTING_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_HOME_SETTING_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}

export function addLocation(state = initialStateLocation, action) {
  switch (action.type) {
    case ADD_LOCATION_LOADING:
      return { ...state, isLoading: true };
    case ADD_LOCATION_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_LOCATION_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_LOCATION_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_ERROR_ADD_LOCATION:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getLocation(state = initialStateLocation, action) {
  switch (action.type) {
    case GET_LOCATION_LOADING:
      return {...state, isLoading: true, locations: [] };
    case GET_LOCATION_SUCCESS:
      return {...state, isLoading: false, locations: action.payload };
    case GET_LOCATION_ERROR:
      return {...state, isLoading: false, locations: [], isError: action.payload };
    default:
      return state;
  }
}

export function editLocation(state = initialStateLocation, action) {
  switch (action.type) {
    case EDIT_LOCATION_LOADING:
      return { ...state, isLoading: true };
    case EDIT_LOCATION_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_LOCATION_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_LOCATION_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_ERROR_EDIT_LOCATION:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function deleteLocation(state = initialStateLocation, action) {
  switch (action.type) {
    case DELETE_LOCATION_LOADING:
      return { ...state, isLoading: true };
    case DELETE_LOCATION_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_LOCATION_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_LOCATION":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}


export function addAd(state = initialStateAd, action) {
  switch (action.type) {
    case ADD_AD_LOADING:
      return { ...state, isLoading: true };
    case ADD_AD_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_AD_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_AD_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_ERROR_ADD_AD:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getAd(state = initialStateAd, action) {
  switch (action.type) {
    case GET_AD_LOADING:
      return {...state, isLoading: true, ads: [] };
    case GET_AD_SUCCESS:
      return {...state, isLoading: false, ads: action.payload };
    case GET_AD_ERROR:
      return {...state, isLoading: false, ads: [], isError: action.payload };
    default:
      return state;
  }
}

export function editAd(state = initialStateAd, action) {
  switch (action.type) {
    case EDIT_AD_LOADING:
      return { ...state, isLoading: true };
    case EDIT_AD_SUCCESS:
      return { ...state, isLoading: false, isEdited: action.payload };
    case EDIT_AD_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_AD_EDIT:
      return { ...state, isEdited: action.payload };
    case DESTROY_ERROR_EDIT_AD:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function deleteAd(state = initialStateAd, action) {
  switch (action.type) {
    case DELETE_AD_LOADING:
      return { ...state, isLoading: true };
    case DELETE_AD_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_AD_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_ERROR_AD":
      return { ...state, isDeleted: action.payload, isError: action.payload };
    default:
      return state;
  }
}

