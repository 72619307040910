import {
  ADD_FAVPURITE_PACKAGE_ERROR,
  ADD_FAVPURITE_PACKAGE_LOADING,
  ADD_FAVPURITE_PACKAGE_SUCCESS,
  DELETE_FAVPURITE_PACKAGE_ERROR,
  DELETE_FAVPURITE_PACKAGE_LOADING,
  DELETE_FAVPURITE_PACKAGE_SUCCESS,
  DESTROY_FAVPURITE_PACKAGE_ADD,
  DESTROY_FAVPURITE_PACKAGE_ERROR_ADD_C,
  GET_FAVPURITE_PACKAGE_ERROR,
  GET_FAVPURITE_PACKAGE_FILTER_SUCCESS,
  GET_FAVPURITE_PACKAGE_LOADING,
  GET_FAVPURITE_PACKAGE_SUCCESS,
  GET_PARTICULAR_FAVPURITE_PACKAGE_FILTER_SUCCESS,
} from "../constants";

const initialStateCategory = {
  isLoading: false,
  isError: "",
  isEdited: "",
  isAdded: "",
  isDeleted: "",
  favourite: [],
  filterFavourite: [],
  parFavourite: [],
};

export function addFavouritePackage(state = initialStateCategory, action) {
  switch (action.type) {
    case ADD_FAVPURITE_PACKAGE_LOADING:
      return { ...state, isLoading: true };
    case ADD_FAVPURITE_PACKAGE_SUCCESS:
      return { ...state, isLoading: false, isAdded: action.payload };
    case ADD_FAVPURITE_PACKAGE_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case DESTROY_FAVPURITE_PACKAGE_ADD:
      return { ...state, isAdded: action.payload };
    case DESTROY_FAVPURITE_PACKAGE_ERROR_ADD_C:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
}

export function getFavouritePackage(state = initialStateCategory, action) {
  switch (action.type) {
    case GET_FAVPURITE_PACKAGE_LOADING:
      return { ...state, isLoading: true };
    case GET_FAVPURITE_PACKAGE_SUCCESS:
      return { ...state, isLoading: false, favourite: action.payload };
    case GET_FAVPURITE_PACKAGE_FILTER_SUCCESS:
      return { ...state, isLoading: false, filterFavourite: action.payload };
    case GET_PARTICULAR_FAVPURITE_PACKAGE_FILTER_SUCCESS:
      return { ...state, isLoading: false, parFavourite: action.payload };
    case GET_FAVPURITE_PACKAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    default:
      return state;
  }
}

export function deleteFavouritePackage(state = initialStateCategory, action) {
  switch (action.type) {
    case DELETE_FAVPURITE_PACKAGE_LOADING:
      return { ...state, isLoading: true };
    case DELETE_FAVPURITE_PACKAGE_SUCCESS:
      return { ...state, isLoading: false, isDeleted: action.payload };
    case DELETE_FAVPURITE_PACKAGE_ERROR:
      return { ...state, isLoading: false, isError: action.payload };
    case "DESTROY_DELETE_FAVPURITE_PACKAGE_ERROR_C":
      return { ...state, isError: action.payload, isDeleted: action.payload };
    default:
      return state;
  }
}
