import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import HeaderHome from "../../common/headers/HeaderHome";
import { getBlogs, getCategory } from "../../redux/actions/blog";
import { getCities, getPackage } from "../../redux/actions/package";
import { filterSettingPage } from "../../redux/actions/settings";
import { baseUrl, imagePath, baseUrlImage } from "../../redux/url";
import GetLength from "./GetLength";
import GetLengthBlog from "./GetLengthBlog";

function MainScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading: cityLoading, cities, isError: cityError } = useSelector(
    (state) => state.getCity
  );
  const { isLoading: loading, settings, isError: error } = useSelector(
    (state) => state.getHomeSetting
  );
  const { isLoading, isError } = useSelector((state) => state.getBlog);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPackage());
    dispatch(
      getCities({
        skip: 0,
        limit: 4,
      })
    );
    dispatch(
      getBlogs({
        skip: 0,
        limit: 3,
      })
    );
    dispatch(getCategory());
    dispatch(
      filterSettingPage({
        key: "Home",
        skip: 0,
        limit: 1,
      })
    );
    return () => {};
  }, []);
  return (
    <>
      {error ? (
        <>
          <HeaderHome />
        </>
      ) : loading ? (
        <>
          <HeaderHome />
        </>
      ) : settings.success == false ? (
        <>
          <HeaderHome />
        </>
      ) : (
        settings.length > 0 &&
        (
        (
          <HeaderHome
            url={baseUrlImage + imagePath + settings[0].headerImage[0]}
            content={`<h1>${settings[0].title}</h1><p>${settings[0].description}</p>`}
            name={settings[0].page}
            icons={settings[0].socialIcons}
          />
        ))
      )}
      {/* <!----Most-popular---> */}
      <div className="most-popular w-100">
        <div className="container">
          <div className="row">
            <h4 className="w-100 float-left heading">
              Hire an Expert to Craft your Trip
            </h4>
            <h2 className="w-100 float-left heading-second">
              <Link to="/city">Destinations Travellers love </Link>
            </h2>
            {cityError ? (
              <>{cityError}</>
            ) : cityLoading ? (
              <>Loading cities tours.....</>
            ) : (
              cities.map((e, i) => {
                return (
                  <div
                    className="col-xs-12 col-sm-6 col-md-6 col-lg-3"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(`/city/${e.cityName}/${e.cityId}`)
                    }
                  >
                    <div className="destini">
                      <img
                        alt="image"
                        src={baseUrlImage + imagePath + e.images[0]}
                        className="w-100"
                        style={{ height: "300px" }}
                      />
                      <GetLength name={e.cityName} />
                      <h3>{e.cityName}</h3>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      {/* <!----why choose-us---> */}
      <div className="choose w-100 background-color-#F9F9F9">
        <div className="container">
          <div className="row">
            <h4 className="w-100 float-left heading">Why</h4>
            <h2 className="w-100 float-left heading-second">Choose Us ?</h2>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <div className="box-content">
                <img alt="image" src="/images/icon1.png" />
                <h3 className="w-100 text-align-left">Fully Hosted</h3>
                <p className=" w-100 text-align-left">
                  Let a local enrich your discovery of what a Place has to
                  offer. Explore Our All Inclusive Packages.
                </p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <div className="box-content">
                <img alt="image" src="/images/icon2.png" />
                <h3 className="w-100 text-align-left">Quality First.</h3>
                <p className=" w-100 text-align-left">
                  Quality Has Always Been Our First Priority. Skilled travel
                  professionals for providing reliable, personalized and
                  professional services.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <div className="box-content">
                <img alt="image" src="/images/icon3.png" />
                <h3 className="w-100 text-align-left">Travel Tailors</h3>
                <p className=" w-100 text-align-left">
                  Why Take Common Tours When You Can Have Your own. Let Us Be
                  Your Travel Tailor For An Life Time Experience Tailored Just
                  For You.
                </p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
              <div className="box-content">
                <img alt="image" src="/images/icon4.png" />
                <h3 className="w-100 text-align-left">Guaranteed Pricing</h3>
                <p className=" w-100 text-align-left">
                  When You Want The Highest Quality & Reasonable Prices, Look No
                  Further , Get In Touch With Us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--fav-desti--> */}
      <div className="fav-desti w-100">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
              <div className="fav-cont">
                <h4 className="w-100 float-left heading">Travel</h4>
                <h2 className="w-100 float- left heading-second">
                  <Link to="/blog">Blogs</Link>
                </h2>
                <p className="w-100 float-left">
                  Top destinations in the world. Best places to travel in 2021.
                  Selected destinations have just competed for the prestigious
                  title of Best Destinations 2021.
                </p>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
              <ul className="info">
                {isError ? (
                  <>{isError}</>
                ) : isLoading ? (
                  <>{"Loading Blogs...."}</>
                ) : (
                  cities.length > 0 &&
                  cities.map((e, i) => {
                    return (
                      <li
                        onClick={() =>
                          history.push(`/blog/${e.cityName}/${e.cityId}`)
                        }
                      >
                        <h3>
                          {e.cityName}
                          <span>
                            <h5 style={{ color: "white" }}>
                              {<GetLengthBlog name={e.cityName} />}
                            </h5>
                          </span>
                        </h3>
                        <img
                          alt="image"
                          src={baseUrlImage + imagePath + e.images[0]}
                        />
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!---activities---> */}
      <div className="tour w-100">
        <div className="container">
          <div className="row">
            <h4 className="w-100 float-left heading">Activities </h4>
            <h2 className="w-100 float-left heading-second">
              <Link to="/tours">& Tours</Link>{" "}
            </h2>
            {cityError ? (
              <>{cityError}</>
            ) : cityLoading ? (
              <>Loading cities tours.....</>
            ) : (
              cities.map((e, i) => {
                return (
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div className="destini">
                      <img
                        alt="image"
                        style={{ height: "300px" }}
                        src={baseUrlImage + imagePath + e.images[0]}
                        className="w-100"
                      />
                      <h5>
                        {e.sightSeeing.length > 0
                          ? e.sightSeeing[0].header
                          : "Some Activity"}
                      </h5>
                      <h3>{e.cityName}</h3>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MainScreen;
