import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add,
  Cancel,
  CheckCircle,
  Delete,
  Edit,
  ExpandMore,
  MoreVert,
  Publish,
} from "@material-ui/icons";
import { AvatarGroup, Pagination } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import {
  deletePackage,
  destroyDeleteErrorPackage,
  filterPackage,
  getCities,
  getHotel,
  getPackage,
  getPackageCategories,
} from "../../../redux/actions/package";
import {
  baseUrl,
  baseUrlImage,
  getPackageEndpoint,
  imagePath,
} from "../../../redux/url";
import AddPackage from "./AddPackage";
import EditPackage from "./EditPackage";
import ImportPackage from "./ImportPackage";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { getInclusions } from "../../../redux/actions/inclusions";
import { getExclusions } from "../../../redux/actions/exclusion";
import { getCompany } from "../../../redux/actions/company";
import { getTransport, getVehicleType } from "../../../redux/actions/transport";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function Package() {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [open, setopen] = useState(false);
  const [open3, setopen3] = useState(false);
  const [open1, setopen1] = useState(false);
  const [open2, setopen2] = useState(false);
  const [info, setInfo] = useState({});
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [modal3, setModal3] = useState(false);
  const [deleted, setDeleted] = useState("");
  const [keyWord, setKeyWord] = useState("");
  const [totalLength, setTotalLength] = useState(1);
  const dispatch = useDispatch();
  const { isLoading, isError, packages } = useSelector(
    (state) => state.getPackage
  );
  const { isDeleted, isError: deleteError } = useSelector(
    (state) => state.deleteP
  );
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { inclusions } = useSelector((state) => state.getInclusion);
  const { exclusions } = useSelector((state) => state.getExclusion);
  const { isEdited } = useSelector((state) => state.editPackage);
  const { isAdded } = useSelector((state) => state.package);
  const { companies } = useSelector((state) => state.getCompany);
  const { packageCategory } = useSelector((state) => state.getPackageCategory);
  const { hotels } = useSelector((state) => state.getHotel);
  const { transport } = useSelector((state) => state.getTransport);
  const { vehicleType } = useSelector((state) => state.getVehicleType);
  const { cities } = useSelector((state) => state.getCity);
  const [itineraryData, setItineraryData] = useState([]);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl(null);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.paddingRight = "0";
  };
  const deletePackageFunction = (id) => {
    dispatch(deletePackage({ packageId: id }));
  };
  const editClickedFunction = (data) => {
    setInfo(data);
    setModal1(true);
    window.scrollTo(0, 0);
  };
  const searchChangeHandler = (e) => {
    setKeyWord(e.target.value);
    if (e.target.value === "") {
      dispatch(
        getPackage({
          skip: page * count - count,
          limit: count,
        })
      );
    } else {
      setPage(1);
      dispatch(
        filterPackage({
          key: e.target.value,
          skip: 0,
          limit: count,
        })
      );
    }
  };
  const paginationHandler = (e, v) => {
    setPage(v);
    if (keyWord === "") {
      dispatch(
        getPackage({
          skip: v * count - count,
          limit: count,
        })
      );
    } else {
      dispatch(
        filterPackage({
          key: keyWord,
          skip: v * count - count,
          limit: count,
        })
      );
    }
  };
  const viewItinerary = (info) => {
    setItineraryData(info);
    setModal3(true);
  };
  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      localStorage.getItem("accessToken") !== ""
    ) {
    } else {
      history.push("/login");
    }
    axios.get(baseUrl + getPackageEndpoint).then((result) => {
      setTotalLength(result.data.length);
    });
    if (isDeleted && isDeleted !== "") {
      setDeleted(isDeleted);
      setopen1(true);
    }
    if (deleteError && deleteError !== "") {
      setDeleted(deleteError);
      setopen1(true);
    }
    dispatch(
      getPackage({
        skip: page * count - count,
        limit: count,
      })
    );
    dispatch(getInclusions());
    dispatch(getExclusions());
    dispatch(getCompany());
    dispatch(getPackageCategories());
    dispatch(getHotel());
    dispatch(getTransport());
    dispatch(getVehicleType());
    dispatch(getCities());
    return () => {
      return dispatch(destroyDeleteErrorPackage());
    };
  }, [isAdded, isDeleted, isEdited, modal2]);
  const handleClose = () => {
    setopen(false);
  };
  const handleClose1 = () => {
    setopen1(false);
  };
  const handleClose2 = () => {
    setopen2(false);
  };
  const handleClose3 = () => {
    setopen3(false);
  };
  return (
    <>
      {!modal && !modal1 && (
        <Container className="top-100">
          <Row>
            <Col className="order-xl-1" lg="12" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col sm="12" lg="4">
                      <h3 className="mb-0">Packages</h3>
                    </Col>
                    <Col sm="12" lg="4">
                      <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                          <TextField
                            id="input-with-icon-grid"
                            label="Search Package"
                            onChange={searchChangeHandler}
                          />
                        </Grid>
                      </Grid>
                    </Col>
                    <Col className="text-right" sm="12" lg="4">
                      <Button
                        color="primary"
                        onClick={() => {
                          return handleClose4(), setModal(true);
                        }}
                        size="sm"
                      >
                        Add Package
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => {
                          return handleClose4(), setModal2(true), setPage(1);
                        }}
                        size="sm"
                      >
                        Import Package
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {isError ? (
                    <>{isError}</>
                  ) : isLoading ? (
                    <>
                      <LinearProgress />
                    </>
                  ) : packages.success === false ? (
                    <>
                      <Alert severity="error">{packages.message}</Alert>
                    </>
                  ) : (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Package Name</th>
                          <th scope="col">Company Id</th>
                          <th scope="col">Package Category</th>
                          <th scope="col">City Name</th>
                          <th scope="col">No. Of Nights</th>
                          <th scope="col">Inclusions</th>
                          <th scope="col">Exclusions</th>
                          <th scope="col">Price (&#8377;)</th>
                          <th scope="col">Itinerary</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packages.length > 0 &&
                          packages.map((row, i) => {
                            return (
                              <tr>
                                <th scope="row">{row.packageName}</th>
                                <th>
                                  {companies.length > 0 &&
                                    companies.map((elm, ind) => {
                                      if (elm.companyId === row.companyId) {
                                        return elm.companyName;
                                      }
                                    })}
                                </th>
                                <td>
                                  {packageCategory.length > 0 &&
                                    packageCategory.map((elm, ind) => {
                                      if (
                                        elm.packageCategoryId ===
                                        row.packageCategory
                                      ) {
                                        return elm.packageCategory;
                                      }
                                    })}
                                </td>
                                <td>
                                  {cities.length > 0 &&
                                    cities.map((elm, ind) => {
                                      if (row.cityName === elm.cityId) {
                                        return elm.cityName;
                                      }
                                    })}
                                </td>
                                <td>{row.numberOfNights}</td>
                                <td>
                                  {row.inclusions[0].split(",").map((e, i) => {
                                    const isFound = inclusions.filter(
                                      (elm, ind) => {
                                        return elm.inclusionName == e;
                                      }
                                    );
                                    if (isFound.length > 0) {
                                      return (
                                        <>
                                          <i
                                            class={`fa fa-${isFound[0].inclusionIcon}`}
                                          >
                                            &nbsp;
                                          </i>
                                          {(" ", isFound[0].inclusionName)},
                                        </>
                                      );
                                    } else {
                                      return <>{(" ", e)},</>;
                                    }
                                  })}
                                  {row.otherInclusions[0]
                                    .split(",")
                                    .map((e, i) => {
                                      const isFound = inclusions.filter(
                                        (elm, ind) => {
                                          return elm.inclusionName == e;
                                        }
                                      );
                                      if (isFound.length > 0) {
                                        return (
                                          <>
                                            <i
                                              class={`fa fa-${isFound[0].inclusionIcon}`}
                                            >
                                              &nbsp;
                                            </i>
                                            {(" ", isFound[0].inclusionName)},
                                          </>
                                        );
                                      } else {
                                        return <>{(" ", e)},</>;
                                      }
                                    })}
                                </td>
                                <td>
                                  {row.exclusions[0].split(",").map((e, i) => {
                                    const isFound = exclusions.filter(
                                      (elm, ind) => {
                                        return elm.exclusionName == e;
                                      }
                                    );
                                    if (isFound.length > 0) {
                                      return (
                                        <>
                                          <i
                                            class={`fa fa-${isFound[0].exclusionIcon}`}
                                          >
                                            &nbsp;
                                          </i>
                                          {(" ", isFound[0].exclusionName)},
                                        </>
                                      );
                                    } else {
                                      return <>{(" ", e)},</>;
                                    }
                                  })}
                                  {row.otherExclusions[0]
                                    .split(",")
                                    .map((e, i) => {
                                      var founded = false;
                                      const isFound = exclusions.filter(
                                        (elm, ind) => {
                                          return elm.exclusionName == e;
                                        }
                                      );
                                      if (isFound.length > 0) {
                                        return (
                                          <>
                                            <i
                                              class={`fa fa-${isFound[0].exclusionIcon}`}
                                            >
                                              &nbsp;
                                            </i>
                                            {(" ", isFound[0].exclusionName)},
                                          </>
                                        );
                                      } else {
                                        return <>{(" ", e)},</>;
                                      }
                                    })}
                                </td>
                                <td>{row.packagePrice}</td>
                                <td>
                                  <Button
                                    variant="contained"
                                    onClick={() => viewItinerary(row.itinerary)}
                                  >
                                    View
                                  </Button>
                                </td>
                                <td>
                                  {" "}
                                  <Edit
                                    style={{ cursor: "pointer" }}
                                    onClick={() => editClickedFunction(row)}
                                  />{" "}
                                </td>
                                <td>
                                  <Delete
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      deletePackageFunction(row._id)
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="float-right m-3">
                <Pagination
                  count={Math.ceil(totalLength / count)}
                  page={page}
                  onChange={paginationHandler}
                />
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {modal1 && (
        <EditPackage
          modal={modal1}
          setModal={setModal1}
          info={info}
          setopen={setopen2}
        />
      )}
      {modal && (
        <AddPackage modal={modal} setModal={setModal} setOpen={setopen} />
      )}
      <ImportPackage modal={modal2} setModal={setModal2} setOpen={setopen3} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          "Saved Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
        <Alert onClose={handleClose3} severity="success">
          "Saved Successfully"
        </Alert>
      </Snackbar>
      <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1}>
        <Alert onClose={handleClose1} severity="error">
          {deleted}
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
        <Alert onClose={handleClose2} severity="success">
          Edited Successfully
        </Alert>
      </Snackbar>

      {modal3 && (
        <Modal isOpen={modal3} toggle={() => setModal3(false)}>
          <ModalBody>
            <Container>
              <Row>
                <Col className="order-xl-1" lg="12" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">{"Itinerary"}</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {itineraryData.length > 0 ? (
                        itineraryData.map((e, i) => {
                          return (
                            <>
                              <Accordion
                                expanded={expanded === `panel${i}`}
                                onChange={handleChange(`panel${i}`)}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                >
                                  <Typography className={classes.heading}>
                                    Day {e.day}
                                  </Typography>
                                  <Typography
                                    className={classes.secondaryHeading}
                                  >
                                    {e.heading}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="itinerary-info">
                                    <b>{e.description}</b>
                                    <br />
                                    <i>
                                      <u>{e.cityName}</u>
                                    </i>
                                    <br />
                                    <br />
                                    <div className="itinerary-type">
                                      {e.type &&
                                        e.type.length > 0 &&
                                        e.type.map((elm, ind) => {
                                          if (elm.name === "Flight") {
                                            return (
                                              <>
                                                <b>{elm.name}</b>
                                                <br />
                                                <div>
                                                  <b>From:</b> {elm.fromCity}{" "}
                                                  <b>at</b> {elm.departureTime}
                                                </div>
                                                <div>
                                                  <b>To:</b> {elm.toCity}{" "}
                                                  <b>at</b> {elm.arrivalTime}
                                                </div>
                                                <div>
                                                  <b>Baggage allowed</b>
                                                  <br />
                                                  <b>Cabin-</b>{" "}
                                                  {elm.cabinBggage}kg &nbsp;
                                                  <b>checkin-</b>{" "}
                                                  {elm.checkBaggage}kg
                                                </div>
                                              </>
                                            );
                                          }
                                          if (elm.name === "Transport") {
                                            return (
                                              <div>
                                                <b>{elm.name}:</b>
                                                <br />
                                                <li>
                                                  To City:
                                                  {cities.length > 0 &&
                                                    cities.map(
                                                      (item, place) => {
                                                        if (
                                                          item.cityId ===
                                                          elm.toCity
                                                        ) {
                                                          return item.cityName;
                                                        }
                                                      }
                                                    )}
                                                </li>
                                                <li>
                                                  Type of Transport:{" "}
                                                  {transport.length > 0 &&
                                                    transport.map(
                                                      (item, place) => {
                                                        if (
                                                          item.transportId ===
                                                          elm.typeTransport
                                                        ) {
                                                          return item.transportType;
                                                        }
                                                      }
                                                    )}
                                                </li>
                                                <li>
                                                  Vehicle Type:{" "}
                                                  {vehicleType.length > 0 &&
                                                    vehicleType.map(
                                                      (item, place) => {
                                                        if (
                                                          item.vehicleId ===
                                                          elm.vehicleType
                                                        ) {
                                                          return item.vehicleType;
                                                        }
                                                      }
                                                    )}
                                                </li>
                                              </div>
                                            );
                                          }
                                          if (elm.name === "Note") {
                                            return (
                                              <div>
                                                <b>{elm.name}</b>
                                                <br />
                                                <li>{elm.nodeDescription}</li>
                                              </div>
                                            );
                                          }
                                          if (elm.name === "Sight Seeing") {
                                            return (
                                              <div>
                                                <b>{elm.name}:</b>
                                                <br />
                                                {elm.sightSeeing}
                                              </div>
                                            );
                                          }
                                          if (elm.name === "Hotel") {
                                            return (
                                              <div>
                                                <b>{elm.name}:</b>
                                                <br />
                                                <li>
                                                  {hotels.length > 0 &&
                                                    hotels.map(
                                                      (item, place) => {
                                                        if (
                                                          item.hotelId ===
                                                          elm.hotelName
                                                        ) {
                                                          return item.hotelName;
                                                        }
                                                      }
                                                    )}
                                                </li>
                                              </div>
                                            );
                                          }
                                          if (elm.name === "End of the day") {
                                            return (
                                              <div>
                                                <b>{elm.name}:</b>
                                                <br />
                                                <li>{elm.endDetail}</li>
                                              </div>
                                            );
                                          }
                                        })}
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          );
                        })
                      ) : (
                        <>No Itinerary Found</>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal3(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default Package;
